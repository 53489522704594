import { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useParams, useOutletContext, useNavigate } from 'react-router-dom';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';

import AddButton from '../../../components/buttons/AddButton';
import {
  deleteSettlementRecord,
  getSettlementsCheck,
  paySettlement,
} from '../../../api/paymentsApi/settlementsApi';
import CommonButton from '../../../components/buttons/CommonButton';
import FormLinkButton from '../../../components/buttons/FormLinkButton';
import FormCopyTextField from '../../../components/formFields/FormCopyTextField';
import FormTextField from '../../../components/formFields/FormTextField';
import FormToggleField from '../../../components/formFields/FormToggleField';
import FormAutocompleteField from '../../../components/formFields/FormAutocompleteField';
import FormCancelButton from '../../../components/buttons/FormCancelButton';
import usePageTitle from '../../../hooks/usePageTitle';
import useSnackBar from '../../../hooks/useSnackBar';
import { settlementsEditFormSchema } from '../../../helpers/formSchemas/formSchemas';
import { SETTLEMENTS_EDIT_FORM_DEFAULT_VALUES } from '../../../helpers/formSchemas/formDefaultValues';
import { numberToUSDCurrency } from '../../../helpers/numberToUSDCurrency';
import TooltipComponent from '../../../components/TooltipComponent';
import FormSubmitButton from '../../../components/buttons/FormSubmitButton';
import { printCheck } from '../../../helpers/printCheck';
import { printCheckInlineStyles } from '../../../helpers/print-check-inline-styles';
import { EntityFlexibleRecord, ErrorData } from '../../../types';
import Popup from '../../../components/Popup';
import { POPUP_DESCRIPTIONS } from '../../../constants/popupDescriptions';
import VerificationPopup from '../../../components/VerificationPopup';
import { checkAdminPin } from '../../../api/adminsApi/adminsApi';
import { SettlementAdditionalInfoData, SettlementsPayoutCheckData } from '../types';
import { handleGetDefendants, mapDefendantsQueryResults } from '../helpers';
import { FieldHeadline } from '../styled';

import SettlementsPayoutCheck from './SettlementsPayoutCheck';
import { ReactComponent as InfoIcon } from './info.svg';

const SettlementsEditFormContainer = () => {
  const { settlementId } = useParams();
  const { entityData } = useOutletContext<EntityFlexibleRecord>();
  const { setPageTitle } = usePageTitle();
  const theme = useTheme();
  const navigate = useNavigate();
  const { setAlert } = useSnackBar();

  const userId = localStorage.getItem('userId');
  const isSuperAdmin = localStorage.getItem('isSuperAdmin');

  const [firmIdValue, setFirmIdValue] = useState<string>('');
  const [payoutCheck, setPayoutCheck] = useState<SettlementsPayoutCheckData>();
  const [isPayoutCheckUpdating, setIsPayoutCheckUpdating] = useState<boolean>(true);
  const [settledAmount, setSettledAmount] = useState<string>('');
  const [additionalSettlementInfo, setAdditionalSettlementInfo] =
    useState<SettlementAdditionalInfoData>();
  const [currentCaseId, setCurrentCaseId] = useState<string>('');
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);
  const [isPaymentWarningPopupOpen, setIsPaymentWarningPopupOpen] = useState<boolean>(false);
  const [isVerificationPopupOpen, setIsVerificationPopupOpen] = useState<boolean>(false);
  const [isConfirmPaymentPopupOpen, setIsConfirmPaymentPopupOpen] = useState<boolean>(false);
  const [pin, setPin] = useState<string>('');

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(settlementsEditFormSchema),
    defaultValues: SETTLEMENTS_EDIT_FORM_DEFAULT_VALUES,
  });

  const {
    reset,
    watch,
    control,
    formState: { isDirty },
  } = formMethods;

  const defendantIdValue = watch('defendant.id');
  const deductionsFields = watch('deduction');
  const plaintiffIdValue = watch('plaintiff.id');

  const { fields } = useFieldArray({
    name: 'deduction',
    control,
  });

  const handleChangeAddToggle = () => {
    const mappedDeductions = deductionsFields
      ?.filter((deduction) => deduction?.isAdded)
      .map((deduction) => deduction?.id);

    handleCalculatePayoutInfo(mappedDeductions as number[]);
  };

  const handleCalculatePayoutInfo = (includedDeductions: number[]) => {
    setIsPayoutCheckUpdating(true);

    getSettlementsCheck(settlementId as string, includedDeductions)
      .then((res) => {
        if (res.data) {
          setPayoutCheck(res.data);

          setTimeout(() => {
            setIsPayoutCheckUpdating(false);
            setAlert({
              type: 'success',
              message: 'Settlement details updated successfully.',
            });
          }, 500);
        }
      })
      .catch((err) => {
        setIsPayoutCheckUpdating(false);
        setAlert({
          message: err.response.data.message,
          type: 'error',
        });
      });
  };

  const handleDeleteSettlement = () => {
    deleteSettlementRecord(settlementId as string)
      .then(() => {
        setAlert((prev) => ({
          ...prev,
          message: 'The settlement deleted successfully.',
          type: 'success',
        }));
        navigate('/settlements');
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
        handleCloseDeletePopup();
      });
  };

  const handleProcessSettlement = () => {
    handleCloseConfirmPaymentPopup();

    const mappedDeductions = deductionsFields
      ?.filter((deduction) => deduction?.isAdded)
      .map((deduction) => deduction?.id);

    paySettlement(settlementId as string, pin, mappedDeductions as number[])
      .then((res) => {
        if (res.status === 202) {
          setPin('');
          setAlert((prev) => ({
            ...prev,
            message: 'Settlement processed successfully.',
            type: 'success',
          }));
          setTimeout(() => window.location.reload(), 500);
        }
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => {
    reset();
    handleCalculatePayoutInfo([]);
  };

  const handleCloseDeletePopup = () => setIsDeletePopupOpen(false);
  const handleClosePaymentWarningPopup = () => setIsPaymentWarningPopupOpen(false);
  const handleCloseVerificationPopup = () => setIsVerificationPopupOpen(false);
  const handleCloseConfirmPaymentPopup = () => setIsConfirmPaymentPopupOpen(false);
  const handleOpenDeletePopup = () => setIsDeletePopupOpen(true);
  const handleOpenPaymentWarningPopup = () => setIsPaymentWarningPopupOpen(true);
  const handleOpenVerificationPopup = () => setIsVerificationPopupOpen(true);
  const handleOpenConfirmPaymentPopup = () => setIsConfirmPaymentPopupOpen(true);

  const handleConfirmPin = (pin: string) => {
    if (!userId) return;

    checkAdminPin(Number(userId), pin)
      .then((response) => {
        if (response.data.isValid) {
          handleCloseVerificationPopup();
          handleOpenConfirmPaymentPopup();
          setPin(pin);
        } else {
          setAlert((prev) => ({
            ...prev,
            message: 'Invalid pin. Please try again.',
            type: 'error',
          }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      });
  };

  const onProcessSettlementBtnClick = () => {
    entityData?.status === 'Draft' && handleOpenPaymentWarningPopup();
  };

  useEffect(() => {
    if (entityData) {
      setFirmIdValue(entityData.firmId as string);
      setSettledAmount(entityData.settledAmount as string);
      setCurrentCaseId(entityData.caseId as string);
      setPageTitle && setPageTitle('Settlement');

      reset({
        ...entityData,
      });
    }

    return () => setPageTitle && setPageTitle('');
  }, [entityData]);

  return (
    <>
      <Box display="flex" bgcolor={theme.palette.primary.dark} width="100%">
        <FormProvider {...formMethods}>
          <form style={{ width: '100%', padding: '20px' }}>
            <Box display="flex" width="100%" gap="32px">
              <Box display="flex" flexDirection="column" width="100%" maxWidth="300px">
                <FieldHeadline>Identity</FieldHeadline>
                <Box mt="10px">
                  <FormCopyTextField entityName="Settlement" name="id" label="Id" isReadOnly />
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt="20px">
                  <FieldHeadline>Case</FieldHeadline>
                  <FormLinkButton path={`/cases/${currentCaseId}/home`} />
                </Box>
                <Box mt="10px">
                  <FormTextField name="caseReferenceId" label="Reference Id" readOnly isReadOnly />
                </Box>
                <Box mt="20px">
                  <FormTextField name="caseSystemName" label="System Name" readOnly isReadOnly />
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt="50px">
                  <FieldHeadline>Firm</FieldHeadline>
                  <FormLinkButton path={`/firms/${firmIdValue}/home`} />
                </Box>
                <Box mt="10px">
                  <FormTextField name="firm" label="Firm" readOnly isReadOnly />
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt="50px">
                  <FieldHeadline>Client</FieldHeadline>
                  <FormLinkButton path={`/clients/${plaintiffIdValue}/home`} />
                </Box>
                <Box mt="10px">
                  <FormTextField name="plaintiff.fullName" label="Client" readOnly isReadOnly />
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center" mt="50px">
                  <FieldHeadline>Defendant</FieldHeadline>
                  <Box display="flex" gap="10px" alignItems="center">
                    <FormLinkButton
                      path={defendantIdValue ? `/defendants/${defendantIdValue}/home` : '#'}
                    />
                  </Box>
                </Box>
                <Box mt="10px">
                  <FormAutocompleteField
                    name="defendant"
                    label="Defendant *"
                    optionsQuery={handleGetDefendants}
                    mapQueryResults={mapDefendantsQueryResults}
                    disabled
                  />
                </Box>

                <Box mt="50px" display="flex" gap="4px" alignItems="center">
                  <FieldHeadline>Settled Amount</FieldHeadline>
                  {additionalSettlementInfo && (
                    <TooltipComponent
                      title={`This settled amount is ${
                        additionalSettlementInfo?.percentOfGrossSettlement
                      }% of the case total gross settlement, or ${
                        additionalSettlementInfo?.percentOfTotalGrossSettlement
                      }% of this defendant's gross settlement. Current unpaid gross settlement for this defendant is ${numberToUSDCurrency(
                        additionalSettlementInfo?.defendantUnpaidGrossSettlement,
                      )} `}
                      placement="right"
                    >
                      <IconButton
                        sx={{
                          padding: 0,
                        }}
                      >
                        <InfoIcon />
                      </IconButton>
                    </TooltipComponent>
                  )}
                </Box>

                <Typography mt="10px" ml="8px">
                  {numberToUSDCurrency(Number(settledAmount))}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" width="100%" maxWidth="600px">
                <Box
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="space-between"
                  width="100%"
                >
                  <FieldHeadline>Deductions</FieldHeadline>
                  {!!deductionsFields?.length && (
                    <FormLinkButton
                      path={
                        deductionsFields?.length
                          ? deductionsFields.length === 1
                            ? `/deductions/${deductionsFields[0]?.id}`
                            : '/deductions'
                          : '#'
                      }
                    />
                  )}
                </Box>

                {!!deductionsFields?.length &&
                  fields.map((field, idx) => (
                    <Box
                      display="flex"
                      alignItems="center"
                      gap="4px"
                      key={field.id}
                      mt="20px"
                      width="100%"
                    >
                      <FormTextField
                        name={`deduction.${idx}.createdAt`}
                        label="Entry Date"
                        readOnly
                        isReadOnly
                        maxWidth="145px"
                      />

                      <FormTextField
                        name={`deduction.${idx}.type`}
                        label="Type"
                        readOnly
                        isReadOnly
                        maxWidth="145px"
                      />

                      <FormTextField
                        name={`deduction.${idx}.amount`}
                        label="Amount"
                        readOnly
                        isReadOnly
                        maxWidth="145px"
                      />

                      <FormTextField
                        name={`deduction.${idx}.isAssigned`}
                        label="Assigned"
                        readOnly
                        isReadOnly
                        maxWidth="75px"
                      />

                      <FormToggleField
                        name={`deduction.${idx}.isAdded`}
                        label="Add"
                        customHandleChange={handleChangeAddToggle}
                        disabled={isPayoutCheckUpdating || payoutCheck === undefined}
                      />
                    </Box>
                  ))}
                <Box mt="20px">
                  <AddButton
                    disabled={entityData?.status === 'Paid'}
                    buttonText="create deduction"
                    path="/deductions/create-deduction"
                  />
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" width="100%" maxWidth="400px">
                <FieldHeadline>Actions</FieldHeadline>
                <Box mt="16px">
                  <CommonButton
                    disabled={isSuperAdmin === 'true' || payoutCheck === undefined}
                    type="warning"
                    buttonText="Pay Settlement"
                    buttonIcon={<RequestQuoteIcon />}
                    maxWidth="270px"
                    onButtonClick={onProcessSettlementBtnClick}
                  />
                </Box>
                <Box mt="16px">
                  <CommonButton
                    disabled={entityData?.status !== 'Draft'}
                    type="error"
                    buttonText="delete"
                    buttonIcon={<CancelIcon />}
                    onButtonClick={handleOpenDeletePopup}
                    maxWidth="270px"
                  />
                </Box>
                {entityData?.status === 'Draft' && (
                  <Box display="flex" justifyContent="space-between" mt="50px">
                    <FieldHeadline>Settlement Preview</FieldHeadline>

                    {payoutCheck !== undefined && (
                      <IconButton
                        onClick={() => printCheck(printCheckInlineStyles)}
                        disableRipple
                        disabled={isPayoutCheckUpdating}
                        sx={{
                          padding: 0,
                          color: 'white',

                          '&.Mui-disabled': {
                            color: 'rgba(255, 255, 255, 0.3)',
                          },
                        }}
                      >
                        <PrintIcon />
                      </IconButton>
                    )}
                  </Box>
                )}

                {entityData?.status === 'Draft' && (
                  <SettlementsPayoutCheck
                    setPayoutCheck={setPayoutCheck}
                    payoutCheck={payoutCheck}
                    isPayoutCheckUpdating={isPayoutCheckUpdating}
                    setAdditionalSettlementInfo={setAdditionalSettlementInfo}
                    leadFirmData={entityData?.firm}
                    secondaryFirmsData={entityData?.secondaryFirms}
                    caseSystemName={entityData?.caseSystemName}
                    setIsPayoutCheckUpdating={setIsPayoutCheckUpdating}
                    status={entityData?.status as string}
                  />
                )}
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" mt="40px">
              <FormSubmitButton
                disabled
                buttonIcon={<SaveIcon />}
                buttonText="Save"
                // TODO: onClick
                onClick={() => console.log('submitted')}
              />
              {isDirty && <FormCancelButton onClick={handleCancelChanges} />}
            </Box>
          </form>
        </FormProvider>
        <Popup
          isOpen={isDeletePopupOpen}
          headlineText={POPUP_DESCRIPTIONS.settlementDelete.headlineText}
          contentText={POPUP_DESCRIPTIONS.settlementDelete.contentText}
          onMainButtonClick={handleDeleteSettlement}
          onSecondaryButtonClick={handleCloseDeletePopup}
        />
        <Popup
          isOpen={isPaymentWarningPopupOpen}
          headlineText={POPUP_DESCRIPTIONS.paySettlement.headlineText}
          contentText={POPUP_DESCRIPTIONS.paySettlement.contentText}
          onMainButtonClick={() => {
            handleClosePaymentWarningPopup();
            handleOpenVerificationPopup();
          }}
          onSecondaryButtonClick={handleClosePaymentWarningPopup}
        />
        <VerificationPopup
          isOpen={isVerificationPopupOpen}
          onMainButtonClick={handleConfirmPin}
          onSecondaryButtonClick={handleCloseVerificationPopup}
        />
        <Popup
          isOpen={isConfirmPaymentPopupOpen}
          headlineText={POPUP_DESCRIPTIONS.paySettlement.headlineText}
          contentText={`Settled amount ${numberToUSDCurrency(settledAmount)}`}
          onMainButtonClick={handleProcessSettlement}
          onSecondaryButtonClick={() => {
            handleCloseConfirmPaymentPopup();
            setPin('');
          }}
        />
      </Box>
    </>
  );
};

export default SettlementsEditFormContainer;
