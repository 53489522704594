import { FieldHeadline } from '../../views/RequestsView/styled';
import { Box, IconButton, Link, useTheme } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ReactComponent as PdfImage } from './pdf.svg';
import { ReactComponent as DocusignImage } from './docusign.svg';
import { ReactComponent as CsvImage } from './csv.svg';

interface DocumentDownloaderProps {
  title?: string;
  url?: string;
  fileType?: 'pdf' | 'docusign' | 'csv';
  fileName?: string;
  onClick?: () => void;
}

const DocumentDownloader = ({
  title,
  url,
  fileType,
  fileName,
  onClick,
}: DocumentDownloaderProps) => {
  const theme = useTheme();

  if (!url && !onClick) return null;

  const renderImage = () => {
    switch (fileType) {
      case 'pdf':
        return <PdfImage />;
      case 'docusign':
        return <DocusignImage />;
      case 'csv':
        return <CsvImage />;
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <FieldHeadline>{title || 'Document File'}</FieldHeadline>
        <Link href={url} target="_blank" onClick={onClick}>
          <IconButton
            sx={{
              padding: 0,
              paddingBottom: '4px',
              color: theme.palette.primary.light,

              '&.Mui-disabled': {
                color: 'rgba(255, 255, 255, 0.12)',
              },
            }}
          >
            <FileDownloadIcon />
          </IconButton>
        </Link>
      </Box>

      <Link href={url} target="_blank" mt="20px" style={{ maxWidth: '90px' }}>
        {renderImage()}
      </Link>

      {!!fileName?.length && (
        <Link
          color="info.main"
          fontSize="16px"
          href={url}
          underline="hover"
          mt="20px"
          target="_blank"
          fontFamily="Roboto"
          onClick={onClick}
          style={{ cursor: 'pointer' }}
        >
          {fileName}
        </Link>
      )}
    </>
  );
};

export default DocumentDownloader;
