import { IconButton, InputAdornment, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import { MAX_SEARCH_VALUE_LENGTH } from '../../constants/constants';
import FormTextField from '../formFields/FormTextField';

interface FormSearchFieldProps {
  readonly disabled?: boolean;
  readonly name: string;
  readonly searchValue: string;
  readonly handleChange?: (value: string) => void;
  readonly handleResetSearch?: () => void;
}

const FormSearchField = ({
  disabled,
  name,
  searchValue,
  handleChange,
  handleResetSearch,
}: FormSearchFieldProps) => {
  const theme = useTheme();

  return (
    <FormTextField
      disabled={disabled}
      name={name}
      label="Search"
      handleChange={handleChange}
      inputProps={{
        autoComplete: 'new-password',
        maxLength: MAX_SEARCH_VALUE_LENGTH,
        form: {
          autocomplete: 'off',
        },
      }}
      InputProps={{
        endAdornment: searchValue ? (
          <InputAdornment position="end">
            <IconButton
              disableRipple
              onClick={handleResetSearch}
              sx={{ padding: 0, color: theme.palette.common.white }}
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ) : (
          <InputAdornment position="end">
            <SearchIcon
              sx={{
                color: searchValue ? theme.palette.primary.light : 'rgba(255, 255, 255, 0.3)',
              }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default FormSearchField;
