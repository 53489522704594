import { Typography } from '@mui/material';

import { styled } from '@mui/system';

export const FieldHeadline = styled(Typography)`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: ${(props) => props.theme.palette.primary.light};
`;
