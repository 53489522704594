import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { Box, Theme, Typography } from '@mui/material';

import CustomerBankingIntergrationContainer from './CustomerBankingIntergrationContainer';
import CustomerBankingWalletAccountContainer from './CustomerBankingWalletAccountContainer';
import CustomerBankingBalanceContainer from './CustomerBankingBalanceContainer';
import CustomerBankingTableDataRow from './CustomerBankingTableDataRow';

import {
  CustomerBankAccountSelectedTableItem,
  CustomerBankAccountSubmitFormProps,
  CustomerBankingAccountData,
  CustomerBankingData,
  CustomerBankShortAccountData,
} from '../types';

import {
  deleteCustomerBankingAccount,
  getCustomerBankingAccount,
  getCustomerBankingAccounts,
  getCustomerBankingInfo,
  startCustomerProvisioning,
  updateCustomerBankingAccount,
} from '../../../api/customersApi/customersApi';
import TableActions from '../../../components/Table/TableActions';
import Loader from '../../../components/Loader';
import TableContainer from '../../../components/Table/TableContainer';
import { CUSTOMER_BANKING_ACCOUNTS_LIST_TABLE_LABELS } from '../../../constants/tableLabels';
import { handleSortTableData } from '../../../helpers/handleSortTableData';
import usePagination from '../../../hooks/usePagination';
import useSnackBar from '../../../hooks/useSnackBar';
import { ActiveSortField, ErrorData } from '../../../types';
import moment from 'moment';
import Popup from '../../../components/Popup';
import { POPUP_DESCRIPTIONS } from '../../../constants/popupDescriptions';
import { AxiosError } from 'axios';
import CustomerBankingAccountPopup from './CustomerBankingAccountPopup';
import FirmBankingAddAccountButton from '../../FirmsView/firmTabs/firmBanking/FirmBankingAddAccountButton';

const useStyles = makeStyles()((theme: Theme) => ({
  systemAccountsWrapper: {
    display: 'flex',
    marginTop: '16px',
    gap: '20px',
    overflowX: 'auto',
  },
  systemAccountContainer: {
    display: 'flex',
    padding: '16px',
    background: theme.palette.primary.dark,
    flexDirection: 'column',
    maxWidth: '500px',
    width: '100%',
    boxSizing: 'border-box',
  },
  systemAccountsBlockLabel: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  systemAccountsLabel: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  systemAccountsInnerContainer: {
    display: 'flex',
    padding: '10px',
    justifyContent: 'center',
    height: '100%',
  },
  systemAccountDivider: {
    height: '100%',
    width: '1px',
    background: '#393939',

    '&:last-of-type': {
      display: 'none',
    },
  },
  balanceContainer: {
    display: 'flex',
    padding: '16px',
    background: theme.palette.primary.dark,
    width: '100%',
    boxSizing: 'border-box',
    justifyContent: 'flex-start',
  },
  tableHeadlineText: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: 'normal',
  },
}));

const CustomerBankingView = () => {
  const styles = useStyles();
  const { customerId } = useParams();
  const { setAlert } = useSnackBar();
  const { pageNumber, rowsPerPage, setTotalCount } = usePagination();

  const [bankingWalletData, setBankingWalletData] = useState<CustomerBankingData>();
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [isCustomerBankingAccountLoading, setIsCustomerBankingAccountLoading] =
    useState<boolean>(false);
  const [customerBankingAccounts, setCustomerBankingAccounts] = useState<
    CustomerBankingAccountData[]
  >([]);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);
  const [accountIdToDelete, setAccountIdToDelete] = useState<number | null>(null);
  const [selectedTableItem, setSelectedTableItem] = useState<CustomerBankShortAccountData | null>(
    null,
  );
  const [isAddForm, setIsAddForm] = useState<boolean>(true);
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);

  const sortableFields = {
    lastUpdatedOn: {
      order: '',
      fieldName: 'lastUpdatedOn',
    },
  };

  const params = useParams();

  const hasParams = !!Object.entries(params).length;

  const fetchBankingData = () => {
    getCustomerBankingInfo(customerId as string).then((res) => {
      setBankingWalletData(res.data);
    });
  };

  const handleIntegrationSubmit = () => {
    startCustomerProvisioning(customerId as string)
      .then(() => {
        fetchBankingData();
      })
      .catch((err) => {
        fetchBankingData();

        if (err) {
          setAlert((prev) => ({
            ...prev,
            message: err.response.data.message,
            type: 'error',
          }));
        }
      });
  };

  const fetchCustomerBankingAccounts = useCallback(() => {
    getCustomerBankingAccounts(
      { page: pageNumber, size: rowsPerPage, count: true, activeSortField },
      params.customerId as string,
    )
      .then((res) => {
        if (res.data) {
          setTotalCount && setTotalCount(res.data.totalCount as number);

          const parsedAccountData = res.data.resultSet.map(
            (resultItem: CustomerBankingAccountData) => ({
              id: resultItem.id,
              createdAt: moment(resultItem.createdAt).format('M/D/YYYY'),
              name: resultItem.name,
              type: resultItem.type,
              accountNumber: resultItem.accountNumber,
              routingNumber: resultItem.routingNumber,
              caseTypes: resultItem.caseTypes,
              isVerified: resultItem.isVerified && !resultItem.bankingProvisionFailed,
              isVerifiedOrigin: resultItem.isVerified,
              canBeDeleted: resultItem.canBeDeleted,
            }),
          );

          setCustomerBankingAccounts(parsedAccountData);
          setIsDataLoading(false);
        }
      })
      .catch(() => {
        setIsDataLoading(false);
      });
  }, [pageNumber, rowsPerPage, activeSortField]);

  const handleAddAccount = () => {
    setIsAddForm(true);
    handleOpenPopup();
  };

  const handleEditAccount = (accountFormData: CustomerBankAccountSubmitFormProps) => {
    if (!selectedTableItem) return;
    const submitData: Partial<CustomerBankAccountSubmitFormProps> = {};

    if (selectedTableItem.accountNumber !== accountFormData.accountNumber) {
      submitData.accountNumber = accountFormData.accountNumber;
    }

    if (selectedTableItem.routingNumber !== accountFormData.routingNumber) {
      submitData.routingNumber = accountFormData.routingNumber;
    }

    if (selectedTableItem.name !== accountFormData.name) {
      submitData.name = accountFormData.name;
    }

    updateCustomerBankingAccount(Number(params?.customerId), selectedTableItem.id, submitData)
      .then(() => {
        setAlert((prev) => ({
          ...prev,
          message: 'Bank account updated successfully.',
          type: 'success',
        }));

        fetchCustomerBankingAccounts();
        handleClosePopup();
        setSelectedTableItem(null);
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const onDeleteButtonClick = (customerId: number, accountId: number) => {
    getCustomerBankingAccount(customerId, accountId)
      .then((response) => {
        if (response.data) {
          setAccountIdToDelete(response.data.id);
          handleOpenDeletePopup();
        } else {
          setAlert((prev) => ({
            ...prev,
            message:
              // eslint-disable-next-line quotes
              `You cannot delete this bank account since it's connected to the cases for which settlement process has been started.`,
            type: 'info',
          }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      });
  };

  const onEditButtonClick = (tableItem: CustomerBankAccountSelectedTableItem) => {
    setIsAddForm(false);
    setIsCustomerBankingAccountLoading(true);
    handleOpenPopup();

    getCustomerBankingAccount(Number(params.customerId), tableItem.id)
      .then(({ data }) => {
        setSelectedTableItem({
          createdAt: data.createdAt,
          id: data.id,
          name: data.name,
          accountNumber: data.accountNumber,
          routingNumber: data.routingNumber,
          type: data.type,
          isAdminCreated: data.isAdminCreated,
          isVerified: data.isVerified,
        });
      })
      .catch((error: AxiosError<ErrorData>) => {
        handleClosePopup();
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      })
      .finally(() => setIsCustomerBankingAccountLoading(false));
  };

  const handleDeleteAccount = () => {
    if (!accountIdToDelete) return;

    deleteCustomerBankingAccount(Number(params.customerId), accountIdToDelete)
      .then(() => {
        setAccountIdToDelete(null);
        handleCloseDeletePopup();
        fetchCustomerBankingAccounts();

        setAlert((prev) => ({
          ...prev,
          message: 'Bank account deleted successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAccountIdToDelete(null);
        handleCloseDeletePopup();

        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleResetFormState = () => {
    setSelectedTableItem(null);
    handleClosePopup();
  };

  const handleOpenDeletePopup = () => setIsDeletePopupOpen(true);
  const handleCloseDeletePopup = () => setIsDeletePopupOpen(false);
  const handleOpenPopup = () => setIsPopupOpen(true);
  const handleClosePopup = () => setIsPopupOpen(false);

  useEffect(() => {
    fetchBankingData();
  }, []);

  useEffect(() => {
    fetchCustomerBankingAccounts();
  }, [activeSortField, pageNumber, rowsPerPage]);

  return (
    <Box pr="8px">
      <Box className={styles.classes.systemAccountsWrapper}>
        <CustomerBankingIntergrationContainer
          canStartIntegration={bankingWalletData?.canStartIntegration}
          handleIntegrationSubmit={handleIntegrationSubmit}
          integrationData={bankingWalletData?.integration}
        />
        <Box className={styles.classes.systemAccountContainer}>
          <Box className={styles.classes.systemAccountsInnerContainer}>
            <CustomerBankingWalletAccountContainer accountData={bankingWalletData?.account} />
          </Box>
        </Box>
        <Box className={styles.classes.balanceContainer}>
          <CustomerBankingBalanceContainer />
        </Box>
      </Box>

      <>
        <Typography className={styles.classes.tableHeadlineText} mt="65px" mb="15px">
          Linked Bank Accounts
        </Typography>
        {isDataLoading && !customerBankingAccounts.length ? (
          <Box position="absolute" top="50%" left="50%">
            <Loader colorType="warning" />
          </Box>
        ) : (
          <>
            <TableActions hasParams={hasParams} />

            <TableContainer
              noPagination
              isNoData={!isDataLoading && customerBankingAccounts.length === 0}
              headerLabels={CUSTOMER_BANKING_ACCOUNTS_LIST_TABLE_LABELS}
              sortableFields={sortableFields}
              activeSortField={activeSortField}
              setActiveSortField={setActiveSortField}
              handleSortTableData={handleSortTableData}
            >
              <CustomerBankingTableDataRow
                accountsData={customerBankingAccounts}
                onEditButtonClick={onEditButtonClick}
                onDeleteButtonClick={onDeleteButtonClick}
                customerId={params?.customerId as string}
              />
            </TableContainer>

            <FirmBankingAddAccountButton
              buttonText="ADD BANK ACCOUNT"
              handleClick={handleAddAccount}
            />

            <CustomerBankingAccountPopup
              isOpen={isPopupOpen}
              isLoading={isCustomerBankingAccountLoading}
              handleEditAccount={handleEditAccount}
              selectedTableItem={selectedTableItem}
              handleResetFormState={handleResetFormState}
              handleClosePopup={handleClosePopup}
              fetchCustomerBankAccounts={fetchCustomerBankingAccounts}
              isAddForm={isAddForm}
            />

            <Popup
              isOpen={isDeletePopupOpen}
              onMainButtonClick={handleDeleteAccount}
              onSecondaryButtonClick={handleCloseDeletePopup}
              headlineText={POPUP_DESCRIPTIONS.customerBankingAccountDelete.headlineText}
              contentText={POPUP_DESCRIPTIONS.customerBankingAccountDelete.contentText}
            />
          </>
        )}
      </>
    </Box>
  );
};

export default CustomerBankingView;
