import { useEffect } from 'react';
import { Navigate } from 'react-router';

import useAuth from '../../hooks/useAuth';

const AuthVerifyView = () => {
  const { onLogin, token } = useAuth();

  if (token) {
    return <Navigate to="dashboard" />;
  }

  useEffect(() => {
    onLogin && onLogin();
  }, []);

  return <div></div>;
};

export default AuthVerifyView;
