import { ReactNode, createContext } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { verifyUser } from '../api/authApi/authApi';
import jwtDecode from 'jwt-decode';
import { getAdmin } from '../api/adminsApi/adminsApi';
import TokenService, { IUserDataResponse } from '../api/service/token.service';

const searchParamName = 'code';

export const AuthContext = createContext<AuthContextProps>({
  token: null,
  onLogin: undefined,
  onSignUp: undefined,
  onLogOut: undefined,
});

interface AuthContextProps {
  token: string | null;
  onSignUp?: () => void;
  onLogin?: () => Promise<void>;
  onLogOut?: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const getUser = async (token: string) => {
    const userTokenData: { [x: string]: string } = jwtDecode(token);

    await getAdmin(userTokenData.username).then((res) => {
      if (res.data) {
        localStorage.setItem('isSuperAdmin', res.data.isSuperAdmin.toString());
        localStorage.setItem('userId', res.data.id.toString());
      }
    });
  };

  const code = location.search.replace(`?${searchParamName}=`, '');

  const handleLogin = async () => {
    return await verifyUser(code)
      .then(async (res: { data: IUserDataResponse }) => {
        if (res.data && res.data.access_token) {
          const backUrl = sessionStorage.getItem('backUrl');
          sessionStorage.removeItem('backUrl');

          if (!TokenService.getLocalAccessToken()) {
            TokenService.setUser(res.data);
            await getUser(res.data.access_token);
            navigate(backUrl ? atob(backUrl) : '/firms');
          } else {
            navigate(backUrl ? atob(backUrl) : '/firms');
          }
        }
      })
      .catch((error) => console.error(error));
  };

  const handleLogOut = () => {
    TokenService.removeUser();
    localStorage.clear();
    sessionStorage.clear();
    navigate('/login');
  };

  const value = {
    token: TokenService.getLocalAccessToken(),
    onLogin: handleLogin,
    onLogOut: handleLogOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
