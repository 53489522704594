import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { CaseTypeData } from '../../types';
import AddButton from '../../components/buttons/AddButton';
import Loader from '../../components/Loader';

import CaseTypeButton from './CaseTypeButton';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  caseTypesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    gap: '16px',
  },
  loaderWrapper: {
    marginTop: '16px',
  },
}));

interface CaseTypeListProps {
  readonly data: CaseTypeData[];
  readonly selectedCaseType: CaseTypeData | null;
  readonly isFetching: boolean;
  readonly totalCount: number | null;
  readonly handleLoadMoreButtonClick: () => void;
  readonly handleSelect: (caseType: CaseTypeData) => void;
}

const CaseTypeList = ({
  data,
  selectedCaseType,
  isFetching,
  totalCount,
  handleLoadMoreButtonClick,
  handleSelect,
}: CaseTypeListProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.caseTypesContainer}>
        {data.map((caseType) => (
          <CaseTypeButton
            key={caseType.id}
            buttonText={caseType.name}
            isActive={selectedCaseType?.id === caseType.id}
            onClick={() => handleSelect(caseType)}
          />
        ))}
        {!isFetching && totalCount !== null && data.length < totalCount && (
          <AddButton
            buttonText="LOAD MORE"
            handleClick={handleLoadMoreButtonClick}
            disabled={isFetching}
          />
        )}
      </Box>
      {isFetching && (
        <Box className={classes.loaderWrapper}>
          <Loader colorType="warning" size={'60px'} />
        </Box>
      )}
    </Box>
  );
};

export default CaseTypeList;
