import { Moment } from 'moment';
import { useFormContext, Controller } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { Box, CircularProgress, FormControl, IconButton, Theme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Close } from '@mui/icons-material';

const useStyles = makeStyles<{ disabled?: boolean; dateValue?: string; isEncrypted?: boolean }>()(
  (theme: Theme, { disabled, dateValue, isEncrypted }) => ({
    defaultTextField: {
      ...(disabled && { background: 'rgba(255, 255, 255, 0.1)' }),
      input: {
        padding: '4px 0px 8px 8px',

        '&.Mui-disabled': {
          color: isEncrypted ? theme.palette.common.white : 'rgba(255, 255, 255, 0.3)',
          WebkitTextFillColor: isEncrypted
            ? theme.palette.common.white
            : 'rgba(255, 255, 255, 0.3)',
        },
        '* ::before': {
          borderBottomStyle: 'solid',
        },
      },

      '& .MuiInput-root': {
        maxWidth: '470px',
        color: theme.palette.primary.light,

        '& .hiddenIcon': {
          svg: {
            color: theme.palette.common.white,
          },
        },

        svg: {
          color: dateValue ? theme.palette.primary.light : 'rgba(255, 255, 255, 0.3)',
        },

        '& .MuiIconButton-root': {
          marginRight: 0,
        },

        ':before': {
          borderBottom: `1px solid ${theme.palette.primary.light}`,
        },

        ':hover:not(.Mui-disabled, .Mui-error):before': {
          borderBottom: `1px solid ${theme.palette.primary.light}`,
        },

        ':after': {
          borderBottom: `1px solid ${theme.palette.primary.light}`,
        },

        '&.Mui-disabled': {
          color: 'rgba(255, 255, 255, 0.3)',
        },
      },

      '& .MuiInputLabel-root': {
        marginLeft: '8px',
        color: 'rgba(255, 255, 255, 0.3)',

        '&[data-shrink="true"]': {
          color: theme.palette.secondary.light,
        },

        '&.Mui-error': {
          color: theme.palette.error.main,
        },

        '&.Mui-focused': {
          color: theme.palette.secondary.light,
        },

        '&.Mui-disabled': {
          color: 'rgba(255, 255, 255, 0.3)',
        },
      },

      '& .MuiFormHelperText-root': {
        color: theme.palette.error.main,
        marginLeft: '8px',
        position: 'absolute',
        bottom: '-20px',
      },
    },
  }),
);

interface FormDateFieldProps {
  name: string;
  label: string;
  maxDate?: Moment;
  disabled?: boolean;
  minDate?: Moment;
  shouldDisableInput?: boolean;
  isEncrypted?: boolean;
  isEncryptedValueLoading?: boolean;
  isValueVisible?: boolean;
  onRightIconClick?: () => void;
  onClearField?: () => void;
}

const FormDateField = ({
  name,
  label,
  disabled,
  maxDate,
  minDate,
  shouldDisableInput,
  isEncrypted,
  isEncryptedValueLoading,
  isValueVisible,
  onRightIconClick,
  onClearField,
}: FormDateFieldProps) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const dateValue = watch(name);

  const styles = useStyles({ disabled, dateValue, isEncrypted });

  const helperText = errors[name] && errors[name]?.message;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-us">
          <FormControl error={!!error} fullWidth>
            <DesktopDatePicker
              disableOpenPicker={isEncrypted}
              label={label}
              onChange={(event) => onChange(event)}
              value={value || null}
              disabled={disabled}
              defaultValue={value || null}
              maxDate={maxDate}
              minDate={minDate}
              slots={{
                openPickerIcon: value ? Close : CalendarMonthIcon,
              }}
              slotProps={{
                openPickerButton: {
                  onClickCapture: value ? onClearField : undefined,
                },
                textField: {
                  type: isEncrypted ? (isValueVisible ? 'text' : 'password') : 'text',
                  className: styles.classes.defaultTextField,
                  variant: 'standard',
                  helperText: helperText as string,
                  disabled: shouldDisableInput,
                  InputProps: {
                    endAdornment:
                      isEncrypted &&
                      (isEncryptedValueLoading ? (
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          padding={'8px'}
                        >
                          <CircularProgress size={24} />
                        </Box>
                      ) : (
                        <IconButton disableRipple onClick={onRightIconClick} className="hiddenIcon">
                          {isValueVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      )),
                  },
                },
              }}
            />
          </FormControl>
        </LocalizationProvider>
      )}
    />
  );
};

export default FormDateField;
