import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import FormTextField from '../../../../components/formFields/FormTextField';
import FormSelectField from '../../../../components/formFields/FormSelectField';
import { UnrestrictedAccountOptionsData } from '../../types';

const useStyles = makeStyles()(() => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  formFieldsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    alignItems: 'center',
    padding: '24px 0 24px 0',
  },
  checkboxWrapper: {
    marginTop: '14px',

    '& label': {
      marginRight: 0,
    },
  },
}));

const FirmBankingWithdrawForm = ({
  disabled = false,
  unrestrictedAccountOptionsData,
}: {
  disabled?: boolean;
  unrestrictedAccountOptionsData: UnrestrictedAccountOptionsData[];
}) => {
  const styles = useStyles();

  return (
    <Box className={styles.classes.formContainer}>
      <Box className={styles.classes.formFieldsWrapper}>
        <FormTextField
          readOnly
          isReadOnly
          name="fromSystemAccountType"
          label="From"
          disabled={disabled}
        />
        <FormSelectField
          options={unrestrictedAccountOptionsData}
          name="toUnrestrictedAccountId"
          label="To *"
          disabled={disabled}
        />
        <FormTextField name="amount" label="Amount" disabled={disabled} />
      </Box>
    </Box>
  );
};

export default FirmBankingWithdrawForm;
