import { parseQueryFilters } from './parseQueryFilters';
import { PaginationQueryParams } from '../types';
import api from '../api/service/api.service';
import { saveCsv } from './saveCsv';

export const downloadCsv = async (
  { match, filters }: PaginationQueryParams,
  targetPath: string,
  filename: string,
) => {
  const queryFilters = parseQueryFilters(filters);
  const targetUrl = `${targetPath}/export?${match ? `&match=${match}` : ''}${queryFilters || ''}`;

  try {
    const response = await api.post(
      targetUrl,
      {},
      {
        responseType: 'blob',
      },
    );

    return saveCsv(response, filename);
  } catch (error) {
    console.error('Error downloading CSV file:', error);
  }
};
