import { Box, useTheme, Typography } from '@mui/material';

interface AvatarProps {
  userShortName: string;
}

const Avatar = ({ userShortName }: AvatarProps) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="50%"
      bgcolor="rgba(255, 255, 255, 0.3)"
      height="40px"
      width="40px"
      color={theme.palette.secondary.light}
    >
      <Typography fontWeight="500" fontSize="20px" lineHeight="23px">
        {userShortName}
      </Typography>
    </Box>
  );
};

export default Avatar;
