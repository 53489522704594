import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import DataTableDataRow from './DataTableDataRow';

import Loader from '../../../components/Loader';
import TableContainer from '../../../components/Table/TableContainer';
import { DATA_LIST_TABLE_LABELS } from '../../../constants/tableLabels';
import usePagination from '../../../hooks/usePagination';
import { ActiveSortField, DataRequestShortData, FilterTypes, ViewProps } from '../../../types';
import { getDataRequests } from '../../../api/requestsApi/dataRequestsApi';
import TableFilters from '../../../components/TableFilters/TableFilters';
import { downloadCsv } from '../../../helpers/downloadCsv';
import TableActions from '../../../components/Table/TableActions';
import useSnackBar from '../../../hooks/useSnackBar';
import { handleSortTableData } from '../../../helpers/handleSortTableData';

const DataView = ({ isRelatedToOtherEntity }: ViewProps) => {
  const params = useParams();

  const { pageNumber, rowsPerPage, setTotalCount } = usePagination();
  const { setAlert } = useSnackBar();

  const [dataList, setDataList] = useState<DataRequestShortData[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [filters, setFilters] = useState<FilterTypes>({
    firmId: params?.firmId || '',
    clientId: '',
    defendantId: '',
  });
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);
  const sortableFields = {
    client: {
      order: '',
      fieldName: 'client',
    },
    sentAt: {
      order: '',
      fieldName: 'sentAt',
    },
    completedAt: {
      order: '',
      fieldName: 'completedAt',
    },
  };

  const hasParams = !!Object.entries(params).length;

  useEffect(() => {
    getDataRequests({
      page: pageNumber,
      size: rowsPerPage,
      count: true,
      filters: { ...filters, ...(hasParams && params) },
      activeSortField,
    }).then((res) => {
      if (res.data) {
        setTotalCount && setTotalCount(res.data.totalCount as number);
        setDataList(res.data.resultSet);
        setIsDataLoading(false);
      }
    });
  }, [pageNumber, rowsPerPage, filters, activeSortField]);

  const exportList = () => {
    downloadCsv(
      {
        page: pageNumber,
        size: rowsPerPage,
        count: true,
        filters: { ...filters, ...(hasParams && params) },
      },
      '/v5/data-requests',
      'Data_List',
    ).then((res) => {
      if (res?.data) {
        setAlert({
          message: 'Data requests exported successfully.',
          type: 'success',
        });
      }
    });
  };

  return (
    <>
      {isDataLoading && !dataList.length ? (
        <Box position="absolute" top="50%" left="50%">
          <Loader colorType="warning" />
        </Box>
      ) : (
        <>
          <TableActions
            hasParams={hasParams}
            createLink={`/data-requests/create-data-request${
              params.caseId ? `/?caseId=${params.caseId}` : '/'
            }`}
            exportList={exportList}
          />

          <Grid container>
            <TableFilters
              filters={filters}
              setFilters={setFilters}
              firms
              timeFilter={'REQUEST DATE'}
              // statusFilter={['Draft', 'Unopened', 'Opened', 'Completed', 'Canceled', 'Archived']}
              statusFilter={['Draft', 'Unopened', 'Opened', 'Completed', 'Canceled', 'Rejected']}
              isStatusArray
            />
            <TableContainer
              isNoData={!isDataLoading && dataList.length === 0}
              headerLabels={isRelatedToOtherEntity || DATA_LIST_TABLE_LABELS}
              sortableFields={sortableFields}
              activeSortField={activeSortField}
              setActiveSortField={setActiveSortField}
              handleSortTableData={handleSortTableData}
            >
              <DataTableDataRow
                tableData={dataList}
                isRelatedToOtherEntity={isRelatedToOtherEntity}
              />
            </TableContainer>
          </Grid>
        </>
      )}
      <Outlet />
    </>
  );
};

export default DataView;
