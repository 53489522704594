import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import { Box, Typography } from '@mui/material';
import { CaseShortData } from '../types';

const useStyles = makeStyles()(() => ({
  searchResultsBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    maxHeight: '300px',
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      backgroundColor: '#393939',
      width: '15px',
      borderRadius: '20px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 20,
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      border: '3px solid #393939',
    },
  },
  resultItemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: '10px',
    borderRadius: '5px',
    gap: '20px',
    flexDirection: 'column',
  },
  resultItemColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '33%',
    whiteSpace: 'nowrap',
    minWidth: 0,
  },
  resultItemColumnLabel: {
    fontSize: '14px',
    fontWeight: '400',
    color: 'rgba(255,255, 255, 0.7)',
  },
  resultItemColumnText: {
    fontSize: '14px',
    fontWeight: '500',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

interface CaseInfoPreviewProps {
  caseItem: CaseShortData;
}

const CaseInfoPreview = ({ caseItem }: CaseInfoPreviewProps) => {
  const styles = useStyles();

  return (
    <Box className={styles.classes.searchResultsBlock}>
      <Box className={clsx(styles.classes.resultItemContainer)}>
        <Box className={styles.classes.resultItemColumn}>
          <Typography className={styles.classes.resultItemColumnLabel}>Reference ID</Typography>
          <Typography className={styles.classes.resultItemColumnText}>
            {caseItem.referenceId}
          </Typography>
        </Box>
        <Box className={styles.classes.resultItemColumn}>
          <Typography className={styles.classes.resultItemColumnLabel}>System Name</Typography>
          <Typography className={styles.classes.resultItemColumnText}>{caseItem.name}</Typography>
        </Box>
        <Box className={styles.classes.resultItemColumn}>
          <Typography className={styles.classes.resultItemColumnLabel}>Firm</Typography>
          <Typography className={styles.classes.resultItemColumnText}>
            {caseItem.firm.name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CaseInfoPreview;
