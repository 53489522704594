import { ReactNode, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {
  Box,
  FilledInputProps,
  InputAdornment,
  InputLabelProps,
  InputProps,
  OutlinedInputProps,
  TextField,
  Theme,
  Typography,
  IconButton,
} from '@mui/material';
import { InputBaseProps } from '@mui/material/InputBase';

import { TInputMaskCorrect } from './types';

import { validateTextFieldValue } from '../../helpers/validateTextFieldValue';

export const useStyles = makeStyles<{
  maxWidth?: string;
  disabled?: boolean;
  minWidth?: string;
  isEncrypted?: boolean;
}>()((theme: Theme, { maxWidth, disabled, minWidth, isEncrypted }) => ({
  defaultTextField: {
    ...(maxWidth && { maxWidth }),
    ...(minWidth && { minWidth }),
    ...(disabled && { background: 'rgba(255, 255, 255, 0.1)' }),
    input: {
      padding: '4px 0px 8px 8px',

      '&.Mui-disabled': {
        color: isEncrypted ? theme.palette.common.white : 'rgba(255, 255, 255, 0.3)',
        WebkitTextFillColor: isEncrypted ? theme.palette.common.white : 'rgba(255, 255, 255, 0.3)',
      },
    },

    '& .MuiInput-root': {
      color: theme.palette.primary.light,

      ':before': {
        borderBottom: `1px solid ${theme.palette.primary.light}`,
      },

      ':hover:not(.Mui-disabled, .Mui-error):before': {
        borderBottom: `1px solid ${theme.palette.primary.light}`,
      },

      ':after': {
        borderBottom: `1px solid ${theme.palette.primary.light}`,
      },

      '&.Mui-disabled': {
        color: 'rgba(255, 255, 255, 0.3)',
      },
    },

    '& .MuiInputLabel-root': {
      '&[data-shrink="true"]': {
        color: theme.palette.secondary.light,
      },

      marginLeft: '8px',
      color: 'rgba(255, 255, 255, 0.3)',

      '&.Mui-error': {
        color: theme.palette.error.main,
      },

      '&.Mui-focused': {
        color: theme.palette.secondary.light,
      },

      '&.Mui-disabled': {
        color: 'rgba(255, 255, 255, 0.3)',
      },
    },

    '& .MuiFormHelperText-root': {
      marginLeft: '8px',
      position: 'absolute',
      bottom: '-20px',
    },
  },
  readOnlyText: {
    '& .MuiInput-root': {
      maxWidth: '470px',
      color: theme.palette.primary.light,

      ':before': {
        borderBottom: 'none',
      },

      ':hover:not(.Mui-disabled, .Mui-error):before': {
        borderBottom: 'none',
      },

      ':after': {
        borderBottom: 'none',
      },
    },
  },
  inputStartAdornment: {
    marginLeft: '8px',
    marginRight: 0,
    height: '100%',
  },
  inputValueDefinitionContainer: {
    marginBottom: '4px',
    marginRight: 0,
    color: theme.palette.common.white,
    borderRight: '1px solid rgba(255, 255, 255, 0.3)',
    paddingRight: '4px',
  },
}));

// eslint-disable-next-line react/prop-types
const InputMaskCorrect: React.FC<TInputMaskCorrect> = ({ children, ...props }) => {
  const child = children as ReactNode;
  return <InputMask {...props}>{child}</InputMask>;
};

interface FormMaskedTextFieldProps {
  name: string;
  label: string;
  readOnly?: boolean;
  maxWidth?: string;
  minWidth?: string;
  disabled?: boolean;
  required?: boolean;
  isReadOnly?: boolean;
  handleChange?: (value: string) => void;
  inputLabelProps?: Partial<InputLabelProps>;
  inputProps?: InputBaseProps['inputProps'];
  maxRows?: number;
  valueDefinition?: string;
  InputProps?:
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | Partial<InputProps>
    | undefined;
  mask: string;
  isEncrypted?: boolean;
  getEncryptedFieldValue?: () => void;
  isValueVisible?: boolean;
  setIsValueVisible?: () => void;
}

const FormMaskedTextField = ({
  name,
  label,
  handleChange,
  readOnly,
  isReadOnly,
  disabled,
  maxWidth,
  inputLabelProps,
  inputProps,
  InputProps,
  valueDefinition,
  minWidth,
  mask,
  isEncrypted,
  getEncryptedFieldValue,
  isValueVisible,
  setIsValueVisible,
}: FormMaskedTextFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const styles = useStyles({ maxWidth, disabled, minWidth, isEncrypted });

  const helperText = errors[name] && errors[name]?.message;

  const getVisiblePropValue = () => {
    if (isEncrypted) {
      if (isValueVisible) {
        return 'text';
      } else {
        return 'password';
      }
    }
  };

  useEffect(() => {
    isValueVisible && getEncryptedFieldValue && getEncryptedFieldValue();
  }, [isValueVisible]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <InputMaskCorrect
            mask={mask}
            maskChar=""
            onChange={(e) =>
              handleChange
                ? handleChange(e.target.value)
                : onChange(validateTextFieldValue(e.target.value))
            }
            value={value}
            disabled={disabled}
          >
            {() => (
              <TextField
                fullWidth
                type={getVisiblePropValue()}
                className={clsx(
                  styles.classes.defaultTextField,
                  isReadOnly && styles.classes.readOnlyText,
                )}
                variant="standard"
                label={label}
                helperText={helperText as string}
                error={!!errors[name]}
                inputProps={inputProps}
                InputProps={{
                  ...InputProps,
                  readOnly: readOnly,
                  ...(valueDefinition && {
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={styles.classes.inputStartAdornment}
                      >
                        <Box className={styles.classes.inputValueDefinitionContainer}>
                          <Typography>{valueDefinition}</Typography>
                        </Box>
                      </InputAdornment>
                    ),
                  }),
                  disabled: isEncrypted || disabled,
                  endAdornment: isEncrypted && (
                    <IconButton
                      disableRipple
                      sx={{
                        color: 'white',
                      }}
                      onClick={setIsValueVisible}
                    >
                      {isValueVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  ),
                }}
                InputLabelProps={inputLabelProps}
              />
            )}
          </InputMaskCorrect>
        );
      }}
    />
  );
};

export default FormMaskedTextField;
