import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

const useStyles = makeStyles()((theme: Theme) => ({
  default: {
    background: 'rgba(255, 255, 255, 0.12)',
    color: 'rgba(255, 255, 255, 0.3)',
    height: '52px',

    '&:hover': {
      background: theme.palette.info.main,
      color: theme.palette.primary.main,
    },
  },
  active: {
    background: theme.palette.info.main,
    color: theme.palette.primary.main,
  },
}));

interface CategoryButtonProps {
  readonly buttonText: string;
  readonly isActive?: boolean;
  readonly onClick?: () => void;
}

const CategoryButton = ({ buttonText, isActive, onClick }: CategoryButtonProps) => {
  const { classes } = useStyles();

  return (
    <Button
      className={clsx({
        [classes.default]: true,
        [classes.active]: isActive,
      })}
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );
};

export default CategoryButton;
