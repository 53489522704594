import api from '../service/api.service';

import {
  ActiveSortField,
  DocumentRequestData,
  PaginationQueryParams,
  ResultSet,
} from '../../types';
import { parseQueryFilters } from '../../helpers/parseQueryFilters';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';

const BASE_URL_V1 = '/v1/document-requests';
const BASE_URL_V2 = '/v2/document-requests';
const BASE_URL_V4 = '/v4/document-requests';

export const getDocumentsList = async ({
  size,
  page,
  count,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL_V4}?size=${size}&page=${page}&count=${count}${
    queryFilters || ''
  }${sortFilters}`;

  return await api.get<ResultSet<DocumentRequestData>>(targetUrl).catch((error) => error.response);
};

export const createDocumentRecord = async (data: unknown) => {
  return await api.post(BASE_URL_V4, data);
};

export const getDocumentRecord = async (documentId: string) => {
  const targetUrl = `${BASE_URL_V4}/${documentId}`;

  return await api.get<DocumentRequestData>(targetUrl);
};

export const getDocumentFile = async (documentId: string) => {
  const targetUrl = `${BASE_URL_V1}/${documentId}/file-url`;

  return await api.get<string>(targetUrl);
};

export const updateDocumentRecord = async (documentId: string, data: unknown) => {
  const targetUrl = `${BASE_URL_V2}/${documentId}`;

  return await api.put(targetUrl, data);
};

export const getDocumentTimeline = async (documentId: string) => {
  const targetUrl = `${BASE_URL_V2}/${documentId}/timeline`;

  return await api.get(targetUrl).catch((error) => error.response);
};

export const sendDocumentRecord = async (id: string) => {
  return await api.post(`${BASE_URL_V4}/${id}/send`, {});
};

export const cancelDocumentRecord = async (id: string) => {
  return await api.post(`${BASE_URL_V4}/${id}/cancel`, {});
};

export const deleteDocumentRecord = async (id: string) => {
  return await api.delete(`${BASE_URL_V2}/${id}`, {});
};
