import { Box, useTheme } from '@mui/material';

import { usersAccountTabs } from '../../constants/tabRoutes';

import GoBackButton from '../../components/buttons/GoBackButton';
import TabsPanel from '../../components/TabsPanel';

const UsersAccountView = () => {
  const theme = useTheme();

  return (
    <>
      <GoBackButton />
      <Box display="flex" bgcolor={theme.palette.primary.dark} width="100%">
        <TabsPanel tabsList={usersAccountTabs} locationSlicePosition={3} />
      </Box>
    </>
  );
};

export default UsersAccountView;
