import { Link } from 'react-router-dom';
import { Box, Theme, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  createButtonContainer: {
    '& a': {
      color: theme.palette.info.main,
      textDecoration: 'none',
    },
  },
  linkMargin: {
    marginRight: '16px',
  },
  exportButton: {
    marginRight: '16px',
    color: theme.palette.info.main,
    cursor: 'pointer',
  },
}));

interface ITableActions {
  exportList?: () => void;
  createLink?: string;
  hasParams?: boolean;
}

const TableActions = ({ exportList, createLink, hasParams }: ITableActions) => {
  const styles = useStyles();

  return (
    <Box
      className={styles.classes.createButtonContainer}
      display="flex"
      justifyContent="flex-end"
      width="100%"
      mr="16px"
      mt={hasParams ? '38px' : 0}
    >
      {!!createLink?.length && (
        <Link className={styles.classes.linkMargin} to={createLink}>
          <Box display="flex" gap="8px" alignItems="center">
            <AddIcon />
            <Typography fontSize="13px" textTransform="uppercase" lineHeight="15px">
              Create
            </Typography>
          </Box>
        </Link>
      )}

      {exportList ? (
        <Box
          onClick={exportList}
          className={styles.classes.exportButton}
          display="flex"
          gap="8px"
          alignItems="center"
        >
          <DownloadIcon />
          <Typography fontSize="13px" textTransform="uppercase" lineHeight="15px">
            Export
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default TableActions;
