import { useRef } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFieldArray, useFormContext } from 'react-hook-form';

import FormTextField from './formFields/FormTextField';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
  },
}));

interface PinInputProps {
  readonly length?: number;
  readonly name: string;
}

const PinInput = ({ length = 4, name }: PinInputProps) => {
  const { classes } = useStyles();

  const inputRef = useRef<HTMLInputElement[]>(Array(length).fill(null));

  const { control, setValue } = useFormContext();

  const { fields } = useFieldArray({
    control,
    name,
    rules: { minLength: length, maxLength: length },
  });

  return (
    <Box className={classes.container}>
      {fields.map((field, index) => {
        return (
          <FormTextField
            key={field.id}
            name={`${name}.${index}.value`}
            label=""
            maxWidth="54px"
            inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
            displayHidden
            handleChange={(value) => {
              setValue(`${name}.${index}.value`, value, {
                shouldDirty: true,
                shouldValidate: true,
              });

              if (value.length === 1 && index < length - 1) {
                inputRef.current[index + 1]?.focus();
              }

              if (value.length === 0 && index > 0) {
                inputRef.current[index - 1]?.focus();
              }
            }}
            inputRef={(ref) => (inputRef.current[index] = ref as HTMLInputElement)}
            helperText=""
          />
        );
      })}
    </Box>
  );
};

export default PinInput;
