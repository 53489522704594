import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import AddButton from '../../../components/buttons/AddButton';
import { QuestionnaireShortData } from '../../../types';

import SelectButton from './SelectButton';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    gap: '16px',
  },
}));

interface QuestionnaireListProps {
  readonly data: QuestionnaireShortData[];
  readonly isFetching: boolean;
  readonly selectedId: string | undefined;
  readonly totalCount: number | null;
  readonly handleLoadMoreButtonClick: () => void;
  readonly handleSelect: (questionnaire: QuestionnaireShortData) => void;
}

const QuestionnaireList = ({
  data,
  isFetching,
  selectedId,
  totalCount,
  handleLoadMoreButtonClick,
  handleSelect,
}: QuestionnaireListProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      {data.map((questionnaire) => (
        <SelectButton
          key={questionnaire.id}
          buttonText={questionnaire.name}
          checked={questionnaire.id === selectedId}
          onClick={() => handleSelect(questionnaire)}
        />
      ))}
      {!isFetching && totalCount !== null && data.length < totalCount && (
        <AddButton
          buttonText="LOAD MORE"
          handleClick={handleLoadMoreButtonClick}
          disabled={isFetching}
        />
      )}
    </Box>
  );
};

export default QuestionnaireList;
