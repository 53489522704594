import { makeStyles } from 'tss-react/mui';

import { Box, Theme, Typography } from '@mui/material';
import FormSelectField from '../../../components/formFields/FormSelectField';
import { FormProvider, useForm } from 'react-hook-form';

const useStyles = makeStyles()((theme: Theme) => ({
  balanceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 10px 10px 10px',
    gap: '20px',
    width: '100%',

    '@media (max-width: 1530px)': {
      gap: 0,
    },
  },
  dropdownPeriod: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    maxWidth: '275px',
    marginLeft: 'auto',

    '@media (max-width: 1530px)': {
      justifyContent: 'flex-start',
      maxWidth: 'inherit',
    },
  },
  systemAccountsColoredLabel: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: 'normal',
    color: theme.palette.info.main,
  },
  systemAccountsSmallLabel: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 'normal',
    color: theme.palette.secondary.main,
  },
  systemAccountsLabel: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  systemAccountsBalanceText: {
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: 'normal',
    marginTop: '8px',

    '@media (max-width: 1530px)': {
      marginTop: 0,
      fontSize: '28px',
    },
  },
  chipsesBalanceWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    gap: '20px',
    height: '100%',
    minWidth: 'fit-content',

    '@media (max-width: 1530px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '0px',
      marginTop: '8px',
      justifyContent: 'center',
    },
  },
  balanceInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 20px 4px 20px',
    borderRight: '1px solid #393939',
    justifyContent: 'center',
    alignItems: 'flex-end',
    whiteSpace: 'nowrap',
    minWidth: '190px',

    '@media (max-width: 1530px)': {
      flexDirection: 'row',
      alignItems: 'center',
      gap: '16px',
      border: 'none',
      padding: '0px 20px 4px 0px',
      minWidth: 'inherit',
    },
  },
}));

const CustomerBankingBalanceContainer = () => {
  const styles = useStyles();

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      period: '',
    },
  });

  return (
    <Box className={styles.classes.balanceWrapper}>
      <Box className={styles.classes.dropdownPeriod}>
        <FormProvider {...formMethods}>
          <FormSelectField
            name="period"
            label="Period"
            options={[{ id: 'currentMonth', label: 'Current Month' }]}
            width="100%"
          />
        </FormProvider>
      </Box>

      <Box className={styles.classes.chipsesBalanceWrapper}>
        <Box className={styles.classes.balanceInnerContainer}>
          <Typography fontSize={20}>Average Balance</Typography>
          <Typography className={styles.classes.systemAccountsBalanceText}>{'-'}</Typography>
        </Box>
        <Box className={styles.classes.balanceInnerContainer}>
          <Typography fontSize={20}>Transactions</Typography>
          <Typography className={styles.classes.systemAccountsBalanceText}>{'-'}</Typography>
        </Box>
        <Box className={styles.classes.balanceInnerContainer}>
          <Typography fontSize={20}>Fees</Typography>
          <Typography className={styles.classes.systemAccountsBalanceText}>{'-'}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerBankingBalanceContainer;
