import { Fragment, useRef } from 'react';
import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

const useStyles = makeStyles()((theme: Theme) => ({
  default: {
    background: 'rgba(255, 255, 255, 0.12)',
    color: 'rgba(255, 255, 255, 0.3)',
    height: '52px',
  },
  mainButton: {
    '&:hover': {
      background: theme.palette.info.main,
      color: theme.palette.primary.main,
    },
  },
  deleteButton: {
    '&:hover': {
      background: theme.palette.error.main,
      color: theme.palette.primary.main,
    },
  },
  activeMainButton: {
    background: theme.palette.info.main,
    color: theme.palette.primary.main,

    '&:hover': {
      background: theme.palette.info.main,
      color: theme.palette.primary.main,
    },
  },
  activeDeleteButton: {
    background: theme.palette.info.main,
    color: theme.palette.primary.main,

    '&:hover': {
      background: theme.palette.error.main,
      color: theme.palette.primary.main,
    },
  },
}));

interface FormSnippetButtonProps {
  readonly buttonText: string;
  readonly isActive?: boolean;
  readonly onPress?: () => void;
  readonly onDelete?: () => void;
}

const FormSnippetButton = ({ buttonText, isActive, onPress, onDelete }: FormSnippetButtonProps) => {
  const { classes } = useStyles();
  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <Fragment>
      <ButtonGroup variant="contained" ref={anchorRef}>
        <Button
          className={clsx({
            [classes.default]: true,
            [classes.mainButton]: true,
            [classes.activeMainButton]: isActive,
          })}
          onClick={onPress}
        >
          {buttonText}
        </Button>
        <Button
          className={clsx({
            [classes.default]: true,
            [classes.deleteButton]: true,
            [classes.activeDeleteButton]: isActive,
          })}
          size="small"
          onClick={onDelete}
        >
          <DeleteForeverIcon />
        </Button>
      </ButtonGroup>
    </Fragment>
  );
};

export default FormSnippetButton;
