import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import PinInput from '../../../components/PinInput';
import useSnackBar from '../../../hooks/useSnackBar';
import { setNewPinFormSchema } from '../../../helpers/formSchemas/formSchemas';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.light,
      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))',
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.secondary.main,
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
  },
  pinInputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px',
    color: theme.palette.secondary.main,
  },
  dialogActions: {
    padding: '16px 8px',
  },
  dialogButton: {
    color: theme.palette.info.main,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
    },

    '&:hover': {
      background: 'rgba(144, 202, 249, 0.08)',
    },
  },
}));

interface FormProps {
  numbers: Array<{
    value: string;
  }>;
  confirmedNumbers: Array<{
    value: string;
  }>;
}

const formDefaultValues = {
  numbers: new Array(4).fill({ value: '' }),
  confirmedNumbers: new Array(4).fill({ value: '' }),
};

interface SetPinCodePopupProps {
  readonly isOpen: boolean;
  onMainButtonClick: (newPin: string) => void;
  onSecondaryButtonClick?: () => void;
}

const SetPinCodePopup = ({
  isOpen,
  onMainButtonClick,
  onSecondaryButtonClick,
}: SetPinCodePopupProps) => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();

  const formMethods = useForm<FormProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(setNewPinFormSchema),
    defaultValues: formDefaultValues,
  });

  const { handleSubmit, reset } = formMethods;

  const onSubmit = (values: FormProps) => {
    const pin = values.numbers.map((num) => num.value).join('');
    const confirmedPin = values.confirmedNumbers.map((num) => num.value).join('');

    if (pin === confirmedPin) {
      onMainButtonClick(confirmedPin);
    } else {
      setAlert((prev) => ({
        ...prev,
        message: 'Pin codes do not match.',
        type: 'error',
      }));
    }

    reset(formDefaultValues);
  };

  const handleCancel = () => {
    reset(formDefaultValues);
    onSecondaryButtonClick?.();
  };

  return (
    <Dialog className={classes.dialogContainer} open={isOpen} onClose={handleCancel} fullWidth>
      <DialogTitle>Set PIN code</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>4-digit PIN code for settlement</DialogContentText>
        <FormProvider {...formMethods}>
          <PinInput name="numbers" />
          <Box className={classes.pinInputWrapper}>
            <Typography className={classes.text}>Confirm PIN</Typography>
            <PinInput name="confirmedNumbers" />
          </Box>
        </FormProvider>
      </DialogContent>
      <DialogActions className={classes.dialogActions} disableSpacing>
        <Button className={classes.dialogButton} onClick={handleSubmit(onSubmit)} type="submit">
          Submit
        </Button>
        <Button className={classes.dialogButton} onClick={handleCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SetPinCodePopup;
