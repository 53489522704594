import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import FormSnippetsEditSection from './FormSnippetsEditSection';

const FormSnippetsView = () => {
  const { firmId } = useParams();

  return (
    <Box padding={'16px 0px 16px 0px'} height={'100%'}>
      <FormSnippetsEditSection firmId={firmId} />
    </Box>
  );
};

export default FormSnippetsView;
