import api from '../service/api.service';

import { ActiveSortField, FirmShortData, PaginationQueryParams, ResultSet } from '../../types';
import { parseQueryFilters } from '../../helpers/parseQueryFilters';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';

const BASE_URL = '/v1';

export const getFirmsList = async ({
  size,
  page,
  count,
  match,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);
  const targetUrl = `${BASE_URL}/firms?size=${size}&page=${page}&count=${count}&match=${
    match || ''
  }${queryFilters || ''}${sortFilters}&extended=true`;

  return await api.get<ResultSet<FirmShortData>>(targetUrl);
};

export const createFirmRecord = async (data: unknown) => {
  const targetUrl = `${BASE_URL}/firms`;

  return await api.post(targetUrl, data);
};

export const getFirmRecord = async (firmId: string) => {
  const targetUrl = `${BASE_URL}/firms/${firmId}`;

  return await api.get(targetUrl);
};

export const updateFirmRecord = async (firmId: string, data: unknown) => {
  const targetUrl = `${BASE_URL}/firms/${firmId}`;

  return await api.put(targetUrl, data);
};

export const uploadFirmLogo = async (firmId: string, file: File | string) => {
  const targetUrl = `${BASE_URL}/firms/${firmId}/logo`;

  return await api.put(
    targetUrl,
    {
      file,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const getFirmBankingInfo = async (firmId: string) => {
  const targetUrl = `${BASE_URL}/firms/${firmId}/banking`;

  return await api.get(targetUrl);
};

export const startFirmProvisioning = async (firmId: string) => {
  const targetUrl = `${BASE_URL}/firms/${firmId}/banking/start-provisioning`;

  return await api.put(targetUrl);
};

export const withrdawFirmFunds = async (firmId: string, data: unknown) => {
  const targetUrl = `${BASE_URL}/bo/firms/${firmId}/banking/withdraw`;

  return await api.put(targetUrl, data);
};
