import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { QuestionnaireShortData } from '../../types';
import AddButton from '../../components/buttons/AddButton';
import Loader from '../../components/Loader';

import QuestionnaireButton from './QuestionnaireButton';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  questionnairesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    gap: '16px',
  },
  loaderWrapper: {
    marginTop: '16px',
  },
}));

interface QuestionnaireListProps {
  readonly data: QuestionnaireShortData[];
  readonly isFetching: boolean;
  readonly totalCount: number | null;
  readonly handleCreateNewQuestionnaireClick: () => void;
  readonly handleDeleteQuestionnaireClick: (caseType: QuestionnaireShortData) => void;
  readonly handleEditQuestionnaireClick: (caseType: QuestionnaireShortData) => void;
  readonly handleLoadMoreButtonClick: () => void;
}

const QuestionnaireList = ({
  data,
  isFetching,
  totalCount,
  handleCreateNewQuestionnaireClick,
  handleDeleteQuestionnaireClick,
  handleEditQuestionnaireClick,
  handleLoadMoreButtonClick,
}: QuestionnaireListProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.questionnairesContainer}>
        {data.map((questionnaire) => (
          <QuestionnaireButton
            key={questionnaire.id}
            buttonText={questionnaire.name}
            onClick={() => handleEditQuestionnaireClick(questionnaire)}
            onDeleteClick={() => handleDeleteQuestionnaireClick(questionnaire)}
          />
        ))}
        {!isFetching && totalCount !== null && data.length < totalCount && (
          <AddButton
            buttonText="LOAD MORE"
            handleClick={handleLoadMoreButtonClick}
            disabled={isFetching}
          />
        )}
        <AddButton
          buttonText="CREATE NEW QUESTIONNAIRE"
          handleClick={handleCreateNewQuestionnaireClick}
        />
      </Box>
      {isFetching && (
        <Box className={classes.loaderWrapper}>
          <Loader colorType="warning" size={'60px'} />
        </Box>
      )}
    </Box>
  );
};

export default QuestionnaireList;
