import { Box } from '@mui/material';

import { defendantsAccountTabs } from '../../constants/tabRoutes';

import GoBackButton from '../../components/buttons/GoBackButton';
import TabsPanel from '../../components/TabsPanel';

const DefendantAccountView = () => {
  return (
    <>
      <GoBackButton />
      <Box display="flex" width="100%">
        <TabsPanel tabsList={defendantsAccountTabs} locationSlicePosition={3} />
      </Box>
    </>
  );
};

export default DefendantAccountView;
