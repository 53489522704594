/* eslint-disable camelcase */
import axios from 'axios';
import api from '../service/api.service';

const AWS_COGNITO_AUTH_URL = process.env.REACT_APP_AWS_COGNITO_AUTH_URL;
const CLIENT_ID = process.env.REACT_APP_AWS_COGNITO_CLIEND_ID;
const AWS_COGNITO_REDIRECT_URL = process.env.REACT_APP_AWS_COGNITO_REDIRECT_URL;

export const AWS_SIGN_IN_URL = `${AWS_COGNITO_AUTH_URL}/login?client_id=${CLIENT_ID}&response_type=code&scope=openid&redirect_uri=${AWS_COGNITO_REDIRECT_URL}`;

export const verifyUser = async (code: string) => {
  const targetUrl = process.env.REACT_APP_AWS_COGNITO_VERIFY_TOKEN_URL as string;

  const formData = new URLSearchParams({
    grant_type: 'authorization_code',
    client_id: CLIENT_ID as string,
    code,
    redirect_uri: AWS_COGNITO_REDIRECT_URL as string,
  });

  return await axios
    .post(targetUrl, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .catch((error) => error.response);
};

export const refreshUserToken = async (refresh_token: string) => {
  const targetUrl = process.env.REACT_APP_AWS_COGNITO_VERIFY_TOKEN_URL as string;

  const formData = new URLSearchParams({
    grant_type: 'refresh_token',
    client_id: CLIENT_ID as string,
    refresh_token: refresh_token,
  });

  return await api
    .post(targetUrl, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .catch((error) => error.response);
};
