import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Outlet, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { Box, Tabs, Tab, Theme } from '@mui/material';

import { getTabIdByLocation } from '../helpers/getTabIdByLocation';
import StatusPillBar from './StatusPillBar';
import { casesAccountTabsType } from '../constants/tabRoutes';

const useStyles = makeStyles()((theme: Theme) => ({
  defaultTab: {
    background: theme.palette.primary.dark,
    '& .MuiTabs-scroller': {
      overflowX: 'auto !important' as 'scroll',
      '&::-webkit-scrollbar': {
        backgroundColor: '#2b2b2b',
        height: 10,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 10,
        backgroundColor: '#6b6b6b',
        minHeight: 24,
        border: '1px solid #2b2b2b',
      },
    },
    '& .MuiTab-root': {
      color: theme.palette.secondary.light,

      '&.Mui-selected': {
        color: theme.palette.info.main,
      },
    },

    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.info.main,
    },

    '& .MuiTabs-flexContainer': {
      borderBottom: '0.5px solid #515151',
    },
  },
}));

interface TabsPanelProps {
  tabsList: Array<casesAccountTabsType>;
  locationSlicePosition?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contextData?: { [key: string]: any };
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
  };
}

const TabsPanel = ({ tabsList, locationSlicePosition, contextData }: TabsPanelProps) => {
  const styles = useStyles();
  const location = useLocation();

  const [selectedTabId, setSelectedTabId] = useState(
    getTabIdByLocation(tabsList, location.pathname),
  );

  const handleActiveTabChange = (newValue: number) => {
    setSelectedTabId(newValue);
  };

  useEffect(() => {
    handleActiveTabChange(getTabIdByLocation(tabsList, location.pathname, locationSlicePosition));
  }, [location.pathname]);

  return (
    <Box display="flex" width="100%" flexDirection="column">
      <Box display="flex">
        <Box display="flex" width="100%" flexDirection="column">
          <Tabs className={styles.classes.defaultTab} value={selectedTabId}>
            {tabsList.map((tab, idx) => (
              <Tab
                key={tab.path}
                component={Link}
                label={tab.name}
                to={tab.path}
                disabled={tab.isDraft}
                style={{ opacity: `${tab.isDraft ? 0.4 : 0.7}`, color: 'white' }}
                {...a11yProps(idx)}
              />
            ))}
          </Tabs>
        </Box>
        {contextData?.status && <StatusPillBar status={contextData?.status} />}
      </Box>
      <Outlet
        context={{
          entityData: contextData,
        }}
      />
    </Box>
  );
};

export default TabsPanel;
