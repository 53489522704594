import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { TableCell, TableRow, Theme } from '@mui/material';

import { formatDate } from '../../helpers/formatDate';
import { translateStatus } from '../../helpers/translateStatus';
import { User } from '../../types';

const useStyles = makeStyles()((theme: Theme) => ({
  styledLink: {
    textDecoration: 'none',
    color: theme.palette.info.main,
  },
}));

interface AdminsTableDataRowProps {
  tableData: User[];
  isRelatedToOtherEntity?: boolean;
}

const UsersTableDataRow = ({ tableData, isRelatedToOtherEntity }: AdminsTableDataRowProps) => {
  const styles = useStyles();

  const formattedLawyersList =
    tableData &&
    tableData.map((lawyer) => ({
      name: (
        <Link className={styles.classes.styledLink} to={`/users/${String(lawyer.id)}/home`}>
          {`${lawyer.firstName} ${lawyer.lastName}`}
        </Link>
      ),
      ...(!isRelatedToOtherEntity && { firm: lawyer.firm.name }),
      createdAt: formatDate(lawyer.createdAt),
      role: lawyer.role === 'FirmAdmins' && 'Firm Admins',
      status: translateStatus(true),
      email: lawyer.email,
      phone: lawyer.phone,
    }));

  console;

  return (
    <>
      {formattedLawyersList.map((tableItem, idx) => (
        <TableRow key={idx}>
          {Object.values(tableItem).map((item, idx) => (
            <TableCell key={idx}>{item}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default UsersTableDataRow;
