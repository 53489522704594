import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import LinkIcon from '@mui/icons-material/Link';
import { IconButton, Theme, useTheme } from '@mui/material';

const useStyles = makeStyles<{ disabled?: boolean }>()((theme: Theme, { disabled }) => ({
  root: {
    color: disabled ? 'rgba(255, 255, 255, 0.12)' : theme.palette.primary.light,
    marginBottom: '-6px',
  },
}));

interface FormLinkButtonProps {
  path: string;
  disabled?: boolean;
}

const FormLinkButton = ({ path, disabled }: FormLinkButtonProps) => {
  const theme = useTheme();
  const styles = useStyles({ disabled });

  return (
    <Link className={styles.classes.root} to={path} target="_blank">
      <IconButton
        sx={{
          padding: 0,
          paddingBottom: '4px',
          color: theme.palette.primary.light,

          '&.Mui-disabled': {
            color: 'rgba(255, 255, 255, 0.12)',
          },
        }}
        disabled={disabled}
      >
        <LinkIcon />
      </IconButton>
    </Link>
  );
};

export default FormLinkButton;
