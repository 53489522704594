import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import MenuIcon from '@mui/icons-material/Menu';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, useTheme, IconButton, Typography, Skeleton } from '@mui/material';

import Avatar from './Avatar';
import { ReactComponent as LogoImage } from './logo.svg';

import { getAdmin } from '../../api/adminsApi/adminsApi';
import usePageTitle from '../../hooks/usePageTitle';
import useAuth from '../../hooks/useAuth';

interface TopBarProps {
  handleExpandSidebar: () => void;
}

const TopBar = ({ handleExpandSidebar }: TopBarProps) => {
  const theme = useTheme();
  const location = useLocation();
  const { token } = useAuth();

  const handleReloadPage = () => {
    window.location.reload();
  };

  const { pageTitle } = usePageTitle();

  const [currentLocationName, setCurrentLocationName] = useState<string>('');
  const [userReferenceId, setUserReferenceId] = useState<string | null>(null);
  const [userName, setUserName] = useState({
    shortName: '',
    fullName: '',
  });

  const splittedLocationName = location.pathname.split('/').filter((loc) => !!loc);

  const creationRoutes = splittedLocationName.filter((location) => location.includes('create'));
  const editRoutes = splittedLocationName.filter((location) => location.includes('home'));

  useEffect(() => {
    if (splittedLocationName.length === 1) {
      setCurrentLocationName(splittedLocationName[0].replaceAll('-', ' '));
    }

    if (creationRoutes.length) {
      setCurrentLocationName(creationRoutes[0].replaceAll('-', ' '));
    }

    if (editRoutes.length) {
      setCurrentLocationName(pageTitle);
    }

    if (splittedLocationName[0] === 'manage-account') {
      setCurrentLocationName('Manage Account');
    }
  }, [location.pathname, pageTitle]);

  useEffect(() => {
    if (token) {
      const userTokenData: { [x: string]: string } = jwtDecode(token);

      setUserReferenceId(userTokenData.username);

      if (userReferenceId) {
        getAdmin(userReferenceId).then((res) => {
          if (res.data) {
            setUserName((prevState) => ({
              ...prevState,
              fullName: `${res.data?.firstName} ${res.data?.lastName}`,
              shortName: `${res.data?.firstName[0]}${res.data?.lastName[0]} `,
            }));
          }
        });
      }
    }
  }, [token, userReferenceId]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      bgcolor={theme.palette.info.dark}
      width="100%"
      height="48px"
      minWidth="0"
    >
      <Box
        display="flex"
        alignItems="center"
        color={theme.palette.secondary.light}
        gap="15px"
        pl="12px"
        flex="33%"
        minWidth="0"
      >
        <IconButton
          disableRipple
          sx={{
            color: theme.palette.secondary.light,
          }}
          onClick={handleExpandSidebar}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          fontWeight="500"
          fontSize="20px"
          lineHeight="23px"
          textTransform={
            editRoutes.length || currentLocationName.includes('v.') ? 'initial' : 'capitalize'
          }
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {currentLocationName}
        </Typography>
      </Box>

      <Box display="flex" flex="33%" justifyContent="center">
        <LogoImage />
      </Box>

      <Box
        display="flex"
        alignItems="center"
        pr="20px"
        color={theme.palette.secondary.light}
        flex="33%"
        justifyContent="flex-end"
      >
        <IconButton
          disableRipple
          sx={{
            color: theme.palette.secondary.light,
          }}
          onClick={handleReloadPage}
        >
          <RefreshIcon />
        </IconButton>
        {!userName.shortName.length ? (
          <Skeleton variant="circular" width={40} height={40} />
        ) : (
          <Avatar userShortName={userName.shortName} />
        )}
        {!userName.fullName.length ? (
          <Skeleton
            variant="text"
            sx={{ fontSize: '14px', marginLeft: '5px', minWidth: '100px' }}
          />
        ) : (
          <Box overflow="hidden" textOverflow="ellipsis" maxWidth="300px">
            <Typography fontWeight="500" fontSize="14px" lineHeight="16px" ml="5px" noWrap>
              {userName.fullName}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TopBar;
