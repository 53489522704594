import { Dispatch, ReactNode, SetStateAction } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Box, Grid, Table, TableBody, Theme } from '@mui/material';

import TableHeader from './TableHeader';
import TablePaginationContainer from './TablePaginationContainer';

import NoDataText from '../NoDataText';

import { ActiveSortField, SortableFieldsObject } from '../../types';

const useStyles = makeStyles()((theme: Theme) => ({
  tableBodyCell: {
    '& .MuiTableCell-root': {
      color: theme.palette.primary.light,
      borderBottom: '1px solid #515151',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      height: '37px',
      padding: 0,
      paddingLeft: '16px',
      maxWidth: '200px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
}));

interface TableContainerProps {
  isNoData: boolean;
  children: ReactNode;
  headerLabels: { accessor: string; isSortable: boolean; label: string }[];
  activeSortField: ActiveSortField | null;
  noPagination?: boolean;
  handleSortTableData?: (
    sortField: string,
    sortableFields: SortableFieldsObject,
    activeSortField: ActiveSortField | null,
    setActiveSortField?: Dispatch<SetStateAction<ActiveSortField | null>>,
  ) => void;
  sortableFields?: SortableFieldsObject;
  setActiveSortField: Dispatch<SetStateAction<ActiveSortField | null>>;
}

const TableContainer = ({
  isNoData,
  headerLabels,
  children,
  noPagination,
  handleSortTableData,
  activeSortField,
  sortableFields,
  setActiveSortField,
}: TableContainerProps) => {
  const styles = useStyles();

  return (
    <Grid item xs={9} xl={10}>
      {isNoData ? (
        <NoDataText />
      ) : (
        <Box padding="0px 24px 0px 0px">
          <Table
            sx={{
              tableLayout: 'fixed',
            }}
          >
            <TableHeader
              headerLabels={headerLabels}
              handleSortTableData={handleSortTableData}
              sortableFields={sortableFields}
              activeSortField={activeSortField}
              setActiveSortField={setActiveSortField}
            />
            <TableBody className={styles.classes.tableBodyCell}>{children}</TableBody>
          </Table>
          {!noPagination && <TablePaginationContainer />}
        </Box>
      )}
    </Grid>
  );
};

export default TableContainer;
