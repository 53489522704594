import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { TableCell, TableRow, Theme } from '@mui/material';

import { formatDate } from '../../../helpers/formatDate';
import { DeductionData } from '../../../types';
import { numberToUSDCurrency } from '../../../helpers/numberToUSDCurrency';
import { parseStatusTitle } from '../../../helpers/parseStatusTitle';

const useStyles = makeStyles()((theme: Theme) => ({
  styledLink: {
    textDecoration: 'none',
    color: theme.palette.info.main,
  },
}));

interface DeductionsTableDataRowProps {
  tableData: DeductionData[];
  isRelatedToOtherEntity?: { accessor: string; isSortable: boolean; label: string }[];
}

const DeductionsTableDataRow = ({
  tableData,
  isRelatedToOtherEntity,
}: DeductionsTableDataRowProps) => {
  const styles = useStyles();
  const tableLabelsObjectToFlat = isRelatedToOtherEntity?.map((labelItem) => labelItem.label);

  const formattedFirmsList =
    tableData &&
    tableData.map((deduction: DeductionData) => ({
      name: (
        <Link className={styles.classes.styledLink} to={`/deductions/${String(deduction.id)}/home`}>
          {deduction.case.name}
        </Link>
      ),
      ...((tableLabelsObjectToFlat?.includes('Firm') || !isRelatedToOtherEntity) && {
        firm: deduction.firm.name,
      }),
      ...((tableLabelsObjectToFlat?.includes('Plaintiff') || !isRelatedToOtherEntity) && {
        plaintiff: `${deduction.client.firstName} ${deduction.client.lastName}`,
      }),
      ...((tableLabelsObjectToFlat?.includes('Defendant') || !isRelatedToOtherEntity) && {
        defendant: deduction.defendant?.name || '-',
      }),
      entryDate: formatDate(deduction.createdAt as string),
      type: deduction.type,
      ...(tableLabelsObjectToFlat?.includes('Assignment') && {
        assignment: deduction.defendant ? deduction.defendant.name : '-',
      }),
      amount: numberToUSDCurrency(deduction.amount),
      status: parseStatusTitle(deduction.status),
      paidAt: deduction.paidAt ? formatDate(deduction.paidAt as string) : '-',
    }));

  return (
    <>
      {formattedFirmsList.map((tableItem, idx) => (
        <TableRow key={idx}>
          {Object.values(tableItem).map((item, idx) => (
            <TableCell key={idx}>{item}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default DeductionsTableDataRow;
