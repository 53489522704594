import api from '../service/api.service';

import {
  ActiveSortField,
  CaseData,
  CaseShortData,
  PaginationQueryParams,
  ResultSet,
} from '../../types';
import { parseQueryFilters } from '../../helpers/parseQueryFilters';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';

const BASE_URL_V4 = `${process.env.REACT_APP_BASE_API_URL}/v4/cases`;
const BASE_URL_V5 = `${process.env.REACT_APP_BASE_API_URL}/v5/cases`;

export const createCaseRecord = async (data: unknown) => {
  return await api.post<CaseData>(BASE_URL_V5, data);
};

export const getCasesList = async ({
  size,
  page,
  count,
  match,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL_V5}?size=${size}&page=${page}&count=${count}&match=${match || ''}${
    queryFilters || ''
  }${sortFilters}`;

  return await api.get<ResultSet<CaseShortData>>(targetUrl);
};

export const getCaseRecord = async (id: number) => {
  const targetUrl = `${BASE_URL_V5}/${id}`;

  return await api.get<CaseData>(targetUrl);
};

export const updateCaseRecord = async (id: number, data: unknown) => {
  const targetUrl = `${BASE_URL_V5}/${id}`;

  return await api.put<CaseData>(targetUrl, data);
};

export const submitCase = async (caseId: string) => {
  const targetUrl = `${BASE_URL_V4}/${caseId}/submit`;

  return await api.put(targetUrl);
};

export const abandonCase = async (caseId: string) => {
  const targetUrl = `${BASE_URL_V4}/${caseId}/abandon`;

  return await api.put(targetUrl);
};
