import { Fragment, useRef, useState } from 'react';
import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { CaseTypeCategories } from '../../constants/constants';

const useStyles = makeStyles()((theme: Theme) => ({
  default: {
    background: 'rgba(255, 255, 255, 0.12)',
    color: 'rgba(255, 255, 255, 0.3)',
    height: '52px',
  },
  mainButton: {
    '&:hover': {
      background: theme.palette.info.main,
      color: theme.palette.primary.main,
    },

    '&:disabled': {
      background: 'rgba(255, 255, 255, 0.12)',
      color: 'rgba(255, 255, 255, 0.3)',
    },
  },
  dropDownButton: {
    '&:hover': {
      background: theme.palette.info.main,
      color: theme.palette.primary.main,
    },
  },
  deleteButton: {
    '&:hover': {
      background: theme.palette.error.main,
      color: theme.palette.primary.main,
    },
  },
}));

enum Options {
  DefaultTrustAccount = 'Default Trust Account',
  Delete = 'Delete',
}

interface CaseTypeButtonProps {
  readonly buttonText: string;
  readonly category: string;
  readonly editable?: boolean;
  readonly global?: boolean;
  readonly onPress?: () => void;
  readonly onDelete?: () => void;
  readonly onDefaultTrustAccountClick?: () => void;
}

const CaseTypeButton = ({
  buttonText,
  category,
  editable,
  global,
  onPress,
  onDelete,
  onDefaultTrustAccountClick,
}: CaseTypeButtonProps) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    onPress?.();
  };

  const handleMenuItemClick = (option: Options) => {
    switch (option) {
      case Options.DefaultTrustAccount:
        onDefaultTrustAccountClick?.();
        break;
      case Options.Delete:
        onDelete?.();
        break;
      default:
        break;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const getMenuOptions = (): Array<Options> => {
    if (global) return [Options.Delete];
    if (category === CaseTypeCategories.Custom)
      return [Options.DefaultTrustAccount, Options.Delete];
    return [Options.DefaultTrustAccount];
  };

  const hasOnlyDeleteOption = (): boolean => {
    const options = getMenuOptions();

    return options.length === 1 && options[0] === Options.Delete;
  };

  return (
    <Fragment>
      <ButtonGroup variant="contained" ref={anchorRef}>
        <Button
          className={clsx(classes.default, classes.mainButton)}
          disabled={!editable}
          onClick={handleClick}
        >
          {buttonText}
        </Button>
        {hasOnlyDeleteOption() ? (
          <Button
            className={clsx(classes.default, classes.deleteButton)}
            size="small"
            onClick={() => {
              setOpen(false);
              onDelete?.();
            }}
          >
            <DeleteForeverIcon />
          </Button>
        ) : (
          <Button
            className={clsx(classes.default, classes.dropDownButton)}
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      {!hasOnlyDeleteOption() && (
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {getMenuOptions().map((option) => (
                      <MenuItem key={option} onClick={() => handleMenuItemClick(option)}>
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </Fragment>
  );
};

export default CaseTypeButton;
