import { useState } from 'react';

import { Box, useTheme } from '@mui/material';

import AppRoutes from '../../api/AppRoutes';
import TopBar from '../../components/TopBar/TopBar';
import Sidebar from '../../components/Sidebar/Sidebar';
import useAuth from '../../hooks/useAuth';
// import Popup from '../../components/Popup';
// import useIdleTimeout from '../../hooks/useIdleTimeout';
// import { POPUP_DESCRIPTIONS } from '../../constants/popupDescriptions';

const MainLayoutView = () => {
  const theme = useTheme();
  const { token } = useAuth();

  const [isSidebarExpanded, setIsSidebarExpanded] = useState<boolean>(true);
  // const [isOpen, setIsOpen] = useState<boolean>(false);

  // const handleIdle = () => {
  //   if (token) {
  //     setIsOpen(true);
  //   } else {
  //     if (isOpen) {
  //       setIsOpen(false);
  //     }
  //     idleTimer.reset();
  //   }
  // };

  // const { idleTimer } = useIdleTimeout({ onIdle: handleIdle, idleTime: 86400 }); // TODO: 300 sec, create a constant

  // const resetIdleTimer = () => {
  //   setIsOpen(false);
  //   idleTimer.reset();
  // };

  // const handleLogout = () => {
  //   setIsOpen(false);
  //   onLogOut?.();
  // };

  return (
    <Box display="flex" width="100%" flexDirection="column" height="100%">
      {token && <TopBar handleExpandSidebar={() => setIsSidebarExpanded(!isSidebarExpanded)} />}

      <Box display="flex" height="calc(100% - 78px)" gap="8px">
        {token && <Sidebar isSidebarExpanded={isSidebarExpanded} />}

        <Box
          color={theme.palette.primary.light}
          width="100%"
          padding="50px 0 0 0"
          sx={{
            overflowX: 'hidden',
          }}
        >
          <AppRoutes />
        </Box>
      </Box>

      {/* {token && (
        <Popup
          isOpen={isOpen}
          headlineText={POPUP_DESCRIPTIONS.sessionExpired.headlineText}
          contentText={POPUP_DESCRIPTIONS.sessionExpired.contentText}
          mainButtonText="Continue working"
          secondaryButtonText="Log out"
          onMainButtonClick={resetIdleTimer}
          onSecondaryButtonClick={handleLogout}
        />
      )} */}
    </Box>
  );
};

export default MainLayoutView;
