import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Theme, Typography } from '@mui/material';

import FirmBankingIntegrationContainer from './FirmBankingIntegrationContainer';
import FirmBankingSystemAccountContainer from './FirmBankingSystemAccountContainer';
import FirmBankingWithdrawForm from './FirmBankingWithdrawForm';
import FirmBankingWithdrawPopup from './FirmBankingWithdrawPopup';
import FirmTrustAccountsView from './trustAccountsView/FirmTrustAccountsView';
import FirmUnrestrictedAccountsView from './unrestrictedAccountsView/FirmUnrestrictedAccountsView';

import {
  AccountTypeShortCuts,
  FirmBankingData,
  FirmBankingWithdrawFormSubmitProps,
  UnrestrictedAccountOptionsData,
} from '../../types';

import {
  getFirmBankingInfo,
  startFirmProvisioning,
  withrdawFirmFunds,
} from '../../../../api/firmsApi/firmsApi';
import PaginationProvider from '../../../../providers/PaginationProvider';
import VerificationPopup from '../../../../components/VerificationPopup';
import { checkAdminPin } from '../../../../api/adminsApi/adminsApi';
import useSnackBar from '../../../../hooks/useSnackBar';
import { ErrorData } from '../../../../types';
import { firmWithdrawFundsFormSchema } from '../../../../helpers/formSchemas/formSchemas';
import { FIRM_WITHRDAW_FUNDS_FORM_DEFAULT_VALUES } from '../../../../helpers/formSchemas/formDefaultValues';

const useStyles = makeStyles()((theme: Theme) => ({
  systemAccountsWrapper: {
    display: 'flex',
    marginTop: '16px',
    gap: '20px',
  },
  systemAccountContainer: {
    display: 'flex',
    padding: '16px',
    background: theme.palette.primary.dark,
    flexDirection: 'column',
    width: '100%',
  },
  systemAccountsBlockLabel: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  systemAccountsLabel: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  systemAccountsInnerContainer: {
    display: 'flex',
    padding: '0px 10px 10px 10px',
    justifyContent: 'space-between',
    height: '100%',
  },
  systemAccountDivider: {
    height: '100%',
    width: '1px',
    background: '#393939',

    '&:last-of-type': {
      display: 'none',
    },
  },
}));

const FirmBankingView = () => {
  const styles = useStyles();
  const { firmId } = useParams();
  const { setAlert } = useSnackBar();

  const [bankingData, setBankingData] = useState<FirmBankingData>();
  const [selectedAccountType, setSelectedAccountType] = useState('');
  const [isWithdrawalPopupOpen, setIsWithdrawalPopupOpen] = useState<boolean>(false);
  const [isVerificationPopupOpen, setIsVerificationPopupOpen] = useState<boolean>(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [shortUnrestrictedAccountData, setShortUnrestrictedAccountData] = useState<
    UnrestrictedAccountOptionsData[]
  >([]);

  const userId = localStorage.getItem('userId');

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(firmWithdrawFundsFormSchema),
    defaultValues: FIRM_WITHRDAW_FUNDS_FORM_DEFAULT_VALUES,
  });

  const { handleSubmit } = formMethods;

  const onSubmitForm = (values: FirmBankingWithdrawFormSubmitProps) => {
    setIsFormSubmitting(true);

    const sumbitData = {
      fromSystemAccountType:
        AccountTypeShortCuts[values.fromSystemAccountType as keyof typeof AccountTypeShortCuts],
      toUnrestrictedAccountId: Number(values.toUnrestrictedAccountId),
      amount: Number(values.amount),
    };

    withrdawFirmFunds(String(firmId), sumbitData)
      .then((res) => {
        if (res.status === 202) {
          setAlert((prev) => ({
            ...prev,
            message: 'Withdraw has been operated successfully.',
            type: 'success',
          }));
          setIsFormSubmitting(false);
          setIsWithdrawalPopupOpen(false);
        }
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message || 'Something went wrong...',
          type: 'error',
        }));
        setIsFormSubmitting(false);
      });
  };

  const fetchBankingData = () => {
    getFirmBankingInfo(firmId as string).then((res) => {
      setBankingData(res.data);
    });
  };

  const handleIntegrationSubmit = () => {
    startFirmProvisioning(firmId as string)
      .then(() => {
        fetchBankingData();
      })
      .catch(() => {
        fetchBankingData();
      });
  };

  const handleConfirmPin = (pin: string) => {
    if (!userId) return;

    checkAdminPin(Number(userId), pin)
      .then((response) => {
        if (response.data.isValid) {
          setIsVerificationPopupOpen(false);
          handleSubmit(onSubmitForm)();
        } else {
          setAlert((prev) => ({
            ...prev,
            message: 'Invalid pin. Please try again.',
            type: 'error',
          }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      });
  };

  const handleCloseVerificationPopup = () => setIsVerificationPopupOpen(false);

  useEffect(() => {
    fetchBankingData();
  }, []);

  return (
    <Box pr="8px">
      <Box className={styles.classes.systemAccountsWrapper}>
        <FirmBankingIntegrationContainer
          canStartIntegration={bankingData?.canStartIntegration}
          handleIntegrationSubmit={handleIntegrationSubmit}
          integrationData={bankingData?.integration}
        />
        <Box className={styles.classes.systemAccountContainer}>
          <Typography className={styles.classes.systemAccountsBlockLabel} mb="10px">
            System Accounts
          </Typography>

          <Box className={styles.classes.systemAccountsInnerContainer}>
            {bankingData?.accounts && (
              <>
                {Object.entries(bankingData?.accounts).map(([accountType, accountData]) => (
                  <React.Fragment key={accountType}>
                    <FirmBankingSystemAccountContainer
                      label={accountType}
                      accountData={accountData}
                      setSelectedAccountType={setSelectedAccountType}
                      setIsWithdrawalPopupOpen={setIsWithdrawalPopupOpen}
                    />
                    <Box className={styles.classes.systemAccountDivider} />
                  </React.Fragment>
                ))}
              </>
            )}
          </Box>
        </Box>
      </Box>

      <PaginationProvider key="firm-unrestricted-accounts">
        <FirmUnrestrictedAccountsView
          setShortUnrestrictedAccountData={setShortUnrestrictedAccountData}
        />
      </PaginationProvider>
      <PaginationProvider key="firm-trust-accounts">
        <FirmTrustAccountsView />
      </PaginationProvider>

      <FormProvider {...formMethods}>
        <FirmBankingWithdrawPopup
          isOpen={isWithdrawalPopupOpen}
          setSelectedAccountType={setSelectedAccountType}
          handleClosePopup={() => setIsWithdrawalPopupOpen(false)}
          selectedAccountType={selectedAccountType}
          isFormSubmitting={isFormSubmitting}
          handleOpenVerificationPopup={() => setIsVerificationPopupOpen(true)}
        >
          <FirmBankingWithdrawForm unrestrictedAccountOptionsData={shortUnrestrictedAccountData} />
        </FirmBankingWithdrawPopup>
      </FormProvider>

      <VerificationPopup
        isOpen={isVerificationPopupOpen}
        onMainButtonClick={handleConfirmPin}
        onSecondaryButtonClick={handleCloseVerificationPopup}
      />
    </Box>
  );
};

export default FirmBankingView;
