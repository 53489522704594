import { Navigate } from 'react-router';

import useAuth from '../hooks/useAuth';

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { token } = useAuth();
  const isSuperAdmin = localStorage.getItem('isSuperAdmin');

  if (!token) {
    const { search, pathname } = window.location;
    sessionStorage.setItem('backUrl', btoa(pathname + search));

    return <Navigate to="/" replace />;
  }

  const baseRouteName = location.pathname.split('/')[1];
  if (baseRouteName === 'admins' && !isSuperAdmin) {
    return <Navigate to="/" replace />;
  }

  return token ? children : null;
};

export default ProtectedRoute;
