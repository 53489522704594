export interface DeductionCreateFormSubmitProps {
  id?: string;
  isAssigned?: boolean;
  defendant: { id?: string; label?: string };
  type: string;
  amount: string;
}

export interface SettlementCreateFormSubmitProps {
  id?: string;
  defendant: { id: string; label: string };
  amount: string;
}

export interface DeductionEditFormSubmitProps {
  id?: string;
  isAssigned?: boolean;
  caseReferenceId?: string;
  defendant: { id?: string; label?: string };
  type: string;
  amount: string;
}

export interface SettlementEditFormSubmitProps {
  id?: string;
  isAssigned?: boolean;
  caseReferenceId?: string;
  defendant: { id?: string; label?: string };
}

export interface SettlementsDeductionData {
  id?: string;
  type: string;
  amount: number;
  isAssigned: string;
  createdAt: string;
  isAdded: boolean;
}

export interface SettlementsPayoutCheckData {
  attorneysFees?: {
    leadFirm?: {
      id: string;
      name: string;
      fee: string | number;
    };
    secondaryFirms?: {
      id: string;
      name: string;
      role: string;
      fees: {
        fixed: string | number;
        variable: string | number;
        total: string | number;
      };
    }[];
    total: string | number;
  };
  deductions?: {
    advances: number | string;
    liens: number | string;
    total: number | string;
  };
  totalGrossSettlement?: number | string;
  netSettlement?: {
    plaintiffId: number;
    amount: number | string;
  };
  additionalInfo?: SettlementAdditionalInfoData;
  total?: string | number;
  isValid?: boolean;
}

export interface SettlementAdditionalInfoData {
  percentOfGrossSettlement: string | number;
  percentOfTotalGrossSettlement: string | number;
  defendantUnpaidGrossSettlement: string | number;
}

export enum statusFundingSettlment {
  NotStarted = 'NotStarted',
  Initializing = 'Initializing',
  WaitingFunds = 'WaitingFunds',
  OnHold = 'OnHold',
  Error = 'Error',
  CriticalError = 'CriticalError',
  Completed = 'Completed',
}

export interface SettlementDetailsData {
  funding: {
    caseAccountNumber: string;
    status: string; // statusFundingSettlment
    canHold: false;
    canRelease: false;
    holdEndedAt: string | null;
    holdUntil: string | null;
    holdStartedAt: string | null;
  };
  distributions: {
    attorneysFees?: {
      leadFirm?: {
        id: string;
        name: string;
        amount: string | number;
        status: string;
      };
      secondaryFirms?: {
        id: string;
        name: string;
        role: string;
        status: string;
        amount: string;
      }[];
      totalAmount: string | number;
    };
    deductions?: {
      id: string;
      status: string;
      type: string;
      amount: number;
    }[];
    deductionsTotalAmount: number;
    netSettlement: {
      amount: number;
      status: string;
    };
  };
  settledAmount: number;
  error?: {
    reason: string;
    retryAvailable: boolean;
  };
  cancelAvailable: boolean;
}

export interface ResponseHoldSettlement {
  holdUntil: string | null;
  holdStartedAt: string | null;
}
