import { Box, TableCell, TableRow } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import FirmBankingTableActionButton from '../FirmBankingTableActionButton';

import { FirmTrustAccountSelectedTableItem, FirmTrustAccountTableData } from '../../../types';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface FirmTrustAccountTableRowProps {
  accountsData: FirmTrustAccountTableData[];
  onEditButtonClick: (tableItem: FirmTrustAccountSelectedTableItem) => void;
  onDeleteButtonClick: (accountId: number) => void;
}

const FirmTrustAccountTableRow = ({
  accountsData,
  onEditButtonClick,
  onDeleteButtonClick,
}: FirmTrustAccountTableRowProps) => {
  const formattedAccountsData =
    accountsData &&
    accountsData.map((account) => ({
      id: account.id,
      createdAt: account.createdAt,
      name: account.name,
      accountNumber: account.accountNumber,
      routingNumber: account.routingNumber,
      isVerified: account.isVerified ? 'Verified' : 'Unverified',
      isVerifiedOrigin: account.isVerifiedOrigin ? 'Verified' : 'Unverified',
      canBeDeleted: account.canBeDeleted,
    }));

  return (
    <>
      {formattedAccountsData.map((tableItem) => (
        <TableRow key={tableItem.id}>
          {Object.entries(tableItem).map(([key, value], idx) => {
            if (key === 'isVerified')
              return (
                <TableCell key={idx}>
                  {value === 'Verified' ? (
                    value
                  ) : (
                    <Box display="flex" gap="5px" justifyContent="flex-center" alignItems="center">
                      <ErrorOutlineIcon sx={{ color: '#F44336' }} />
                      {tableItem.isVerifiedOrigin}
                    </Box>
                  )}
                </TableCell>
              );
            return (
              <>
                {!(key === 'id' || key === 'isVerifiedOrigin' || key === 'canBeDeleted') && (
                  <TableCell key={idx}>{value as string}</TableCell>
                )}
              </>
            );
          })}
          <TableCell>
            <Box display="flex" gap="12px" justifyContent="flex-end" alignItems="center">
              <FirmBankingTableActionButton
                type="info"
                buttonText="more"
                buttonIcon={
                  <ReadMoreIcon
                    sx={{
                      fontSize: '22px',
                    }}
                  />
                }
                handleClick={() => onEditButtonClick(tableItem)}
              />
              <FirmBankingTableActionButton
                type="error"
                buttonText="delete"
                buttonIcon={
                  <DeleteForeverIcon
                    sx={{
                      fontSize: '18px',
                    }}
                  />
                }
                disabled={!tableItem.canBeDeleted}
                handleClick={() => onDeleteButtonClick(tableItem.id)}
              />
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default FirmTrustAccountTableRow;
