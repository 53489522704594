import { TooltipProps, Tooltip } from '@mui/material';

interface DefaultTooltipProps extends TooltipProps {
  children: JSX.Element;
}

const CustomerBankingErrorTooltip = ({ children, ...rest }: DefaultTooltipProps) => {
  return (
    <Tooltip
      {...rest}
      componentsProps={{
        tooltip: {
          sx: {
            padding: '16px',
            borderRadius: 0,
            backgroundColor: '#333030',
            maxWidth: '500px',
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default CustomerBankingErrorTooltip;
