import { makeStyles } from 'tss-react/mui';

import { Box, Theme, Typography, useTheme } from '@mui/material';

import { CLIENT_WALLET_ACCOUNT_STATUSES, CustomerBankgingWalletAccountData } from '../types';

import { numberToUSDCurrency } from '../../../helpers/numberToUSDCurrency';

const useStyles = makeStyles<{ chipColor?: string }>()((theme: Theme, { chipColor }) => ({
  systemAccountWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '90%',
    justifyContent: 'space-between',
    minWidth: '262px',
  },
  systemAccountsColoredLabel: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: 'normal',
    color: theme.palette.info.main,
  },
  systemAccountsSmallLabel: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 'normal',
    color: theme.palette.secondary.main,
  },
  systemAccountsLabel: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  systemAccountsBalanceText: {
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  noAccountText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '19px',
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.3)',
    maxWidth: '260px',
  },
  systemAccountStatusChip: {
    padding: '4px 16px',
    borderRadius: '16px',
    textTransform: 'uppercase',
    border: `1px solid ${chipColor}`,
    background: `${chipColor}19`,
    color: chipColor,
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Roboto',
    lineHeight: '16px',
  },
}));

interface CustomerBankingWalletAccountContainerProps {
  accountData?: CustomerBankgingWalletAccountData | null;
}

const CustomerBankingWalletAccountContainer = ({
  accountData,
}: CustomerBankingWalletAccountContainerProps) => {
  const styles = useStyles({ chipColor: '' });

  return (
    <>
      {!accountData ? (
        <Box className={styles.classes.systemAccountWrapper}>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Typography className={styles.classes.noAccountText}>
              No information about this account, or it has not been created yet.
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box className={styles.classes.systemAccountWrapper}>
          <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px">
              <Typography fontSize={20}>Current Balance</Typography>
              <SystemAccountStatusChip status={accountData.status} />
            </Box>

            <Box>
              <Typography className={styles.classes.systemAccountsBalanceText} mt="8px">
                {numberToUSDCurrency(accountData.balance)}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between" gap="10px">
            <Box>
              <Typography className={styles.classes.systemAccountsSmallLabel}>
                Account Number
              </Typography>
              <Typography className={styles.classes.systemAccountsLabel} mt="8px">
                {accountData.accountNumber}
              </Typography>
            </Box>

            <Box>
              <Typography className={styles.classes.systemAccountsSmallLabel}>
                {'Routing Number (ABA)'}
              </Typography>
              <Typography className={styles.classes.systemAccountsLabel} mt="8px">
                {accountData.routingNumber}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

interface SystemAccountStatusChipProps {
  status: string;
}

const SystemAccountStatusChip = ({ status }: SystemAccountStatusChipProps) => {
  const theme = useTheme();

  const SystemAccountStatusesChipColor = {
    CLOSED: theme.palette.error.main,
    BLOCKED: theme.palette.error.main,
    CLOSURE_INITIATED: theme.palette.warning.main,
    INACTIVE: theme.palette.warning.main,
    ACTIVE: theme.palette.success.main,
  };

  const currentChipColor =
    SystemAccountStatusesChipColor[status as keyof typeof CLIENT_WALLET_ACCOUNT_STATUSES];

  const styles = useStyles({ chipColor: currentChipColor });

  return (
    <Box className={styles.classes.systemAccountStatusChip}>
      {CLIENT_WALLET_ACCOUNT_STATUSES[status as keyof typeof CLIENT_WALLET_ACCOUNT_STATUSES]}
    </Box>
  );
};

export default CustomerBankingWalletAccountContainer;
