import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { Box, Chip, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { AxiosError } from 'axios';

import CommonButton from '../../../components/buttons/CommonButton';
import Popup from '../../../components/Popup';
import { POPUP_DESCRIPTIONS } from '../../../constants/popupDescriptions';
import { numberToUSDCurrency } from '../../../helpers/numberToUSDCurrency';
import {
  getSettlementDetails,
  holdSettlement,
  paySettlement,
  paySettlementRetry,
  releaseSettlement,
} from '../../../api/paymentsApi/settlementsApi';
import { useParams } from 'react-router-dom';
import { ResponseHoldSettlement, SettlementDetailsData } from '../types';
import Loader from '../../../components/Loader';
import useSnackBar from '../../../hooks/useSnackBar';
import { ErrorData } from '../../../types';
import VerificationPopup from '../../../components/VerificationPopup';
import { checkAdminPin } from '../../../api/adminsApi/adminsApi';
import { formatDate } from '../../../helpers/formatDate';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { StatusFundingLabels } from '../../../constants/constants';

const useStyles = makeStyles()((theme: Theme) => ({
  wrapperButton: { position: 'relative' },
  loader: {
    position: 'absolute',
    width: '100%',
    top: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    height: '100%',
  },
  coloredHeadlineText: {
    color: theme.palette.info.main,
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  settledAmountText: {
    color: theme.palette.common.white,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  accountText: {
    color: theme.palette.common.white,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  commonText: {
    color: theme.palette.common.white,
    fontWeight: 500,
    lineHeight: 'normal',
    fontStyle: 'normal',
  },
  fontSize12: {
    fontSize: '12px',
  },
  fontSize14: {
    fontSize: '14px',
  },
  fontSize16: {
    fontSize: '16px',
  },
  fontSize18: {
    fontSize: '18px',
  },
  fontSize20: {
    fontSize: '20px',
  },
  fontSize24: {
    fontSize: '24px',
  },
  fontSize32: {
    fontSize: '32px',
  },
  textGrayColor: {
    color: 'rgba(255, 255, 255, 0.70)',
  },
  detailsContainer: {
    display: 'flex',
    backgroundColor: theme.palette.primary.dark,
    padding: '16px',
    maxWidth: '1350px',
  },
  detailsInnerContainer: {
    display: 'flex',
    padding: '10px',
    width: '100%',
    maxWidth: '1350px',
    justifyContent: 'space-between',
  },
  accountInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    maxWidth: '300px',
    width: '100%',
    paddingRight: '10px',
  },
  statusChip: {
    padding: '4px 16px',
    borderRadius: '16px',
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Roboto',
    lineHeight: 'normal',
    maxHeight: '24px',
    maxWidth: '200px',

    '& .MuiChip-label': {
      padding: 0,
    },
  },
  distributionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '480px',
    width: '100%',
    padding: '0 10px',
  },
  distributionContainer: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    padding: '10px',
  },
  distributionStatusContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  distributionsSectionDivider: {
    height: '1px',
    background: 'rgba(255, 255, 255, 0.40)',
    margin: '20px 0',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    maxWidth: '270px',
    width: '100%',
  },
  formSectionsDivider: {
    width: '1px',
    background: '#393939',
    height: '100%',
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '400px',
    width: '100%',
    justifyContent: 'center',
  },
  noDetailsTextBlock: {
    display: 'flex',
    minHeight: '400px',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  errorMessageContainer: {
    display: 'flex',
    padding: '16px',
    background: '#333030',
    width: '100%',
    margin: '12px 0',
    boxSizing: 'border-box',
  },
  errorMessageText: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: 'normal',
    color: theme.palette.error.main,
  },
  holdAlert: {
    color: theme.palette.warning.main,
    marginLeft: 10,
    marginTop: 5,
    opacity: 0.8,
  },
}));

const SettlementsDetailsView = () => {
  const theme = useTheme();
  const { settlementId } = useParams();
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();
  const { loading, error, handleApiRequest, success } = useApiRequest<
    ResponseHoldSettlement,
    [string]
  >();
  const userId = localStorage.getItem('userId');
  const isSuperAdmin = localStorage.getItem('isSuperAdmin');
  const [settlementDetails, setSettlementDetails] = useState<SettlementDetailsData>();
  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState<boolean>(false);
  const [isPaymentDetailsFetching, setIsPaymentDetailsFetching] = useState<boolean>(true);
  const [isVerificationPopupOpen, setIsVerificationPopupOpen] = useState<boolean>(false);
  const [statusRequest, setStatusRequest] = useState<'hold' | 'release' | 'retry'>('hold');

  const handleCloseVerificationPopup = () => setIsVerificationPopupOpen(false);
  // const handleOpenVerificationPopup = () => setIsVerificationPopupOpen(true);

  const FundingStatusChipColor = {
    NotStarted: theme.palette.warning.main,
    Initializing: theme.palette.warning.main,
    WaitingFunds: theme.palette.warning.main,
    OnHold: theme.palette.warning.main,
    Error: theme.palette.error.main,
    CriticalError: theme.palette.error.main,
    InProgress: theme.palette.warning.main,
    Completed: theme.palette.success.main,
  };

  const DistributionStatusChipColor = {
    Unpaid: theme.palette.warning.main,
    PaymentInProgress: theme.palette.warning.main,
    CriticalError: theme.palette.error.main,
    Error: theme.palette.error.main,
    Paid: theme.palette.success.main,
  };

  const getFundingStatusChipStyles = () => {
    const chipColor =
      FundingStatusChipColor[
        settlementDetails?.funding.status as keyof typeof FundingStatusChipColor
      ];

    return {
      color: chipColor,
      border: `1px solid ${chipColor}`,
      background: `${chipColor}1A`,
    } as SxProps<Theme> | undefined;
  };

  const getDistributionStatusChipStyles = (status: string) => {
    const chipColor =
      DistributionStatusChipColor[status as keyof typeof DistributionStatusChipColor];

    return {
      color: chipColor,
      border: `1px solid ${chipColor}`,
      background: `${chipColor}1A`,
      width: '120px',
      span: {
        fontSize: '12px',
      },
    } as SxProps<Theme> | undefined;
  };

  const handleRetryPayProcess = (pin: string) => {
    setIsPaymentDetailsFetching(true);

    const mappedDeductions = settlementDetails?.distributions.deductions?.map((deduction) =>
      Number(deduction.id),
    );

    paySettlement(settlementId as string, pin, mappedDeductions as number[])
      .then((res) => {
        // This status check probably must be changed to just res.data when pay will proccess through real trust account
        if (res.status === 202) {
          setIsPaymentDetailsFetching(false);
          setAlert((prev) => ({
            ...prev,
            message: 'The settlement payment process has been initiated.',
            type: 'success',
          }));

          setTimeout(() => window.location.reload(), 500);
        }
      })
      .catch((err) => {
        setIsPaymentDetailsFetching(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleConfirmPin = (pin: string) => {
    if (!userId) return;

    checkAdminPin(Number(userId), pin)
      .then((response) => {
        if (response.data.isValid) {
          handleCloseVerificationPopup();
          handleRetryPayProcess(pin);
        } else {
          setAlert((prev) => ({
            ...prev,
            message: 'Invalid pin. Please try again.',
            type: 'error',
          }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      });
  };

  /*   const handleRetryPayProcessV2 = () => {
    setIsPaymentDetailsFetching(true);
    paySettlementRetry(settlementId as string)
      .then((res) => {
        // This status check probably must be changed to just res.data when pay will proccess through real trust account
        if (res.status === 202) {
          setIsPaymentDetailsFetching(false);
          setAlert((prev) => ({
            ...prev,
            message: 'The settlement payment process has been initiated.',
            type: 'success',
          }));

          setTimeout(() => window.location.reload(), 500);
        }
      })
      .catch((err) => {
        setIsPaymentDetailsFetching(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };
   */

  const handleRetryPayProcessV2 = () => {
    setStatusRequest('retry');
    settlementId && handleApiRequest(paySettlementRetry, settlementId);
  };

  const onHoldSettlement = () => {
    setStatusRequest('hold');
    settlementId && handleApiRequest(holdSettlement, settlementId);
  };

  const onReleaseSettlement = () => {
    setStatusRequest('release');
    settlementId && handleApiRequest(releaseSettlement, settlementId);
  };

  const getDetails = () => {
    getSettlementDetails(settlementId as string)
      .then((res) => {
        if (res.data) {
          setSettlementDetails(res.data);
          setIsPaymentDetailsFetching(false);
        }
      })
      .catch(() => {
        setIsPaymentDetailsFetching(false);
        setSettlementDetails(undefined);
      });
  };

  useEffect(() => {
    getDetails();
  }, [settlementId]);

  useEffect(() => {
    if (error) {
      setAlert((prev) => ({
        ...prev,
        message: error,
        type: 'error',
      }));
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      const message =
        statusRequest === 'retry'
          ? 'The settlement payment process has been initiated.'
          : `The request to start the ${statusRequest} period has been sent.`;
      localStorage.setItem('alertMessage', message);
      window.location.reload();
    }
  }, [success]);

  useEffect(() => {
    const alertMessage = localStorage.getItem('alertMessage');
    if (alertMessage) {
      setAlert((prev) => ({
        ...prev,
        message: alertMessage,
        type: 'success',
      }));
      localStorage.removeItem('alertMessage');
    }
  }, []);

  if (isPaymentDetailsFetching) {
    return (
      <Box className={classes.detailsContainer}>
        <Box className={classes.loaderContainer}>
          <Loader colorType="inherit" />
        </Box>
      </Box>
    );
  }

  return (
    <>
      {!!settlementDetails?.error?.reason?.length && (
        <Box className={classes.errorMessageContainer}>
          <Typography className={classes.errorMessageText}>
            {settlementDetails.error.reason}
          </Typography>
        </Box>
      )}
      <Box className={classes.detailsContainer}>
        {settlementDetails === undefined ? (
          <Box className={classes.noDetailsTextBlock}>
            <Typography>
              No data is currently available. Transaction details will be displayed once a
              settlement is initiated for payment.
            </Typography>
          </Box>
        ) : (
          <Box className={classes.detailsInnerContainer}>
            <Box className={classes.accountInfoContainer}>
              <Box display="flex" justifyContent="space-between" pb="7px" alignItems="center">
                <Typography className={clsx(classes.commonText, classes.fontSize24)}>
                  Funding
                </Typography>
                <Chip
                  className={classes.statusChip}
                  label={
                    StatusFundingLabels[
                      settlementDetails?.funding.status as keyof typeof StatusFundingLabels
                    ]
                  }
                  sx={getFundingStatusChipStyles()}
                />
              </Box>
              <Box display="flex" flexDirection="column" gap="12px" pb="8px">
                <Typography className={classes.coloredHeadlineText}>Case Account</Typography>
                <Typography className={classes.accountText}>
                  {settlementDetails?.funding.caseAccountNumber}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" gap="8px">
                <Typography
                  className={clsx(
                    classes.settledAmountText,
                    classes.fontSize12,
                    classes.textGrayColor,
                  )}
                >
                  Settled Amount
                </Typography>
                <Typography className={clsx(classes.accountText, classes.fontSize32)}>
                  {numberToUSDCurrency(settlementDetails?.settledAmount)}
                </Typography>
              </Box>
              {settlementDetails?.funding?.holdStartedAt && (
                <>
                  <Box className={classes.distributionsSectionDivider} />
                  <Box className={classes.distributionContainer} pt="0px !important">
                    <Typography className={clsx(classes.commonText, classes.fontSize20)}>
                      Date Hold Started
                    </Typography>
                  </Box>
                  <Box className={classes.distributionStatusContainer} ml="10px">
                    <Typography
                      className={clsx(classes.commonText, classes.fontSize16)}
                      color="#F4B139 !important"
                    >
                      {formatDate(settlementDetails?.funding?.holdStartedAt)}
                    </Typography>
                  </Box>
                </>
              )}

              {settlementDetails?.funding?.holdUntil && (
                <>
                  <Box className={classes.distributionsSectionDivider} />
                  <Box className={classes.distributionContainer} pt="0px !important">
                    <Typography className={clsx(classes.commonText, classes.fontSize20)}>
                      Release Start Date
                    </Typography>
                  </Box>
                  <Box className={classes.distributionStatusContainer} ml="10px">
                    <Typography
                      className={clsx(classes.commonText, classes.fontSize16)}
                      color="#99CAF9 !important"
                    >
                      {formatDate(settlementDetails?.funding?.holdUntil)}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>

            <Box className={classes.formSectionsDivider} />

            <Box className={classes.distributionsContainer}>
              <Typography className={clsx(classes.commonText, classes.fontSize24)} pb="7px">
                Distributions
              </Typography>
              <Box className={classes.distributionContainer}>
                <Typography className={clsx(classes.commonText, classes.fontSize20)}>
                  Attorneys’ Fees
                </Typography>
                <Box className={classes.distributionStatusContainer}>
                  <Typography className={clsx(classes.commonText, classes.fontSize20)}>
                    {numberToUSDCurrency(
                      settlementDetails?.distributions.attorneysFees?.totalAmount,
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.distributionContainer}>
                <Box display="flex" flexDirection="column">
                  <Typography className={clsx(classes.commonText, classes.fontSize14)}>
                    {settlementDetails?.distributions.attorneysFees?.leadFirm?.name}
                  </Typography>
                  <Typography
                    className={clsx(classes.commonText, classes.fontSize14)}
                    sx={{
                      opacity: '0.5',
                    }}
                  >
                    Lead Counsel
                  </Typography>
                </Box>

                <Box className={classes.distributionStatusContainer}>
                  <Typography className={clsx(classes.commonText, classes.fontSize14)}>
                    {numberToUSDCurrency(
                      settlementDetails?.distributions.attorneysFees?.leadFirm?.amount,
                    )}
                  </Typography>
                  <Chip
                    className={classes.statusChip}
                    label={settlementDetails?.distributions.attorneysFees?.leadFirm?.status}
                    sx={getDistributionStatusChipStyles(
                      settlementDetails?.distributions.attorneysFees?.leadFirm?.status as string,
                    )}
                  />
                </Box>
              </Box>
              {settlementDetails?.distributions.attorneysFees?.secondaryFirms?.map((firm) => (
                <Box className={classes.distributionContainer} key={firm.id}>
                  <Box display="flex" flexDirection="column">
                    <Typography className={clsx(classes.commonText, classes.fontSize14)}>
                      {firm.name}
                    </Typography>
                    <Typography
                      className={clsx(classes.commonText, classes.fontSize14)}
                      sx={{
                        opacity: '0.5',
                      }}
                    >
                      {firm.role}
                    </Typography>
                  </Box>

                  <Box className={classes.distributionStatusContainer}>
                    <Typography className={clsx(classes.commonText, classes.fontSize14)}>
                      {numberToUSDCurrency(firm.amount)}
                    </Typography>
                    <Chip
                      className={classes.statusChip}
                      label={firm.status}
                      sx={getDistributionStatusChipStyles(firm.status)}
                    />
                  </Box>
                </Box>
              ))}

              <Box className={classes.distributionsSectionDivider} />
              <Box className={classes.distributionContainer}>
                <Typography className={clsx(classes.commonText, classes.fontSize20)}>
                  Deductions
                </Typography>

                <Box className={classes.distributionStatusContainer}>
                  <Typography className={clsx(classes.commonText, classes.fontSize20)}>
                    {numberToUSDCurrency(settlementDetails?.distributions.deductionsTotalAmount)}
                  </Typography>
                </Box>
              </Box>
              {settlementDetails?.distributions.deductions?.map((deduction) => (
                <Box className={classes.distributionContainer} key={deduction.id}>
                  <Typography className={clsx(classes.commonText, classes.fontSize14)}>
                    {deduction.type}
                  </Typography>

                  <Box className={classes.distributionStatusContainer}>
                    <Typography className={clsx(classes.commonText, classes.fontSize14)}>
                      {numberToUSDCurrency(deduction.amount)}
                    </Typography>
                    <Chip
                      className={classes.statusChip}
                      label={deduction.status}
                      sx={getDistributionStatusChipStyles(deduction.status)}
                    />
                  </Box>
                </Box>
              ))}

              <Box className={classes.distributionsSectionDivider} />

              <Box className={classes.distributionContainer}>
                <Typography className={clsx(classes.commonText, classes.fontSize20)}>
                  Net Settlement
                </Typography>

                <Box className={classes.distributionStatusContainer}>
                  <Typography className={clsx(classes.commonText, classes.fontSize20)}>
                    {numberToUSDCurrency(
                      !settlementDetails?.distributions.netSettlement?.amount
                        ? 0
                        : Number(settlementDetails?.distributions.netSettlement?.amount),
                    )}
                  </Typography>
                  {settlementDetails?.distributions.netSettlement?.status && (
                    <Chip
                      className={classes.statusChip}
                      label={settlementDetails?.distributions.netSettlement?.status}
                      sx={getDistributionStatusChipStyles(
                        settlementDetails?.distributions.netSettlement?.status as string,
                      )}
                    />
                  )}
                </Box>
              </Box>
            </Box>

            <Box className={classes.formSectionsDivider} />

            <Box className={classes.actionsContainer}>
              <Typography className={clsx(classes.commonText, classes.fontSize20)} pb="12px">
                Actions
              </Typography>
              <Box display="flex" flexDirection="column" gap="24px">
                <CommonButton
                  onButtonClick={() => setIsCancelPopupOpen(true)}
                  type="error"
                  buttonText="CANCEL SETTLEMENT"
                  maxWidth="auto"
                  disabled
                />
                {/* <CommonButton
                  type="info"
                  buttonText="RETRY"
                  maxWidth="auto"
                  onButtonClick={handleOpenVerificationPopup}
                  disabled={
                    isSuperAdmin === 'true' || (entityData && entityData?.status !== 'Error')
                  }
                /> */}
                <Box className={classes.wrapperButton}>
                  <CommonButton
                    type="info"
                    buttonText="RETRY"
                    maxWidth="auto"
                    onButtonClick={handleRetryPayProcessV2}
                    disabled={!settlementDetails?.error?.retryAvailable || isSuperAdmin === 'true'}
                  />
                  {loading && statusRequest === 'retry' && (
                    <Box className={classes.loader}>
                      <Loader colorType="secondary" size={20} />
                    </Box>
                  )}
                </Box>
                <Box className={classes.wrapperButton}>
                  <CommonButton
                    type="warning"
                    buttonText="HOLD"
                    maxWidth="auto"
                    onButtonClick={onHoldSettlement}
                    disabled={!settlementDetails?.funding?.canHold}
                  />
                  {!settlementDetails?.funding?.canHold &&
                    settlementDetails?.funding?.status === 'WaitingFunds' && (
                      <Typography
                        className={clsx(classes.commonText, classes.fontSize14, classes.holdAlert)}
                      >
                        The button will be available soon. We are waiting for funding.
                      </Typography>
                    )}
                  {loading && statusRequest === 'hold' && (
                    <Box className={classes.loader}>
                      <Loader colorType="secondary" size={20} />
                    </Box>
                  )}
                </Box>
                <Box className={classes.wrapperButton}>
                  <CommonButton
                    type="info"
                    buttonText="Release"
                    maxWidth="auto"
                    onButtonClick={onReleaseSettlement}
                    disabled={!settlementDetails?.funding?.canRelease}
                  />
                  {loading && statusRequest === 'release' && (
                    <Box className={classes.loader}>
                      <Loader colorType="secondary" size={20} />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        <Popup
          isOpen={isCancelPopupOpen}
          headlineText={POPUP_DESCRIPTIONS.cancelSettlementPay.headlineText}
          contentText={POPUP_DESCRIPTIONS.cancelSettlementPay.contentText}
          onMainButtonClick={() => setIsCancelPopupOpen(false)}
          onSecondaryButtonClick={() => setIsCancelPopupOpen(false)}
        />
        <VerificationPopup
          isOpen={isVerificationPopupOpen}
          onMainButtonClick={handleConfirmPin}
          onSecondaryButtonClick={handleCloseVerificationPopup}
        />
      </Box>
    </>
  );
};

export default SettlementsDetailsView;
