import { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import AddIcon from '@mui/icons-material/Add';
import { Box, Theme, Typography } from '@mui/material';

import AdminsTableDataRow from './AdminsTableDataRow';

import { getAdminsList } from '../../api/adminsApi/adminsApi';
import TableContainer from '../../components/Table/TableContainer';
import Loader from '../../components/Loader';
import { ADMINS_LIST_TABLE_LABELS } from '../../constants/tableLabels';
import usePagination from '../../hooks/usePagination';
import { ActiveSortField, AdminsListData } from '../../types';
import { handleSortTableData } from '../../helpers/handleSortTableData';

const useStyles = makeStyles()((theme: Theme) => ({
  createButtonContainer: {
    '& a': {
      color: theme.palette.info.main,
      textDecoration: 'none',
    },
  },
  linkMargin: {
    marginRight: '16px',
  },
}));

const AdminsView = () => {
  const styles = useStyles();

  const { pageNumber, rowsPerPage, setTotalCount } = usePagination();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [adminsList, setAdminsList] = useState<AdminsListData[]>([]);
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);
  const sortableFields = {
    name: {
      order: '',
      fieldName: 'name',
    },
    createdAt: {
      order: '',
      fieldName: 'createdAt',
    },
  };

  useEffect(() => {
    getAdminsList({ page: pageNumber, size: rowsPerPage, count: true, activeSortField }).then(
      (res) => {
        if (res.data) {
          setTotalCount && setTotalCount(res.data.totalCount);
          setAdminsList(res.data.resultSet);
          setIsDataLoading(false);
        }
      },
    );
  }, [pageNumber, rowsPerPage, activeSortField]);

  return (
    <>
      {!adminsList.length ? (
        <Loader colorType="warning" />
      ) : (
        <>
          <Box
            className={styles.classes.createButtonContainer}
            display="flex"
            justifyContent="flex-end"
            width="100%"
            mr="16px"
          >
            <Link className={styles.classes.linkMargin} to="/admins/create-admin">
              <Box display="flex" gap="8px" alignItems="center">
                <AddIcon />
                <Typography fontSize="13px" textTransform="uppercase" lineHeight="15px">
                  Create
                </Typography>
              </Box>
            </Link>
          </Box>
          <TableContainer
            isNoData={!isDataLoading && adminsList.length === 0}
            headerLabels={ADMINS_LIST_TABLE_LABELS}
            handleSortTableData={handleSortTableData}
            sortableFields={sortableFields}
            activeSortField={activeSortField}
            setActiveSortField={setActiveSortField}
          >
            <AdminsTableDataRow tableData={adminsList} />
          </TableContainer>
        </>
      )}
      <Outlet />
    </>
  );
};

export default AdminsView;
