import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { FormSnippetShortData } from '../../types';
import AddButton from '../../components/buttons/AddButton';
import Loader from '../../components/Loader';

import FormSnippetButton from './FormSnippetButton';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  caseTypesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    gap: '16px',
  },
  loaderWrapper: {
    marginTop: '16px',
  },
}));

interface FormSnippetListProps {
  readonly data: FormSnippetShortData[];
  readonly isFetching: boolean;
  readonly totalCount: number | null;
  readonly handleCreate: () => void;
  readonly handleDelete: (caseType: FormSnippetShortData) => void;
  readonly handleUpdate: (caseType: FormSnippetShortData) => void;
  readonly handleLoadMore: () => void;
}

const FormSnippetList = ({
  data,
  isFetching,
  totalCount,
  handleCreate,
  handleDelete,
  handleUpdate,
  handleLoadMore,
}: FormSnippetListProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.caseTypesContainer}>
        {data.map((formSnippet) => (
          <FormSnippetButton
            key={formSnippet.id}
            buttonText={formSnippet.name}
            onPress={() => handleUpdate(formSnippet)}
            onDelete={() => handleDelete(formSnippet)}
          />
        ))}
        {!isFetching && (
          <>
            {totalCount !== null && data.length < totalCount && (
              <AddButton
                buttonText="LOAD MORE"
                handleClick={handleLoadMore}
                disabled={isFetching}
              />
            )}
            <AddButton buttonText="CREATE NEW FORM SNIPPET" handleClick={handleCreate} />
          </>
        )}
      </Box>
      {isFetching && (
        <Box className={classes.loaderWrapper}>
          <Loader colorType="warning" size={'60px'} />
        </Box>
      )}
    </Box>
  );
};

export default FormSnippetList;
