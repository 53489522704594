import { Box, Theme, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  buttonDefault: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    maxWidth: 'fit-content',
    marginTop: '10px',

    '&:hover': {
      cursor: 'pointer',
    },

    svg: {
      color: theme.palette.info.main,
    },
  },
  buttonText: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 'normal',
    textTransform: 'uppercase',
    color: theme.palette.info.main,
  },
}));

interface FirmBankingAddAccountButtonProps {
  disabled?: boolean;
  buttonText?: string;
  handleClick?: () => void;
}

const FirmBankingAddAccountButton = ({
  buttonText,
  handleClick,
}: FirmBankingAddAccountButtonProps) => {
  const styles = useStyles();
  return (
    <Box className={styles.classes.buttonDefault} onClick={handleClick}>
      <AddCircleIcon
        sx={{
          fontSize: '18px',
        }}
      />
      <Typography className={styles.classes.buttonText}>{buttonText}</Typography>
    </Box>
  );
};

export default FirmBankingAddAccountButton;
