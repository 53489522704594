import { Link, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { TableCell, TableRow, Theme } from '@mui/material';

import { formatDate } from '../../helpers/formatDate';
import { ClientData } from '../../types';
import { numberToUSDCurrency } from '../../helpers/numberToUSDCurrency';

const useStyles = makeStyles()((theme: Theme) => ({
  styledLink: {
    textDecoration: 'none',
    color: theme.palette.info.main,
  },
}));

interface ClientTableDataRowProps {
  tableData: ClientData[];
  isRelatedToOtherEntity?: boolean;
  customerId?: string;
}

const ClientsTableDataRow = ({ tableData, customerId }: ClientTableDataRowProps) => {
  const styles = useStyles();
  const params = useParams();

  const formattedClientsList =
    tableData &&
    tableData.map((client) => ({
      ...(customerId && {
        firm: (
          <Link
            className={styles.classes.styledLink}
            to={`/firms/${String(client.firm.id)}/home`}
            target="_blank"
          >
            {client.firm.name}
          </Link>
        ),
      }),
      name: !customerId ? (
        <Link
          className={styles.classes.styledLink}
          to={`/clients/${String(client.id)}/home?firmId=${params?.firmId}`}
        >
          {`${client.firstName} ${client.middleName || ''} ${client.lastName}`}
        </Link>
      ) : (
        `${client.firstName} ${client.middleName || ''} ${client.lastName}`
      ),
      email: client.email,
      createdAt: formatDate(client.createdAt),
      status: client.status,
      activeCases: client.additonalInfo.activeCasesCount,
      grossSettlements: numberToUSDCurrency(client?.additonalInfo.grossSettlements),
      ...(!customerId && {
        netSettlements: numberToUSDCurrency(client?.additonalInfo.netSettlements),
      }),
      ...(!customerId && { walletBalance: '-' }),
    }));

  return (
    <>
      {formattedClientsList.map((tableItem, idx) => (
        <TableRow key={idx}>
          {Object.values(tableItem).map((item, idx) => (
            <TableCell key={idx}>{item}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default ClientsTableDataRow;
