import { useEffect } from 'react';
import { Theme, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import FormTextField from '../../components/formFields/FormTextField';
import { CaseTypeData } from '../../types';

const useStyles = makeStyles()((theme: Theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.light,
      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))',
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.secondary.main,
    },
  },
  popupActions: {
    padding: '16px 8px',
  },
  popupButton: {
    color: theme.palette.info.main,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
    },

    '&:hover': {
      background: 'rgba(144, 202, 249, 0.08)',
    },
  },
}));

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .max(100, 'The maximum field length is 100 characters.')
      .required('Required *'),
  })
  .required();

interface EditCaseTypePopupProps {
  caseType: CaseTypeData | null;
  onMainButtonClick: (name: string) => void;
  onSecondaryButtonClick: () => void;
}

interface FormSubmitProps {
  name: string;
}

const EditCaseTypePopup = ({
  caseType,
  onMainButtonClick,
  onSecondaryButtonClick,
}: EditCaseTypePopupProps) => {
  const { classes } = useStyles();

  const formMethods = useForm<FormSubmitProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { name: '' },
  });

  const {
    formState: { isDirty, isValid },
    handleSubmit,
    reset,
  } = formMethods;

  const handleSaveButtonClick = (values: FormSubmitProps) => {
    onMainButtonClick(values.name);
  };

  useEffect(() => {
    if (caseType)
      reset({
        name: caseType.name,
      });
  }, [caseType]);

  return (
    <Dialog
      className={classes.popupContainer}
      open={caseType !== null}
      onClose={onSecondaryButtonClick}
      fullWidth
      maxWidth={'xs'}
    >
      <DialogTitle>{'Edit Case Type'}</DialogTitle>
      <DialogContent>
        <FormProvider {...formMethods}>
          <form>
            <FormTextField name="name" label="Name" />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions className={classes.popupActions}>
        <Button
          className={classes.popupButton}
          onClick={handleSubmit(handleSaveButtonClick)}
          disabled={!isValid || !isDirty}
        >
          {'Save'}
        </Button>
        <Button className={classes.popupButton} onClick={onSecondaryButtonClick}>
          {'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCaseTypePopup;
