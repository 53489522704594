import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams, useOutletContext } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';

// import ArchiveIcon from '@mui/icons-material/Archive';
import SaveIcon from '@mui/icons-material/Save';
import { Box, useTheme } from '@mui/material';

import {
  cancelSignatureRequest,
  updateSignatureRecord,
} from '../../../api/requestsApi/signaturesApi';
import FormTextField from '../../../components/formFields/FormTextField';
import FormSubmitButton from '../../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../../components/buttons/FormCancelButton';
import CommonButton from '../../../components/buttons/CommonButton';
import { signaturesEditFormSchema } from '../../../helpers/formSchemas/formSchemas';
import { SIGNATURES_EDIT_FORM_DEFAULT_VALUES } from '../../../helpers/formSchemas/formDefaultValues';
import { FieldHeadline } from '../styled';
import FormCopyTextField from '../../../components/formFields/FormCopyTextField';
import { SignaturesEditFormSubmitProps } from '../types';
import FormLinkButton from '../../../components/buttons/FormLinkButton';
import CancelIcon from '@mui/icons-material/Cancel';
import usePageTitle from '../../../hooks/usePageTitle';
import DocumentDownloader from '../../../components/DocumentsDownloader/DocumentDownloader';
import useSnackBar from '../../../hooks/useSnackBar';
import { EntityFlexibleRecord } from '../../../types';
import { POPUP_DESCRIPTIONS } from '../../../constants/popupDescriptions';
import Popup from '../../../components/Popup';

const SignaturesEditFormContainer = () => {
  const { signatureId } = useParams<string>();
  const { entityData } = useOutletContext<EntityFlexibleRecord>();

  const theme = useTheme();
  const navigate = useNavigate();
  const { setPageTitle } = usePageTitle();
  const { setAlert } = useSnackBar();

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [plaintiffId, setPlaintiffId] = useState<string>();
  const [signatureStatus, setSignatureStatus] = useState('');
  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(signaturesEditFormSchema),
    defaultValues: SIGNATURES_EDIT_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
    watch,
  } = formMethods;

  const caseField = watch('case');
  const firmField = watch('firm');
  const documentUrl = watch('documentUrl');
  const fileName = watch('fileName');

  const handleCancelSignatureRequest = () => {
    cancelSignatureRequest(signatureId as string)
      .then(() => {
        navigate(-1);
        setAlert((prev) => ({
          ...prev,
          message: 'Signature request canceled successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const onSubmitForm = (values: SignaturesEditFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      name: values.name,
    };

    updateSignatureRecord(signatureId as string, submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);
          navigate('/signature-requests');

          setAlert((prev) => ({
            ...prev,
            message: 'Signature request updated successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => reset();

  useEffect(() => {
    if (entityData) {
      setPlaintiffId(entityData.plaintiffId as string);
      setSignatureStatus(entityData.status as string);

      if (entityData?.fileName) {
        reset({
          ...entityData,
        });
      } else {
        reset({
          ...entityData,
        });
      }

      setPageTitle && setPageTitle(entityData.name as string);
    }

    return () => setPageTitle && setPageTitle('');
  }, [entityData]);

  return (
    <Box display="flex" bgcolor={theme.palette.primary.dark} width="100%">
      <FormProvider {...formMethods}>
        <form style={{ width: '100%', padding: '20px' }}>
          <Box display="flex" width="100%" gap="32px">
            <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
              <FieldHeadline>Identity</FieldHeadline>
              <Box mt="10px">
                <FormCopyTextField entityName="Signature request" name="id" label="Id" isReadOnly />
              </Box>

              <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt="50px">
                <FieldHeadline>Case</FieldHeadline>
                <FormLinkButton path={`/cases/${caseField.id}/home`} />
              </Box>
              <Box mt="10px">
                <FormTextField name="case.referenceId" label="Reference Id" disabled />
              </Box>
              <Box mt="10px">
                <FormTextField name="case.name" label="System Name" disabled />
              </Box>

              <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt="50px">
                <FieldHeadline>Firm</FieldHeadline>
                <FormLinkButton path={`/firms/${firmField.id}/home`} />
              </Box>
              <Box mt="10px">
                <FormTextField name="firm.name" label="Firm" disabled />
              </Box>

              <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt="50px">
                <FieldHeadline>Client</FieldHeadline>
                <FormLinkButton path={`/clients/${plaintiffId}/home`} />
              </Box>
              <Box mt="10px">
                <FormTextField name="plaintiffFullName" label="Client" disabled />
              </Box>

              <FieldHeadline mt="50px">Document</FieldHeadline>
              <Box mt="12px">
                <FormTextField name="name" label="Document Name *" />
              </Box>
              <Box mt="12px">
                <FormTextField name="documentId" label="Document Id *" disabled />
              </Box>
            </Box>

            <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
              <DocumentDownloader url={documentUrl} fileType={'docusign'} fileName={fileName} />
            </Box>

            <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
              <FieldHeadline>Actions</FieldHeadline>
              <Box mt="20px">
                <CommonButton
                  disabled={
                    signatureStatus === 'Opened' || signatureStatus === 'Unopened' ? false : true
                  }
                  type="error"
                  buttonText="cancel"
                  buttonIcon={<CancelIcon />}
                  onButtonClick={() => setIsCancelPopupOpen(true)}
                />
              </Box>
              {/* <Box mt="20px">
                <CommonButton
                  disabled
                  type="error"
                  buttonText="archive"
                  buttonIcon={<ArchiveIcon />}
                />
              </Box> */}
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" mt="40px">
            <FormSubmitButton
              disabled={!isValid || !isDirty || isFormSubmitting}
              buttonIcon={<SaveIcon />}
              buttonText="Save"
              onClick={handleSubmit(onSubmitForm)}
            />
            {isDirty && <FormCancelButton onClick={handleCancelChanges} />}
          </Box>
        </form>
      </FormProvider>
      <Popup
        isOpen={isCancelPopupOpen}
        headlineText={POPUP_DESCRIPTIONS.signatureRequestCancel.headlineText}
        contentText={POPUP_DESCRIPTIONS.signatureRequestCancel.contentText}
        onMainButtonClick={handleCancelSignatureRequest}
        onSecondaryButtonClick={() => setIsCancelPopupOpen(false)}
      />
    </Box>
  );
};

export default SignaturesEditFormContainer;
