import { useFormContext, Controller } from 'react-hook-form';
import clsx from 'clsx';

import {
  Box,
  FilledInputProps,
  InputAdornment,
  InputLabelProps,
  InputProps,
  OutlinedInputProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { validateTextFieldValue } from '../../helpers/validateTextFieldValue';
import { InputBaseProps } from '@mui/material/InputBase';
import { getTextFieldErrorMessage } from '../../helpers/getTextFieldErrorMessage';

export const useStyles = makeStyles<{ maxWidth?: string; disabled?: boolean; minWidth?: string }>()(
  (theme: Theme, { maxWidth, disabled, minWidth }) => ({
    defaultTextField: {
      ...(maxWidth && { maxWidth }),
      ...(minWidth && { minWidth }),
      ...(disabled && { background: 'rgba(255, 255, 255, 0.1)' }),
      input: {
        padding: '4px 0px 8px 8px',

        '&.Mui-disabled': {
          color: 'rgba(255, 255, 255, 0.3)',
          WebkitTextFillColor: 'rgba(255, 255, 255, 0.3)',
        },
      },

      '& .MuiInput-root': {
        color: theme.palette.primary.light,

        ':before': {
          borderBottom: `1px solid ${theme.palette.primary.light}`,
        },

        ':hover:not(.Mui-disabled, .Mui-error):before': {
          borderBottom: `1px solid ${theme.palette.primary.light}`,
        },

        ':after': {
          borderBottom: `1px solid ${theme.palette.primary.light}`,
        },

        '&.Mui-disabled': {
          color: 'rgba(255, 255, 255, 0.3)',
        },
      },

      '& .MuiInputLabel-root': {
        '&[data-shrink="true"]': {
          color: theme.palette.secondary.light,
        },

        marginLeft: '8px',
        color: 'rgba(255, 255, 255, 0.3)',

        '&.Mui-error': {
          color: theme.palette.error.main,
        },

        '&.Mui-focused': {
          color: theme.palette.secondary.light,
        },

        '&.Mui-disabled': {
          color: 'rgba(255, 255, 255, 0.3)',
        },
      },

      '& .MuiFormHelperText-root': {
        marginLeft: '8px',
        position: 'absolute',
        bottom: '-20px',
      },
    },
    readOnlyText: {
      '& .MuiInput-root': {
        maxWidth: '470px',
        color: theme.palette.primary.light,

        ':before': {
          borderBottom: 'none',
        },

        ':hover:not(.Mui-disabled, .Mui-error):before': {
          borderBottom: 'none',
        },

        ':after': {
          borderBottom: 'none',
        },
      },
    },
    multilinePadding: {
      textarea: {
        padding: '4px 0px 8px 8px',
        fontSize: '12px',
      },
    },
    inputStartAdornment: {
      marginLeft: '8px',
      marginRight: 0,
      height: '100%',
    },
    inputValueDefinitionContainer: {
      marginBottom: '4px',
      marginRight: 0,
      color: disabled ? 'rgba(255, 255, 255, 0.3)' : theme.palette.common.white,
      borderRight: '1px solid rgba(255, 255, 255, 0.3)',
      paddingRight: '4px',
    },
  }),
);

interface FormTextFieldProps {
  name: string;
  label: string;
  readOnly?: boolean;
  maxWidth?: string;
  minWidth?: string;
  disabled?: boolean;
  required?: boolean;
  isReadOnly?: boolean;
  displayHidden?: boolean;
  handleChange?: (value: string) => void;
  isMultiline?: boolean;
  inputLabelProps?: Partial<InputLabelProps>;
  inputProps?: InputBaseProps['inputProps'];
  maxRows?: number;
  valueDefinition?: string;
  InputProps?:
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | Partial<InputProps>
    | undefined;
  onBlur?: (value: string) => void;
  height?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef?: React.Ref<any>;
  helperText?: string;
}

const FormTextField = ({
  name,
  label,
  handleChange,
  readOnly,
  isReadOnly,
  disabled,
  maxWidth,
  displayHidden,
  isMultiline,
  inputLabelProps,
  inputProps,
  maxRows,
  InputProps,
  valueDefinition,
  minWidth,
  onBlur,
  inputRef,
  helperText,
}: FormTextFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const styles = useStyles({ maxWidth, disabled, minWidth });

  const textFieldKeyName = `${name}.message`;

  const errorMessage = errors && getTextFieldErrorMessage(errors, textFieldKeyName);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <TextField
            inputRef={inputRef}
            fullWidth
            type={displayHidden ? 'password' : 'text'}
            className={clsx(
              styles.classes.defaultTextField,
              isReadOnly && styles.classes.readOnlyText,
              isMultiline && styles.classes.multilinePadding,
            )}
            multiline={isMultiline}
            minRows={4}
            maxRows={maxRows}
            variant="standard"
            label={label}
            onBlur={(e) => onBlur?.(e.target.value)}
            onChange={(e) =>
              handleChange
                ? handleChange(e.target.value)
                : onChange(validateTextFieldValue(e.target.value))
            }
            helperText={helperText !== undefined ? helperText : errorMessage}
            value={value}
            error={!!errorMessage}
            disabled={disabled}
            inputProps={inputProps}
            InputProps={{
              readOnly: readOnly,
              ...InputProps,
              ...(valueDefinition && {
                startAdornment: (
                  <InputAdornment position="start" className={styles.classes.inputStartAdornment}>
                    <Box className={styles.classes.inputValueDefinitionContainer}>
                      <Typography>{valueDefinition}</Typography>
                    </Box>
                  </InputAdornment>
                ),
              }),
            }}
            InputLabelProps={inputLabelProps}
          />
        );
      }}
    />
  );
};

export default FormTextField;
