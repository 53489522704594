import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import AddIcon from '@mui/icons-material/Add';
import { Box, Theme, Typography } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  createButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  buttonInnerContainer: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    color: theme.palette.info.main,
    textDecoration: 'none',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  linkMargin: {
    marginLeft: '8px',
  },
}));

interface ViewHeaderLinkButtonProps {
  linkText: string;
  path: string;
}

const ViewHeaderLinkButton = ({ path, linkText }: ViewHeaderLinkButtonProps) => {
  const styles = useStyles();

  return (
    <Box className={styles.classes.createButtonContainer}>
      <Link className={styles.classes.buttonInnerContainer} to={path} target="_blank">
        <AddIcon />
        <Typography fontSize="13px" textTransform="uppercase" lineHeight="15px" mt="3px">
          {linkText}
        </Typography>
      </Link>
    </Box>
  );
};

export default ViewHeaderLinkButton;
