import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams, useOutletContext } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';

import ArchiveIcon from '@mui/icons-material/Archive';
import SaveIcon from '@mui/icons-material/Save';
import { Box, useTheme } from '@mui/material';

import {
  cancelDocumentRecord,
  deleteDocumentRecord,
  sendDocumentRecord,
  updateDocumentRecord,
} from '../../../api/requestsApi/documentRequestsApi';
import FormTextField from '../../../components/formFields/FormTextField';
import FormSubmitButton from '../../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../../components/buttons/FormCancelButton';
import CommonButton from '../../../components/buttons/CommonButton';
import { documentsEditFormSchema } from '../../../helpers/formSchemas/formSchemas';
import { DOCUMENTS_EDIT_FORM_DEFAULT_VALUES } from '../../../helpers/formSchemas/formDefaultValues';
import { FieldHeadline } from '../styled';
import FormCopyTextField from '../../../components/formFields/FormCopyTextField';
import { DocumentsEditFormSubmitProps } from '../types';
import FormLinkButton from '../../../components/buttons/FormLinkButton';
import CancelIcon from '@mui/icons-material/Cancel';
import usePageTitle from '../../../hooks/usePageTitle';
import DocumentDownloader from '../../../components/DocumentsDownloader/DocumentDownloader';
import useSnackBar from '../../../hooks/useSnackBar';
import { EntityFlexibleRecord } from '../../../types';
import Popup from '../../../components/Popup';
import { POPUP_DESCRIPTIONS } from '../../../constants/popupDescriptions';

const DocumentEditFormContainer = () => {
  const { documentId } = useParams<string>();

  const theme = useTheme();
  const navigate = useNavigate();
  const { setPageTitle } = usePageTitle();
  const { setAlert } = useSnackBar();
  const { entityData } = useOutletContext<EntityFlexibleRecord>();

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(documentsEditFormSchema),
    defaultValues: DOCUMENTS_EDIT_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
    watch,
  } = formMethods;

  const caseField = watch('case');
  const firmField = watch('firm');
  const plaintiffId = watch('plaintiffId');
  const documentUrl = watch('documentUrl');
  const fileName = watch('fileName');

  const isDraftStatus = entityData?.status === 'Draft';
  const shouldDisableSubmitButton = isDraftStatus || entityData?.status === 'Unopened';
  const isCancelable = entityData?.status === 'Unopened' || entityData?.status === 'Opened';

  const handleRenderCancelDeleteButton = () => {
    if (entityData?.status) {
      if (isDraftStatus) {
        return (
          <CommonButton
            disabled={!isDraftStatus}
            type="error"
            buttonText="delete"
            buttonIcon={<CancelIcon />}
            onButtonClick={() => setIsDeletePopupOpen(true)}
          />
        );
      } else {
        return (
          <CommonButton
            disabled={!isCancelable}
            type="error"
            buttonText="cancel"
            buttonIcon={<CancelIcon />}
            onButtonClick={() => setIsCancelPopupOpen(true)}
          />
        );
      }
    }
  };

  const onSubmitForm = (values: DocumentsEditFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      name: values.name,
    };

    updateDocumentRecord(documentId as string, submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);
          navigate('/document-requests');

          setAlert((prev) => ({
            ...prev,
            message: 'Document request updated successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleDeleteDocumentRequest = () => {
    deleteDocumentRecord(documentId as string)
      .then(() => {
        navigate(-1);
        setAlert((prev) => ({
          ...prev,
          message: 'Document request deleted successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelDocumentRequest = () => {
    cancelDocumentRecord(documentId as string)
      .then(() => {
        navigate(-1);
        setAlert((prev) => ({
          ...prev,
          message: 'Document request canceled successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleSendDocumentRequest = () => {
    documentId &&
      sendDocumentRecord(documentId).then((res) => {
        if (res?.data) {
          navigate('/document-requests');
          setAlert((prev) => ({
            ...prev,
            message: 'Document request has been sent successfully.',
            type: 'success',
          }));
        }
      });
  };

  const handleCancelChanges = () => reset();

  useEffect(() => {
    if (entityData) {
      if (entityData?.fileName) {
        reset({
          ...entityData,
        });
      } else {
        reset({
          ...entityData,
        });
      }

      setPageTitle && setPageTitle(entityData.name as string);
    }

    return () => setPageTitle && setPageTitle('');
  }, [entityData]);

  return (
    <Box display="flex" bgcolor={theme.palette.primary.dark} width="100%">
      <FormProvider {...formMethods}>
        <form style={{ width: '100%', padding: '20px' }}>
          <Box display="flex" width="100%" gap="32px">
            <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
              <FieldHeadline>{'Identity'}</FieldHeadline>
              <Box mt="10px">
                <FormCopyTextField entityName="Document request" name="id" label="Id" isReadOnly />
              </Box>

              <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt="50px">
                <FieldHeadline>{'Case'}</FieldHeadline>
                <FormLinkButton path={`/cases/${caseField.id}/home`} />
              </Box>
              <Box mt="10px">
                <FormCopyTextField entityName="Case" name="case.id" label="Id" isReadOnly />
              </Box>
              <Box mt="10px">
                <FormTextField name="case.referenceId" label="Reference Id" disabled />
              </Box>
              <Box mt="10px">
                <FormTextField name="case.name" label="System Name" disabled />
              </Box>

              <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt="50px">
                <FieldHeadline>{'Firm'}</FieldHeadline>
                <FormLinkButton path={`/firms/${firmField.id}/home`} />
              </Box>
              <Box mt="10px">
                <FormTextField name="firm.name" label="Firm" disabled />
              </Box>

              <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt="50px">
                <FieldHeadline>{'Client'}</FieldHeadline>
                <FormLinkButton path={`/clients/${plaintiffId}/home`} />
              </Box>
              <Box mt="10px">
                <FormTextField name="plaintiffFullName" label="Client" disabled />
              </Box>

              <FieldHeadline mt="50px">{'Document'}</FieldHeadline>
              <Box mt="12px">
                <FormTextField
                  name="name"
                  label="Document Name *"
                  disabled={!shouldDisableSubmitButton}
                />
              </Box>
            </Box>

            <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
              <DocumentDownloader url={documentUrl} fileType={'pdf'} fileName={fileName ?? ''} />
            </Box>

            <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
              <FieldHeadline>{'Actions'}</FieldHeadline>
              <Box mt="20px">
                <CommonButton
                  disabled={isDirty || !isValid || !isDraftStatus}
                  type="info"
                  buttonText="Send doc request"
                  buttonIcon={<ArchiveIcon />}
                  onButtonClick={handleSendDocumentRequest}
                />
              </Box>

              <Box mt="20px">{handleRenderCancelDeleteButton()}</Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" mt="40px">
            <FormSubmitButton
              disabled={!isValid || !shouldDisableSubmitButton || !isDirty || isFormSubmitting}
              buttonIcon={<SaveIcon />}
              buttonText="Save"
              onClick={handleSubmit(onSubmitForm)}
            />
            {isDirty && <FormCancelButton onClick={handleCancelChanges} />}
          </Box>
        </form>
      </FormProvider>
      <Popup
        isOpen={isCancelPopupOpen}
        headlineText={POPUP_DESCRIPTIONS.documentRequestCancel.headlineText}
        contentText={POPUP_DESCRIPTIONS.documentRequestCancel.contentText}
        onMainButtonClick={handleCancelDocumentRequest}
        onSecondaryButtonClick={() => setIsCancelPopupOpen(false)}
      />
      <Popup
        isOpen={isDeletePopupOpen}
        headlineText={POPUP_DESCRIPTIONS.documentRequestDelete.headlineText}
        contentText={POPUP_DESCRIPTIONS.documentRequestDelete.contentText}
        onMainButtonClick={handleDeleteDocumentRequest}
        onSecondaryButtonClick={() => setIsDeletePopupOpen(false)}
      />
    </Box>
  );
};

export default DocumentEditFormContainer;
