import { Box, useTheme } from '@mui/material';

import TabsPanel from '../../components/TabsPanel';
import { adminAccountTabs } from '../../constants/tabRoutes';
import GoBackButton from '../../components/buttons/GoBackButton';

const AdminAccountView = () => {
  const theme = useTheme();

  return (
    <>
      <GoBackButton />
      <Box display="flex" bgcolor={theme.palette.primary.dark} width="100%">
        <TabsPanel tabsList={adminAccountTabs} locationSlicePosition={3} />
      </Box>
    </>
  );
};

export default AdminAccountView;
