import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { Collapse, Typography, Drawer, CssBaseline, Box } from '@mui/material';

import { styled, Theme, CSSObject } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';

import { menuBottomItems, menuItems } from './menuItems';
import { sidebarStyles } from './styles';

import { StyledLink } from '../styled';

import useAuth from '../../hooks/useAuth';

const drawerWidth = 200;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  background: theme.palette.primary.main,
  color: theme.palette.common.white,
  top: '48px',
  height: 'calc(100% - 48px)',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  background: theme.palette.primary.main,
  color: theme.palette.common.white,
  top: '48px',
  width: `calc(${theme.spacing(6)} + 1px)`,
  height: 'calc(100% - 48px)',
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',

    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

interface SidebarProps {
  isSidebarExpanded: boolean;
}

const Sidebar = ({ isSidebarExpanded }: SidebarProps) => {
  const location = useLocation();
  const { classes } = sidebarStyles();
  const { onLogOut } = useAuth();

  const [activeGroupState, setActiveGroupState] = useState<{ [x: string]: { isOpen: boolean } }>({
    content: {
      isOpen: false,
    },
    payments: {
      isOpen: false,
    },
    requests: {
      isOpen: false,
    },
  });

  const baseRouteName = location.pathname.split('/')[1];
  const isSuperAdmin = localStorage.getItem('isSuperAdmin');

  const isRequestsRoutes = ['/data-requests', '/signature-requests', '/document-requests'].some(
    (item) => location.pathname.includes(item),
  );
  const isPaymentsRoutes = ['/settlements', '/deductions'].some((item) =>
    location.pathname.includes(item),
  );

  const handleGroupExpand = (groupName: string) => {
    const updatedState = {
      [groupName]: {
        isOpen: !activeGroupState[groupName].isOpen,
      },
    };

    setActiveGroupState((prevState) => {
      return {
        ...prevState,
        ...updatedState,
      };
    });
  };

  useEffect(() => {
    if (isRequestsRoutes) {
      handleGroupExpand('requests');
    }
    if (isPaymentsRoutes) {
      handleGroupExpand('payments');
    }
  }, []);

  return (
    <Box display="flex">
      <CssBaseline />
      <StyledDrawer variant="permanent" open={isSidebarExpanded}>
        <Box className={classes.upperItemsListContainer}>
          {menuItems.map((item, index) => (
            <Box key={index}>
              {item.innerElements ? (
                <Box display="flex" flexDirection="column">
                  <Box
                    className={classes.collapseControlContainer}
                    onClick={() => handleGroupExpand(item.groupName)}
                  >
                    <Box ml="20px">
                      {activeGroupState[item.groupName]?.isOpen ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        item.icon
                      )}
                    </Box>
                    <Typography
                      className={clsx(
                        activeGroupState[item.groupName]?.isOpen
                          ? classes.activeLink
                          : classes.defaultLink,
                      )}
                    >
                      {item.linkName}
                    </Typography>
                  </Box>

                  <Collapse
                    in={activeGroupState[item.groupName]?.isOpen}
                    timeout="auto"
                    unmountOnExit
                  >
                    {activeGroupState[item.groupName]?.isOpen &&
                      item.innerElements.elements.map((innerMenuItem, idx: number) => (
                        <Box key={idx} mt="8px" width="100%">
                          <StyledLink to={innerMenuItem.path} className={clsx(classes.linkHover)}>
                            <Box
                              className={clsx(
                                classes.menuItemWrapper,
                                baseRouteName === innerMenuItem.path && classes.activeLinkBorder,
                              )}
                            >
                              <Box
                                ml={isSidebarExpanded ? '40px' : '20px'}
                                className={classes.iconWrapper}
                              >
                                {innerMenuItem.icon}
                              </Box>
                              <Typography
                                className={clsx(
                                  baseRouteName === innerMenuItem.path
                                    ? classes.activeLink
                                    : classes.defaultLink,
                                )}
                              >
                                {innerMenuItem.linkName}
                              </Typography>
                            </Box>
                          </StyledLink>
                        </Box>
                      ))}
                  </Collapse>
                  {item.hasDividerBelow && <Box className={classes.divider} />}
                </Box>
              ) : (
                <>
                  <StyledLink to={item.path as string} className={clsx(classes.linkHover)}>
                    <Box
                      className={clsx(
                        classes.menuItemWrapper,
                        baseRouteName === item.path && classes.activeLinkBorder,
                      )}
                    >
                      <Box ml="20px" className={classes.iconWrapper}>
                        {item.icon}
                      </Box>
                      <Typography
                        className={clsx(
                          baseRouteName === item.path ? classes.activeLink : classes.defaultLink,
                        )}
                      >
                        {item.linkName}
                      </Typography>
                    </Box>
                  </StyledLink>
                  {item.hasDividerBelow && <Box className={classes.divider} />}
                </>
              )}
            </Box>
          ))}
        </Box>

        <Box className={classes.bottomItemsListContainer}>
          {menuBottomItems.map((item, index) => {
            if (!isSuperAdmin && item.path === 'admins') return <></>;

            return (
              <Box key={index} marginBottom="auto">
                <StyledLink to={item.path} className={clsx(classes.linkHover)}>
                  <Box
                    className={clsx(
                      classes.menuItemWrapper,
                      baseRouteName === item.path && classes.activeLinkBorder,
                    )}
                  >
                    <Box ml="20px" className={classes.iconWrapper}>
                      {item.icon}
                    </Box>
                    <Typography
                      className={clsx(
                        baseRouteName === item.path ? classes.activeLink : classes.defaultLink,
                      )}
                    >
                      {item.linkName}
                    </Typography>
                  </Box>
                </StyledLink>
              </Box>
            );
          })}

          <Box className={clsx(classes.menuItemWrapper, classes.linkHover)} onClick={onLogOut}>
            <Box ml="20px" className={classes.iconWrapper}>
              <LogoutIcon />
            </Box>
            <Typography className={classes.defaultLink}>Sign Out</Typography>
          </Box>
        </Box>
      </StyledDrawer>
    </Box>
  );
};

export default Sidebar;
