import { Box } from '@mui/material';

import TabsPanel from '../../components/TabsPanel';
import { firmAccountTabs } from '../../constants/tabRoutes';
import GoBackButton from '../../components/buttons/GoBackButton';

// TODO height="calc(100% - 78px)" ???
const FirmAccountView = () => {
  return (
    <>
      <GoBackButton />
      <Box display="flex" width="100%" height="calc(100% - 78px)">
        <TabsPanel tabsList={firmAccountTabs} locationSlicePosition={3} />
      </Box>
    </>
  );
};

export default FirmAccountView;
