import Timeline from '../../../components/Timeline';
import { useEffect, useState } from 'react';
import { getDocumentTimeline } from '../../../api/requestsApi/documentRequestsApi';
import { TimelineType } from '../../../types';
import { useParams } from 'react-router';

const DocumentsTimeline = () => {
  const { documentId } = useParams<string>();
  const [documentTimeline, setDocumentTimeline] = useState<TimelineType>([]);

  useEffect(() => {
    getDocumentTimeline(documentId as string).then((res) => {
      if (res.data) {
        setDocumentTimeline(res.data);
      }
    });
  }, []);

  return <Timeline data={documentTimeline} />;
};

export default DocumentsTimeline;
