import { Box, Typography, IconButton } from '@mui/material';

import { styled } from '@mui/system';

export const FieldHeadline = styled(Typography)`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: ${(props) => props.theme.palette.primary.light};
`;

export const FieldsGroupWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: 470px;
  width: 100%;
`;

export const FirmCategorySaveButton = styled(IconButton)`
  padding: 0;
  margin-top: 13px;
  color: ${(props) => props.theme.palette.warning.main};
`;

export const FirmCategoryDeleteButton = styled(IconButton)`
  padding: 0;
  margin-top: 13px;
  color: ${(props) => props.theme.palette.error.main};
`;

export const FirmCategoryPillLeftPart = styled(Box)`
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px 0px 0px 16px;
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 12px;
  font-family: Roboto;
  font-weight: 500;
`;

export const FirmCategoryPillRightPart = styled(Box)`
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 8px;
  background: rgba(255, 255, 255, 0.16);
  border-radius: 0px 16px 16px 0px;
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 12px;
  font-family: Roboto;
  font-weight: 500;
`;

export const FirmCategoryWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: 338px;
  width: 100%;
  gap: 16px;
  min-height: 280px;
`;

export const FirmCategoryHeader = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 16px 0px 16px;
  gap: 16px;
  box-sizing: border-box;
  position: relative;
`;

export const PreviewImageContainer = styled(Box)`
  margin-top: 12px;
  border: 1px dashed rgba(81, 81, 81, 1);
  display: flex;
  justify-content: center;
  height: 130px;
`;
