import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

// import ArchiveIcon from '@mui/icons-material/Archive';
import SaveIcon from '@mui/icons-material/Save';
import { Box, useTheme } from '@mui/material';

import { FieldHeadline } from './styled';
import { DefendantFormSubmitProps } from './types';

import { getDefendantRecord, updateDefendantRecord } from '../../api/defendantsApi/defendantsApi';

import FormCopyTextField from '../../components/formFields/FormCopyTextField';
import FormTextField from '../../components/formFields/FormTextField';
// import CommonButton from '../../components/buttons/CommonButton';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import Loader from '../../components/Loader';

import { DEFENDANT_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { defendantFormSchema } from '../../helpers/formSchemas/formSchemas';
import usePageTitle from '../../hooks/usePageTitle';
import useSnackBar from '../../hooks/useSnackBar';
import FormAutocompleteField from '../../components/formFields/FormAutocompleteField';
import { handleGetFirms, mapFirmsQueryResults } from '../CasesView/helpers';

const DefendantsEditFormContainer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { defendantId } = useParams();
  const { setPageTitle } = usePageTitle();
  const { setAlert } = useSnackBar();

  const [isDataLoading, setIsDataLoading] = useState(false);

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(defendantFormSchema),
    defaultValues: DEFENDANT_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = formMethods;

  const onSubmitForm = (values: DefendantFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      name: values.name,
      referenceId: values.referenceId,
      firmId: values.firm.id,
    };

    updateDefendantRecord(defendantId as string, submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);
          navigate('/defendants');

          setAlert((prev) => ({
            ...prev,
            message: 'Defendant updated successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => reset();

  useEffect(() => {
    setIsDataLoading(true);
    getDefendantRecord(defendantId as string)
      .then((res) => {
        if (res.data) {
          setIsDataLoading(false);
          reset({
            id: res.data.id,
            referenceId: res.data.referenceId,
            name: res.data.name,
            firm: {
              id: res.data.firm.id,
              label: res.data.firm.name,
            },
          });

          setPageTitle && setPageTitle(res.data.name);
        }
      })
      .catch(() => setIsDataLoading(false));

    return () => setPageTitle && setPageTitle('');
  }, [defendantId]);

  return (
    <>
      {isDataLoading ? (
        <Loader colorType="warning" />
      ) : (
        <>
          <Box display="flex" bgcolor={theme.palette.primary.dark} width="100%">
            <FormProvider {...formMethods}>
              <form style={{ width: '100%', padding: '20px' }}>
                <Box display="flex" width="100%" gap="32px">
                  <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                    <FieldHeadline>Identity</FieldHeadline>
                    <Box mt="10px">
                      <FormCopyTextField entityName="Defendant" name="id" label="Id" isReadOnly />
                    </Box>
                    <Box mt="20px">
                      <FormTextField name="referenceId" label="Reference Id *" />
                    </Box>
                    <Box mt="20px">
                      <FormTextField name="name" label="Name *" />
                    </Box>

                    <FieldHeadline mt="50px">Firm</FieldHeadline>

                    <Box mt="10px">
                      <FormAutocompleteField
                        name="firm"
                        label="Firm *"
                        optionsQuery={handleGetFirms}
                        mapQueryResults={mapFirmsQueryResults}
                      />
                    </Box>
                  </Box>

                  {/* <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                    <FieldHeadline>Actions</FieldHeadline>

                    <Box mt="24px">
                      <CommonButton
                        type="error"
                        buttonText="archive"
                        buttonIcon={<ArchiveIcon />}
                        disabled
                      />
                    </Box>
                  </Box> */}
                </Box>

                <Box display="flex" justifyContent="space-between" mt="40px">
                  <FormSubmitButton
                    disabled={!isValid || !isDirty || isFormSubmitting}
                    buttonIcon={<SaveIcon />}
                    buttonText="Save"
                    onClick={handleSubmit(onSubmitForm)}
                  />
                  {isDirty && <FormCancelButton onClick={handleCancelChanges} />}
                </Box>
              </form>
            </FormProvider>
          </Box>
        </>
      )}
    </>
  );
};

export default DefendantsEditFormContainer;
