import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import FormTextField from '../../../components/formFields/FormTextField';
import FormMaskedTextField from '../../../components/formFields/FormMaskedTextField';
import FormSelectField from '../../../components/formFields/FormSelectField';
import { CUSTOMER_BANK_ACCOUNT_TYPE_OPTIONS } from '../../../constants/constants';

const useStyles = makeStyles()(() => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  formFieldsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    alignItems: 'center',
    padding: '24px 0 24px 0',
  },
  checkboxWrapper: {
    marginTop: '14px',

    '& label': {
      marginRight: 0,
    },
  },
}));

const CustomerBankingAccountForm = ({ disabled = false }: { disabled?: boolean }) => {
  const styles = useStyles();

  return (
    <Box className={styles.classes.formContainer}>
      <Box className={styles.classes.formFieldsWrapper}>
        <FormTextField name="name" label="Name" />
        <FormSelectField
          name="type"
          label="Account Type"
          options={CUSTOMER_BANK_ACCOUNT_TYPE_OPTIONS}
          disabled={disabled}
        />
        <FormTextField name="accountNumber" label="Account Number" disabled={disabled} />
        <FormMaskedTextField
          mask="999999999"
          name="routingNumber"
          label="Routing Number"
          disabled={disabled}
        />
      </Box>
    </Box>
  );
};

export default CustomerBankingAccountForm;
