export const CREATE_ADMIN_FORM_DEFAULT_VALUES = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: '',
};

export const CREATE_FIRM_FORM_DEFAULT_VALUES = {
  id: '',
  name: '',
  tin: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  // brandColor: '',
  zendeskBrandName: '',
  sendAppInviteTrigger: '',
  createWalletTrigger: '',
  issueVirtualCardTrigger: '',
  issuePhysicalCardTrigger: '',
  isClientTriggerActive: false,
  logoImageId: '',
  firmUser: {
    isFirmUserToggleActive: false,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
  docusignKeys: {
    isDocusignToggleActive: false,
    accountId: '',
    integrationKey: '',
    userId: '',
    rsaPrivateKey: '',
    connectKey: '',
    baseUri: '',
  },
};

export const EDIT_FIRM_FORM_DEFAULT_VALUES = {
  id: '',
  name: '',
  tin: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  // brandColor: '',
  zendeskBrandName: '',
  docusignAccountId: '',
  sendAppInviteTrigger: '',
  createWalletTrigger: '',
  issueVirtualCardTrigger: '',
  issuePhysicalCardTrigger: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  isClientTriggerActive: false,
  logoImageId: '',
  logoImageUrl: '',
  docusignKeys: {
    isDocusignToggleActive: false,
    accountId: '',
    integrationKey: '',
    userId: '',
    rsaPrivateKey: '',
    connectKey: '',
    baseUri: '',
  },
};

export const MANAGE_ACCOUNT_FORM_DEFAULT_VALUES = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: '',
  isPinSet: false,
  isSuperAdmin: false,
};

export const CREATE_USER_FORM_DEFAULT_VALUES = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: '',
  firm: {
    id: '',
    label: '',
  },
  isPinSet: false,
};

export const DEFENDANT_FORM_DEFAULT_VALUES = {
  id: '',
  firm: {
    id: '',
    label: '',
  },
  name: '',
  referenceId: '',
};

export const CLIENT_CREATE_FORM_DEFAULT_VALUES = {
  firmId: {
    id: '',
    label: '',
  },
  firstName: '',
  middleName: '',
  lastName: '',
  referenceId: '',
  email: '',
  phone: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  birthdate: null,
  ssn: '',
};

export const CLIENT_EDIT_FORM_DEFAULT_VALUES = {
  id: '',
  firmId: {
    id: '',
    label: '',
  },
  firstName: '',
  middleName: '',
  lastName: '',
  referenceId: '',
  email: '',
  phone: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
};

export const CASES_FORM_DEFAULT_VALUES = {
  id: '',
  referenceId: '',
  caseTypeCategory: '',
  type: { id: '', label: '' },
  trustAccount: { id: '', label: '' },
  feePercentage: '',
  filedAt: '',
  sol: '',
  liens: '',
  advances: '',
  clientId: { id: '', label: '' },
  leadFirm: {
    id: '',
    label: '',
  },
  defendant: [
    {
      defendantId: { id: '', label: '' },
      gross: '',
    },
  ],
  secondaryFirms: [
    {
      id: { id: '', label: '' },
      role: '',
    },
  ],
};

export const FIRM_CODES_FORM_DEFAULT_VALUES = {
  code: '',
  name: '',
  dataFormat: '',
  category: '',
  prompt: '',
};

export const CASES_EDIT_FORM_DEFAULT_VALUES = {
  id: '',
  name: '',
  referenceId: '',
  caseTypeCategory: '',
  type: { id: '', label: '' },
  trustAccount: { id: '', label: '' },
  firmId: '',
  // feePercentage: '',
  filedAt: '',
  sol: '',
  liens: '',
  advances: '',
  clientId: { id: '', label: '' },
  defendant: [
    {
      defendantId: { id: '', label: '' },
      gross: '',
    },
  ],
  secondaryFirms: [
    {
      id: { id: '', label: '' },
      role: 'LeadCounsel',
    },
  ],
  paymentStarted: false,
};

export const DEDUCTIONS_CREATE_FORM_DEFAULT_VALUES = {
  id: '',
  isAssigned: false,
  defendant: { id: '', label: '' },
  type: '',
  amount: '',
};

export const SETTLEMENTS_CREATE_FORM_DEFAULT_VALUES = {
  id: '',
  isPartial: false,
  caseId: { id: '', label: '' },
  defendant: { id: '', label: '' },
  amount: '',
};

export const DEDUCTIONS_EDIT_FORM_DEFAULT_VALUES = {
  id: '',
  caseReferenceId: '',
  caseSystemName: '',
  firm: '',
  isAssigned: false,
  defendant: { id: '', label: '' },
  amount: '',
  type: '',
};

export const SETTLEMENTS_EDIT_FORM_DEFAULT_VALUES = {
  id: '',
  caseReferenceId: '',
  caseSystemName: '',
  firm: '',
  defendant: { id: '', label: '' },
  plaintiff: {
    id: '',
    fullName: '',
  },
  deduction: [],
};

export const DOCUMENTS_CREATE_FORM_DEFAULT_VALUES = {
  name: '',
};

export const DOCUMENTS_EDIT_FORM_DEFAULT_VALUES = {
  status: '',
  completedAt: '',
  canceledAt: '',
  rejectedAt: '',
  openedAt: '',
  createdAt: '',
  updatedAt: '',
  id: 0,
  case: {
    id: 0,
    name: '',
    referenceId: '',
    type: {
      id: 0,
      name: '',
    },
  },
  firm: {
    id: 0,
    name: '',
  },
  plaintiffId: '',
  plaintiffFullName: '',
  fileName: '',
  name: '',
  documentUrl: '',
};

export const SIGNATURES_CREATE_FORM_DEFAULT_VALUES = {
  caseId: { id: '', label: '' },
  name: '',
  documentId: '',
};

export const SIGNATURES_EDIT_FORM_DEFAULT_VALUES = {
  id: '',
  status: '',
  updatedAt: '',
  case: { id: '', name: '', referenceId: '' },
  completedAt: '',
  createdAt: '',
  firm: { id: '', name: '' },
  name: '',
  documentId: '',
  plaintiff: { id: '', firstName: '', middleName: '', lastName: '' },
  plaintiffFullName: '',
  documentUrl: '',
  fileName: '',
};

export const DATA_CREATE_FORM_DEFAULT_VALUES = {
  caseId: 0,
  name: '',
  notes: '',
  message: '',
};

export const DATA_REQUEST_FORM_BUILDER_DEFAULT_VALUES = {
  form: {
    sections: [
      {
        title: '',
        items: [],
        immutable: false,
      },
    ],
  },
};

export const QUESTIONNAIRE_FORM_BUILDER_DEFAULT_VALUES = {
  name: '',
  form: {
    sections: [
      {
        title: '',
        items: [],
        immutable: false,
      },
    ],
  },
};

export const FORM_SNIPPET_BUILDER_DEFAULT_VALUES = {
  name: '',
  data: {
    items: [],
  },
};

export const DATA_EDIT_FORM_DEFAULT_VALUES = {
  form: null,
  answers: [],
  status: '',
  id: 0,
  name: '',
  case: {
    id: 0,
    name: '',
    referenceId: '',
    type: {
      id: 0,
      name: '',
    },
  },
  firm: {
    id: 0,
    name: '',
  },
  plaintiff: {
    id: 0,
    firstName: '',
    middleName: '',
    lastName: '',
  },
  plaintiffFullName: '',
  notes: '',
  message: '',
  formItemsCount: 0,
  formItemAnswersCount: 0,
};

export const CASE_ATTORNEYS_FEES_FORM_DEFAULT_VALUES = {
  caseFeesConfig: {
    variableFeePercent: '',
    maximumAmount: '',
    variableFeeBasis: '',
  },
  secondaryFirms: [],
};

export const FIRM_BANKING_ACCOUNT_CREATE_DEFAULT_VALUES = {
  accountNumber: '',
  routingNumber: '',
  name: '',
  isVerified: false,
};

export const FIRM_TRUST_ACCOUNT_FORM_DEFAULT_VALUES = {
  accountNumber: '',
  routingNumber: '',
  name: '',
  isVerified: false,
  wasUsedForPayment: false,
};

export const CUSTOMER_EDIT_FORM_DEFAULT_VALUES = {
  id: '',
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  phone: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
};

export const EDIT_CLIENT_SSN_DOB_FORM_DEFAULT_VALUES = {
  ssn: '',
  birthdate: null,
};

export const FIRM_WITHRDAW_FUNDS_FORM_DEFAULT_VALUES = {
  fromSystemAccountType: '',
  toUnrestrictedAccountId: '',
  amount: '',
};

export const CUSTOMER_BANK_ACCOUNT_FORM_DEFAULT_VALUES = {
  accountNumber: '',
  routingNumber: '',
  name: '',
  type: '',
};
