import { IconButton as MUIIconButton, Typography, Theme } from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

const useStyles = makeStyles()((theme: Theme) => ({
  default: {
    paddingLeft: '10px',
    paddingRight: '10px',
    gap: '4px',
    '&:disabled': {
      opacity: 0.3,
    },
  },
  button: {
    color: theme.palette.info.main,
    '&:disabled': {
      color: theme.palette.info.main,
    },
  },
  deleteButton: {
    color: theme.palette.error.main,
    '&:disabled': {
      color: theme.palette.error.main,
    },
  },
  title: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '16px',
  },
}));

type ButtonType = 'snippets' | 'move-up' | 'move-down' | 'delete';

interface IconButtonProps {
  readonly buttonType: ButtonType;
  readonly disabled?: boolean | undefined;
  readonly onClick?: () => void;
}

const IconButton = ({ buttonType, disabled, onClick }: IconButtonProps) => {
  const { classes } = useStyles();

  const getButtonObject = () => {
    switch (buttonType) {
      case 'snippets':
        return {
          title: 'SNIPPETS',
          icon: <TextSnippetIcon />,
        };
      case 'move-up':
        return {
          title: 'MOVE UP',
          icon: <ArrowUpward />,
        };
      case 'move-down':
        return {
          title: 'MOVE DOWN',
          icon: <ArrowDownward />,
        };
      case 'delete':
        return {
          title: 'DELETE',
          icon: <DeleteForeverIcon />,
        };
      default:
        return null;
    }
  };

  return (
    <MUIIconButton
      className={clsx({
        [classes.default]: true,
        [classes.button]: buttonType !== 'delete',
        [classes.deleteButton]: buttonType === 'delete',
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {getButtonObject()?.icon}
      <Typography className={classes.title}>{getButtonObject()?.title}</Typography>
    </MUIIconButton>
  );
};

export default IconButton;
