import { Box, Chip, ChipPropsColorOverrides, Theme } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { makeStyles } from 'tss-react/mui';

import { parseStatusTitle } from '../helpers/parseStatusTitle';
import { statusPillBarTypes } from '../constants/constants';

const useStyles = makeStyles()((theme: Theme) => ({
  statusPillBarWrapper: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '0.5px solid #515151',
    background: theme.palette.primary.dark,
    paddingRight: '8px',
  },
  statusPillBar: {
    '& .MuiChip-label': {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: '500',
      color: 'rgba(0, 0, 0, 0.87)',
      textTransform: 'uppercase',
      padding: '0px 16px 0px 16px',
    },
  },
}));

interface StatusPillBarProps {
  status: string;
}

const StatusPillBar = ({ status }: StatusPillBarProps) => {
  const styles = useStyles();

  const activeStatusType = statusPillBarTypes[status as keyof typeof statusPillBarTypes] as
    | OverridableStringUnion<
        'success' | 'warning' | 'error' | 'default' | 'primary' | 'secondary' | 'info',
        ChipPropsColorOverrides
      >
    | undefined;

  return (
    <Box className={styles.classes.statusPillBarWrapper}>
      <Chip
        label={parseStatusTitle(status)}
        color={activeStatusType}
        className={styles.classes.statusPillBar}
      />
    </Box>
  );
};

export default StatusPillBar;
