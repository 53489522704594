import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import DefendantsTableDataRow from './DefendantsTableDataRow';

import { getDefendantsList } from '../../api/defendantsApi/defendantsApi';
import TableContainer from '../../components/Table/TableContainer';
import Loader from '../../components/Loader';
import { DEFENDANTS_LIST_TABLE_LABELS } from '../../constants/tableLabels';
import usePagination from '../../hooks/usePagination';
import { ActiveSortField, DefendantData, FilterTypes, ViewProps } from '../../types';
import TableFilters from '../../components/TableFilters/TableFilters';
import TableActions from '../../components/Table/TableActions';
import { downloadCsv } from '../../helpers/downloadCsv';
import useSnackBar from '../../hooks/useSnackBar';
import { handleSortTableData } from '../../helpers/handleSortTableData';

const DefendantsView = ({ isRelatedToOtherEntity }: ViewProps) => {
  const params = useParams();
  const caseIdSearchParam = new URLSearchParams(window.location.search).get('caseId');

  const { pageNumber, rowsPerPage, setTotalCount } = usePagination();
  const { setAlert } = useSnackBar();

  const [defendantsList, setDefendantsList] = useState<DefendantData[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [filters, setFilters] = useState<FilterTypes>({
    firmId: params?.firmId || '',
    clientId: '',
    defendantId: '',
    caseId: caseIdSearchParam || '',
  });
  const [match, setMatch] = useState<string>('');
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);
  const sortableFields = {
    name: {
      order: '',
      fieldName: 'name',
    },
    createdAt: {
      order: '',
      fieldName: 'createdAt',
    },
  };

  const hasParams = !!Object.entries(params).length;

  useEffect(() => {
    getDefendantsList({
      page: pageNumber,
      size: rowsPerPage,
      count: true,
      match: match,
      filters: { ...filters, ...(hasParams && params) },
      activeSortField,
    }).then((res) => {
      if (res.data) {
        setTotalCount && setTotalCount(res.data.totalCount);
        setDefendantsList(res.data.resultSet);
        setIsDataLoading(false);
      }
    });
  }, [pageNumber, rowsPerPage, match, filters, activeSortField]);

  const exportList = () => {
    downloadCsv(
      {
        page: pageNumber,
        size: rowsPerPage,
        count: true,
        match: match,
        filters: filters,
      },
      '/v1/defendants',
      'Defendants_List',
    ).then((res) => {
      if (res?.data) {
        setAlert({
          message: 'Defendants exported successfully.',
          type: 'success',
        });
      }
    });
  };

  return (
    <>
      {isDataLoading && !defendantsList.length ? (
        <Box position="absolute" top="50%" left="50%">
          <Loader colorType="warning" />
        </Box>
      ) : (
        <>
          <TableActions
            hasParams={hasParams}
            createLink="/defendants/create-defendant"
            exportList={exportList}
          />

          <Grid container>
            <TableFilters
              filters={filters}
              setFilters={setFilters}
              setMatch={setMatch}
              timeFilter={'CREATED'}
              // statusFilter={['Active', 'Archived']}
              firms
            />
            <TableContainer
              isNoData={!isDataLoading && defendantsList.length === 0}
              headerLabels={isRelatedToOtherEntity || DEFENDANTS_LIST_TABLE_LABELS}
              sortableFields={sortableFields}
              activeSortField={activeSortField}
              setActiveSortField={setActiveSortField}
              handleSortTableData={handleSortTableData}
            >
              <DefendantsTableDataRow
                tableData={defendantsList}
                isRelatedToOtherEntity={!!isRelatedToOtherEntity}
              />
            </TableContainer>
          </Grid>
        </>
      )}
      <Outlet />
    </>
  );
};

export default DefendantsView;
