import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import FirmUnrestrictedAccountPopup from './FirmUnrestrictedAccountPopup';
import FirmUnrestrictedAccountTableRow from './FirmUnrestrictedAccountTableRow';

import FirmBankingAddAccountButton from '../FirmBankingAddAccountButton';

import {
  FirmUnrestrictedAccountSelectedTableItem,
  FirmUnrestrictedAccountShortData,
  FirmUnrestrictedAccountSubmitFormProps,
  UnrestrictedAccountOptionsData,
} from '../../../types';

import {
  deleteFirmUnrestrictedAccount,
  getFirmUnrestrictedAccounts,
  updateFirmUnrestrictedAccount,
} from '../../../../../api/firmsApi/unrestrictedAccountsApi';
import Loader from '../../../../../components/Loader';
import Popup from '../../../../../components/Popup';
import TableContainer from '../../../../../components/Table/TableContainer';
import { FIRM_BANKING_UNRESTRICTED_ACCOUNTS_LIST_TABLE_LABELS } from '../../../../../constants/tableLabels';
import { POPUP_DESCRIPTIONS } from '../../../../../constants/popupDescriptions';
import { handleSortTableData } from '../../../../../helpers/handleSortTableData';
import useSnackBar from '../../../../../hooks/useSnackBar';
import usePagination from '../../../../../hooks/usePagination';
import { ActiveSortField } from '../../../../../types';

const useStyles = makeStyles()(() => ({
  systemAccountsLabel: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: 'normal',
  },
}));

interface FirmUnrestrictedAccountsViewProps {
  setShortUnrestrictedAccountData: Dispatch<SetStateAction<UnrestrictedAccountOptionsData[]>>;
}

const FirmUnrestrictedAccountsView = ({
  setShortUnrestrictedAccountData,
}: FirmUnrestrictedAccountsViewProps) => {
  const styles = useStyles();
  const { firmId } = useParams();
  const { setAlert } = useSnackBar();
  const { pageNumber, rowsPerPage, setTotalCount } = usePagination();

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [unrestrictedAccounts, setUnrestrictedAccountsData] = useState<
    FirmUnrestrictedAccountShortData[]
  >([]);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);
  const [accountIdToDelete, setAccountIdToDelete] = useState<number | null>(null);
  const [selectedTableItem, setSelectedTableItem] =
    useState<FirmUnrestrictedAccountShortData | null>(null);
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);
  const [isAddForm, setIsAddForm] = useState<boolean>(true);
  const sortableFields = {
    createdAt: {
      order: '',
      fieldName: 'createdAt',
    },
  };

  const fetchUnrestrictedAccounts = () => {
    getFirmUnrestrictedAccounts(
      { page: pageNumber, size: rowsPerPage, count: true, activeSortField },
      firmId as string,
    )
      .then((res) => {
        if (res.data) {
          setTotalCount && setTotalCount(res.data.totalCount as number);

          const parsedAccountData = res.data.resultSet.map((resultItem) => ({
            id: resultItem.id,
            createdAt: moment(resultItem.createdAt).format('M/D/YYYY'),
            name: resultItem.name,
            accountNumber: resultItem.accountNumber,
            routingNumber: resultItem.routingNumber,
            isVerified: resultItem.isVerified && !resultItem?.bankingProvisionFailed,
            isVerifiedOrigin: resultItem.isVerified,
            canBeDeleted: resultItem.canBeDeleted,
          }));

          const parsedShortData = res.data.resultSet.map((resultItem) => ({
            id: String(resultItem.id),
            label: resultItem.name,
          }));

          setShortUnrestrictedAccountData(parsedShortData);
          setUnrestrictedAccountsData(parsedAccountData);
          setIsDataLoading(false);
        }
      })
      .catch(() => {
        setIsDataLoading(false);
      });
  };

  const handleEditAccount = (accountFormData: FirmUnrestrictedAccountSubmitFormProps) => {
    if (!selectedTableItem) return;
    const submitData: Partial<FirmUnrestrictedAccountSubmitFormProps> = {};

    if (selectedTableItem.accountNumber !== accountFormData.accountNumber) {
      submitData.accountNumber = accountFormData.accountNumber;
    }

    if (selectedTableItem.routingNumber !== accountFormData.routingNumber) {
      submitData.routingNumber = accountFormData.routingNumber;
    }

    if (selectedTableItem.name !== accountFormData.name) {
      submitData.name = accountFormData.name;
    }

    if (selectedTableItem.isVerified !== accountFormData.isVerified) {
      submitData.isVerified = accountFormData.isVerified;
    }
    updateFirmUnrestrictedAccount(selectedTableItem.id, submitData)
      .then(() => {
        setAlert((prev) => ({
          ...prev,
          message: 'Unrestricted account updated successfully.',
          type: 'success',
        }));

        fetchUnrestrictedAccounts();
        handleClosePopup();
        setSelectedTableItem(null);
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleDeleteAccount = () => {
    if (!accountIdToDelete) return;

    deleteFirmUnrestrictedAccount(accountIdToDelete)
      .then(() => {
        setAccountIdToDelete(null);
        handleCloseDeletePopup();
        fetchUnrestrictedAccounts();

        setAlert((prev) => ({
          ...prev,
          message: 'Unrestricted account deleted successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAccountIdToDelete(null);
        handleCloseDeletePopup();

        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const onEditButtonClick = (tableItem: FirmUnrestrictedAccountSelectedTableItem) => {
    setIsAddForm(false);
    const parsedTableItem = {
      ...tableItem,
      isVerified: tableItem.isVerified === 'Verified',
      isVerifiedOrigin: tableItem.isVerifiedOrigin === 'Verified',
    };

    setSelectedTableItem(parsedTableItem);
    handleOpenPopup();
  };

  const onDeleteButtonClick = (accountId: number) => {
    setAccountIdToDelete(accountId);
    handleOpenDeletePopup();
  };

  const handleResetFormState = () => {
    setSelectedTableItem(null);
    handleClosePopup();
  };

  const handleOpenDeletePopup = () => setIsDeletePopupOpen(true);
  const handleCloseDeletePopup = () => setIsDeletePopupOpen(false);
  const handleOpenPopup = () => setIsPopupOpen(true);
  const handleClosePopup = () => setIsPopupOpen(false);

  useEffect(() => {
    fetchUnrestrictedAccounts();
  }, [pageNumber, rowsPerPage, activeSortField]);

  const handleAddAccount = () => {
    setIsAddForm(true);
    handleOpenPopup();
  };
  return (
    <Box mt="40px">
      <Typography className={styles.classes.systemAccountsLabel} mb="16px">
        Unrestricted Accounts
      </Typography>
      {isDataLoading ? (
        <Loader colorType="warning"></Loader>
      ) : (
        <>
          <TableContainer
            isNoData={!unrestrictedAccounts.length}
            headerLabels={FIRM_BANKING_UNRESTRICTED_ACCOUNTS_LIST_TABLE_LABELS}
            sortableFields={sortableFields}
            activeSortField={activeSortField}
            setActiveSortField={setActiveSortField}
            handleSortTableData={handleSortTableData}
          >
            <FirmUnrestrictedAccountTableRow
              accountsData={unrestrictedAccounts}
              onEditButtonClick={onEditButtonClick}
              onDeleteButtonClick={onDeleteButtonClick}
            />
          </TableContainer>

          <FirmBankingAddAccountButton
            buttonText="ADD UNRESTRICTED ACCOUNT"
            handleClick={handleAddAccount}
          />

          <FirmUnrestrictedAccountPopup
            isOpen={isPopupOpen}
            handleEditAccount={handleEditAccount}
            selectedTableItem={selectedTableItem}
            handleResetFormState={handleResetFormState}
            handleClosePopup={handleClosePopup}
            fetchUnrestrictedAccounts={fetchUnrestrictedAccounts}
            isAddForm={isAddForm}
          />

          <Popup
            isOpen={isDeletePopupOpen}
            onMainButtonClick={handleDeleteAccount}
            onSecondaryButtonClick={handleCloseDeletePopup}
            headlineText={POPUP_DESCRIPTIONS.unrestrictedAccountDelete.headlineText}
            contentText={POPUP_DESCRIPTIONS.unrestrictedAccountDelete.contentText}
          />
        </>
      )}
    </Box>
  );
};

export default FirmUnrestrictedAccountsView;
