import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';

import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import SaveIcon from '@mui/icons-material/Save';
import { Box, useTheme } from '@mui/material';

import { handleGetDefendants, mapDefendantsQueryResults } from '../helpers';
import { FieldHeadline } from '../styled';
import { DeductionCreateFormSubmitProps } from '../types';

import { createDeductionRecord } from '../../../api/paymentsApi/deductionsApi';
import FormSubmitButton from '../../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../../components/buttons/FormCancelButton';
import GoBackButton from '../../../components/buttons/GoBackButton';
import FormSelectField from '../../../components/formFields/FormSelectField';
import FormTextField from '../../../components/formFields/FormTextField';
import FormAutocompleteField from '../../../components/formFields/FormAutocompleteField';
import FormToggleField from '../../../components/formFields/FormToggleField';
import { DEDUCTION_TYPES } from '../../../constants/constants';
import { DEDUCTIONS_CREATE_FORM_DEFAULT_VALUES } from '../../../helpers/formSchemas/formDefaultValues';
import { deductionsCreateFormSchema } from '../../../helpers/formSchemas/formSchemas';
import useSnackBar from '../../../hooks/useSnackBar';
import SearchCasePopup from '../../../components/SearchCasePopup';
import { CaseShortData } from '../../../types';
import CaseInfoPreview from '../../../components/CaseInfoPreview';
import CommonButton from '../../../components/buttons/CommonButton';
import { getCaseRecord } from '../../../api/casesApi/casesApi';

const CreateDeductionFormContainer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setAlert } = useSnackBar();

  const caseId = new URLSearchParams(window.location.search).get('caseId');

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [caseDetails, setCaseDetails] = useState<CaseShortData | null>(null);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(deductionsCreateFormSchema),
    defaultValues: DEDUCTIONS_CREATE_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isValid, isDirty },
  } = formMethods;

  const isAssignedToggleValue = watch('isAssigned');
  const defendantFieldValue = watch('defendant.id');

  const handleSetCaseValue = (item: CaseShortData) => {
    setIsPopupOpen(false);
    setCaseDetails(item);
  };

  const onSubmitForm = (values: DeductionCreateFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      defendantId: !values.defendant.id?.length ? null : Number(values.defendant.id),
      caseId: caseDetails?.id,
      amount: values.amount,
      type: values.type,
    };

    createDeductionRecord(submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);
          navigate(`/deductions/${res.data.id}/home`);

          setAlert((prev) => ({
            ...prev,
            message: 'Deduction created successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => {
    reset();
    !caseId && setCaseDetails(null);
  };

  useEffect(() => {
    if (!isAssignedToggleValue) {
      setValue('defendant', { id: '', label: '' });
    }
  }, [isAssignedToggleValue]);

  useEffect(() => {
    if (!caseId) return;

    getCaseRecord(Number(caseId)).then((res) => {
      setCaseDetails(res.data);
    });
  }, [caseId]);

  return (
    <>
      <GoBackButton />
      <Box display="flex" bgcolor={theme.palette.primary.dark} width="100%">
        <FormProvider {...formMethods}>
          <form style={{ width: '100%', padding: '20px' }}>
            <Box display="flex" width="100%" gap="32px">
              <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                <FieldHeadline>Case</FieldHeadline>
                <Box mt="25px" display="flex" flexDirection="column" gap="16px">
                  {caseDetails && <CaseInfoPreview caseItem={caseDetails} />}
                  {!caseId && (
                    <CommonButton
                      type="info"
                      buttonText="Find case"
                      buttonIcon={<ContentPasteSearchIcon />}
                      onButtonClick={() => setIsPopupOpen(true)}
                    />
                  )}
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center" mt="50px">
                  <FieldHeadline>Defendant</FieldHeadline>
                  <FormToggleField name="isAssigned" label="Assigned" disabled={!caseDetails} />
                </Box>

                <Box mt="10px">
                  <FormAutocompleteField
                    optionsQuery={handleGetDefendants}
                    mapQueryResults={mapDefendantsQueryResults}
                    name="defendant"
                    label="Defendant"
                    filters={{ caseId: caseDetails?.id }}
                    disabled={!caseDetails || !isAssignedToggleValue}
                  />
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                <FieldHeadline>Amount</FieldHeadline>
                <Box mt="10px">
                  <FormTextField name="amount" label="Amount *" />
                </Box>

                <FieldHeadline mt="58px">Type</FieldHeadline>

                <Box mt="16px">
                  <FormSelectField options={DEDUCTION_TYPES} name="type" label="Type *" />
                </Box>
              </Box>
            </Box>

            <Box display="flex" justifyContent="space-between" mt="40px">
              <FormSubmitButton
                disabled={
                  !isValid || (isAssignedToggleValue && !defendantFieldValue) || isFormSubmitting
                }
                buttonIcon={<SaveIcon />}
                buttonText="Save"
                onClick={handleSubmit(onSubmitForm)}
              />
              {caseId && isDirty && <FormCancelButton onClick={handleCancelChanges} />}
              {!caseId && (isDirty || caseDetails) && (
                <FormCancelButton onClick={handleCancelChanges} />
              )}
            </Box>
          </form>
        </FormProvider>
        <SearchCasePopup
          isOpen={isPopupOpen}
          onMainButtonClick={handleSetCaseValue}
          onSecondaryButtonClick={() => setIsPopupOpen(false)}
        />
      </Box>
    </>
  );
};

export default CreateDeductionFormContainer;
