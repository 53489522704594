import { Box, Theme, Typography, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { CustomerAccountActivationsState } from './types';

const useStyles = makeStyles()((theme: Theme) => ({
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  buttonContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: 'normal',
    fontFamily: 'Roboto',
    padding: '7px',
    borderRight: '1px solid #515151',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    ':first-of-type': {
      borderRadius: '4px 0 0 4px',
    },

    ':last-of-type': {
      borderRadius: '0 4px 4px 0',
      borderRight: 'none',
    },
  },
  buttonsGroupLabel: {
    color: theme.palette.secondary.main,
    textAlign: 'start',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: 'normal',
    textTransform: 'capitalize',
    marginBottom: '3px',
  },
  buttonDisabled: {
    color: 'rgba(255, 255, 255, 0.3)',
    background: 'rgba(255, 255, 255, 0.12)',
  },
}));

interface CustomerActivationsButtonGroupProps {
  buttonGroupLabel: string;
  accountActivationsState: CustomerAccountActivationsState;
  activeButtonName?: string;
  maxWidth?: string;
}

const CustomerActivationsButtonGroup = ({
  buttonGroupLabel,
  accountActivationsState,
  maxWidth,
}: CustomerActivationsButtonGroupProps) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Typography className={styles.classes.buttonsGroupLabel}>{buttonGroupLabel}</Typography>
      <Box className={styles.classes.buttonsWrapper}>
        {Object.values(accountActivationsState).map((state, idx) => (
          <Box
            key={idx}
            maxWidth={maxWidth}
            className={clsx(styles.classes.buttonContainer)}
            bgcolor={state.isActive ? theme.palette.success.main : theme.palette.error.main}
          >
            {state.label}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CustomerActivationsButtonGroup;
