import { makeStyles } from 'tss-react/mui';
import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.light,
      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))',
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.secondary.main,
    },
  },
  popupActions: {
    padding: '16px 8px',
  },
  popupButton: {
    color: theme.palette.info.main,

    '&:hover': {
      background: 'rgba(144, 202, 249, 0.08)',
    },
  },
}));

interface PopupProps {
  isOpen: boolean;
  headlineText: string;
  contentText: string;
  mainButtonText?: string;
  secondaryButtonText?: string;
  onMainButtonClick: () => void;
  onSecondaryButtonClick?: () => void;
}

const Popup = ({
  isOpen,
  headlineText,
  contentText,
  mainButtonText,
  secondaryButtonText,
  onMainButtonClick,
  onSecondaryButtonClick,
}: PopupProps) => {
  const styles = useStyles();

  return (
    <Dialog
      className={styles.classes.popupContainer}
      open={isOpen}
      onClose={onSecondaryButtonClick}
    >
      <DialogTitle>{headlineText}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions className={styles.classes.popupActions}>
        <Button className={styles.classes.popupButton} onClick={onMainButtonClick}>
          {mainButtonText || 'OK'}
        </Button>
        {onSecondaryButtonClick && (
          <Button className={styles.classes.popupButton} onClick={onSecondaryButtonClick}>
            {secondaryButtonText || 'Cancel'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
