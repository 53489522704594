import api from '../service/api.service';

import { ActiveSortField, Admin, PaginationQueryParams } from '../../types';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';

const BASE_URL = '/v1/admins';

export const getAdminsList = async ({
  size,
  page,
  count,
  match = '',
  activeSortField,
}: PaginationQueryParams) => {
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL}?size=${size}&page=${page}&count=${count}&match=${match}${sortFilters}`;

  return await api.get(targetUrl);
};

export const createAdminRecord = async (data: unknown) => {
  const targetUrl = `${BASE_URL}`;

  return await api.post(targetUrl, data);
};

export const getAdmin = async (id: string | number) => {
  const targetUrl = `${BASE_URL}/${id}`;

  return await api.get<Admin>(targetUrl);
};

export const updateAdminRecord = async (adminId: string, data: unknown) => {
  const targetUrl = `${BASE_URL}/${adminId}`;

  return await api.put(targetUrl, data);
};

export const resetAdminAccount = async (adminId: string) => {
  const targetUrl = `${BASE_URL}/${adminId}/reset`;

  return await api.post(targetUrl, {});
};

export const checkAdminPin = async (id: number, pin: string) => {
  const targetUrl = `${BASE_URL}/${id}/check-pin`;

  return await api.put<{ isValid: boolean }>(targetUrl, { pin });
};

export const setAdminPin = async (id: number, newPin: string, oldPin?: string) => {
  const targetUrl = `${BASE_URL}/${id}/set-pin`;

  return await api.put<void>(targetUrl, { newPin, ...(oldPin && { oldPin }) });
};

export const resetAdminPin = async (id: number) => {
  const targetUrl = `${BASE_URL}/${id}/reset-pin`;

  return await api.put<void>(targetUrl);
};
