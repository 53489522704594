import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { TableCell, TableRow, Theme } from '@mui/material';

import { formatDate } from '../../helpers/formatDate';
import { translateStatus } from '../../helpers/translateStatus';
import { DefendantData } from '../../types';

const useStyles = makeStyles()((theme: Theme) => ({
  styledLink: {
    textDecoration: 'none',
    color: theme.palette.info.main,
  },
}));

interface AdminsTableDataRowProps {
  tableData: DefendantData[];
  isRelatedToOtherEntity?: boolean;
}

const DefendantsTableDataRow = ({ tableData, isRelatedToOtherEntity }: AdminsTableDataRowProps) => {
  const styles = useStyles();

  const formattedLawyersList =
    tableData &&
    tableData.map((defendant) => ({
      name: (
        <Link className={styles.classes.styledLink} to={`/defendants/${String(defendant.id)}/home`}>
          {defendant.name}
        </Link>
      ),
      ...(!isRelatedToOtherEntity && { firm: defendant.firm.name }),
      createdAt: formatDate(defendant.createdAt),
      status: translateStatus(true),
      activeCases: '-',
      grossSettlements: '-',
      netSettlements: '-',
    }));

  return (
    <>
      {formattedLawyersList.map((tableItem, idx) => (
        <TableRow key={idx}>
          {Object.values(tableItem).map((item, idx) => (
            <TableCell key={idx}>{item}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default DefendantsTableDataRow;
