import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { TableCell, TableRow, Theme } from '@mui/material';

import { formatDate } from '../../helpers/formatDate';
import { FirmShortData } from '../../types';
import { numberToUSDCurrency } from '../../helpers/numberToUSDCurrency';

const useStyles = makeStyles()((theme: Theme) => ({
  styledLink: {
    textDecoration: 'none',
    color: theme.palette.info.main,
  },
}));

interface AdminsTableDataRowProps {
  tableData: FirmShortData[];
}

const FirmsTableDataRow = ({ tableData }: AdminsTableDataRowProps) => {
  const styles = useStyles();

  const formattedFirmsList =
    tableData &&
    tableData.map((firm) => ({
      name: (
        <Link className={styles.classes.styledLink} to={`/firms/${String(firm.id)}/home`}>
          {firm.name}
        </Link>
      ),
      createdAt: formatDate(firm.createdAt as string),
      status: firm.isArchived ? 'Archieved' : 'Active',
      cases: firm?.caseCount,
      clients: firm?.plaintiffCount,
      settlements: numberToUSDCurrency(firm?.settledAmountCount),
      payouts: '-',
      ytdFees: '-',
    }));

  return (
    <>
      {formattedFirmsList.map((tableItem, idx) => (
        <TableRow key={idx}>
          {Object.values(tableItem).map((item, idx) => (
            <TableCell key={idx}>{item}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default FirmsTableDataRow;
