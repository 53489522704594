import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams, useOutletContext } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

// import ArchiveIcon from '@mui/icons-material/Archive';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { Box, useTheme } from '@mui/material';

import { handleGetDefendants, mapDefendantsQueryResults } from '../helpers';
import { FieldHeadline } from '../styled';
import { DeductionEditFormSubmitProps } from '../types';

import {
  deleteDeductionRecord,
  updateDeductionRecord,
} from '../../../api/paymentsApi/deductionsApi';
import CommonButton from '../../../components/buttons/CommonButton';
import FormLinkButton from '../../../components/buttons/FormLinkButton';
import FormCopyTextField from '../../../components/formFields/FormCopyTextField';
import FormTextField from '../../../components/formFields/FormTextField';
import FormToggleField from '../../../components/formFields/FormToggleField';
import FormAutocompleteField from '../../../components/formFields/FormAutocompleteField';
import FormSelectField from '../../../components/formFields/FormSelectField';
import FormSubmitButton from '../../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../../components/buttons/FormCancelButton';
import { DEDUCTION_TYPES } from '../../../constants/constants';
import { deductionsEditFormSchema } from '../../../helpers/formSchemas/formSchemas';
import { DEDUCTIONS_EDIT_FORM_DEFAULT_VALUES } from '../../../helpers/formSchemas/formDefaultValues';
import usePageTitle from '../../../hooks/usePageTitle';
import useSnackBar from '../../../hooks/useSnackBar';
import { EntityFlexibleRecord } from '../../../types';
import Popup from '../../../components/Popup';
import { POPUP_DESCRIPTIONS } from '../../../constants/popupDescriptions';

const DeductionsEditFormContainer = () => {
  const { deductionId } = useParams();
  const { entityData } = useOutletContext<EntityFlexibleRecord>();
  const { setPageTitle } = usePageTitle();
  const theme = useTheme();
  const navigate = useNavigate();
  const { setAlert } = useSnackBar();

  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [firmIdValue, setFirmIdValue] = useState<string>('');
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(deductionsEditFormSchema),
    defaultValues: DEDUCTIONS_EDIT_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isValid, isDirty },
  } = formMethods;

  const caseIdValue = watch('caseReferenceId');
  const defendantIdValue = watch('defendant.id');
  const isAssignedToggleValue = watch('isAssigned');

  const onDeleteButtonClick = () => handleOpenDeletePopup();

  const handleDeleteDeduction = () => {
    deleteDeductionRecord(deductionId as string)
      .then(() => {
        setAlert((prev) => ({
          ...prev,
          message: 'Deduction deleted successfully.',
          type: 'success',
        }));
        navigate('/deductions');
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
        handleCloseDeletePopup();
      });
  };

  const onSubmitForm = (values: DeductionEditFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      defendantId: !values.defendant.id?.length ? null : Number(values.defendant.id),
      amount: values.amount,
      type: values.type,
    };

    updateDeductionRecord(deductionId as string, submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);
          navigate('/deductions');

          setAlert((prev) => ({
            ...prev,
            message: 'Deduction updated successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => reset();
  const handleCloseDeletePopup = () => setIsDeletePopupOpen(false);
  const handleOpenDeletePopup = () => setIsDeletePopupOpen(true);

  useEffect(() => {
    if (entityData) {
      setPageTitle && setPageTitle(entityData.type as string);
      setFirmIdValue(entityData.firmId as string);

      reset({
        ...entityData,
      });
    }

    return () => setPageTitle && setPageTitle('');
  }, [entityData]);

  useEffect(() => {
    if (!isAssignedToggleValue) {
      setValue('defendant', { id: '', label: '' });
    }
  }, [isAssignedToggleValue, setValue]);

  return (
    <>
      <Box display="flex" bgcolor={theme.palette.primary.dark} width="100%">
        <FormProvider {...formMethods}>
          <form style={{ width: '100%', padding: '20px' }}>
            <Box display="flex" width="100%" gap="32px">
              <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                <FieldHeadline>Identity</FieldHeadline>
                <Box mt="10px">
                  <FormCopyTextField entityName="Deduction" name="id" label="Id" isReadOnly />
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt="20px">
                  <FieldHeadline>Case</FieldHeadline>
                  <FormLinkButton path={`/cases/${caseIdValue}/home`} />
                </Box>
                <Box mt="20px">
                  <FormTextField name="caseReferenceId" label="ReferenceId" readOnly isReadOnly />
                </Box>
                <Box mt="20px">
                  <FormTextField name="caseSystemName" label="System Name" readOnly isReadOnly />
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt="50px">
                  <FieldHeadline>Firm</FieldHeadline>
                  <FormLinkButton path={`/firms/${firmIdValue}/home`} />
                </Box>
                <Box mt="20px">
                  <FormTextField name="firm" label="Firm" readOnly isReadOnly />
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center" mt="50px">
                  <FieldHeadline>Defendant</FieldHeadline>
                  <Box display="flex" gap="10px" alignItems="center">
                    <FormToggleField name="isAssigned" label="Assigned" />
                    <FormLinkButton
                      path={defendantIdValue ? `/defendants/${defendantIdValue}/home` : '#'}
                      disabled={!defendantIdValue}
                    />
                  </Box>
                </Box>
                <Box mt="20px">
                  <FormAutocompleteField
                    name="defendant"
                    label="Defendant"
                    optionsQuery={handleGetDefendants}
                    mapQueryResults={mapDefendantsQueryResults}
                    disabled={!isAssignedToggleValue}
                    filters={{ caseId: caseIdValue }}
                  />
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                <FieldHeadline>Amount</FieldHeadline>
                <Box mt="20px">
                  <FormTextField name="amount" label="Amount *" />
                </Box>

                <FieldHeadline mt="50px">Type</FieldHeadline>
                <Box mt="20px">
                  <FormSelectField name="type" label="Type *" options={DEDUCTION_TYPES} />
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                <FieldHeadline>Actions</FieldHeadline>
                <Box mt="16px">
                  <CommonButton
                    disabled={entityData?.status !== 'Unpaid'}
                    type="error"
                    buttonText="delete"
                    buttonIcon={<CancelIcon />}
                    onButtonClick={onDeleteButtonClick}
                  />
                </Box>
                {/* <Box mt="16px">
                  <CommonButton
                    disabled
                    type="error"
                    buttonText="archive"
                    buttonIcon={<ArchiveIcon />}
                  />
                </Box> */}
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" mt="40px">
              <FormSubmitButton
                disabled={
                  !isValid ||
                  !isDirty ||
                  (isAssignedToggleValue && !defendantIdValue) ||
                  isFormSubmitting
                }
                buttonIcon={<SaveIcon />}
                buttonText="Save"
                onClick={handleSubmit(onSubmitForm)}
              />
              {isDirty && <FormCancelButton onClick={handleCancelChanges} />}
            </Box>
          </form>
        </FormProvider>
        <Popup
          isOpen={isDeletePopupOpen}
          headlineText={POPUP_DESCRIPTIONS.deductionDelete.headlineText}
          contentText={POPUP_DESCRIPTIONS.deductionDelete.contentText}
          onMainButtonClick={handleDeleteDeduction}
          onSecondaryButtonClick={handleCloseDeletePopup}
        />
      </Box>
    </>
  );
};

export default DeductionsEditFormContainer;
