import { Box } from '@mui/material';

import TabsPanel from '../../../components/TabsPanel';
import { deductionsAccountTabs } from '../../../constants/tabRoutes';
import GoBackButton from '../../../components/buttons/GoBackButton';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getDeductionRecord } from '../../../api/paymentsApi/deductionsApi';

const DeductionAccountView = () => {
  const { deductionId } = useParams();
  const [entityRecordData, setEntityRecordData] = useState<{ [key: string]: unknown }>();

  useEffect(() => {
    getDeductionRecord(deductionId as string).then((res) => {
      if (res.data) {
        const hasDefendant = res.data.defendant !== null && Boolean(res.data.defendant.name.length);

        setEntityRecordData({
          id: res.data.id,
          caseReferenceId: res.data.case.id,
          caseSystemName: res.data.case.name,
          firm: res.data.firm.name,
          isAssigned: hasDefendant,
          amount: res.data.amount,
          type: res.data.type,
          status: res.data.status,
          firmId: res.data.firm.id,
          defendant: hasDefendant
            ? {
                id: res.data.defendant.id,
                label: res.data.defendant.name,
              }
            : {
                id: '',
                label: '',
              },
        });
      }
    });
  }, [deductionId]);

  return (
    <>
      <GoBackButton />
      <Box display="flex" width="100%">
        <TabsPanel
          tabsList={deductionsAccountTabs}
          locationSlicePosition={3}
          contextData={entityRecordData}
        />
      </Box>
    </>
  );
};

export default DeductionAccountView;
