import api from '../service/api.service';

import {
  ActiveSortField,
  CustomerData,
  FilterTypes,
  PaginationQueryParams,
  ResultSet,
} from '../../types';
import { parseClientBankingFilters, parseQueryFilters } from '../../helpers/parseQueryFilters';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';

const BASE_URL_V1 = '/v1/customers';

export const createCustomerRecord = async (data: unknown) => {
  return await api.post(BASE_URL_V1, data);
};

export const getCustomersList = async ({
  size,
  page,
  count,
  match,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL_V1}?size=${size}&page=${page}&count=${count}&match=${match || ''}${
    queryFilters || ''
  }${sortFilters}&includeAdditionalInfo=true`;

  return await api.get<ResultSet<CustomerData>>(targetUrl);
};

export const getCustomerRecord = async (id: number | string) => {
  const targetUrl = `${BASE_URL_V1}/${id}`;

  return await api.get<CustomerData>(targetUrl);
};

export const updateCustomerRecord = async (clientId: string, data: unknown) => {
  const targetUrl = `${BASE_URL_V1}/${clientId}`;

  return await api.put(targetUrl, data);
};

export const resetCustomerAccount = async (clientId: string) => {
  const targetUrl = `${BASE_URL_V1}/${clientId}/reset`;

  return await api.post(targetUrl, null);
};

export const getCustomerBankingInfo = async (clientId: string) => {
  const targetUrl = `${BASE_URL_V1}/${clientId}/banking`;

  return await api.get(targetUrl);
};

export const startCustomerProvisioning = async (clientId: string) => {
  const targetUrl = `${BASE_URL_V1}/${clientId}/banking/start-provisioning`;

  return await api.put(targetUrl);
};

export const getCustomerTransactionList = async (
  { size, page, filters, activeSortField }: PaginationQueryParams,
  clientId: string,
) => {
  const queryFilters = parseClientBankingFilters(filters as FilterTypes);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL_V1}/${clientId}/banking/ledger?size=${size}&page=${page}${
    queryFilters || ''
  }${sortFilters}`;

  return await api.get(targetUrl);
};

export const getCustomerBirthDate = async (id: number) => {
  const targetUrl = `${BASE_URL_V1}/${id}/birthdate`;

  return await api.get<{ birthdate: string; id: number }>(targetUrl);
};

export const getCustomerSSN = async (id: number) => {
  const targetUrl = `${BASE_URL_V1}/${id}/ssn`;

  return await api.get<{ id: number; ssn: string }>(targetUrl);
};

export const getCustomerToClientsList = async ({
  size,
  page,
  count,
  match,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `v1/clients?size=${size}&page=${page}&count=${count}&match=${match || ''}${
    queryFilters || ''
  }${sortFilters}`;

  return await api.get(targetUrl);
};

export const getCustomerBankingAccounts = async (
  { size, page, filters, activeSortField }: PaginationQueryParams,
  customerId: string,
) => {
  const queryFilters = parseQueryFilters(filters as FilterTypes);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `/v1/bo/customers/${customerId}/banking/bank-accounts?size=${size}&page=${page}${
    queryFilters || ''
  }${sortFilters}`;

  return await api.get(targetUrl);
};

export const deleteCustomerBankingAccount = async (customerId: number, accountId: number) => {
  const targetUrl = `/v1/bo/customers/${customerId}/banking/bank-accounts/${accountId}`;

  return await api.delete(targetUrl);
};

export const getCustomerBankingAccount = async (customerId: number, accountId: number) => {
  const targetUrl = `/v1/bo/customers/${customerId}/banking/bank-accounts/${accountId}`;

  return await api.get(targetUrl);
};

export const createCustomerBankingAccount = async (customerId: number, data: unknown) => {
  const targetUrl = `/v1/bo/customers/${customerId}/banking/bank-accounts`;

  return await api.post(targetUrl, data);
};

export const startCustomerBankingAccountProvisioning = async (
  customerId: number,
  accountId: number,
) => {
  const targetUrl = `/v1/bo/customers/${customerId}/banking/bank-accounts/${accountId}/start-provisioning`;

  return await api.put(targetUrl);
};

export const updateCustomerBankingAccount = async (
  customerId: number,
  accountId: number,
  data: unknown,
) => {
  const targetUrl = `/v1/bo/customers/${customerId}/banking/bank-accounts/${accountId}`;

  return await api.put(targetUrl, data);
};
