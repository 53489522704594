import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme } from '@mui/material';
import { useRef } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.light,
      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))',
      height: '90%',
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.secondary.main,
    },
  },
  popupButton: {
    color: theme.palette.info.main,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
    },

    '&:hover': {
      background: 'rgba(144, 202, 249, 0.08)',
    },
  },
}));

interface IPreviewFormPopupProps {
  isOpen: boolean;
  title: string;
  data: string;
  onSecondaryButtonClick: () => void;
}

const PreviewFormPopup = ({
  isOpen,
  title,
  data,
  onSecondaryButtonClick,
}: IPreviewFormPopupProps) => {
  const styles = useStyles();
  const iRef = useRef<HTMLIFrameElement | null>(null);

  const handleLoad = () => {
    setTimeout(() => {
      if (iRef && iRef?.current && iRef?.current?.contentWindow)
        iRef.current.contentWindow.postMessage(data, process.env.REACT_APP_FORMS_APP_URL as string);
    }, 1000);
  };

  return (
    <Dialog
      className={styles.classes.popupContainer}
      open={isOpen}
      onClose={onSecondaryButtonClick}
      fullWidth
      maxWidth={'md'}
    >
      <DialogTitle textAlign="center">{title}</DialogTitle>
      <DialogContent style={{ overflow: 'hidden' }}>
        <iframe
          ref={iRef}
          src={process.env.REACT_APP_FORMS_APP_URL}
          width={'100%'}
          height={'100%'}
          onLoad={handleLoad}
          style={{ border: 'none', borderRadius: '4px' }}
        ></iframe>
      </DialogContent>
      <DialogActions>
        <Button className={styles.classes.popupButton} onClick={onSecondaryButtonClick}>
          {'Close'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewFormPopup;
