import { useEffect, useState } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from 'tss-react/mui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';

import {
  checkAdminPin,
  getAdmin,
  setAdminPin,
  updateAdminRecord,
} from '../../../api/adminsApi/adminsApi';
import FormTextField from '../../../components/formFields/FormTextField';
import FormSubmitButton from '../../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../../components/buttons/FormCancelButton';
import FormCopyTextField from '../../../components/formFields/FormCopyTextField';
import { MANAGE_ACCOUNT_FORM_DEFAULT_VALUES } from '../../../helpers/formSchemas/formDefaultValues';
import { manageAccountFormSchema } from '../../../helpers/formSchemas/formSchemas';
import FormSelectField from '../../../components/formFields/FormSelectField';
import { ROLE_OPTIONS } from '../../../constants/constants';
import useSnackBar from '../../../hooks/useSnackBar';
import CommonButton from '../../../components/buttons/CommonButton';
import VerificationPopup from '../../../components/VerificationPopup';
import { ErrorData } from '../../../types';
import { FieldHeadline } from '../styled';

import SetPinCodePopup from './SetPinCodePopup';

const useStyles = makeStyles()((theme: Theme) => ({
  formWrapper: {
    display: 'flex',
    backgroundColor: theme.palette.primary.dark,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '10px',
  },
  formContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px',
    gap: '32px',
  },
  formColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '468px',
    gap: '32px',
  },
  formSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  text: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14.4px',
    color: theme.palette.primary.light,
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px 16px 24px',
  },
}));

export interface ManageAccountFormSubmitProps {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  isPinSet: boolean;
  isSuperAdmin: boolean;
}

const ManageAccountHomeTab = () => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();

  const userId = localStorage.getItem('userId');

  const [isSetNewPinPopupOpen, setIsSetNewPinPopupOpen] = useState<boolean>(false);
  const [isVerificationPopupOpen, setIsVerificationPopupOpen] = useState<boolean>(false);
  const [currentPin, setCurrentPin] = useState<string | null>(null);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(manageAccountFormSchema),
    defaultValues: MANAGE_ACCOUNT_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isValid, isDirty },
  } = formMethods;

  const userIdValue = watch('id');
  const isPinSet = watch('isPinSet');
  const isSuperAdmin = watch('isSuperAdmin');

  const handleCloseSetNewPinPopup = () => setIsSetNewPinPopupOpen(false);
  const handleCloseVerificationPopup = () => setIsVerificationPopupOpen(false);
  const handleOpenSetNewPinPopup = () => setIsSetNewPinPopupOpen(true);
  const handleOpenVerificationPopup = () => setIsVerificationPopupOpen(true);

  const handleCancelChanges = () => reset();

  const onSubmitForm = (values: ManageAccountFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      firstName: values.firstName,
      lastName: values.lastName,
    };

    updateAdminRecord(userIdValue, submitData)
      .then((res) => {
        if (!res.data.error) {
          setAlert((prev) => ({
            ...prev,
            message: 'Account updated successfully',
            type: 'success',
          }));

          reset({
            id: res.data.id,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            email: res.data.email,
            phone: res.data.phone,
            role: ROLE_OPTIONS[0].id,
            isPinSet: res.data.isPinSet,
            isSuperAdmin: res.data.isSuperAdmin,
          });
        }

        setIsFormSubmitting(false);
        setTimeout(() => window.location.reload(), 500);
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const getAdminData = () => {
    if (!userId) return;

    getAdmin(userId)
      .then((res) => {
        if (res.data) {
          reset({
            id: res.data.id.toString(),
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            email: res.data.email,
            phone: res.data.phone,
            role: ROLE_OPTIONS[0].id,
            isPinSet: res.data.isPinSet,
            isSuperAdmin: res.data.isSuperAdmin,
          });
        }
      })
      .catch(() => {
        setAlert((prev) => ({
          ...prev,
          message: 'Cannot load account data.',
          type: 'error',
        }));
      });
  };

  const onConfirmCurrentPin = (pin: string) => {
    if (!userId) return;

    checkAdminPin(Number(userId), pin)
      .then((response) => {
        if (response.data.isValid) {
          handleCloseVerificationPopup();
          handleOpenSetNewPinPopup();
          setCurrentPin(pin);
        } else {
          setAlert((prev) => ({
            ...prev,
            message: 'Invalid pin. Please try again.',
            type: 'error',
          }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      });
  };

  const setPinCode = (newPin: string) => {
    if (!userId) return;

    const oldPin = currentPin || undefined;

    setAdminPin(Number(userId), newPin, oldPin)
      .then(() => {
        getAdminData();
        handleCloseSetNewPinPopup();
        setAlert((prev) => ({
          ...prev,
          message: 'PIN code has been set successfully.',
          type: 'success',
        }));
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert({
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        });
      });
  };

  useEffect(() => {
    getAdminData();
  }, [userId]);

  return (
    <Box className={classes.formWrapper}>
      <FormProvider {...formMethods}>
        <form className={classes.formContainer}>
          <Box className={classes.formContentContainer}>
            <Box className={classes.formColumn}>
              <Box className={classes.formSection}>
                <FieldHeadline>Identity</FieldHeadline>
                <FormCopyTextField entityName="Account" name="id" label="Id" isReadOnly />
                <FormTextField name="firstName" label="First Name *" />
                <FormTextField name="lastName" label="Last Name *" />
              </Box>

              <Box className={classes.formSection}>
                <FieldHeadline>Credentials</FieldHeadline>
                <FormTextField name="email" label="Email Address *" disabled />
                <FormTextField name="phone" label="Mobile Number *" disabled />
              </Box>

              <Box className={classes.formSection}>
                <FieldHeadline>Role</FieldHeadline>
                <FormSelectField options={ROLE_OPTIONS} name="role" label="Role *" />
              </Box>
            </Box>

            <Box className={classes.formColumn}>
              <Box className={classes.formSection}>
                <FieldHeadline>Actions</FieldHeadline>
                <Typography className={classes.text}>Set up the PIN code for settlement</Typography>
                <CommonButton
                  buttonText={isPinSet ? 'Change PIN' : 'Set PIN'}
                  type="info"
                  onButtonClick={isPinSet ? handleOpenVerificationPopup : handleOpenSetNewPinPopup}
                  disabled={isSuperAdmin}
                />
              </Box>
            </Box>
          </Box>

          <Box className={classes.actionButtons}>
            <FormSubmitButton
              disabled={!isValid || !isDirty || isFormSubmitting}
              buttonIcon={<SaveIcon />}
              buttonText="Save"
              onClick={handleSubmit(onSubmitForm)}
            />
            {isDirty && <FormCancelButton onClick={handleCancelChanges} />}
          </Box>
        </form>
      </FormProvider>

      <VerificationPopup
        isOpen={isVerificationPopupOpen}
        onMainButtonClick={onConfirmCurrentPin}
        onSecondaryButtonClick={handleCloseVerificationPopup}
      />
      <SetPinCodePopup
        isOpen={isSetNewPinPopupOpen}
        onMainButtonClick={setPinCode}
        onSecondaryButtonClick={handleCloseSetNewPinPopup}
      />
    </Box>
  );
};

export default ManageAccountHomeTab;
