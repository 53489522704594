import { QuestionnaireItemFormat } from '../types';
import { StatusAccount, StatusProvisioning } from '../views/FirmsView/types';
import { statusFundingSettlment } from '../views/PaymentsView/types';

export const DEFAULT_PAGINATION_SETTINGS = {
  size: 10,
  page: 1,
  count: true,
};

export const ROWS_PER_PAGE_OPTIONS = [20, 50, 100];

export const US_STATES_LIST = [
  {
    id: 'AL',
    label: 'Alabama',
  },
  {
    id: 'AK',
    label: 'Alaska',
  },
  {
    id: 'AZ',
    label: 'Arizona',
  },
  {
    id: 'AR',
    label: 'Arkansas',
  },
  {
    id: 'CA',
    label: 'California',
  },
  {
    id: 'CO',
    label: 'Colorado',
  },
  {
    id: 'CT',
    label: 'Connecticut',
  },
  {
    id: 'DC',
    label: 'District of Columbia',
  },
  {
    id: 'DE',
    label: 'Delaware',
  },
  {
    id: 'FL',
    label: 'Florida',
  },
  {
    id: 'GA',
    label: 'Georgia',
  },
  {
    id: 'HI',
    label: 'Hawaii',
  },
  {
    id: 'ID',
    label: 'Idaho',
  },
  {
    id: 'IL',
    label: 'Illinois',
  },
  {
    id: 'IN',
    label: 'Indiana',
  },
  {
    id: 'IA',
    label: 'Iowa',
  },
  {
    id: 'KS',
    label: 'Kansas',
  },
  {
    id: 'KY',
    label: 'Kentucky',
  },
  {
    id: 'LA',
    label: 'Louisiana',
  },
  {
    id: 'ME',
    label: 'Maine',
  },
  {
    id: 'MD',
    label: 'Maryland',
  },
  {
    id: 'MA',
    label: 'Massachusetts',
  },
  {
    id: 'MI',
    label: 'Michigan',
  },
  {
    id: 'MN',
    label: 'Minnesota',
  },
  {
    id: 'MS',
    label: 'Mississippi',
  },
  {
    id: 'MO',
    label: 'Missouri',
  },
  {
    id: 'MT',
    label: 'Montana',
  },
  {
    id: 'NE',
    label: 'Nebraska',
  },
  {
    id: 'NV',
    label: 'Nevada',
  },
  {
    id: 'NH',
    label: 'New Hampshire',
  },
  {
    id: 'NJ',
    label: 'New Jersey',
  },
  {
    id: 'NM',
    label: 'New Mexico',
  },
  {
    id: 'NY',
    label: 'New York',
  },
  {
    id: 'NC',
    label: 'North Carolina',
  },
  {
    id: 'ND',
    label: 'North Dakota',
  },
  {
    id: 'OH',
    label: 'Ohio',
  },
  {
    id: 'OK',
    label: 'Oklahoma',
  },
  {
    id: 'OR',
    label: 'Oregon',
  },
  {
    id: 'PA',
    label: 'Pennsylvania',
  },
  {
    id: 'RI',
    label: 'Rhode Island',
  },
  {
    id: 'SC',
    label: 'South Carolina',
  },
  {
    id: 'SD',
    label: 'South Dakota',
  },
  {
    id: 'TN',
    label: 'Tennessee',
  },
  {
    id: 'TX',
    label: 'Texas',
  },
  {
    id: 'UT',
    label: 'Utah',
  },
  {
    id: 'VT',
    label: 'Vermont',
  },
  {
    id: 'VA',
    label: 'Virginia',
  },
  {
    id: 'WA',
    label: 'Washington',
  },
  {
    id: 'WV',
    label: 'West Virginia',
  },
  {
    id: 'WI',
    label: 'Wisconsin',
  },
  {
    id: 'WY',
    label: 'Wyoming',
  },
];

export const CLIENT_ACTIVATION_TRIGGER_OPTIONS = {
  sendAppInviteUpon: [{ id: 'case', label: 'Case Creation' }],
  createWalletUpon: [{ id: 'settlement', label: 'First Settlement' }],
  issueVirtualCardUpon: [{ id: 'issueVirtual', label: 'Not Applicable (Physical Only)' }],
  issuePhysicalCardUpon: [{ id: 'issuePhysical', label: 'First Settlement' }],
};

export const ROLE_OPTIONS = [
  {
    id: 'FirmAdmins',
    label: 'Admin',
  },
];

export const CLIENT_ACTIVATION_BUTTON_GROUPS = {
  app: [
    {
      buttonLabel: 'NO APP',
      buttonType: 'error',
    },
    {
      buttonLabel: 'INVITED',
      buttonType: 'warning',
    },
    {
      buttonLabel: 'ACTIVATED',
      buttonType: 'success',
    },
  ],
  wallet: [
    {
      buttonLabel: 'NO WALLET',
      buttonType: 'error',
    },
    {
      buttonLabel: 'CREATED',
      buttonType: 'warning',
    },
    {
      buttonLabel: 'FUNDED',
      buttonType: 'success',
    },
  ],
  virtualCard: [
    {
      buttonLabel: 'NO VIRTUAL',
      buttonType: 'error',
    },
    {
      buttonLabel: 'ISSUED',
      buttonType: 'warning',
    },
    {
      buttonLabel: 'ACTIVATED',
      buttonType: 'success',
    },
    {
      buttonLabel: 'FUNDED',
      buttonType: 'success',
    },
  ],
  physicalCard: [
    {
      buttonLabel: 'NO PHYSICAL',
      buttonType: 'error',
    },
    {
      buttonLabel: 'ISSUED',
      buttonType: 'warning',
    },
    {
      buttonLabel: 'ACTIVATED',
      buttonType: 'success',
    },
    {
      buttonLabel: 'FUNDED',
      buttonType: 'success',
    },
  ],
  identity: [
    {
      buttonLabel: 'EMAIL',
      buttonType: 'error',
    },
    {
      buttonLabel: 'SSN',
      buttonType: 'warning',
    },
    {
      buttonLabel: 'DATE OF BIRTH',
      buttonType: 'success',
    },
  ],
};

export const CODE_MAPPING_FORMATS = [
  { id: 'Text', label: 'Text' },
  { id: 'DateRange', label: 'Date Range' },
  { id: 'Address', label: 'Address' },
  { id: 'Date', label: 'Date' },
];

export const CODE_MAPPING_CATEGORIES = [
  { id: 'Personal', label: 'Personal' },
  { id: 'Residence', label: 'Residence' },
  { id: 'Employment', label: 'Employment' },
  { id: 'Injury', label: 'Injury' },
];

export const DEDUCTION_TYPES = [
  {
    id: 'Advance',
    label: 'Advance',
  },
  {
    id: 'Lien',
    label: 'Lien',
  },
];

export const DATA_TYPES_OPTIONS = [
  {
    id: QuestionnaireItemFormat.Boolean,
    label: 'Boolean',
  },
  {
    id: QuestionnaireItemFormat.Date,
    label: 'Date',
  },
  {
    id: QuestionnaireItemFormat.DateRange,
    label: 'Date Range',
  },
  {
    id: QuestionnaireItemFormat.Text,
    label: 'Text',
  },
];

export enum ExpectedAnswer {
  No = 'False',
  Yes = 'True',
}

export const EXPECTED_ANSWERS_OPTIONS = [
  {
    id: ExpectedAnswer.Yes,
    label: 'Yes',
  },
  {
    id: ExpectedAnswer.No,
    label: 'No',
  },
];

export const FIRM_ROLES_OPTIONS = [
  {
    id: 'LeadCounsel',
    label: 'Lead Counsel',
  },
];

export const SECONDARY_FIRM_ROLES_OPTIONS = [
  {
    id: 'CoCounsel',
    label: 'Co-counsel',
  },
  {
    id: 'ReferringCounsel',
    label: 'Referring Counsel',
  },
];

export const MAX_SEARCH_VALUE_LENGTH = 51;

export enum CaseTypeCategories {
  Drugs = 'Drugs',
  MedicalDevices = 'MedicalDevices',
  ToxicExposure = 'ToxicExposure',
  OtherMassTorts = 'OtherMassTorts',
  DisasterLitigation = 'DisasterLitigation',
  PremisesLiability = 'PremisesLiability',
  MovingVehicleInjury = 'MovingVehicleInjury',
  OtherPersonalInjury = 'OtherPersonalInjury',
  Other = 'Other',
  Custom = 'Custom',
}

export const CASE_TYPES_CATEGORIES_OPTIONS = [
  {
    id: CaseTypeCategories.DisasterLitigation,
    label: 'Disaster Litigation',
  },
  {
    id: CaseTypeCategories.Drugs,
    label: 'Drugs',
  },
  {
    id: CaseTypeCategories.MedicalDevices,
    label: 'Medical Devices',
  },
  {
    id: CaseTypeCategories.MovingVehicleInjury,
    label: 'Moving Vehicle Injury',
  },
  {
    id: CaseTypeCategories.OtherMassTorts,
    label: 'Other Mass Torts',
  },
  {
    id: CaseTypeCategories.OtherPersonalInjury,
    label: 'Other Personal Injury',
  },
  {
    id: CaseTypeCategories.PremisesLiability,
    label: 'Premises Liability',
  },
  {
    id: CaseTypeCategories.ToxicExposure,
    label: 'Toxic Exposure',
  },
  {
    id: CaseTypeCategories.Other,
    label: 'Other',
  },
  {
    id: CaseTypeCategories.Custom,
    label: 'Custom',
  },
];

export const statuses: { [key: string]: string } = {
  Draft: 'Draft',
  Unpaid: 'Unpaid',
  Funding: 'Funding',
  OnHold: 'On Hold',
  DistributingFunds: 'Distributing Funds',
  Paid: 'Paid',
  Error: 'Error',
  CriticalError: 'Critical Error',
};

export const statusPillBarTypes = {
  Active: 'success',
  Draft: 'warning',
  Pending: 'warning',
  Closed: 'error',
  Abandoned: 'error',
  Archived: 'error',
  Created: 'warning',
  Deactivated: 'error',
  Completed: 'success',
  Unopened: 'warning',
  Opened: 'info',
  Rejected: 'error',
  Canceled: 'error',
  Paid: 'success',
  Unpaid: 'warning',
  CancelInProgress: 'error',
  Error: 'error',
  CriticalError: 'error',
  Inactive: 'error',
  OnHold: 'warning',
  DistributingFunds: 'warning',
  Funding: 'warning',
};

export const StatusProvisioningLabels = {
  [StatusProvisioning.InProgress]: 'In Progress',
  [StatusProvisioning.Error]: 'Error',
  [StatusProvisioning.CriticalError]: 'Critical Error',
  [StatusProvisioning.Completed]: 'Completed',
};

export const StatusAccountLabels = {
  [StatusAccount.INACTIVE]: 'INACTIVE',
  [StatusAccount.PENDING_VERIFICATION]: 'PENDING VERIFICATION',
  [StatusAccount.ACTIVE]: 'ACTIVE',
  [StatusAccount.BLOCKED]: 'CompletedBLOCKED',
};

export const StatusFundingLabels = {
  [statusFundingSettlment.NotStarted]: 'Not Started',
  [statusFundingSettlment.Initializing]: 'Initializing',
  [statusFundingSettlment.WaitingFunds]: 'Waiting Funds',
  [statusFundingSettlment.OnHold]: 'On Hold',
  [statusFundingSettlment.Error]: 'Error',
  [statusFundingSettlment.CriticalError]: 'Critical Error',
  [statusFundingSettlment.Completed]: 'Completed',
};

export const CUSTOMER_BANK_ACCOUNT_TYPE_OPTIONS = [
  {
    id: 'Savings',
    label: 'Savings',
  },
  {
    id: 'Checking',
    label: 'Checking',
  },
];
