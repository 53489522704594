import { makeStyles } from 'tss-react/mui';

import CancelIcon from '@mui/icons-material/Cancel';
import { Theme, Button, Typography, Box } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  button: {
    maxWidth: '96px',
    width: '100%',
    border: 'none',
    background: 'transparent',
    color: theme.palette.error.main,

    '&:hover': {
      border: 'none',
      background: 'transparent',
      color: theme.palette.error.main,
    },
  },
}));

interface FormCancelButtonProps {
  onClick: () => void;
}

const FormCancelButton = ({ onClick }: FormCancelButtonProps) => {
  const styles = useStyles();

  return (
    <Button disableRipple className={styles.classes.button} variant="outlined" onClick={onClick}>
      <Box display="flex" alignItems="center" gap="8px">
        <CancelIcon />
        <Typography fontWeight="500" fontSize="14px" lineHeight="16px">
          Cancel
        </Typography>
      </Box>
    </Button>
  );
};

export default FormCancelButton;
