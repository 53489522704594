import { useState } from 'react';

interface ApiResponse<T> {
  data: T;
}

interface IUseApiRequest<T, A extends unknown[] = unknown[]> {
  handleApiRequest: (
    apiFunction: (...args: A) => Promise<ApiResponse<T>>,
    ...args: A
  ) => Promise<T | void>;
  loading: boolean;
  error: string;
  data: T | null;
  success: boolean;
}

export const useApiRequest = <T, A extends unknown[]>(): IUseApiRequest<T, A> => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [data, setData] = useState<T | null>(null);

  const handleApiRequest = async (
    apiFunction: (...args: A) => Promise<ApiResponse<T>>,
    ...args: A
  ): Promise<T | void> => {
    try {
      setLoading(true);
      setError('');
      setSuccess(false);
      const response = await apiFunction(...args);
      setData(response.data);
      setSuccess(true);
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'response' in err && err.response) {
        setError((err as { response: { data: { message: string } } }).response.data.message);
      } else if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Unknown error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    handleApiRequest,
    loading,
    error,
    data,
    success,
  };
};
