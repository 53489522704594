import { useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import CasesTableDataRow from './CasesTableDataRow';

import { getCasesList } from '../../api/casesApi/casesApi';
import TableContainer from '../../components/Table/TableContainer';
import Loader from '../../components/Loader';
import { CASES_LIST_TABLE_LABELS } from '../../constants/tableLabels';
import usePagination from '../../hooks/usePagination';
import { ActiveSortField, CaseShortData, FilterTypes, ViewProps } from '../../types';
import TableFilters from '../../components/TableFilters/TableFilters';
import TableActions from '../../components/Table/TableActions';
import { downloadCsv } from '../../helpers/downloadCsv';
import useSnackBar from '../../hooks/useSnackBar';
import { handleSortTableData } from '../../helpers/handleSortTableData';

const CasesView = ({ isRelatedToOtherEntity }: ViewProps) => {
  const params = useParams();
  const location = useLocation();

  const { pageNumber, rowsPerPage, setTotalCount } = usePagination();
  const { setAlert } = useSnackBar();

  const [casesList, setCasesList] = useState<CaseShortData[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [filters, setFilters] = useState<FilterTypes>({
    firmId: params?.firmId || '',
    clientId: '',
    defendantId: '',
    caseTypeId: '',
  });
  const [match, setMatch] = useState<string>('');
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);
  const sortableFields = {
    name: {
      order: '',
      fieldName: 'name',
    },
    createdAt: {
      order: '',
      fieldName: 'createdAt',
    },
    filedAt: {
      order: '',
      fieldName: 'filedAt',
    },
    sol: {
      order: '',
      fieldName: 'sol',
    },
  };

  const hasParams = !!Object.entries(params).length;

  useEffect(() => {
    getCasesList({
      page: pageNumber,
      size: rowsPerPage,
      count: true,
      match: match,
      filters: { ...filters, ...(hasParams && params) },
      activeSortField,
    }).then((res) => {
      if (res.data.resultSet) {
        setTotalCount && setTotalCount(res.data.totalCount || 0);
        setCasesList(res.data.resultSet);
        setIsDataLoading(false);
      }
    });
  }, [pageNumber, rowsPerPage, filters, match, activeSortField]);

  const exportList = () => {
    downloadCsv(
      {
        page: pageNumber,
        size: rowsPerPage,
        count: true,
        match: match,
        filters: { ...filters, ...(hasParams && params) },
      },
      '/v5/cases',
      'Cases_List',
    ).then((res) => {
      if (res?.data) {
        setAlert({
          message: 'Cases exported successfully.',
          type: 'success',
        });
      }
    });
  };

  return (
    <>
      {isDataLoading && !casesList.length ? (
        <Box position="absolute" top="50%" left="50%">
          <Loader colorType="warning" />
        </Box>
      ) : (
        <>
          <TableActions
            hasParams={hasParams}
            createLink={!params.customerId ? '/cases/create-case' : ''}
            exportList={exportList}
          />

          <Grid container>
            <TableFilters
              filters={filters}
              setFilters={setFilters}
              setMatch={setMatch}
              timeFilter={'CREATED'}
              statusFilter={['Draft', 'Active', 'Closed', 'Abandoned']}
              firms
              client={!params.customerId}
              defendant={!location.pathname.includes('/defendants')}
              caseType
            />
            <TableContainer
              isNoData={!isDataLoading && casesList.length === 0}
              headerLabels={isRelatedToOtherEntity || CASES_LIST_TABLE_LABELS}
              sortableFields={sortableFields}
              activeSortField={activeSortField}
              setActiveSortField={setActiveSortField}
              handleSortTableData={handleSortTableData}
            >
              <CasesTableDataRow
                tableData={casesList}
                isRelatedToOtherEntity={!!isRelatedToOtherEntity}
                clientId={params.clientId}
                customerId={params.customerId}
                firmId={params.firmId}
              />
            </TableContainer>
          </Grid>
          <Outlet />
        </>
      )}
    </>
  );
};

export default CasesView;
