import { Typography, Box } from '@mui/material';

import { ReactComponent as NoResultsIcon } from './no-results.svg';

const NoDataText = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      flexDirection="column"
      gap="16px"
    >
      <NoResultsIcon />
      <Typography fontSize="20px">No results found</Typography>
    </Box>
  );
};

export default NoDataText;
