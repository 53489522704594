import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import SettlementsTableDataRow from './SettlementsTableDataRow';

import { getSettlementsList } from '../../../api/paymentsApi/settlementsApi';
import Loader from '../../../components/Loader';
import TableContainer from '../../../components/Table/TableContainer';
import { SETTLEMENTS_LIST_TABLE_LABELS } from '../../../constants/tableLabels';
import usePagination from '../../../hooks/usePagination';
import { ActiveSortField, FilterTypes, SettlementData, ViewProps } from '../../../types';
import TableFilters from '../../../components/TableFilters/TableFilters';
import { downloadCsv } from '../../../helpers/downloadCsv';
import TableActions from '../../../components/Table/TableActions';
import useSnackBar from '../../../hooks/useSnackBar';
import { handleSortTableData } from '../../../helpers/handleSortTableData';

const SettlementsView = ({ isRelatedToOtherEntity }: ViewProps) => {
  const params = useParams();

  const { pageNumber, rowsPerPage, setTotalCount } = usePagination();
  const { setAlert } = useSnackBar();

  const [settlementsList, setSettlementsList] = useState<SettlementData[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [filters, setFilters] = useState<FilterTypes>({
    firmId: params?.firmId || '',
    clientId: '',
    defendantId: '',
  });
  const [match, setMatch] = useState<string>('');
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);
  const sortableFields = {
    caseName: {
      order: '',
      fieldName: 'caseName',
    },
    createdAt: {
      order: '',
      fieldName: 'createdAt',
    },
    paidAt: {
      order: '',
      fieldName: 'paidAt',
    },
  };

  const hasParams = !!Object.entries(params).length;

  useEffect(() => {
    getSettlementsList({
      page: pageNumber,
      size: rowsPerPage,
      count: true,
      match: match,
      filters: { ...filters, ...(hasParams && params) },
      activeSortField,
    }).then((res) => {
      if (res.data) {
        setTotalCount && setTotalCount(res.data.totalCount);
        setSettlementsList(res.data.resultSet);
        setIsDataLoading(false);
      }
    });
  }, [pageNumber, rowsPerPage, filters, match, activeSortField]);

  const exportList = () => {
    downloadCsv(
      {
        page: pageNumber,
        size: rowsPerPage,
        count: true,
        match: match,
        filters: { ...filters, ...(hasParams && params) },
      },
      '/v3/settlements',
      'Settlements_List',
    ).then((res) => {
      if (res?.data) {
        setAlert({
          message: 'Settlements exported successfully.',
          type: 'success',
        });
      }
    });
  };

  return (
    <>
      {isDataLoading && !settlementsList.length ? (
        <Box position="absolute" top="50%" left="50%">
          <Loader colorType="warning" />
        </Box>
      ) : (
        <>
          <TableActions
            hasParams={hasParams}
            createLink={`/settlements/create-settlement${
              params.caseId ? `?caseId=${params.caseId}` : '/'
            }`}
            exportList={exportList}
          />

          <Grid container>
            <TableFilters
              filters={filters}
              setFilters={setFilters}
              setMatch={setMatch}
              firms
              client
              defendant
              timeFilter={'ENTRY DATE'}
              // statusFilter={['Draft', 'Paid', 'Canceled', 'Archived']}
              statusFilter={[
                'Draft',
                'Unpaid',
                'Funding',
                'OnHold',
                'DistributingFunds',
                'Paid',
                'Error',
                'CriticalError',
              ]}
            />
            <TableContainer
              isNoData={!isDataLoading && settlementsList.length === 0}
              headerLabels={isRelatedToOtherEntity || SETTLEMENTS_LIST_TABLE_LABELS}
              sortableFields={sortableFields}
              activeSortField={activeSortField}
              setActiveSortField={setActiveSortField}
              handleSortTableData={handleSortTableData}
            >
              <SettlementsTableDataRow
                tableData={settlementsList}
                isRelatedToOtherEntity={isRelatedToOtherEntity}
              />
            </TableContainer>
          </Grid>
        </>
      )}
      <Outlet />
    </>
  );
};

export default SettlementsView;
