import { Box } from '@mui/material';

import TabsPanel from '../../components/TabsPanel';
import { customersAccountTabs } from '../../constants/tabRoutes';
import GoBackButton from '../../components/buttons/GoBackButton';
import { getCustomerRecord } from '../../api/customersApi/customersApi';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const CustomerAccountView = () => {
  const { customerId } = useParams();
  // TODO: types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [entityRecordData, setEntityRecordData] = useState<any>();

  useEffect(() => {
    getCustomerRecord(customerId as string).then((res) => {
      if (res.data) {
        setEntityRecordData({
          ...res.data,
          address2: res.data.address2 || '',
          middleName: res.data.middleName || '',
          state: res.data.countryState,
        });
      }
    });
  }, [customerId]);

  return (
    <>
      <GoBackButton />
      <Box display="flex" width="100%">
        <TabsPanel
          tabsList={customersAccountTabs}
          locationSlicePosition={3}
          contextData={entityRecordData}
        />
      </Box>
    </>
  );
};

export default CustomerAccountView;
