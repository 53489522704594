import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { FormControlLabel, Switch, Theme } from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    marginRight: 0,
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
    },

    '&.Mui-checked': {
      backgroundColor: theme.palette.info.main,
    },
  },
  inactiveToggle: {
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.secondary.light,
    },
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  activeToggle: {
    '& .MuiSwitch-thumb': {
      backgroundColor: `${theme.palette.info.main} !important`,
    },
    '& .Mui-checked+.MuiSwitch-track': {
      backgroundColor: `${theme.palette.info.main} !important`,
    },
  },
}));

interface FormToggleFieldProps {
  name: string;
  label?: string;
  disabled?: boolean;
  customHandleChange?: (value: boolean) => void;
}

const FormToggleField = ({ name, disabled, label, customHandleChange }: FormToggleFieldProps) => {
  const { control, setValue } = useFormContext();

  const styles = useStyles();

  const customOnChange = (value: boolean) => {
    setValue(name, value, { shouldDirty: true });
    customHandleChange && customHandleChange(value);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          className={styles.classes.root}
          control={
            <Switch
              className={clsx(value ? styles.classes.activeToggle : styles.classes.inactiveToggle)}
              disableRipple
              checked={value}
              disabled={disabled}
            />
          }
          label={label}
          onChange={(_, newValue) =>
            customHandleChange ? customOnChange(newValue) : onChange(newValue)
          }
        />
      )}
    />
  );
};

export default FormToggleField;
