import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import SaveIcon from '@mui/icons-material/Save';
import { Box, useTheme } from '@mui/material';

import { FieldHeadline } from './styled';
import { ClientCreateFormSubmitProps } from './types';

import FormCancelButton from '../../components/buttons/FormCancelButton';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import GoBackButton from '../../components/buttons/GoBackButton';
import FormDateField from '../../components/formFields/FormDateField';
import FormTextField from '../../components/formFields/FormTextField';
import FormSelectField from '../../components/formFields/FormSelectField';
import { US_STATES_LIST } from '../../constants/constants';
import { CLIENT_CREATE_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { clientCreateFormSchema } from '../../helpers/formSchemas/formSchemas';
import useSnackBar from '../../hooks/useSnackBar';
import { handleGetFirms, mapFirmsQueryResults } from '../CasesView/helpers';
import FormAutocompleteField from '../../components/formFields/FormAutocompleteField';
import FormMaskedTextField from '../../components/formFields/FormMaskedTextField';
import { createClientRecord } from '../../api/clientsApi/clientsApi';

const ClientCreateFormContainer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setAlert } = useSnackBar();

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const firmId = new URLSearchParams(window.location.search).get('firmId');

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(clientCreateFormSchema),
    defaultValues: CLIENT_CREATE_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = formMethods;

  const onSubmitForm = (values: ClientCreateFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      ...(values.middleName?.length && { middleName: values.middleName }),
      ...(values.address2?.length && { address2: values.address2 }),
      referenceId: values.referenceId,
      firmId: values.firmId.id,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      address1: values.address1,
      city: values.city,
      zip: values.zip,
      ssn: values.ssn,
      birthdate: moment(values.birthdate).format('YYYY-MM-DD'),
      countryState: values.state,
    };

    createClientRecord(submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);
          navigate(`/clients/${res.data.id}/home?firmId=${firmId}`);

          setAlert((prev) => ({
            ...prev,
            message: 'Client created successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => reset();

  return (
    <>
      <GoBackButton />
      <Box display="flex" bgcolor={theme.palette.primary.dark} width="100%">
        <FormProvider {...formMethods}>
          <form style={{ width: '100%', padding: '20px' }}>
            <Box display="flex" width="100%" gap="32px">
              <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                <FieldHeadline>Identity</FieldHeadline>
                <Box mt="10px">
                  <FormTextField name="referenceId" label="Reference Id *" />
                </Box>
                <Box mt="20px">
                  <FormTextField name="firstName" label="First Name *" />
                </Box>
                <Box mt="20px">
                  <FormTextField name="middleName" label="Middle Name" />
                </Box>
                <Box mt="20px">
                  <FormTextField name="lastName" label="Last Name *" />
                </Box>

                <FieldHeadline mt="50px">Firm</FieldHeadline>
                <Box mt="10px">
                  <FormAutocompleteField
                    name="firmId"
                    label="Firm *"
                    optionsQuery={handleGetFirms}
                    mapQueryResults={mapFirmsQueryResults}
                  />
                </Box>

                <FieldHeadline mt="50px">Credentials</FieldHeadline>
                <Box mt="10px">
                  <FormTextField name="email" label="Email Address *" />
                </Box>
                <Box mt="20px">
                  <FormMaskedTextField mask="+19999999999" name="phone" label="Mobile Number *" />
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                <FieldHeadline>Address</FieldHeadline>
                <Box mt="10px">
                  <FormTextField name="address1" label="Address Line 1 *" />
                </Box>
                <Box mt="20px">
                  <FormTextField name="address2" label="Address Line 2" />
                </Box>
                <Box mt="20px" display="flex" gap="20px" flexDirection="column">
                  <FormTextField name="city" label="City *" />
                  <FormSelectField options={US_STATES_LIST} name="state" label="State *" />
                  <FormTextField name="zip" label="Zip *" />
                </Box>

                <FieldHeadline mt="50px">Personal Information</FieldHeadline>
                <Box mt="10px">
                  <FormDateField name="birthdate" label="Date of Birth *" maxDate={moment()} />
                </Box>
                <Box mt="20px">
                  <FormMaskedTextField
                    mask="999-99-9999"
                    name="ssn"
                    label="Social Security Number *"
                  />
                </Box>
              </Box>
            </Box>

            <Box display="flex" justifyContent="space-between" mt="40px">
              <FormSubmitButton
                disabled={!isValid || isFormSubmitting}
                buttonIcon={<SaveIcon />}
                buttonText="Save"
                onClick={handleSubmit(onSubmitForm)}
              />
              {isDirty && <FormCancelButton onClick={handleCancelChanges} />}
            </Box>
          </form>
        </FormProvider>
      </Box>
    </>
  );
};

export default ClientCreateFormContainer;
