import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { TableCell, TableRow, Theme } from '@mui/material';

import { formatDate } from '../../../helpers/formatDate';
import { SignatureData } from '../../../types';
import { getDatesDiff } from '../../../helpers/getDatesDiff';
import { parseStatusTitle } from '../../../helpers/parseStatusTitle';

const useStyles = makeStyles()((theme: Theme) => ({
  styledLink: {
    textDecoration: 'none',
    color: theme.palette.info.main,
  },
}));

interface SignaturesTableDataRowProps {
  tableData: SignatureData[];
  isRelatedToOtherEntity?: boolean;
}

const SignaturesTableDataRow = ({
  tableData,
  isRelatedToOtherEntity,
}: SignaturesTableDataRowProps) => {
  const styles = useStyles();

  const formattedSignaturesList =
    tableData &&
    tableData.map((signature: SignatureData) => ({
      name: (
        <Link
          className={styles.classes.styledLink}
          to={`/signature-requests/${String(signature.id)}/home`}
        >
          {`${signature.client.firstName} ${signature.client.middleName || ''} ${
            signature.client.lastName
          }`}
        </Link>
      ),
      ...(!isRelatedToOtherEntity && { firm: signature.firm.name }),
      createdAt: signature.createdAt ? formatDate(signature.createdAt as string) : '-',
      documentName: signature.name,
      status: parseStatusTitle(signature.status),
      completionDate: signature.completedAt ? formatDate(signature.completedAt as string) : '-',
      completionTime:
        signature.status === 'Completed'
          ? getDatesDiff(signature.createdAt, signature.completedAt)
          : '-',
      documentId: signature.documentId,
    }));

  return (
    <>
      {formattedSignaturesList.map((tableItem, idx) => (
        <TableRow key={idx}>
          {Object.values(tableItem).map((item, idx) => (
            <TableCell key={idx}>{item}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default SignaturesTableDataRow;
