import { Box } from '@mui/material';

import TabsPanel from '../../../components/TabsPanel';
import { settlementsAccountTabs } from '../../../constants/tabRoutes';
import GoBackButton from '../../../components/buttons/GoBackButton';
import { useEffect, useState } from 'react';
import { getSettlementRecord } from '../../../api/paymentsApi/settlementsApi';
import { useParams } from 'react-router-dom';
import { SettlementsDeductionData } from '../types';
import { numberToUSDCurrency } from '../../../helpers/numberToUSDCurrency';
import { formatDate } from '../../../helpers/formatDate';

const SettlementAccountView = () => {
  const { settlementId } = useParams();
  const [entityRecordData, setEntityRecordData] = useState<{ [key: string]: unknown }>();

  useEffect(() => {
    getSettlementRecord(settlementId as string).then((res) => {
      if (!res.data.error) {
        const hasDefendant = res.data.defendant !== null && Boolean(res.data.defendant.name.length);
        const mappedDeductions = res.data.deductions?.map(
          (deduction: SettlementsDeductionData) => ({
            ...deduction,
            amount: numberToUSDCurrency(deduction.amount),
            createdAt: formatDate(deduction.createdAt),
            isAssigned: deduction.isAssigned ? 'Yes' : 'No',
            isAdded: false,
          }),
        );

        setEntityRecordData({
          id: res.data.id,
          caseReferenceId: res.data.case.referenceId,
          caseSystemName: res.data.case.name,
          firm: res.data.firm.name,
          plaintiff: {
            id: res.data.client.id,
            fullName: `${res.data.client.firstName} ${res.data.client.lastName}`,
          },
          defendant: hasDefendant
            ? {
                id: res.data.defendant.id,
                label: res.data.defendant.name,
              }
            : {
                id: '',
                label: '',
              },
          deduction: mappedDeductions,
          status: res.data.status,
          firmId: res.data.firm.id,
          caseId: res.data.case.id,
          settledAmount: res.data.settledAmount,
          secondaryFirms: res.data.secondaryFirms,
        });
      }
    });
  }, [settlementId]);

  return (
    <>
      <GoBackButton />
      <Box display="flex" width="100%">
        <TabsPanel
          tabsList={settlementsAccountTabs}
          locationSlicePosition={3}
          contextData={entityRecordData}
        />
      </Box>
    </>
  );
};

export default SettlementAccountView;
