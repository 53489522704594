import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';

import { Box, Grid, Typography, useTheme } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import BusinessIcon from '@mui/icons-material/Business';
import CategoryIcon from '@mui/icons-material/Category';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import BadgeIcon from '@mui/icons-material/Badge';

import { CREATED_ITEMS } from './constants';
import { useStyles } from './styles';

import FormAutocompleteField from '../formFields/FormAutocompleteField';

import { useIsFirstRender } from '../../hooks/useIsFirstRender';
import {
  handleGetCaseTypes,
  handleGetDefendants,
  handleGetFirms,
  handleGetClients,
  mapCaseTypesQueryResults,
  mapDefendantsQueryResults,
  mapFirmsQueryResults,
  mapClientsQueryResults,
} from '../../views/CasesView/helpers';
import { FilterTypes, SelectOption } from '../../types';
import { validateTextFieldValue } from '../../helpers/validateTextFieldValue';
import usePagination from '../../hooks/usePagination';
import FormDateField from '../formFields/FormDateField';
import FormSearchField from '../formFields/FormSearchField';

interface ITableFilters {
  filters: FilterTypes;
  setFilters: React.Dispatch<React.SetStateAction<FilterTypes>>;
  setMatch?: (arg: string) => void;
  searchDebounce?: number;
  timeFilter?: string;
  deductionTypeFilter?: string[];
  statusFilter?: string[];
  firms?: boolean;
  client?: boolean;
  defendant?: boolean;
  rolesFilter?: string[];
  caseType?: boolean;
  dateRange?: boolean;
  title?: {
    text: string;
    icon: JSX.Element;
  };
  isStatusArray?: boolean;
}

interface IIconLabel {
  icon: React.ReactNode;
  label: string;
}

const IconLabel = ({ icon, label }: IIconLabel) => {
  const styles = useStyles();

  return (
    <Box display="flex" gap="8px">
      <Box className={styles.classes.iconWrapper}>{icon}</Box>
      <Typography my="3px" className={clsx(styles.classes.label)}>
        {label}
      </Typography>
    </Box>
  );
};

interface FilterGroup {
  currentValue: string | SelectOption | undefined;
  filters: string[];
  icon: React.ReactNode;
  label: string;
  name: keyof FilterTypes;
  handleFilterClick: (item: string, name: keyof FilterTypes) => void;
}

const FilterGroup = ({
  currentValue,
  filters,
  icon,
  label,
  name,
  handleFilterClick,
}: FilterGroup) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <Box display="flex" alignItems={'flex-start'} gap="8px" color={theme.palette.primary.light}>
      <Box className={styles.classes.iconWrapper}>{icon}</Box>
      <Box display="flex" flexDirection="column" gap="12px" my="3px">
        <Typography className={clsx(styles.classes.label)}>{label}</Typography>
        {filters.map((item, index) => {
          return (
            <Typography
              key={item + index}
              className={clsx(
                item === currentValue ? styles.classes.filterItemActive : styles.classes.filterItem,
                item === 'Archived' && styles.classes.archivedItem,
              )}
              onClick={() => handleFilterClick(item, name)}
            >
              {item.replace(/([a-z])([A-Z])/g, '$1 $2')}
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
};

const TableFilters = ({
  filters,
  setFilters,
  setMatch,
  searchDebounce = 300,
  timeFilter,
  deductionTypeFilter,
  statusFilter,
  firms,
  client,
  defendant,
  rolesFilter,
  caseType,
  dateRange,
  title,
  isStatusArray,
}: ITableFilters) => {
  const isFirstRender = useIsFirstRender();

  const { setPageNumber } = usePagination();
  const theme = useTheme();
  const styles = useStyles();
  const params = useParams();
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [dateFilter, setDateFilter] = useState<string>('');

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      searchQuery: '',
      isArchived: false,
      firmId: { id: '', label: '' },
      clientId: { id: '', label: '' },
      defendantId: { id: '', label: '' },
      caseTypeId: { id: '', label: '' },
      from: '',
      to: '',
      statuses: '',
    },
  });
  const { setValue, watch } = formMethods;

  const firmFieldValue = watch('firmId');
  const clientFieldValue = watch('clientId');
  const defendantFieldValue = watch('defendantId');
  const caseTypeFieldValue = watch('caseTypeId');
  const dateFromFieldValue = watch('from');
  const dateToFieldValue = watch('to');

  useEffect(() => {
    if (isFirstRender) return;

    const newState = {
      firmId: firmFieldValue?.id || params?.firmId || '',
      clientId: clientFieldValue?.id || '',
      defendantId: defendantFieldValue?.id || '',
      caseTypeId: caseTypeFieldValue?.id || '',
      from: dateFromFieldValue || '',
      to: dateToFieldValue || '',
    };

    setFilters((prevState) => {
      setPageNumber && setPageNumber(1);
      if (JSON.stringify(prevState) !== JSON.stringify(newState)) {
        return {
          ...prevState,
          ...newState,
        };
      } else {
        return prevState;
      }
    });
  }, [
    firmFieldValue,
    clientFieldValue,
    defendantFieldValue,
    caseTypeFieldValue,
    dateFromFieldValue,
    dateToFieldValue,
  ]);

  const handleSearchCase = (value: string) => {
    if (value.length > 2) {
      setPageNumber && setPageNumber(1);
      setMatch && setMatch(value);
    } else {
      setMatch && setMatch('');
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleSearchCase(searchInputValue.trimEnd());
    }, searchDebounce);

    return () => clearTimeout(timeout);
  }, [searchInputValue]);

  const handleDateClick = (value: string, from: string, to: string) => {
    setPageNumber && setPageNumber(1);

    if (value === dateFilter) {
      setDateFilter('');

      const newState = { ...filters };
      delete newState.date;

      setFilters(newState);
    } else {
      setDateFilter(value);

      const fromDate = new Date(from);
      const toDate = new Date(to);

      setFilters((prevState) => ({
        ...prevState,
        date: { from: fromDate, to: toDate },
      }));
    }
  };

  const handleFiltersGroupClick = (value: string, name: keyof typeof filters) => {
    setPageNumber && setPageNumber(1);

    setFilters((prevState) => ({
      ...prevState,
      [name]: value === prevState[name] ? '' : value,
    }));
  };

  const handleResetMatch = () => {
    setPageNumber && setPageNumber(1);
    setValue('searchQuery', '');
    setSearchInputValue('');
  };

  const handleDisableFieldsByFirmId = () => {
    if (params?.firmId) {
      return false;
    } else {
      if (!firmFieldValue?.id) {
        return true;
      }
      if (firmFieldValue === null) {
        return true;
      }
    }
  };

  useEffect(() => {
    if (firmFieldValue === null) {
      setValue('defendantId', { id: '', label: '' });
      setValue('clientId', { id: '', label: '' });
      setValue('caseTypeId', { id: '', label: '' });
    }
  }, [firmFieldValue]);

  return (
    <Grid item xs={3} xl={2}>
      <FormProvider {...formMethods}>
        <form style={{ width: '100%' }}>
          <Box
            display="flex"
            flexDirection="column"
            bgcolor="#1E1E1E"
            p="8px 16px 24px"
            gap={title ? '0px' : '16px'}
            mr="12px"
          >
            {setMatch && !params?.caseId ? (
              <FormSearchField
                name="searchQuery"
                searchValue={searchInputValue}
                handleChange={(value) => {
                  setValue('searchQuery', validateTextFieldValue(value));
                  setSearchInputValue(validateTextFieldValue(value));
                }}
                handleResetSearch={handleResetMatch}
              />
            ) : null}

            {/* <Box>
              <FormToggleField name="isArchived" label="Show Archived" />
            </Box> */}

            {dateRange ? (
              <Box display="flex" flexDirection="column" gap="10px">
                <Box display={'flex'}>
                  {title && (
                    <Box display="flex" gap="10px" margin="8px 0" alignItems="center">
                      {title.icon}
                      <Typography fontSize="12px" lineHeight="14.06px" fontWeight="400">
                        {title.text}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <FormDateField
                  label="From"
                  name="from"
                  maxDate={dateToFieldValue ? moment(dateToFieldValue) : moment()}
                  shouldDisableInput
                  onClearField={() => {
                    setValue('from', '');
                  }}
                />
                <FormDateField
                  label="To"
                  name="to"
                  maxDate={moment()}
                  minDate={moment(dateFromFieldValue)}
                  shouldDisableInput
                  onClearField={() => {
                    setValue('to', '');
                  }}
                />
              </Box>
            ) : null}

            {firms && !params?.firmId && !params?.caseId ? (
              <Box>
                <IconLabel icon={<BusinessIcon />} label={'FIRM'} />
                <Box ml="32px">
                  <FormAutocompleteField
                    name="firmId"
                    label="Firm"
                    optionsQuery={handleGetFirms}
                    mapQueryResults={mapFirmsQueryResults}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </Box>
              </Box>
            ) : null}

            {client && !params?.caseId ? (
              <Box>
                <IconLabel icon={<PersonIcon />} label={'CLIENT'} />
                <Box ml="32px">
                  <FormAutocompleteField
                    disabled={handleDisableFieldsByFirmId()}
                    name="clientId"
                    label="Client"
                    optionsQuery={handleGetClients}
                    mapQueryResults={mapClientsQueryResults}
                    filters={{
                      firmId: Number(firmFieldValue?.id || params?.firmId),
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </Box>
              </Box>
            ) : null}

            {defendant && !params?.caseId ? (
              <Box>
                <IconLabel icon={<WorkIcon />} label={'DEFENDANT'} />
                <Box ml="32px">
                  <FormAutocompleteField
                    name="defendantId"
                    label="Defendant"
                    disabled={handleDisableFieldsByFirmId()}
                    optionsQuery={handleGetDefendants}
                    mapQueryResults={mapDefendantsQueryResults}
                    filters={{
                      firmId: Number(firmFieldValue?.id || params?.firmId),
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </Box>
              </Box>
            ) : null}

            {caseType && !params?.caseId ? (
              <Box>
                <IconLabel icon={<CategoryIcon />} label={'CASE TYPE'} />
                <Box ml="32px">
                  <FormAutocompleteField
                    name="caseTypeId"
                    label="Case Type"
                    disabled={handleDisableFieldsByFirmId()}
                    optionsQuery={handleGetCaseTypes}
                    mapQueryResults={mapCaseTypesQueryResults}
                    filters={{
                      firmId: Number(firmFieldValue?.id || params?.firmId),
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </Box>
              </Box>
            ) : null}

            {timeFilter ? (
              <Box
                display="flex"
                alignItems={'flex-start'}
                gap="8px"
                color={theme.palette.primary.light}
              >
                <Box className={styles.classes.iconWrapper}>
                  <AccessTimeIcon />
                </Box>
                <Box display="flex" flexDirection="column" gap="12px" my="3px">
                  <Typography className={clsx(styles.classes.label)}>{timeFilter}</Typography>
                  {CREATED_ITEMS.map((item) => {
                    return (
                      <Typography
                        key={item.title}
                        className={clsx(
                          item.value === dateFilter
                            ? styles.classes.filterItemActive
                            : styles.classes.filterItem,
                        )}
                        onClick={() => handleDateClick(item.value, item.from, item.to)}
                      >
                        {item.title}
                      </Typography>
                    );
                  })}
                </Box>
              </Box>
            ) : null}

            {rolesFilter ? (
              <FilterGroup
                label={'ROLE'}
                filters={rolesFilter}
                name={'role'}
                handleFilterClick={handleFiltersGroupClick}
                currentValue={filters.role}
                icon={<BadgeIcon />}
              />
            ) : null}

            {deductionTypeFilter ? (
              <FilterGroup
                label={'TYPE'}
                name={'type'}
                filters={deductionTypeFilter}
                handleFilterClick={handleFiltersGroupClick}
                currentValue={filters.type}
                icon={<BadgeIcon />}
              />
            ) : null}

            {statusFilter ? (
              <FilterGroup
                label={'STATUS'}
                filters={statusFilter}
                name={isStatusArray ? 'statuses' : 'status'}
                handleFilterClick={handleFiltersGroupClick}
                currentValue={isStatusArray ? filters.statuses : filters.status}
                icon={<SignalCellular1BarIcon />}
              />
            ) : null}
          </Box>
        </form>
      </FormProvider>
    </Grid>
  );
};

export default TableFilters;
