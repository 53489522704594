import axios from 'axios';
import TokenService from './token.service';
import { refreshUserToken } from '../authApi/authApi';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}`,
});

instance.interceptors.request.use(
  (config) => {
    const authUrl = process.env.REACT_APP_AWS_COGNITO_VERIFY_TOKEN_URL as string;
    const token = TokenService.getLocalAccessToken();

    if (token && config.url !== authUrl) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await refreshUserToken(TokenService.getLocalRefreshToken());
          TokenService.updateLocalAccessToken(rs.data.access_token);

          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    if (err.response.status === 401) {
      const { search, pathname } = window.location;
      sessionStorage.setItem('backUrl', btoa(pathname + search));

      window.location.href = '/';

      return instance(originalConfig);
    }

    return Promise.reject(err);
  },
);

export default instance;
