import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { FormControl, IconButton, InputLabel, MenuItem, Select, Theme } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles<{
  maxWidth?: string;
  selectValue?: string;
  disabled?: boolean;
  minWidth?: string;
  width?: string;
}>()((theme: Theme, { maxWidth, selectValue, disabled, minWidth, width }) => ({
  defaultSelect: {
    ...(maxWidth && { maxWidth }),
    ...(minWidth && { minWidth }),
    ...(width && { width }),
    ...(disabled && { background: 'rgba(255, 255, 255, 0.1)' }),
    width: '100%',
    margin: 0,
    justifyContent: 'flex-end',

    '& .MuiSelect-select': {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },

    '& .MuiInput-input': {
      color: theme.palette.primary.light,
      paddingLeft: '7px',
      paddingBottom: '7px',

      '&.Mui-disabled': {
        color: 'rgba(255, 255, 255, 0.3)',
        WebkitTextFillColor: 'rgba(255, 255, 255, 0.3)',
      },
    },

    '& .MuiInput-root': {
      marginTop: '20px',
      '&:before': {
        borderBottom: `1px solid ${theme.palette.primary.light}`,
      },
      '&:after': {
        borderBottom: `1px solid ${theme.palette.primary.light}`,
      },
      ':hover:not(.Mui-disabled, .Mui-error):before': {
        borderBottom: `1px solid ${theme.palette.primary.light}`,
      },

      '&.Mui-disabled': {
        color: 'rgba(255, 255, 255, 0.3)',
      },
    },

    '& .MuiInputLabel-root[data-shrink="false"]': {
      color: 'rgba(255, 255, 255, 0.3)',
      top: '3px',
      left: '7px',
    },

    '& .MuiInputLabel-root[data-shrink="true"]': {
      color: theme.palette.secondary.light,
      top: '0',
      left: '7px',

      '&.Mui-focused': {
        color: theme.palette.secondary.light,
      },
    },

    '& .MuiSelect-icon': {
      color: selectValue ? theme.palette.primary.light : 'rgba(255, 255, 255, 0.3)',
      top: '0px',
    },
  },
}));

interface FormSelectFieldProps {
  name: string;
  label: string;
  options: Array<{ id: string; label: string }>;
  readOnly?: boolean;
  maxWidth?: string;
  minWidth?: string;
  disabled?: boolean;
  required?: boolean;
  resetFields?: () => void;
  customHandleChange?: (name: string, value: string) => void;
  width?: string;
  shouldClearable?: boolean;
}

const FormSelectField = ({
  name,
  label,
  options,
  maxWidth,
  disabled,
  readOnly,
  resetFields,
  customHandleChange,
  minWidth,
  width,
  shouldClearable,
}: FormSelectFieldProps) => {
  const { control, setValue, watch } = useFormContext();

  const selectValue = watch(name);

  const styles = useStyles({ maxWidth, selectValue, disabled, minWidth, width });

  return (
    <>
      {options && (
        <Controller
          name={name}
          control={control}
          render={({ field: { value } }) => (
            <FormControl
              className={styles.classes.defaultSelect}
              size="small"
              variant="standard"
              sx={{ m: 1, minWidth: maxWidth }}
              disabled={disabled}
            >
              <InputLabel>{label}</InputLabel>
              <Select
                defaultValue={value || ''}
                value={value}
                onChange={(e) => {
                  resetFields && resetFields();
                  customHandleChange
                    ? customHandleChange(name, e.target.value)
                    : setValue(name, e.target.value, { shouldDirty: true, shouldValidate: true });
                }}
                disabled={disabled}
                readOnly={readOnly}
                {...(shouldClearable && {
                  IconComponent: value
                    ? () => (
                        <IconButton
                          sx={{
                            color: 'white',
                          }}
                          onClick={() => setValue(name, '')}
                        >
                          <ClearIcon />
                        </IconButton>
                      )
                    : undefined,
                })}
              >
                {options &&
                  options.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        />
      )}
    </>
  );
};

export default FormSelectField;
