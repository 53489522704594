import { Box, useTheme } from '@mui/material';

import { manageAccountTabs } from '../../constants/tabRoutes';

import TabsPanel from '../../components/TabsPanel';

const ManageAccountView = () => {
  const theme = useTheme();

  return (
    <Box display="flex" bgcolor={theme.palette.primary.dark} width="100%">
      <TabsPanel tabsList={manageAccountTabs} />
    </Box>
  );
};

export default ManageAccountView;
