import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { TableCell, TableRow, Theme } from '@mui/material';

import { formatDate } from '../../../helpers/formatDate';
import { DocumentRequestData } from '../../../types';
import { getDatesDiff } from '../../../helpers/getDatesDiff';

const useStyles = makeStyles()((theme: Theme) => ({
  styledLink: {
    textDecoration: 'none',
    color: theme.palette.info.main,
  },
}));

interface DocumentRequestTableDataRowProps {
  tableData: DocumentRequestData[];
  isRelatedToOtherEntity?: boolean;
}

const DocumentsRequestTableDataRow = ({
  tableData,
  isRelatedToOtherEntity,
}: DocumentRequestTableDataRowProps) => {
  const styles = useStyles();

  const formattedDocumentsList =
    tableData &&
    tableData.map((document: DocumentRequestData) => ({
      name: (
        <Link
          className={styles.classes.styledLink}
          to={`/document-requests/${String(document.id)}/home`}
        >
          {`${document.client.firstName} ${document.client.middleName || ''} ${
            document.client.lastName
          }`}
        </Link>
      ),
      ...(!isRelatedToOtherEntity && { firm: document.firm.name }),
      sentAt: document.sentAt ? formatDate(document.sentAt as string) : '-',
      documentName: document.name,
      status: document.status,
      completionDate: document.completedAt ? formatDate(document.completedAt as string) : '-',
      completionTime:
        document.status === 'Completed'
          ? getDatesDiff(document.createdAt, document.completedAt)
          : '-',
      file: document.fileName ? document.fileName : '-',
    }));

  return (
    <>
      {formattedDocumentsList.map((tableItem, idx) => (
        <TableRow key={idx}>
          {Object.values(tableItem).map((item, idx) => (
            <TableCell key={idx}>{item}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default DocumentsRequestTableDataRow;
