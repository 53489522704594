import { SelectOption } from '../../types';
import { AnyObject } from 'yup';

export interface CaseCreateFormSubmitProps {
  id?: string;
  name?: string;
  referenceId: string;
  caseTypeCategory: string;
  type: SelectOption;
  trustAccount: {
    id?: string;
    label?: string;
  } | null;
  status?: string;
  defendant: DefendantField[] | undefined;
  clientId: SelectOption;
  sol: AnyObject | undefined | null;
  filedAt: AnyObject | undefined | null;
  advances?: string;
  liens?: string;
  secondaryFirms:
    | {
        role?: string | undefined;
        id: {
          id?: string | undefined;
          label?: string | undefined;
        };
      }[]
    | undefined;
  leadFirm: {
    id: string;
    label: string;
  };
}

export interface CaseEditFormSubmitProps {
  id: string;
  name: string;
  referenceId: string;
  caseTypeCategory: string;
  type: SelectOption;
  trustAccount: {
    id?: string;
    label?: string;
  } | null;
  defendant: DefendantField[] | undefined;
  clientId: SelectOption;
  sol: AnyObject | undefined | null;
  filedAt: AnyObject | undefined | null;
  advances?: string;
  liens?: string;
  secondaryFirms: SecondaryFirmsField[] | undefined;
}

export type DefendantField = {
  defendantId: { id: string; label: string };
  gross?: string | undefined;
};

export type SecondaryFirmsField = {
  role?: string | undefined;
  id: {
    id?: string | undefined;
    label?: string | undefined;
  };
};

export interface CaseAttorneyFeesConfigSubmitProps {
  caseFeesConfig: {
    variableFeePercent?: string;
    maximumAmount?: string;
    variableFeeBasis: string;
  };
  secondaryFirms: Array<{
    role: 'CoCounsel' | 'ReferringCounsel';
    id: number;
    name: string;
    config: {
      fixedFeeAmount?: string;
      variableFeePercent?: string;
      variableFeeMaximumAmount?: string;
      variableFeeBasis: string;
    };
  }>;
}

export enum FIRM_ROLES {
  LeadCounsel = 'Lead Counsel',
  CoCounsel = 'Co-counsel',
  ReferringCounsel = 'Referring Counsel',
}
