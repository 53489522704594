import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { firmBankingCreateUnrestrictedAccountFormSchema } from '../../../../../helpers/formSchemas/formSchemas';
import { FIRM_BANKING_ACCOUNT_CREATE_DEFAULT_VALUES } from '../../../../../helpers/formSchemas/formDefaultValues';
import {
  FirmUnrestrictedAccountSubmitFormProps,
  FirmUnrestrictedAccountShortData,
} from '../../../types';
import { createFirmUnrestrictedAccount } from '../../../../../api/firmsApi/unrestrictedAccountsApi';
import useSnackBar from '../../../../../hooks/useSnackBar';

import FirmBankingAccountCreateUpdateForm from './FirmUnrestrictedAccountForm';
import FirmBankingPopupTabWrapper from '../FirmBankingPopupTabWrapper';

const useStyles = makeStyles()((theme: Theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.light,
      maxWidth: '960px',
      width: '100%',
      padding: '24px',
      boxSizing: 'border-box',
    },

    '& .MuiDialogTitle-root': {
      padding: 0,
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: 'normal',
    },

    '& .MuiDialogContent-root': {
      padding: 0,
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.secondary.main,
    },
  },
  popupActions: {
    padding: '16px 0',
  },
  popupButton: {
    color: theme.palette.info.main,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
    },

    '&:hover': {
      background: 'rgba(144, 202, 249, 0.08)',
    },
  },
}));

interface FirmUnrestrictedAccountPopupProps {
  isOpen: boolean;
  selectedTableItem: FirmUnrestrictedAccountShortData | null;
  fetchUnrestrictedAccounts: () => void;
  handleClosePopup: () => void;
  handleEditAccount: (data: FirmUnrestrictedAccountSubmitFormProps) => void;
  handleResetFormState: () => void;
  isAddForm?: boolean;
}

const FirmUnrestrictedAccountPopup = ({
  isOpen,
  selectedTableItem,
  fetchUnrestrictedAccounts,
  handleClosePopup,
  handleEditAccount,
  handleResetFormState,
  isAddForm,
}: FirmUnrestrictedAccountPopupProps) => {
  const styles = useStyles();
  const { firmId } = useParams();
  const { setAlert } = useSnackBar();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(firmBankingCreateUnrestrictedAccountFormSchema),
    defaultValues: FIRM_BANKING_ACCOUNT_CREATE_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = formMethods;

  const handleAddNewAccount = (accountFormData: FirmUnrestrictedAccountSubmitFormProps) => {
    if (!firmId) return;

    setIsFormSubmitting(true);

    createFirmUnrestrictedAccount(firmId, accountFormData)
      .then((res) => {
        if (res.data) {
          setAlert((prev) => ({
            ...prev,
            message: 'Unresrticted account created successfully.',
            type: 'success',
          }));

          handleClosePopup();
          fetchUnrestrictedAccounts();
          reset(FIRM_BANKING_ACCOUNT_CREATE_DEFAULT_VALUES);
          setIsFormSubmitting(false);
        }
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
        setIsFormSubmitting(false);
      });
  };

  const onSubmitForm = (values: FirmUnrestrictedAccountSubmitFormProps) => {
    const submitData = {
      accountNumber: values.accountNumber,
      routingNumber: values.routingNumber,
      name: values.name,
      isVerified: values.isVerified,
    };

    selectedTableItem ? handleEditAccount(submitData) : handleAddNewAccount(submitData);
  };

  const onPopupClose = () => {
    handleResetFormState();
    handleDisabledForm(false);
    reset(FIRM_BANKING_ACCOUNT_CREATE_DEFAULT_VALUES);
  };

  const handleDisabledForm = (newDisabled: boolean) => {
    setDisabled(newDisabled);
  };

  useEffect(() => {
    selectedTableItem
      ? reset({
          accountNumber: selectedTableItem.accountNumber,
          routingNumber: selectedTableItem.routingNumber,
          name: selectedTableItem.name,
          isVerified: selectedTableItem.isVerified,
        })
      : reset({ ...FIRM_BANKING_ACCOUNT_CREATE_DEFAULT_VALUES });
  }, [selectedTableItem]);

  return (
    <Dialog className={styles.classes.popupContainer} open={isOpen} onClose={onPopupClose}>
      <FirmBankingPopupTabWrapper
        selectedTableItem={selectedTableItem}
        entity="unrestricted"
        isOpen={isOpen}
        onPopupClose={onPopupClose}
        handleDisabledForm={handleDisabledForm}
        isAddForm={isAddForm}
        fetchAccounts={fetchUnrestrictedAccounts}
      >
        {isAddForm && <DialogTitle>Unrestricted Account</DialogTitle>}
        <DialogContent>
          <FormProvider {...formMethods}>
            <FirmBankingAccountCreateUpdateForm disabled={disabled} />
          </FormProvider>
        </DialogContent>
        <DialogActions className={styles.classes.popupActions}>
          <Button
            className={styles.classes.popupButton}
            onClick={handleSubmit(onSubmitForm)}
            disabled={!isValid || !isDirty || isFormSubmitting}
          >
            Submit
          </Button>
          <Button className={styles.classes.popupButton} onClick={onPopupClose}>
            Cancel
          </Button>
        </DialogActions>
      </FirmBankingPopupTabWrapper>
    </Dialog>
  );
};

export default FirmUnrestrictedAccountPopup;
