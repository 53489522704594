import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

const useStyles = makeStyles()((theme: Theme) => ({
  default: {
    background: 'rgba(255, 255, 255, 0.12)',
    color: 'rgba(255, 255, 255, 0.3)',
    height: '52px',
  },
  mainButton: {
    '&:hover': {
      background: theme.palette.info.main,
      color: theme.palette.primary.main,
    },
  },
  deleteButton: {
    '&:hover': {
      background: theme.palette.error.main,
      color: theme.palette.primary.main,
    },
  },
}));

interface QuestionnaireButtonProps {
  readonly buttonText: string;
  readonly onClick?: () => void;
  readonly onDeleteClick?: () => void;
}

const QuestionnaireButton = ({ buttonText, onClick, onDeleteClick }: QuestionnaireButtonProps) => {
  const { classes } = useStyles();

  return (
    <ButtonGroup variant="contained">
      <Button className={clsx(classes.default, classes.mainButton)} onClick={onClick}>
        {buttonText}
      </Button>
      <Button
        className={clsx(classes.default, classes.deleteButton)}
        size="small"
        onClick={onDeleteClick}
      >
        <DeleteForeverIcon />
      </Button>
    </ButtonGroup>
  );
};

export default QuestionnaireButton;
