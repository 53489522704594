import api from '../service/api.service';
import { ActiveSortField, User, PaginationQueryParams, ResultSet } from '../../types';
import { parseQueryFilters } from '../../helpers/parseQueryFilters';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';

const BASE_URL = '/v1/firm-users';

export const getUsers = async ({
  size,
  page,
  count,
  match,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL}?size=${size}&page=${page}&count=${count}&match=${match || ''}${
    queryFilters || ''
  }${sortFilters || ''}`;

  return await api.get<ResultSet<User>>(targetUrl);
};

export const createUser = async (data: {
  phone: string;
  role: string;
  firstName: string;
  lastName: string;
  email: string;
  firmId: number;
}) => {
  const targetUrl = `${BASE_URL}`;

  return await api.post<User>(targetUrl, data);
};

export const getUser = async (id: number) => {
  const targetUrl = `${BASE_URL}/${id}`;

  return await api.get<User>(targetUrl);
};

export const updateUser = async (
  id: number,
  data: {
    role?: string;
    firstName?: string;
    lastName?: string;
  },
) => {
  const targetUrl = `${BASE_URL}/${id}`;

  return await api.put<User>(targetUrl, data);
};

export const resetUserAccount = async (id: number) => {
  const targetUrl = `${BASE_URL}/${id}/reset`;

  return await api.post<void>(targetUrl, {});
};

export const resetUserPin = async (id: number) => {
  const targetUrl = `${BASE_URL}/${id}/reset-pin`;

  return await api.put<void>(targetUrl);
};
