import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { Box, Theme } from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from 'tss-react/mui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { handleGetFirms, mapFirmsQueryResults } from '../CasesView/helpers';

import { getUser, resetUserAccount, resetUserPin, updateUser } from '../../api/usersApi/usersApi';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import FormLinkButton from '../../components/buttons/FormLinkButton';
import FormSelectField from '../../components/formFields/FormSelectField';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import FormTextField from '../../components/formFields/FormTextField';
import Loader from '../../components/Loader';
import { ROLE_OPTIONS } from '../../constants/constants';
import { CREATE_USER_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { createUserFormSchema } from '../../helpers/formSchemas/formSchemas';
import FormCopyTextField from '../../components/formFields/FormCopyTextField';
import CommonButton from '../../components/buttons/CommonButton';
import usePageTitle from '../../hooks/usePageTitle';
import useSnackBar from '../../hooks/useSnackBar';
import FormAutocompleteField from '../../components/formFields/FormAutocompleteField';
import Popup from '../../components/Popup';
import { POPUP_DESCRIPTIONS } from '../../constants/popupDescriptions';

import { CreateUserFormSubmitProps } from './types';
import { FieldHeadline } from './styled';

const useStyles = makeStyles()((theme: Theme) => ({
  formWrapper: {
    display: 'flex',
    backgroundColor: theme.palette.primary.dark,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '10px',
  },
  formContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px',
    gap: '32px',
  },
  formColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '468px',
    gap: '32px',
  },
  formSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px 16px 24px',
  },
}));

const UsersEditFormContainer = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { lawyerId } = useParams();
  const { setPageTitle } = usePageTitle();
  const { setAlert } = useSnackBar();

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [isResetAccountPopupOpen, setIsResetAccountPopupOpen] = useState<boolean>(false);
  const [isResetPinPopupOpen, setIsResetPinPopupOpen] = useState<boolean>(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(createUserFormSchema),
    defaultValues: CREATE_USER_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isValid, isDirty },
  } = formMethods;

  const handleCloseResetAccountPopup = () => setIsResetAccountPopupOpen(false);
  const handleCloseResetPinPopup = () => setIsResetPinPopupOpen(false);
  const handleOpenResetAccountPopup = () => setIsResetAccountPopupOpen(true);
  const handleOpenResetPinPopup = () => setIsResetPinPopupOpen(true);

  const handleCancelChanges = () => reset();

  const firmFieldValue = watch('firm.id');
  const isPinSet = watch('isPinSet');

  const onSubmitForm = (values: CreateUserFormSubmitProps) => {
    if (!lawyerId) return;

    setIsFormSubmitting(true);

    const submitData = {
      firstName: values.firstName,
      lastName: values.lastName,
      role: values.role,
    };

    updateUser(Number(lawyerId), submitData)
      .then(() => {
        setIsFormSubmitting(false);
        navigate('/users');

        setAlert((prev) => ({
          ...prev,
          message: 'User updated successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const getUserData = () => {
    if (!lawyerId) return;

    setIsDataLoading(true);

    getUser(Number(lawyerId))
      .then((res) => {
        if (res.data) {
          setIsDataLoading(false);

          reset({
            id: res.data.id.toString(),
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            email: res.data.email,
            phone: res.data.phone,
            role: res.data.role,
            firm: {
              id: res.data.firm.id,
              label: res.data.firm.name,
            },
            isPinSet: res.data.isPinSet,
          });

          setPageTitle && setPageTitle(`${res.data.firstName} ${res.data.lastName}`);
        }
      })
      .catch(() => setIsDataLoading(false));
  };

  useEffect(() => {
    getUserData();

    return () => setPageTitle && setPageTitle('');
  }, [lawyerId]);

  const handleResetUserAccount = () => {
    if (!lawyerId) return;

    resetUserAccount(Number(lawyerId))
      .then(() => {
        navigate('/users');

        setAlert((prev) => ({
          ...prev,
          message: 'User account reseted successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response?.data.message,
          type: 'error',
        }));
      });
  };

  const handleResetUserPin = () => {
    if (!lawyerId) return;

    handleCloseResetPinPopup();

    resetUserPin(Number(lawyerId))
      .then(() => {
        getUserData();
        setAlert((prev) => ({
          ...prev,
          message: 'User PIN reset successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  return (
    <>
      {isDataLoading ? (
        <Loader colorType="warning" />
      ) : (
        <>
          <Box className={classes.formWrapper}>
            <FormProvider {...formMethods}>
              <form className={classes.formContainer}>
                <Box className={classes.formContentContainer}>
                  <Box className={classes.formColumn}>
                    <Box className={classes.formSection}>
                      <FieldHeadline>Identity</FieldHeadline>
                      <FormCopyTextField entityName="User" name="id" label="Id" isReadOnly />
                      <FormTextField name="firstName" label="First Name *" />
                      <FormTextField name="lastName" label="Last Name *" />
                    </Box>

                    <Box className={classes.formSection}>
                      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                        <FieldHeadline>Firm</FieldHeadline>
                        <FormLinkButton path={`/firms/${firmFieldValue}/home`} />
                      </Box>
                      <FormAutocompleteField
                        name="firm"
                        label="Firm *"
                        mapQueryResults={mapFirmsQueryResults}
                        optionsQuery={handleGetFirms}
                        disabled
                      />
                    </Box>

                    <Box className={classes.formSection}>
                      <FieldHeadline>Credentials</FieldHeadline>
                      <FormTextField name="email" label="Email Address *" disabled />
                      <FormTextField name="phone" label="Mobile Number *" disabled />
                    </Box>
                  </Box>

                  <Box className={classes.formColumn}>
                    <Box className={classes.formSection}>
                      <FieldHeadline>Role</FieldHeadline>
                      <FormSelectField options={ROLE_OPTIONS} name="role" label="Role *" />
                    </Box>
                  </Box>

                  <Box className={classes.formColumn}>
                    <Box className={classes.formSection}>
                      <FieldHeadline>Actions</FieldHeadline>
                      <CommonButton
                        type="info"
                        buttonText="Reset Account"
                        buttonIcon={<LockResetIcon />}
                        onButtonClick={handleOpenResetAccountPopup}
                      />
                      <CommonButton
                        type="info"
                        buttonText="Reset PIN"
                        buttonIcon={<LockResetIcon />}
                        onButtonClick={handleOpenResetPinPopup}
                        disabled={!isPinSet}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box className={classes.actionButtons}>
                  <FormSubmitButton
                    disabled={!isValid || !isDirty || isFormSubmitting}
                    buttonIcon={<SaveIcon />}
                    buttonText="Save"
                    onClick={handleSubmit(onSubmitForm)}
                  />
                  {isDirty && <FormCancelButton onClick={handleCancelChanges} />}
                </Box>
              </form>
            </FormProvider>

            <Popup
              isOpen={isResetAccountPopupOpen}
              headlineText={POPUP_DESCRIPTIONS.lawyersPage.headlineText}
              contentText={POPUP_DESCRIPTIONS.lawyersPage.contentText}
              onMainButtonClick={handleResetUserAccount}
              onSecondaryButtonClick={handleCloseResetAccountPopup}
            />
            <Popup
              isOpen={isResetPinPopupOpen}
              headlineText={POPUP_DESCRIPTIONS.resetPin.headlineText}
              contentText={POPUP_DESCRIPTIONS.resetPin.contentText}
              onMainButtonClick={handleResetUserPin}
              onSecondaryButtonClick={handleCloseResetPinPopup}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default UsersEditFormContainer;
