import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { verificationPinFormSchema } from '../helpers/formSchemas/formSchemas';

import PinInput from './PinInput';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.light,
      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))',
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.secondary.main,
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
  },
  dialogActions: {
    padding: '16px 8px',
  },
  dialogButton: {
    color: theme.palette.info.main,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
    },

    '&:hover': {
      background: 'rgba(144, 202, 249, 0.08)',
    },
  },
}));

interface FormProps {
  numbers: Array<{
    value: string;
  }>;
}

const formDefaultValues = {
  numbers: new Array(4).fill({ value: '' }),
};

interface VerificationPopupProps {
  isOpen: boolean;
  onMainButtonClick: (pin: string) => void;
  onSecondaryButtonClick: () => void;
}

const VerificationPopup = ({
  isOpen,
  onMainButtonClick,
  onSecondaryButtonClick,
}: VerificationPopupProps) => {
  const { classes } = useStyles();

  const formMethods = useForm<FormProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(verificationPinFormSchema),
    defaultValues: formDefaultValues,
  });

  const { handleSubmit, reset } = formMethods;

  const onSubmit = (values: FormProps) => {
    const pin = values.numbers.map((num) => num.value).join('');
    onMainButtonClick(pin);
    reset(formDefaultValues);
  };

  const handleCancel = () => {
    reset(formDefaultValues);
    onSecondaryButtonClick?.();
  };

  return (
    <Dialog className={classes.dialogContainer} open={isOpen} onClose={handleCancel} fullWidth>
      <DialogTitle>Verification</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>Enter 4-digit PIN</DialogContentText>
        <FormProvider {...formMethods}>
          <PinInput name="numbers" />
        </FormProvider>
      </DialogContent>
      <DialogActions className={classes.dialogActions} disableSpacing>
        <Button className={classes.dialogButton} onClick={handleSubmit(onSubmit)}>
          Submit
        </Button>
        <Button className={classes.dialogButton} onClick={handleCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VerificationPopup;
