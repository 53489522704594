import { Link, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { TableCell, TableRow, Theme } from '@mui/material';

import { formatDate } from '../../helpers/formatDate';
import { CaseShortData } from '../../types';
import { numberToUSDCurrency } from '../../helpers/numberToUSDCurrency';

const useStyles = makeStyles()((theme: Theme) => ({
  styledLink: {
    textDecoration: 'none',
    color: theme.palette.info.main,
  },
}));

interface CasesTableDataRowProps {
  tableData: CaseShortData[];
  isRelatedToOtherEntity?: boolean;
  clientId?: string;
  customerId?: string;
  firmId?: string;
}

const CasesTableDataRow = ({
  tableData,
  isRelatedToOtherEntity,
  customerId,
  firmId,
}: CasesTableDataRowProps) => {
  const styles = useStyles();
  const { caseId } = useParams();

  const formattedList =
    tableData &&
    tableData.map((caseItem) => ({
      caseType: (
        <Link className={styles.classes.styledLink} to={`/cases/${String(caseItem.id)}/home`}>
          {caseItem.name}
        </Link>
      ),
      ...(customerId && { firm: caseItem.firm.name }),
      ...(!isRelatedToOtherEntity && { firm: caseItem.firm.name }),
      ...((firmId || !customerId) && {
        client: `${caseItem.client.firstName} ${caseItem.client.lastName}`,
      }),
      ...(caseId && { client: `${caseItem.client.firstName} ${caseItem.client.lastName}` }),
      defendants: `${caseItem.defendants[0].name}`,
      type: caseItem.type.name,
      createdAt: formatDate(caseItem.createdAt),
      filedAt: formatDate(caseItem.filedAt || ''),
      sol: formatDate(caseItem.sol || ''),
      status: caseItem.status,
      gross: numberToUSDCurrency(
        caseItem.defendants.reduce((acc, def) => {
          acc += def.grossSettlement || 0;

          return acc;
        }, 0),
      ),
    }));

  return (
    <>
      {formattedList.map((tableItem, idx) => (
        <TableRow key={idx}>
          {Object.values(tableItem).map((item, idx) => (
            <TableCell key={idx}>{item}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default CasesTableDataRow;
