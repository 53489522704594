import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
  label: {
    color: theme.palette.primary.light,
    fontSize: '12px',
  },
  filterItem: {
    color: theme.palette.primary.light,
    fontSize: '14px',
    cursor: 'pointer',
  },
  filterItemActive: {
    color: theme.palette.info.main,
    fontSize: '14px',
    fontWeight: 600,
    cursor: 'pointer',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  archivedItem: {
    color: theme.palette.primary.light,
    opacity: 0.3,
  },
}));
