import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import SignaturesTableDataRow from './SignaturesTableDataRow';

import Loader from '../../../components/Loader';
import TableContainer from '../../../components/Table/TableContainer';
import { SIGNATURES_LIST_TABLE_LABELS } from '../../../constants/tableLabels';
import usePagination from '../../../hooks/usePagination';
import { ActiveSortField, FilterTypes, SignatureData, ViewProps } from '../../../types';
import { getSignaturesList } from '../../../api/requestsApi/signaturesApi';
import TableFilters from '../../../components/TableFilters/TableFilters';
import { downloadCsv } from '../../../helpers/downloadCsv';
import TableActions from '../../../components/Table/TableActions';
import useSnackBar from '../../../hooks/useSnackBar';
import { handleSortTableData } from '../../../helpers/handleSortTableData';

const SignaturesView = ({ isRelatedToOtherEntity }: ViewProps) => {
  const params = useParams();

  const { pageNumber, rowsPerPage, setTotalCount } = usePagination();
  const { setAlert } = useSnackBar();

  const [signaturesList, setSignaturesList] = useState<SignatureData[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [filters, setFilters] = useState<FilterTypes>({
    firmId: params?.firmId || '',
    clientId: '',
    defendantId: '',
  });
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);
  const sortableFields = {
    client: {
      order: '',
      fieldName: 'client',
    },
    createdAt: {
      order: '',
      fieldName: 'createdAt',
    },
    completedAt: {
      order: '',
      fieldName: 'completedAt',
    },
  };

  const hasParams = !!Object.entries(params).length;

  useEffect(() => {
    getSignaturesList({
      page: pageNumber,
      size: rowsPerPage,
      count: true,
      filters: { ...filters, ...(hasParams && params) },
      activeSortField,
    }).then((res) => {
      if (res.data) {
        setTotalCount && setTotalCount(res.data.totalCount);
        setSignaturesList(res.data.resultSet);
        setIsDataLoading(false);
      }
    });
  }, [pageNumber, rowsPerPage, filters, activeSortField]);

  const exportList = () => {
    downloadCsv(
      {
        page: pageNumber,
        size: rowsPerPage,
        count: true,
        filters: { ...filters, ...(hasParams && params) },
      },
      '/v3/signature-requests',
      'Signatures_List',
    ).then((res) => {
      if (res?.data) {
        setAlert({
          message: 'Signature requests exported successfully.',
          type: 'success',
        });
      }
    });
  };

  return (
    <>
      {isDataLoading && !signaturesList.length ? (
        <Box position="absolute" top="50%" left="50%">
          <Loader colorType="warning" />
        </Box>
      ) : (
        <>
          <TableActions hasParams={hasParams} exportList={exportList} />

          <Grid container>
            <TableFilters
              filters={filters}
              setFilters={setFilters}
              firms
              timeFilter={'REQUEST DATE'}
              // statusFilter={[
              //   'Opened',
              //   'Completed',
              //   'Rejected',
              //   'CancelInProgress',
              //   'Canceled',
              //   'Archived',
              // ]}
              statusFilter={[
                'Opened',
                'Completed',
                'Rejected',
                'CancelInProgress',
                'Canceled',
                'Unopened',
              ]}
              isStatusArray
            />
            <TableContainer
              isNoData={!isDataLoading && signaturesList.length === 0}
              headerLabels={isRelatedToOtherEntity || SIGNATURES_LIST_TABLE_LABELS}
              sortableFields={sortableFields}
              activeSortField={activeSortField}
              setActiveSortField={setActiveSortField}
              handleSortTableData={handleSortTableData}
            >
              <SignaturesTableDataRow
                tableData={signaturesList}
                isRelatedToOtherEntity={!!isRelatedToOtherEntity}
              />
            </TableContainer>
          </Grid>
        </>
      )}
      <Outlet />
    </>
  );
};

export default SignaturesView;
