/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEventHandler, Dispatch, Key, SetStateAction, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { Box, Theme, Typography } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

const useStyles = makeStyles()((theme: Theme) => ({
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  },
  img: {
    display: 'block',
    width: '-webkit-fill-available',
    height: '100%',
  },
  rejectedFileName: {
    p: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  rejectedErrorText: {
    listStyle: 'none',
    color: theme.palette.error.main,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontFamily: 'Roboto',
    fontSize: '14px',
    marginTop: '12px',
  },
  imageContainer: {
    display: 'inline-flex',
    width: '100%',
    boxSizing: 'border-box',
    justifyContent: 'center',
    maxHeight: '120px',
  },
  dropzoneWrapper: {
    width: '100%',
    marginTop: '20px',
  },
  dropzoneContainer: {
    maxHeight: '150px',
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px dashed rgba(255, 255, 255, 0.3)',
    flexDirection: 'column',
  },
  dropzoneText: {
    margin: 0,
    color: 'rgba(255, 255, 255, 0.3)',
  },
  dropzoneTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const parseErrorCode = (code: Key) => {
  switch (code) {
    case 'file-invalid-type':
      return 'File type must be *jpeg, *jpg, *png, *svg, *gif';
    case 'file-too-large':
      return 'File is larger than 5 MB';
    default:
      break;
  }
};

interface DropzoneFieldProps {
  name: string;
  multiple?: boolean;
  setFiles: Dispatch<SetStateAction<File[] | [] | string[]>>;
  files: File[] | [] | string[];
  setFileError: Dispatch<SetStateAction<boolean>>;
  fileError: boolean;
}

interface DropzoneCustomProps {
  multiple?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  setFiles: Dispatch<SetStateAction<File[] | [] | string[]>>;
  files: File[] | [] | string[];
  setFileError: Dispatch<SetStateAction<boolean>>;
  fileError: boolean;
}

const DropzoneField = ({
  name,
  multiple,
  setFiles,
  files,
  setFileError,
  fileError,
}: DropzoneFieldProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ field: { onChange } }) => (
        <Dropzone
          multiple={multiple}
          onChange={(e) => onChange(multiple ? e.target.files : e.target.files?.[0] ?? null)}
          setFiles={setFiles}
          files={files}
          setFileError={setFileError}
          fileError={fileError}
        />
      )}
      name={name}
      control={control}
    />
  );
};

const Dropzone = ({
  multiple,
  onChange,
  setFiles,
  files,
  setFileError,
  fileError,
}: DropzoneCustomProps) => {
  const styles = useStyles();

  const { getRootProps, getInputProps, fileRejections, isDragActive } = useDropzone({
    multiple,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
      'image/svg+xml': [],
      'image/gif': [],
    },
    maxSize: 5242880,
    onDrop: (acceptedFiles: any) => {
      setFiles(
        acceptedFiles.map((file: File) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }: any) => (
    <>
      <div className={styles.classes.rejectedFileName}>
        <Typography>{file.path}</Typography>
      </div>
      {errors.map((e: { code: Key; message: string }) => (
        <li key={e.code} className={styles.classes.rejectedErrorText}>
          {parseErrorCode(e.code)}
        </li>
      ))}
    </>
  ));

  const thumbs = files.map((file: any) => (
    <Box key={file} className={styles.classes.imageContainer}>
      <Box className={styles.classes.thumbInner}>
        <img
          id="imageElement"
          className={styles.classes.img}
          src={file.preview || file}
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </Box>
    </Box>
  ));

  useEffect(() => {
    if (fileRejectionItems.length) {
      setFileError(true);
    } else {
      setFileError(false);
    }
    return () => files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, [fileRejectionItems.length]);

  return (
    <Box className={styles.classes.dropzoneWrapper}>
      <Box {...getRootProps()} className={styles.classes.dropzoneContainer}>
        <input {...getInputProps({ onChange })} />

        {isDragActive && !!thumbs.length && (
          <Box className={styles.classes.dropzoneTextContainer}>
            <Typography className={styles.classes.dropzoneText}>
              Drop image file here or click to select
            </Typography>
            <CloudUploadOutlinedIcon fontSize="large" htmlColor="rgba(255, 255, 255, 0.3)" />
          </Box>
        )}

        {!isDragActive && !thumbs.length && (
          <Box className={styles.classes.dropzoneTextContainer}>
            <Typography className={styles.classes.dropzoneText}>
              Drop image file here or click to select
            </Typography>
            <CloudUploadOutlinedIcon fontSize="large" htmlColor="rgba(255, 255, 255, 0.3)" />
          </Box>
        )}

        {!isDragActive && !!thumbs.length && <>{thumbs}</>}
      </Box>
      {fileError && <Box>{fileRejectionItems}</Box>}
    </Box>
  );
};

export default DropzoneField;
