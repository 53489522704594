import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme } from '@mui/material';
import { useParams } from 'react-router-dom';

import { FirmTrustAccountData, FirmTrustAccountSubmitFormProps } from '../../../types';
import { firmTrustAccountFormSchema } from '../../../../../helpers/formSchemas/formSchemas';
import { FIRM_TRUST_ACCOUNT_FORM_DEFAULT_VALUES } from '../../../../../helpers/formSchemas/formDefaultValues';
import useSnackBar from '../../../../../hooks/useSnackBar';
import { createFirmTrustAccount } from '../../../../../api/firmsApi/trustAccountsApi';

import FirmTrustAccountForm from './FirmTrustAccountForm';
import FirmBankingPopupTabWrapper from '../FirmBankingPopupTabWrapper';

const useStyles = makeStyles()((theme: Theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.light,
      maxWidth: '960px',
      width: '100%',
      padding: '24px',
      boxSizing: 'border-box',
    },

    '& .MuiDialogTitle-root': {
      padding: 0,
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: 'normal',
    },

    '& .MuiDialogContent-root': {
      padding: 0,
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.secondary.main,
    },
  },
  popupActions: {
    padding: '16px 0',
  },
  popupButton: {
    color: theme.palette.info.main,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
    },

    '&:hover': {
      background: 'rgba(144, 202, 249, 0.08)',
    },
  },
}));

interface FirmTrustAccountPopupProps {
  isOpen: boolean;
  isLoading: boolean;
  handleEditAccount: (data: FirmTrustAccountSubmitFormProps) => void;
  selectedTableItem: FirmTrustAccountData | null;
  handleResetFormState: () => void;
  handleClosePopup: () => void;
  fetchTrustAccounts: () => void;
  isAddForm?: boolean;
}

const FirmTrustAccountPopup = ({
  isOpen,
  handleEditAccount,
  selectedTableItem,
  handleResetFormState,
  handleClosePopup,
  fetchTrustAccounts,
  isAddForm,
}: FirmTrustAccountPopupProps) => {
  const styles = useStyles();
  const { firmId } = useParams();
  const { setAlert } = useSnackBar();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(firmTrustAccountFormSchema),
    defaultValues: FIRM_TRUST_ACCOUNT_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
    watch,
  } = formMethods;

  const wasUsedForPayment = watch('wasUsedForPayment');

  const handleAddNewAccount = (accountFormData: FirmTrustAccountSubmitFormProps) => {
    if (!firmId) return;

    setIsFormSubmitting(true);

    const submitData = {
      accountNumber: accountFormData.accountNumber,
      routingNumber: accountFormData.routingNumber,
      name: accountFormData.name,
      isVerified: accountFormData.isVerified,
    };

    createFirmTrustAccount(Number(firmId), submitData)
      .then((res) => {
        if (res.data) {
          setAlert((prev) => ({
            ...prev,
            message: 'Trust account created successfully.',
            type: 'success',
          }));

          handleClosePopup();
          fetchTrustAccounts();
          reset(FIRM_TRUST_ACCOUNT_FORM_DEFAULT_VALUES);
          setIsFormSubmitting(false);
        }
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
        setIsFormSubmitting(false);
      });
  };

  const onSubmitForm = (values: FirmTrustAccountSubmitFormProps) => {
    if (wasUsedForPayment) {
      setAlert((prev) => ({
        ...prev,
        message:
          // eslint-disable-next-line quotes
          `You cannot edit this trust account since it's connected to the cases for which settlement process has been started.`,
        type: 'info',
      }));
      return;
    }

    selectedTableItem ? handleEditAccount(values) : handleAddNewAccount(values);
  };

  const onPopupClose = () => {
    handleResetFormState();
    handleDisabledForm(false);
    reset(FIRM_TRUST_ACCOUNT_FORM_DEFAULT_VALUES);
  };

  const handleDisabledForm = (newDisabled: boolean) => {
    setDisabled(newDisabled);
  };

  useEffect(() => {
    selectedTableItem
      ? reset({
          accountNumber: selectedTableItem.accountNumber,
          routingNumber: selectedTableItem.routingNumber,
          name: selectedTableItem.name,
          isVerified: selectedTableItem.isVerified,
          wasUsedForPayment: selectedTableItem.wasUsedForPayment,
        })
      : reset({
          ...FIRM_TRUST_ACCOUNT_FORM_DEFAULT_VALUES,
        });
  }, [selectedTableItem]);

  return (
    <Dialog className={styles.classes.popupContainer} open={isOpen} onClose={onPopupClose}>
      <FirmBankingPopupTabWrapper
        selectedTableItem={selectedTableItem}
        isOpen={isOpen}
        onPopupClose={onPopupClose}
        entity="trust"
        handleDisabledForm={handleDisabledForm}
        isAddForm={isAddForm}
        fetchAccounts={fetchTrustAccounts}
      >
        {isAddForm && <DialogTitle>Trust Account</DialogTitle>}
        <DialogContent>
          <FormProvider {...formMethods}>
            <FirmTrustAccountForm disabled={disabled} />
          </FormProvider>
        </DialogContent>
        <DialogActions className={styles.classes.popupActions}>
          <Button
            className={styles.classes.popupButton}
            onClick={handleSubmit(onSubmitForm)}
            disabled={!isValid || !isDirty || isFormSubmitting}
          >
            Submit
          </Button>
          <Button className={styles.classes.popupButton} onClick={onPopupClose}>
            Cancel
          </Button>
        </DialogActions>
      </FirmBankingPopupTabWrapper>
    </Dialog>
  );
};

export default FirmTrustAccountPopup;
