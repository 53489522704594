import moment from 'moment/moment';

export const CREATED_ITEMS = [
  {
    title: 'Today',
    value: 'today',
    from: moment().startOf('day').format(),
    to: moment().endOf('day').format(),
  },
  {
    title: 'This Week',
    value: 'thisWeek',
    from: moment().startOf('week').format(),
    to: moment().endOf('week').format(),
  },
  {
    title: 'Last Week',
    value: 'lastWeek',
    from: moment().subtract(1, 'week').startOf('week').format(),
    to: moment().subtract(1, 'week').endOf('week').format(),
  },
  {
    title: 'This Month',
    value: 'thisMonth',
    from: moment().startOf('month').format(),
    to: moment().endOf('month').format(),
  },
  {
    title: 'Last Month',
    value: 'lastMonth',
    from: moment().subtract(1, 'month').startOf('month').format(),
    to: moment().subtract(1, 'month').endOf('month').format(),
  },
  {
    title: 'Earlier',
    value: 'earlier',
    from: moment(0).format(),
    to: moment().subtract(1, 'month').startOf('month').format(),
  },
];
