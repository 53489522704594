import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import TabsPanel from '../../../components/TabsPanel';
import { documentsAccountTabs } from '../../../constants/tabRoutes';
import GoBackButton from '../../../components/buttons/GoBackButton';
import { getDocumentFile, getDocumentRecord } from '../../../api/requestsApi/documentRequestsApi';

const DocumentsAccountView = () => {
  const { documentId } = useParams<string>();
  const [entityRecordData, setEntityRecordData] = useState();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getDocumentRecord(documentId as string).then((res: any) => {
      if (res.data) {
        if (res.data?.fileName) {
          getDocumentFile(documentId as string).then((fileRes) => {
            setEntityRecordData({
              ...res.data,
              plaintiffFullName: `${res.data.client.firstName} ${
                res.data.client.middleName || ''
              } ${res.data.client.lastName}`,
              documentUrl: fileRes?.data,
              plaintiffId: res.data.client.id,
            });
          });
        } else {
          setEntityRecordData({
            ...res.data,
            plaintiffFullName: `${res.data.client.firstName} ${res.data.client.middleName || ''} ${
              res.data.client.lastName
            }`,
            plaintiffId: res.data.client.id,
          });
        }
      }
    });
  }, [documentId]);

  return (
    <>
      <GoBackButton />
      <Box display="flex" width="100%">
        <TabsPanel
          tabsList={documentsAccountTabs}
          locationSlicePosition={3}
          contextData={entityRecordData}
        />
      </Box>
    </>
  );
};

export default DocumentsAccountView;
