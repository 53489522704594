import { Dispatch, SetStateAction } from 'react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import { Box, IconButton, TableCell, TableHead, TableRow, Theme, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { ActiveSortField, SortableFieldsObject } from '../../types';

const useStyles = makeStyles()((theme: Theme) => ({
  tableHeaderCell: {
    '& .MuiTableCell-root': {
      color: theme.palette.primary.light,
      borderBottom: '1px solid #515151',
      height: '37px',
      padding: 0,
      paddingLeft: '16px',
      maxWidth: '200px',
      width: '5%',
    },
  },
  defaultSortArrow: {
    padding: 0,
    color: 'rgba(255, 255, 255, 0.7)',
    marginBottom: '1px',
  },
  nonActiveSortArrow: {
    marginBottom: '1px',
    padding: 0,
    svg: {
      color: 'rgba(255, 255, 255, 0.3)',
    },

    '&:hover': {
      svg: {
        color: 'rgba(255, 255, 255, 0.7)',
      },
    },
  },
  cellText: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16.5px',
    maxWidth: '140px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

interface TableHeaderProps {
  headerLabels: { accessor: string; isSortable: boolean; label: string }[];
  setActiveSortField: Dispatch<SetStateAction<ActiveSortField | null>>;
  activeSortField: ActiveSortField | null;
  handleSortTableData?: (
    sortField: string,
    sortableFields: SortableFieldsObject,
    activeSortField: ActiveSortField | null,
    setActiveSortField?: Dispatch<SetStateAction<ActiveSortField | null>>,
  ) => void;
  sortableFields?: SortableFieldsObject;
}

const TableHeader = ({
  headerLabels,
  handleSortTableData,
  sortableFields,
  setActiveSortField,
  activeSortField,
}: TableHeaderProps) => {
  const styles = useStyles();

  return (
    <TableHead className={styles.classes.tableHeaderCell}>
      <TableRow>
        <>
          {headerLabels.map((item, idx) => (
            <TableCell key={idx}>
              <Box display="flex" alignItems="center" gap="4px">
                <Typography className={styles.classes.cellText}>{item?.label}</Typography>

                {item.isSortable && (
                  <IconButton
                    className={clsx(
                      activeSortField &&
                        activeSortField.fieldName === item.accessor &&
                        activeSortField.order
                        ? styles.classes.defaultSortArrow
                        : styles.classes.nonActiveSortArrow,
                    )}
                    disableRipple
                    onClick={() =>
                      handleSortTableData &&
                      handleSortTableData(
                        item.accessor,
                        sortableFields as SortableFieldsObject,
                        activeSortField,
                        setActiveSortField,
                      )
                    }
                  >
                    {activeSortField &&
                    activeSortField.fieldName === item.accessor &&
                    activeSortField.order === 'desc' ? (
                      <ArrowDownwardIcon fontSize="small" />
                    ) : (
                      <ArrowUpwardIcon fontSize="small" />
                    )}
                  </IconButton>
                )}
              </Box>
            </TableCell>
          ))}
        </>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
