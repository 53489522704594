import { useRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import clsx from 'clsx';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, IconButton, TextField, Theme, useTheme, InputAdornment } from '@mui/material';

import { makeStyles } from 'tss-react/mui';
import useSnackBar from '../../hooks/useSnackBar';

const useStyles = makeStyles()((theme: Theme) => ({
  defaultTextField: {
    input: {
      padding: '4px 0px 8px 8px',

      '&.Mui-disabled': {
        color: 'rgba(255, 255, 255, 0.3)',
        WebkitTextFillColor: 'rgba(255, 255, 255, 0.3)',
      },
    },

    '& .MuiInput-root': {
      maxWidth: '470px',

      color: theme.palette.primary.light,

      ':before': {
        borderBottom: `1px solid ${theme.palette.primary.light}`,
      },

      ':hover:not(.Mui-disabled, .Mui-error):before': {
        borderBottom: `1px solid ${theme.palette.primary.light}`,
      },

      ':after': {
        borderBottom: `1px solid ${theme.palette.primary.light}`,
      },

      '&.Mui-disabled': {
        color: 'rgba(255, 255, 255, 0.3)',
      },
    },

    '& .MuiInputLabel-root': {
      '&[data-shrink="true"]': {
        color: theme.palette.secondary.light,
      },

      marginLeft: '8px',
      color: 'rgba(255, 255, 255, 0.3)',

      '&.Mui-error': {
        color: theme.palette.error.main,
      },

      '&.Mui-focused': {
        color: theme.palette.secondary.light,
      },

      '&.Mui-disabled': {
        color: 'rgba(255, 255, 255, 0.3)',
      },
    },

    '& .MuiFormHelperText-root': {
      marginLeft: '8px',
      position: 'absolute',
      bottom: '-20px',
    },
  },

  readOnlyText: {
    input: {
      pointerEvents: 'none',
      '&:hover': {
        cursor: 'default',
      },
    },

    '& .MuiInput-root': {
      maxWidth: '470px',
      color: theme.palette.primary.light,

      ':before': {
        borderBottom: 'none',
      },

      ':hover:not(.Mui-disabled, .Mui-error):before': {
        borderBottom: 'none',
      },

      ':after': {
        borderBottom: 'none',
      },

      '&:hover': {
        cursor: 'default',
      },
    },
  },
  multilinePadding: {
    textarea: {
      padding: '4px 0px 8px 8px',
    },
  },
}));

interface FormCopyTextFieldProps {
  name: string;
  label: string;
  entityName: string;
  isReadOnly?: boolean;
  isMultiline?: boolean;
}

const FormCopyTextField = ({
  name,
  label,
  entityName,
  isReadOnly,
  isMultiline,
}: FormCopyTextFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const theme = useTheme();
  const styles = useStyles();
  const copyTextFieldRef = useRef<HTMLInputElement>(null);
  const { setAlert } = useSnackBar();

  const helperText = errors[name] && errors[name]?.message;

  const handleCopyText = () => {
    navigator.clipboard.writeText(copyTextFieldRef?.current?.value as string);

    setAlert((prev) => ({
      ...prev,
      message: `${entityName} ID copied successfully.`,
      type: 'success',
    }));
  };

  return (
    <Box
      maxWidth="470px"
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            className={clsx(
              styles.classes.defaultTextField,
              isReadOnly && styles.classes.readOnlyText,
              isMultiline && styles.classes.multilinePadding,
            )}
            variant="standard"
            label={label}
            value={value}
            fullWidth
            onChange={isReadOnly ? undefined : (e) => onChange(e.target.value)}
            error={!!errors[name]}
            helperText={helperText as string}
            multiline={isMultiline}
            minRows={4}
            InputProps={{
              inputRef: copyTextFieldRef,
              readOnly: isReadOnly,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disableRipple
                    sx={{
                      color: theme.palette.primary.light,
                      padding: 0,
                    }}
                    onClick={handleCopyText}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default FormCopyTextField;
