import { Dispatch, SetStateAction, createContext, useState } from 'react';

interface RequestStateContextProps {
  setRequestState?: Dispatch<SetStateAction<string>>;
  requestState: string;
}

export const RequestStateContext = createContext<RequestStateContextProps>({
  setRequestState: undefined,
  requestState: '',
});

interface RequestStateProviderProps {
  children: JSX.Element;
}

const RequestStateProvider = ({ children }: RequestStateProviderProps) => {
  const [requestState, setRequestState] = useState<string>('');

  const value = {
    requestState,
    setRequestState,
  };

  return <RequestStateContext.Provider value={value}>{children}</RequestStateContext.Provider>;
};

export default RequestStateProvider;
