import { Box, Typography, useTheme } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from 'react-router-dom';

interface AddButtonProps {
  disabled?: boolean;
  buttonText?: string;
  path?: string;
  handleClick?: () => void;
}

const AddButton = ({ buttonText, path, disabled, handleClick }: AddButtonProps) => {
  const theme = useTheme();

  return (
    <>
      {path ? (
        <Link
          to={path as string}
          target="_blank"
          style={{
            pointerEvents: disabled ? 'none' : 'auto',
            textDecoration: 'none',
          }}
        >
          <Box display="flex" alignItems="center" gap="4px">
            <AddCircleIcon
              sx={{
                color: disabled ? 'rgba(255, 255, 255, 0.3)' : theme.palette.info.main,
              }}
            />
            <Typography
              fontSize="14px"
              fontWeight="500"
              lineHeight="normal"
              textTransform="uppercase"
              color={disabled ? 'rgba(255, 255, 255, 0.3)' : theme.palette.info.main}
            >
              {buttonText}
            </Typography>
          </Box>
        </Link>
      ) : (
        <Box
          onClick={handleClick}
          display="flex"
          alignItems="center"
          gap="4px"
          maxWidth="fit-content"
          sx={{
            pointerEvents: disabled ? 'none' : 'auto',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          <AddCircleIcon
            sx={{
              color: disabled ? 'rgba(255, 255, 255, 0.3)' : theme.palette.info.main,
            }}
          />
          <Typography
            fontSize="14px"
            fontWeight="500"
            lineHeight="normal"
            textTransform="uppercase"
            color={disabled ? 'rgba(255, 255, 255, 0.3)' : theme.palette.info.main}
          >
            {buttonText}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default AddButton;
