import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useParams, useOutletContext } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import SaveIcon from '@mui/icons-material/Save';
import { Box, useTheme } from '@mui/material';

import CustomerActivationsButtonGroup from './CustomerActivationsButtonGroup';

import { FieldHeadline } from './styled';
import { CustomerEditFormSubmitProps } from './types';

import ClientActivationsButtonGroup from '../ClientsView/ClientActivationsButtonGroup';

import {
  getCustomerSSN,
  getCustomerBirthDate,
  updateCustomerRecord,
} from '../../api/customersApi/customersApi';
import Loader from '../../components/Loader';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import FormCopyTextField from '../../components/formFields/FormCopyTextField';
import FormDateField from '../../components/formFields/FormDateField';
import FormTextField from '../../components/formFields/FormTextField';
import FormSelectField from '../../components/formFields/FormSelectField';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import FormSSNField from '../../components/formFields/FormSSNField';
import { CLIENT_ACTIVATION_BUTTON_GROUPS, US_STATES_LIST } from '../../constants/constants';
import { CUSTOMER_EDIT_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import {
  customerEditFormSchema,
  personalInfoFormSchema,
} from '../../helpers/formSchemas/formSchemas';
import usePageTitle from '../../hooks/usePageTitle';
import useSnackBar from '../../hooks/useSnackBar';
import { CustomerData, EntityFlexibleRecord } from '../../types';

const CustomerEditFormContainer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { customerId } = useParams();
  const { setPageTitle } = usePageTitle();
  const { setAlert } = useSnackBar();
  const { entityData } = useOutletContext<EntityFlexibleRecord>();

  const [isSSNValueVisible, setIsSSNValueVisible] = useState<boolean>(false);
  const [isSSNLoading, setIsSSNLoading] = useState<boolean>(false);
  const [isBirthDateValueVisible, setIsBirthDateValueVisible] = useState<boolean>(false);
  const [isBirthDateLoading, setIsBirthDateLoading] = useState<boolean>(false);
  const [accountActivationsState, setAccountActivationsState] = useState({
    email: {
      label: 'EMAIL',
      isActive: false,
    },
    ssn: {
      label: 'SSN',
      isActive: false,
    },
    dob: {
      label: 'DATE OF BIRTH',
      isActive: false,
    },
  });

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(customerEditFormSchema),
    defaultValues: CUSTOMER_EDIT_FORM_DEFAULT_VALUES,
  });

  const personalInfoFormMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(personalInfoFormSchema),
    defaultValues: {
      birthdate: moment(),
      ssn: '***-**-****',
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = formMethods;

  const { setValue: personalInfoFormSetValue, watch: personalInfoFormWatch } =
    personalInfoFormMethods;

  const ssn = personalInfoFormWatch('ssn');
  const birthdate = personalInfoFormWatch('birthdate');

  const onSubmitForm = (values: CustomerEditFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      firstName: values.firstName,
      lastName: values.lastName,
      address1: values.address1,
      city: values.city,
      countryState: values.state,
      zip: values.zip,
      address2: values.address2?.length ? values.address2 : null,
      middleName: values.middleName?.length ? values.middleName : null,
    };

    updateCustomerRecord(customerId as string, submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);
          navigate('/customers');

          setAlert((prev) => ({
            ...prev,
            message: 'Customer updated successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => {
    setIsBirthDateValueVisible(false);
    setIsSSNValueVisible(false);
    reset();
  };

  const getSSNFieldValue = () => {
    if (!customerId) return;

    if (ssn !== '***-**-****') {
      setIsSSNValueVisible(!isSSNValueVisible);
      return;
    }

    setIsSSNLoading(true);

    getCustomerSSN(Number(customerId))
      .then((res) => {
        personalInfoFormSetValue('ssn', res.data.ssn);
        setIsSSNValueVisible(true);
      })
      .catch(() => setIsSSNValueVisible(false))
      .finally(() => setIsSSNLoading(false));
  };

  const getBirhtDateFieldValue = () => {
    if (!customerId) return;

    if (!moment(birthdate).isSame(moment(), 'day')) {
      setIsBirthDateValueVisible(!isBirthDateValueVisible);
      return;
    }

    setIsBirthDateLoading(true);

    getCustomerBirthDate(Number(customerId))
      .then((res) => {
        personalInfoFormSetValue('birthdate', moment(res.data.birthdate));
        setIsBirthDateValueVisible(true);
      })
      .catch(() => setIsBirthDateValueVisible(false))
      .finally(() => setIsBirthDateLoading(false));
  };

  useEffect(() => {
    if (entityData) {
      const data: CustomerData = entityData as CustomerData;

      reset({
        id: data.id.toString(),
        firstName: data.firstName,
        middleName: data.middleName || '',
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        address1: data.address1,
        address2: data.address2 || '',
        city: data.city,
        state: data.countryState,
        zip: data.zip,
      });

      setPageTitle && setPageTitle(`${entityData.firstName} ${entityData.lastName}`);
      setAccountActivationsState({
        email: {
          label: 'EMAIL',
          isActive: data.emailConfirmed,
        },
        ssn: {
          label: 'SSN',
          isActive: data.ssnConfirmed,
        },
        dob: {
          label: 'DATE OF BIRTH',
          isActive: data.dobConfirmed,
        },
      });
    }

    return () => setPageTitle && setPageTitle('');
  }, [entityData]);

  return (
    <>
      {!entityData ? (
        <Loader colorType="warning" />
      ) : (
        <>
          <Box display="flex" bgcolor={theme.palette.primary.dark} width="100%">
            <form style={{ width: '100%', padding: '20px' }}>
              <Box display="flex" width="100%" gap="32px">
                <FormProvider {...formMethods}>
                  <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                    <FieldHeadline>Identity</FieldHeadline>
                    <Box mt="10px">
                      <FormCopyTextField entityName="Customer" name="id" label="Id" isReadOnly />
                    </Box>

                    <Box mt="20px">
                      <FormTextField name="firstName" label="First Name *" />
                    </Box>
                    <Box mt="20px">
                      <FormTextField name="middleName" label="Middle Name" />
                    </Box>
                    <Box mt="20px">
                      <FormTextField name="lastName" label="Last Name *" />
                    </Box>

                    <FieldHeadline mt="50px">Credentials</FieldHeadline>
                    <Box mt="10px">
                      <FormTextField name="email" label="Email Address *" disabled />
                    </Box>
                    <Box mt="20px">
                      <FormTextField name="phone" label="Mobile Number *" disabled />
                    </Box>
                  </Box>
                </FormProvider>

                <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                  <FormProvider {...formMethods}>
                    <FieldHeadline>Address</FieldHeadline>
                    <Box mt="10px">
                      <FormTextField name="address1" label="Address Line 1 *" />
                    </Box>
                    <Box mt="20px">
                      <FormTextField name="address2" label="Address Line 2" />
                    </Box>
                    <Box mt="20px" display="flex" gap="20px" flexDirection="column">
                      <FormTextField name="city" label="City *" />
                      <FormSelectField options={US_STATES_LIST} name="state" label="State *" />
                      <FormTextField name="zip" label="Zip *" />
                    </Box>
                  </FormProvider>

                  <FormProvider {...personalInfoFormMethods}>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                      <FieldHeadline mt="50px">Personal Information</FieldHeadline>
                    </Box>
                    <Box mt="10px">
                      <FormDateField
                        name="birthdate"
                        label="Date of Birth *"
                        maxDate={moment()}
                        shouldDisableInput
                        isEncrypted
                        isEncryptedValueLoading={isBirthDateLoading}
                        isValueVisible={isBirthDateValueVisible}
                        onRightIconClick={getBirhtDateFieldValue}
                      />
                    </Box>
                    <Box mt="20px">
                      <FormSSNField
                        name="ssn"
                        label="Social Security Number *"
                        isEncrypted
                        isEncryptedValueLoading={isSSNLoading}
                        isValueVisible={isSSNValueVisible}
                        onRightIconClick={getSSNFieldValue}
                      />
                    </Box>
                  </FormProvider>
                </Box>

                <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                  <FieldHeadline>Confirmations</FieldHeadline>

                  <Box mt="20px">
                    <CustomerActivationsButtonGroup
                      buttonGroupLabel="Identity"
                      maxWidth="148px"
                      accountActivationsState={accountActivationsState}
                    />
                  </Box>

                  <FieldHeadline mt="50px">Activations</FieldHeadline>
                  <Box mt="12px" width="100%">
                    <ClientActivationsButtonGroup
                      buttonGroupLabel="app"
                      buttonsList={CLIENT_ACTIVATION_BUTTON_GROUPS.app}
                      maxWidth="148px"
                    />
                  </Box>

                  <Box mt="20px">
                    <ClientActivationsButtonGroup
                      buttonGroupLabel="virtual card"
                      buttonsList={CLIENT_ACTIVATION_BUTTON_GROUPS.virtualCard}
                      maxWidth="111px"
                    />
                  </Box>

                  <Box mt="20px">
                    <ClientActivationsButtonGroup
                      buttonGroupLabel="physical card"
                      buttonsList={CLIENT_ACTIVATION_BUTTON_GROUPS.physicalCard}
                      maxWidth="111px"
                    />
                  </Box>
                </Box>
              </Box>

              <Box display="flex" justifyContent="space-between" mt="40px">
                <FormSubmitButton
                  disabled={!isValid || !isDirty || isFormSubmitting}
                  buttonIcon={<SaveIcon />}
                  buttonText="Save"
                  onClick={handleSubmit(onSubmitForm)}
                />
                {isDirty && <FormCancelButton onClick={handleCancelChanges} />}
              </Box>
            </form>
          </Box>
        </>
      )}
    </>
  );
};

export default CustomerEditFormContainer;
