import { useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { Box, IconButton, useTheme } from '@mui/material';

import {
  handleGetCaseTypes,
  handleGetDefendants,
  handleGetFirms,
  handleGetClients,
  handleGetTrustAccounts,
  mapCaseTypesQueryResults,
  mapDefendantsQueryResults,
  mapFirmsQueryResults,
  mapClientsQueryResults,
  mapTrustAccountsQueryResults,
} from './helpers';
import { FieldHeadline } from './styled';
import { CaseCreateFormSubmitProps, DefendantField, SecondaryFirmsField } from './types';

import { createCaseRecord } from '../../api/casesApi/casesApi';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import GoBackButton from '../../components/buttons/GoBackButton';
import FormDateField from '../../components/formFields/FormDateField';
import FormTextField from '../../components/formFields/FormTextField';
import FormSelectField from '../../components/formFields/FormSelectField';
import { CASES_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { casesFormSchema } from '../../helpers/formSchemas/formSchemas';
import AddButton from '../../components/buttons/AddButton';
import FormAutocompleteField from '../../components/formFields/FormAutocompleteField';
import useSnackBar from '../../hooks/useSnackBar';
import {
  CASE_TYPES_CATEGORIES_OPTIONS,
  CaseTypeCategories,
  SECONDARY_FIRM_ROLES_OPTIONS,
} from '../../constants/constants';
import { CaseTypeData, PaginationQueryParams, SelectOption } from '../../types';
import ViewHeaderLinkButton from '../../components/buttons/ViewHeaderLinkButton';

const CasesCreateFormContainer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setAlert } = useSnackBar();

  const [caseTypes, setCaseTypes] = useState<CaseTypeData[]>([]);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(casesFormSchema),
    defaultValues: CASES_FORM_DEFAULT_VALUES,
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isValid, isDirty },
  } = formMethods;

  const leadFirmValue = watch('leadFirm');
  const caseTypeCategory = watch('caseTypeCategory');
  const type = watch('type');
  const hasChosenFirm = !leadFirmValue.label?.length;

  const {
    fields: defendantFields,
    append: defendantAppend,
    remove: defendantRemove,
  } = useFieldArray({
    name: 'defendant',
    control,
  });

  const {
    fields: secondaryFirmsFields,
    append: secondaryFirmAppend,
    remove: secondaryFirmRemove,
  } = useFieldArray({
    name: 'secondaryFirms',
    control,
  });

  const handleAddDefendant = () => {
    defendantAppend({
      defendantId: { id: '', label: '' },
      gross: '',
    });
  };

  const handleAddSecondaryFirm = () => {
    secondaryFirmAppend({
      id: { id: '', label: '' },
      role: '',
    });
  };

  const onSubmitForm = (values: CaseCreateFormSubmitProps) => {
    setIsFormSubmitting(true);

    const mappedDefendants = values?.defendant?.map((defendant: DefendantField) => ({
      id: defendant.defendantId.id,
      grossSettlement: defendant.gross ? Number(defendant.gross) : null,
    }));

    const mappedFirms = values?.secondaryFirms?.reduce(
      (acc: { id: string; role: string }[], firm: SecondaryFirmsField) => {
        if (firm.id.id && firm.role?.length) {
          acc.push({ id: firm.id.id, role: firm.role });
        }
        return acc;
      },
      [],
    );

    const submitData = {
      filedAt: values.filedAt ? moment(values.filedAt).format('MM-DD-YYYY') : null,
      sol: values.sol ? moment(values.sol).format('MM-DD-YYYY') : null,
      referenceId: values.referenceId,
      firmId: values.leadFirm.id,
      secondaryFirms: mappedFirms,
      clientId: Number(values.clientId.id),
      defendants: mappedDefendants,
      trustAccountId: values.trustAccount?.id ? Number(values.trustAccount.id) : null,
      caseTypeId: Number(values.type.id),
    };

    createCaseRecord(submitData)
      .then((res) => {
        setIsFormSubmitting(false);
        navigate(`/cases/${res.data.id}/home`);

        setAlert((prev) => ({
          ...prev,
          message: 'Case created successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => reset();

  const handleChangeFirmValue = () => {
    setValue('clientId', { id: '', label: '' });
    setValue('defendant', [{ defendantId: { id: '', label: '' }, gross: '' }]);
    setValue('caseTypeCategory', '');
    setValue('type', { id: '', label: '' });
    setValue('trustAccount', { id: '', label: '' });
  };

  const handleChangeCaseTypeValue = (value: SelectOption | null) => {
    if (!value) {
      setValue(
        'trustAccount',
        {
          id: '',
          label: '',
        },
        { shouldDirty: true, shouldValidate: true },
      );
      return;
    }

    const caseType = caseTypes.find((caseType) => caseType.id === Number(value.id));

    if (caseType && caseType.trustAccounts.length) {
      const trustAccount = caseType.trustAccounts.find(
        (el) => el.firmId === Number(leadFirmValue?.id),
      );

      if (trustAccount) {
        setValue(
          'trustAccount',
          {
            id: trustAccount.id.toString(),
            label: trustAccount.name,
          },
          { shouldDirty: true, shouldValidate: true },
        );
      }
    }
  };

  const handleChangeCaseTypeCategoryValue = (_name: string, value: string) => {
    setValue('caseTypeCategory', value, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue('type', { id: '', label: '' }, { shouldDirty: true, shouldValidate: true });
    setValue('trustAccount', { id: '', label: '' }, { shouldDirty: true, shouldValidate: true });
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" pr="10px">
        <GoBackButton />
        <ViewHeaderLinkButton path="/firms/create-firm" linkText="Create new firm" />
      </Box>
      <Box display="flex" bgcolor={theme.palette.primary.dark} width="100%">
        <FormProvider {...formMethods}>
          <form style={{ width: '100%', padding: '16px' }}>
            <Box display="flex" width="100%" gap="32px">
              <Box display="flex" flexDirection="column" width="100%" maxWidth="300px">
                <FieldHeadline>Lead Firm</FieldHeadline>
                <Box mt="12px">
                  <FormAutocompleteField
                    maxWidth="468px"
                    name="leadFirm"
                    label="Lead Firm *"
                    optionsQuery={handleGetFirms}
                    mapQueryResults={mapFirmsQueryResults}
                    onValueChange={handleChangeFirmValue}
                  />
                </Box>

                <FieldHeadline mt="50px">Identity</FieldHeadline>
                <Box mt="12px">
                  <FormTextField name="referenceId" label="Reference Id *" />
                </Box>
                <Box mt="20px">
                  <FormSelectField
                    disabled={hasChosenFirm}
                    options={CASE_TYPES_CATEGORIES_OPTIONS}
                    name="caseTypeCategory"
                    label="Case Type Category *"
                    customHandleChange={handleChangeCaseTypeCategoryValue}
                  />
                </Box>
                <Box mt="20px">
                  <FormAutocompleteField
                    disabled={!caseTypeCategory}
                    name={'type'}
                    label="Case Type *"
                    filters={{
                      category: caseTypeCategory,
                      ...(caseTypeCategory === CaseTypeCategories.Custom && {
                        firmId: leadFirmValue?.id,
                      }),
                    }}
                    optionsQuery={handleGetCaseTypes}
                    mapQueryResults={mapCaseTypesQueryResults}
                    onSearchResultsChange={(items) => setCaseTypes(items)}
                    onValueChange={handleChangeCaseTypeValue}
                  />
                </Box>

                <FieldHeadline mt="50px">Trust Account</FieldHeadline>
                <Box mt="12px">
                  <FormAutocompleteField
                    maxWidth="468px"
                    disabled={!type?.id}
                    name="trustAccount"
                    label="Trust Account *"
                    optionsQuery={(params: PaginationQueryParams) => {
                      const firmId = leadFirmValue?.id;
                      return handleGetTrustAccounts(params, firmId || '');
                    }}
                    mapQueryResults={mapTrustAccountsQueryResults}
                  />
                </Box>

                <FieldHeadline mt="50px">Client</FieldHeadline>
                <Box mt="12px">
                  <FormAutocompleteField
                    maxWidth="468px"
                    disabled={hasChosenFirm}
                    name="clientId"
                    label="Client *"
                    optionsQuery={handleGetClients}
                    mapQueryResults={mapClientsQueryResults}
                    filters={{
                      firmId: leadFirmValue?.id,
                    }}
                  />
                </Box>
                <Box mt="30px">
                  <AddButton
                    disabled={hasChosenFirm}
                    buttonText="create new client"
                    path="/clients/create-client"
                  />
                </Box>

                <FieldHeadline mt="50px">Key Dates</FieldHeadline>
                <Box display="flex" gap="16px" mt="12px" flexDirection="column">
                  <Box>
                    <FormDateField name="filedAt" label="Date Filed" />
                  </Box>
                  <Box>
                    <FormDateField name="sol" label="SOL Date" />
                  </Box>
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" width="100%" maxWidth="420px">
                <FieldHeadline>Defendants</FieldHeadline>
                {defendantFields.map((field, idx) => (
                  <Box display="flex" alignItems="center" gap="16px" key={field.id} mt="12px">
                    <FormAutocompleteField
                      name={`defendant.${idx}.defendantId`}
                      label="Defendant *"
                      minWidth="220px"
                      maxWidth="220px"
                      disabled={hasChosenFirm}
                      optionsQuery={handleGetDefendants}
                      mapQueryResults={mapDefendantsQueryResults}
                      filters={{
                        firmId: leadFirmValue?.id,
                      }}
                    />

                    <FormTextField
                      name={`defendant.${idx}.gross`}
                      label="Gross Settlement"
                      maxWidth="150px"
                      minWidth="150px"
                      disabled={hasChosenFirm}
                    />
                    {idx !== 0 && (
                      <IconButton
                        sx={{
                          padding: 0,
                          color: theme.palette.error.main,
                          marginTop: '13px',
                        }}
                        onClick={() => defendantRemove(idx)}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    )}
                  </Box>
                ))}

                <Box mt="30px" display="flex" justifyContent="space-between" maxWidth="468px">
                  <AddButton
                    disabled={hasChosenFirm}
                    buttonText="create new defendant"
                    path="/defendants/create-defendant"
                  />
                  <AddButton
                    disabled={hasChosenFirm}
                    buttonText="add defendant"
                    handleClick={handleAddDefendant}
                  />
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" width="100%" maxWidth="420px">
                <FieldHeadline>Firms</FieldHeadline>
                {secondaryFirmsFields.map((field, idx) => (
                  <Box display="flex" alignItems="center" gap="16px" key={field.id} mt="12px">
                    <FormAutocompleteField
                      name={`secondaryFirms.${idx}.id`}
                      label="Firm"
                      minWidth="220px"
                      maxWidth="220px"
                      optionsQuery={handleGetFirms}
                      mapQueryResults={mapFirmsQueryResults}
                      disabled={!leadFirmValue?.id}
                    />

                    <FormSelectField
                      name={`secondaryFirms.${idx}.role`}
                      label="Role"
                      maxWidth="150px"
                      minWidth="150px"
                      options={SECONDARY_FIRM_ROLES_OPTIONS}
                      disabled={!leadFirmValue?.id}
                    />
                    <IconButton
                      sx={{
                        padding: 0,
                        color: theme.palette.error.main,
                        marginTop: '13px',
                      }}
                      onClick={() => secondaryFirmRemove(idx)}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Box>
                ))}

                <Box mt="30px" display="flex" justifyContent="space-between" maxWidth="468px">
                  <AddButton
                    buttonText="add firm"
                    handleClick={handleAddSecondaryFirm}
                    disabled={!leadFirmValue.id}
                  />
                </Box>
              </Box>
            </Box>

            <Box display="flex" justifyContent="space-between" mt="40px">
              <FormSubmitButton
                disabled={!isValid || isFormSubmitting}
                buttonIcon={<SaveIcon />}
                buttonText="Save"
                onClick={handleSubmit(onSubmitForm)}
              />
              {isDirty && <FormCancelButton onClick={handleCancelChanges} />}
            </Box>
          </form>
        </FormProvider>
      </Box>
    </>
  );
};

export default CasesCreateFormContainer;
