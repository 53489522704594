import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box } from '@mui/material';
import { AxiosError } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  getGlobalFormSnippets,
  getFormSnippets,
  createFormSnippet,
  updateFormSnippet,
  deleteFormSnippet,
} from '../../api/questionnairesApi/formSnippetsApi';
import Loader from '../../components/Loader';
import { FormSnippetShortData, ErrorData, PaginationQueryParams } from '../../types';
import useSnackBar from '../../hooks/useSnackBar';
import Popup from '../../components/Popup';
import { POPUP_DESCRIPTIONS } from '../../constants/popupDescriptions';
import FormSnippetBuilderPopup from '../../components/FormSnippetBuilderPopup';
import FormSearchField from '../../components/formFields/FormSearchField';

import FormSnippetList from './FormSnippetList';
import { FormSnippetEditFormSubmitProps } from './types';
import { mapSnippetData } from './helpers/mapSnippetData';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    gap: '16px',
  },
  searchFieldWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '470px',
  },
}));

interface FormSnippetsEditSectionProps {
  readonly firmId?: string | number;
}

interface FormSubmitProps {
  name: string;
}

const schema = yup
  .object()
  .shape({
    name: yup.string().required('Required *'),
  })
  .required();

const FormSnippetsEditSection = ({ firmId }: FormSnippetsEditSectionProps) => {
  const { setAlert } = useSnackBar();
  const { classes } = useStyles();

  const [formSnippetsList, setFormSnippetsList] = useState<Array<FormSnippetShortData>>([]);
  const [selectedSnippet, setSelectedSnippet] = useState<FormSnippetShortData | null>(null);
  const [isBuilderPopupOpen, setIsBuilderPopupOpen] = useState(false);
  const [deletePopupData, setDeletePopupData] = useState<FormSnippetShortData | null>(null);
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const PAGE_SIZE = 15;

  const formMethods = useForm<FormSubmitProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { name: '' },
  });

  const { setValue, watch } = formMethods;

  const searchValue = watch('name');

  const getFetchParams = ({
    page,
    count,
    match,
  }: {
    page?: number;
    count?: boolean;
    match?: string;
  }) => {
    const queryParams: PaginationQueryParams = {
      page: page || 1,
      size: PAGE_SIZE,
      count: count !== undefined ? count : true,
      match: match?.trim() || '',
    };

    return queryParams;
  };

  const fetchFormSnippets = (queryParams: PaginationQueryParams) => {
    const fetch = firmId ? getFormSnippets : getGlobalFormSnippets;

    fetch({
      page: queryParams.page,
      size: queryParams.size,
      count: queryParams.count,
      match: queryParams.match,
      ...(firmId && { filters: { firmId } }),
    })
      .then((response) => {
        if (response.data) {
          setPage(queryParams.page);
          if (queryParams.page === 1) {
            setFormSnippetsList(response.data.resultSet);
            setTotalCount(response.data.totalCount);
          } else {
            setFormSnippetsList(
              Array.from(new Set([...formSnippetsList, ...response.data.resultSet])),
            );
          }
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      })
      .finally(() => {
        setIsLoading(false);
        setIsFetching(false);
      });
  };

  const handleCancelButtonClick = () => {
    setIsLoading(true);
    setValue('name', '');
    fetchFormSnippets(getFetchParams({}));
  };

  const handleLoadMoreButtonClick = () => {
    if (totalCount !== formSnippetsList.length) {
      setIsFetching(true);
      fetchFormSnippets(getFetchParams({ page: page + 1, count: false, match: searchValue }));
    }
  };

  const handleSearchFieldChange = (value: string) => {
    setValue('name', value, { shouldDirty: true });

    if (value.length === 0 || value.trim().length > 2) {
      setIsLoading(true);
      fetchFormSnippets(getFetchParams({ match: value }));
    }
  };

  const handleCreateFormSnippet = (values: FormSnippetEditFormSubmitProps) => {
    setIsLoading(true);

    const data = {
      data: mapSnippetData(values.data),
      ...(firmId && { firmId: Number(firmId) }),
      name: values.name.trim(),
    };

    createFormSnippet(data)
      .then((response) => {
        if (response.status === 201) {
          setAlert((prev) => ({
            ...prev,
            message: 'Snippet created successfully.',
            type: 'success',
          }));

          setIsBuilderPopupOpen(false);
          fetchFormSnippets(getFetchParams({ match: searchValue }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
        setIsLoading(false);
      });
  };

  const handleUpdateFormSnippet = (values: FormSnippetEditFormSubmitProps) => {
    if (!selectedSnippet) return;

    setIsLoading(true);
    setSelectedSnippet(null);

    const data = {
      data: mapSnippetData(values.data),
      name: values.name.trim(),
    };

    updateFormSnippet(selectedSnippet.id, data)
      .then((response) => {
        if (response.status === 200) {
          setAlert((prev) => ({
            ...prev,
            message: 'Snippet updated successfully.',
            type: 'success',
          }));

          setIsBuilderPopupOpen(false);
          fetchFormSnippets(getFetchParams({ match: searchValue }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
        setIsLoading(false);
      });
  };

  const handleDeleteFormSnippet = () => {
    if (!deletePopupData) return;

    setIsLoading(true);
    setDeletePopupData(null);

    deleteFormSnippet(deletePopupData.id)
      .then((response) => {
        if (response.status === 204) {
          setAlert((prev) => ({
            ...prev,
            message: 'Snippet deleted successfully.',
            type: 'success',
          }));

          fetchFormSnippets(getFetchParams({ match: searchValue }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchFormSnippets(getFetchParams({}));
  }, [firmId]);

  return (
    <Box className={classes.container}>
      <FormProvider {...formMethods}>
        <form>
          <Box className={classes.searchFieldWrapper}>
            <FormSearchField
              name="name"
              searchValue={searchValue}
              handleChange={handleSearchFieldChange}
              handleResetSearch={handleCancelButtonClick}
            />
          </Box>
        </form>
      </FormProvider>

      {isLoading ? (
        <Loader colorType="warning" />
      ) : (
        <FormSnippetList
          data={formSnippetsList}
          isFetching={isFetching}
          totalCount={totalCount}
          handleCreate={() => setIsBuilderPopupOpen(true)}
          handleDelete={(snippet) => setDeletePopupData(snippet)}
          handleUpdate={(snippet) => {
            setSelectedSnippet(snippet);
            setIsBuilderPopupOpen(true);
          }}
          handleLoadMore={handleLoadMoreButtonClick}
        />
      )}

      <FormSnippetBuilderPopup
        isOpen={isBuilderPopupOpen}
        data={selectedSnippet}
        isFormSubmitting={isLoading}
        onMainButtonClick={selectedSnippet ? handleUpdateFormSnippet : handleCreateFormSnippet}
        onSecondaryButtonClick={() => {
          setIsBuilderPopupOpen(false);
          setSelectedSnippet(null);
        }}
      />
      <Popup
        isOpen={deletePopupData !== null}
        headlineText={POPUP_DESCRIPTIONS.formSnippetDelete.headlineText}
        contentText={POPUP_DESCRIPTIONS.formSnippetDelete.contentText}
        onMainButtonClick={handleDeleteFormSnippet}
        onSecondaryButtonClick={() => setDeletePopupData(null)}
      />
    </Box>
  );
};

export default FormSnippetsEditSection;
