import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { CaseTypeData } from '../../types';
import CategoryList from '../../components/CategoryList/CategoryList';

import CaseTypesSearchSection from './CaseTypesSearchSection';
import QuestionnaireEditSection from './QuestionnairesEditSection';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 0px 16px 0px',
    height: '100%',
    gap: '16px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    gap: '8px',
  },
  headline: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '23px',
  },
}));

const QuestionnairesView = () => {
  const { classes } = useStyles();
  const { firmId } = useParams();

  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedCaseType, setSelectedCaseType] = useState<CaseTypeData | null>(null);

  return (
    <Box className={classes.container}>
      <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
        <Typography className={classes.headline}>{'Categories'}</Typography>
        <CategoryList
          hasCustom
          selectedCategory={selectedCategory}
          handleSelect={(category) => setSelectedCategory(category)}
        />
      </Box>

      {selectedCategory && (
        <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
          <Typography className={classes.headline}>{'Case Types'}</Typography>
          <CaseTypesSearchSection
            category={selectedCategory}
            firmId={firmId}
            selectedCaseType={selectedCaseType}
            handleSelect={(caseType) => setSelectedCaseType(caseType)}
          />
        </Box>
      )}

      {firmId && selectedCategory && selectedCaseType && (
        <Box className={classes.section}>
          <Typography className={classes.headline}>{'Questionnaires'}</Typography>
          <QuestionnaireEditSection firmId={firmId} caseType={selectedCaseType} />
        </Box>
      )}
    </Box>
  );
};

export default QuestionnairesView;
