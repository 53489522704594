import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
} from '@mui/material';

import CustomerBankingPopupTabWrapper from './CustomerBankingPopupTabWrapper';
import CustomerBankingAccountForm from './CustomerBankingAccountForm';

import { CustomerBankAccountSubmitFormProps, CustomerBankShortAccountData } from '../types';

import { createCustomerBankingAccount } from '../../../api/customersApi/customersApi';
import useSnackBar from '../../../hooks/useSnackBar';
import { customerBankAccountFormSchema } from '../../../helpers/formSchemas/formSchemas';
import { CUSTOMER_BANK_ACCOUNT_FORM_DEFAULT_VALUES } from '../../../helpers/formSchemas/formDefaultValues';

const useStyles = makeStyles()((theme: Theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.light,
      maxWidth: '960px',
      width: '100%',
      padding: '24px',
      boxSizing: 'border-box',
    },

    '& .MuiDialogTitle-root': {
      padding: 0,
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: 'normal',
    },

    '& .MuiDialogContent-root': {
      padding: 0,
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.secondary.main,
    },
  },
  popupActions: {
    padding: '16px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  popupButton: {
    color: theme.palette.info.main,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
    },

    '&:hover': {
      background: 'rgba(144, 202, 249, 0.08)',
    },
  },
}));

interface CustomerBankingAccountPopupProps {
  isOpen: boolean;
  isLoading: boolean;
  handleEditAccount: (data: CustomerBankAccountSubmitFormProps) => void;
  selectedTableItem: CustomerBankShortAccountData | null;
  handleResetFormState: () => void;
  handleClosePopup: () => void;
  fetchCustomerBankAccounts: () => void;
  isAddForm?: boolean;
}

const CustomerBankingAccountPopup = ({
  isOpen,
  handleEditAccount,
  selectedTableItem,
  handleResetFormState,
  handleClosePopup,
  fetchCustomerBankAccounts,
  isAddForm,
}: CustomerBankingAccountPopupProps) => {
  const styles = useStyles();
  const { customerId } = useParams();
  const { setAlert } = useSnackBar();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(customerBankAccountFormSchema),
    defaultValues: CUSTOMER_BANK_ACCOUNT_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = formMethods;

  const handleAddNewAccount = (accountFormData: CustomerBankAccountSubmitFormProps) => {
    if (!customerId) return;

    setIsFormSubmitting(true);

    const submitData = {
      accountNumber: accountFormData.accountNumber,
      routingNumber: accountFormData.routingNumber,
      name: accountFormData.name,
      type: accountFormData.type,
    };

    createCustomerBankingAccount(Number(customerId), submitData)
      .then((res) => {
        if (res.data) {
          setAlert((prev) => ({
            ...prev,
            message: 'Customer bank account created successfully.',
            type: 'success',
          }));

          handleClosePopup();
          fetchCustomerBankAccounts();
          reset(CUSTOMER_BANK_ACCOUNT_FORM_DEFAULT_VALUES);
          setIsFormSubmitting(false);
        }
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
        setIsFormSubmitting(false);
      });
  };

  const onSubmitForm = (values: CustomerBankAccountSubmitFormProps) => {
    selectedTableItem ? handleEditAccount(values) : handleAddNewAccount(values);
  };

  const onPopupClose = () => {
    handleResetFormState();
    handleDisabledForm(false);
    reset(CUSTOMER_BANK_ACCOUNT_FORM_DEFAULT_VALUES);
  };

  const handleDisabledForm = (newDisabled: boolean) => {
    setDisabled(newDisabled);
  };

  useEffect(() => {
    selectedTableItem
      ? reset({
          accountNumber: selectedTableItem.accountNumber,
          routingNumber: selectedTableItem.routingNumber,
          name: selectedTableItem.name,
          type: selectedTableItem.type,
        })
      : reset({
          ...CUSTOMER_BANK_ACCOUNT_FORM_DEFAULT_VALUES,
        });
  }, [selectedTableItem]);

  return (
    <Dialog className={styles.classes.popupContainer} open={isOpen} onClose={onPopupClose}>
      <CustomerBankingPopupTabWrapper
        selectedTableItem={selectedTableItem}
        isOpen={isOpen}
        onPopupClose={onPopupClose}
        handleDisabledForm={handleDisabledForm}
        isAddForm={isAddForm}
        fetchCustomerBankAccounts={fetchCustomerBankAccounts}
        isAdminCreated={selectedTableItem?.isAdminCreated}
      >
        {isAddForm && <DialogTitle>Add Bank Account</DialogTitle>}
        <DialogContent>
          <FormProvider {...formMethods}>
            <CustomerBankingAccountForm disabled={disabled} />
          </FormProvider>
        </DialogContent>
        <DialogActions className={styles.classes.popupActions}>
          {selectedTableItem?.isAdminCreated && <Typography>CREATED BY ADMIN </Typography>}
          <Box>
            <Button
              className={styles.classes.popupButton}
              onClick={handleSubmit(onSubmitForm)}
              disabled={!isValid || !isDirty || isFormSubmitting}
            >
              Submit
            </Button>
            <Button className={styles.classes.popupButton} onClick={onPopupClose}>
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomerBankingPopupTabWrapper>
    </Dialog>
  );
};

export default CustomerBankingAccountPopup;
