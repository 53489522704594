import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import GavelIcon from '@mui/icons-material/Gavel';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ThreePIcon from '@mui/icons-material/ThreeP';
import BalanceIcon from '@mui/icons-material/Balance';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import DrawIcon from '@mui/icons-material/Draw';
import RuleIcon from '@mui/icons-material/Rule';
import CategoryIcon from '@mui/icons-material/Category';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export const menuItems = [
  {
    linkName: 'Cases',
    path: 'cases',
    icon: <GavelIcon />,
  },
  {
    activeGroupId: 1,
    groupName: 'requests',
    linkName: 'Requests',
    icon: <AssignmentTurnedInIcon />,
    hasDividerBelow: false,
    innerElements: {
      elements: [
        {
          linkName: 'Documents',
          path: 'document-requests',
          icon: <DocumentScannerIcon />,
        },
        {
          linkName: 'Signatures',
          path: 'signature-requests',
          icon: <DrawIcon />,
        },
        {
          linkName: 'Data',
          path: 'data-requests',
          icon: <RuleIcon />,
        },
      ],
    },
  },
  {
    activeGroupId: 0,
    linkName: 'Payments',
    groupName: 'payments',
    icon: <ReceiptIcon />,
    hasDividerBelow: true,
    innerElements: {
      elements: [
        {
          linkName: 'Deductions',
          path: 'deductions',
          icon: <LocalOfferIcon />,
        },
        {
          linkName: 'Settlements',
          path: 'settlements',
          icon: <BalanceIcon />,
        },
      ],
    },
  },
  {
    linkName: 'Firms',
    path: 'firms',
    icon: <BusinessIcon />,
  },
  {
    linkName: 'Customers',
    path: 'customers',
    icon: <FolderSharedIcon />,
  },
  {
    linkName: 'Defendants',
    path: 'defendants',
    icon: <FingerprintIcon />,
    hasDividerBelow: true,
  },
  {
    linkName: 'Case Types',
    path: 'case-types',
    icon: <CategoryIcon />,
  },
  {
    linkName: 'Questionnaires',
    path: 'questionnaires',
    icon: <DynamicFormIcon />,
  },
  {
    linkName: 'Snippets',
    path: 'snippets',
    icon: <TextSnippetIcon />,
    hasDividerBelow: true,
  },
  {
    linkName: 'Communications',
    path: 'communications',
    icon: <ThreePIcon />,
  },
  {
    linkName: 'Users',
    path: 'users',
    icon: <PeopleIcon />,
  },
];

export const menuBottomItems = [
  {
    linkName: 'Manage Account',
    path: 'manage-account',
    icon: <ManageAccountsIcon />,
  },
  {
    linkName: 'Admins',
    path: 'admins',
    icon: <AdminPanelSettingsIcon />,
  },
];
