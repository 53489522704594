import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import CustomerTableDataRow from './CustomerTableDataRow';

import { getCustomersList } from '../../api/customersApi/customersApi';
import TableContainer from '../../components/Table/TableContainer';
import Loader from '../../components/Loader';
import { CUSTOMERS_LIST_TABLE_LABELS } from '../../constants/tableLabels';
import usePagination from '../../hooks/usePagination';
import { ActiveSortField, CustomerData, FilterTypes } from '../../types';
import TableFilters from '../../components/TableFilters/TableFilters';
import TableActions from '../../components/Table/TableActions';
import { downloadCsv } from '../../helpers/downloadCsv';
import useSnackBar from '../../hooks/useSnackBar';
import { handleSortTableData } from '../../helpers/handleSortTableData';

const CustomersView = () => {
  const params = useParams();

  const { pageNumber, rowsPerPage, setTotalCount } = usePagination();
  const { setAlert } = useSnackBar();

  const [customersList, setCustomersList] = useState<CustomerData[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [filters, setFilters] = useState<FilterTypes>({
    firmId: params?.firmId || '',
    defendantId: '',
  });
  const [match, setMatch] = useState<string>('');
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);
  const sortableFields = {
    name: {
      order: '',
      fieldName: 'name',
    },
    createdAt: {
      order: '',
      fieldName: 'createdAt',
    },
  };

  const hasParams = !!Object.entries(params).length;

  useEffect(() => {
    getCustomersList({
      page: pageNumber,
      size: rowsPerPage,
      count: true,
      match: match,
      filters: { ...filters, ...(hasParams && params) },
      activeSortField,
    }).then((res) => {
      if (res.data) {
        setTotalCount && setTotalCount(res.data.totalCount || 0);
        setCustomersList(res.data.resultSet);
        setIsDataLoading(false);
      }
    });
  }, [pageNumber, rowsPerPage, filters, match, activeSortField]);

  const exportList = () => {
    downloadCsv(
      {
        page: pageNumber,
        size: rowsPerPage,
        count: true,
        match: match,
        filters: filters,
      },
      '/v1/customers',
      'Customers_List',
    ).then((res) => {
      if (res?.data) {
        setAlert({
          message: 'Customers exported successfully.',
          type: 'success',
        });
      }
    });
  };

  return (
    <>
      {isDataLoading && !customersList.length ? (
        <Box position="absolute" top="50%" left="50%">
          <Loader colorType="warning" />
        </Box>
      ) : (
        <>
          <TableActions hasParams={hasParams} exportList={exportList} />

          <Grid container>
            <TableFilters
              filters={filters}
              setFilters={setFilters}
              setMatch={setMatch}
              timeFilter={'CREATED'}
              // statusFilter={['Created', 'Active', 'Deactivated', 'Archived']}
              statusFilter={['Created', 'Active', 'Inactive']}
            />
            <TableContainer
              isNoData={!isDataLoading && customersList.length === 0}
              headerLabels={CUSTOMERS_LIST_TABLE_LABELS}
              sortableFields={sortableFields}
              activeSortField={activeSortField}
              setActiveSortField={setActiveSortField}
              handleSortTableData={handleSortTableData}
            >
              <CustomerTableDataRow tableData={customersList} />
            </TableContainer>
          </Grid>
        </>
      )}
      <Outlet />
    </>
  );
};

export default CustomersView;
