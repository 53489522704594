import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { Box, Theme } from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from 'tss-react/mui';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  getAdmin,
  resetAdminAccount,
  resetAdminPin,
  updateAdminRecord,
} from '../../api/adminsApi/adminsApi';
import CommonButton from '../../components/buttons/CommonButton';
import FormTextField from '../../components/formFields/FormTextField';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import FormSelectField from '../../components/formFields/FormSelectField';
import FormCopyTextField from '../../components/formFields/FormCopyTextField';
import Popup from '../../components/Popup';
import { POPUP_DESCRIPTIONS } from '../../constants/popupDescriptions';
import { ROLE_OPTIONS } from '../../constants/constants';
import { createAdminFormSchema } from '../../helpers/formSchemas/formSchemas';
import { CREATE_ADMIN_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import usePageTitle from '../../hooks/usePageTitle';
import useSnackBar from '../../hooks/useSnackBar';

import { AdminEditFormSubmitProps } from './types';
import { FieldHeadline } from './styled';

const useStyles = makeStyles()((theme: Theme) => ({
  formWrapper: {
    display: 'flex',
    backgroundColor: theme.palette.primary.dark,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '10px',
  },
  formContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px',
    gap: '32px',
  },
  formColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '468px',
    gap: '32px',
  },
  formSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px 16px 24px',
  },
}));

const AdminEditFormContainer = () => {
  const { classes } = useStyles();
  const { adminId } = useParams<string>();
  const { setPageTitle } = usePageTitle();
  const { setAlert } = useSnackBar();

  const navigate = useNavigate();

  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [isButtonActionSubmitting, setIsButtonActionSubmitting] = useState<boolean>(false);
  const [isResetAccountPopupOpen, setIsResetAccountPopupOpen] = useState<boolean>(false);
  const [isResetPinPopupOpen, setIsResetPinPopupOpen] = useState<boolean>(false);
  const [isPinSet, setIsPinSet] = useState<boolean>(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(createAdminFormSchema),
    defaultValues: CREATE_ADMIN_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = formMethods;

  const handleCloseResetAccountPopup = () => setIsResetAccountPopupOpen(false);
  const handleCloseResetPinPopup = () => setIsResetPinPopupOpen(false);
  const handleOpenResetAccountPopup = () => setIsResetAccountPopupOpen(true);
  const handleOpenResetPinPopup = () => setIsResetPinPopupOpen(true);

  const handleCancelChanges = () => reset();

  const handleResetAccount = () => {
    setIsButtonActionSubmitting(true);

    resetAdminAccount(adminId as string)
      .then((res) => {
        if (!res.data.erorr) {
          setIsButtonActionSubmitting(false);

          setAlert((prev) => ({
            ...prev,
            message: 'Admin account reset successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsButtonActionSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });

    handleCloseResetAccountPopup();
  };

  const handleResetAdminPin = () => {
    if (!adminId) return;

    handleCloseResetPinPopup();
    setIsButtonActionSubmitting(true);

    resetAdminPin(Number(adminId))
      .then(() => {
        setAlert((prev) => ({
          ...prev,
          message: 'Admin PIN reset successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      })
      .finally(() => setIsButtonActionSubmitting(false));
  };

  const onSubmitForm = (values: AdminEditFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      firstName: values.firstName,
      lastName: values.lastName,
    };

    updateAdminRecord(adminId as string, submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);
          navigate('/admins');

          setAlert((prev) => ({
            ...prev,
            message: 'Admin account updated successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  useEffect(() => {
    getAdmin(adminId as string).then((res) => {
      if (res.data) {
        setPageTitle && setPageTitle(`${res.data.firstName} ${res.data.lastName}`);
        reset({
          id: res.data.id.toString(),
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          email: res.data.email,
          phone: res.data.phone,
          role: ROLE_OPTIONS[0].id,
        });
        setIsPinSet(res.data.isPinSet);
      }
    });

    return () => setPageTitle && setPageTitle('');
  }, [adminId]);

  return (
    <Box className={classes.formWrapper}>
      <FormProvider {...formMethods}>
        <form className={classes.formContainer}>
          <Box className={classes.formContentContainer}>
            <Box className={classes.formColumn}>
              <Box className={classes.formSection}>
                <FieldHeadline>Identity</FieldHeadline>
                <FormCopyTextField entityName="Admin" name="id" label="Id *" isReadOnly />
                <FormTextField name="firstName" label="First Name *" />
                <FormTextField name="lastName" label="Last Name *" />
              </Box>

              <Box className={classes.formSection}>
                <FieldHeadline mt="50px">Credentials</FieldHeadline>
                <FormTextField name="email" label="Email Address *" disabled />
                <FormTextField name="phone" label="Mobile Number *" disabled />
              </Box>
            </Box>

            <Box className={classes.formColumn}>
              <Box className={classes.formSection}>
                <FieldHeadline>Role</FieldHeadline>
                <FormSelectField options={ROLE_OPTIONS} name="role" label="Role *" />
              </Box>
            </Box>

            <Box className={classes.formColumn}>
              <Box className={classes.formSection}>
                <FieldHeadline>Actions</FieldHeadline>
                <CommonButton
                  disabled={isButtonActionSubmitting}
                  type="info"
                  buttonText="Reset Account"
                  buttonIcon={<LockResetIcon />}
                  onButtonClick={handleOpenResetAccountPopup}
                />
                <CommonButton
                  disabled={isButtonActionSubmitting || !isPinSet}
                  type="info"
                  buttonText="Reset PIN"
                  buttonIcon={<LockResetIcon />}
                  onButtonClick={handleOpenResetPinPopup}
                />
              </Box>
            </Box>
          </Box>

          <Box className={classes.actionButtons}>
            <FormSubmitButton
              disabled={!isValid || !isDirty || isFormSubmitting}
              buttonIcon={<SaveIcon />}
              buttonText="Save"
              onClick={handleSubmit(onSubmitForm)}
            />
            {isDirty && <FormCancelButton onClick={handleCancelChanges} />}
          </Box>
        </form>
      </FormProvider>

      <Popup
        isOpen={isResetAccountPopupOpen}
        headlineText={POPUP_DESCRIPTIONS.adminsPage.headlineText}
        contentText={POPUP_DESCRIPTIONS.adminsPage.contentText}
        onMainButtonClick={handleResetAccount}
        onSecondaryButtonClick={handleCloseResetAccountPopup}
      />
      <Popup
        isOpen={isResetPinPopupOpen}
        headlineText={POPUP_DESCRIPTIONS.resetPin.headlineText}
        contentText={POPUP_DESCRIPTIONS.resetPin.contentText}
        onMainButtonClick={handleResetAdminPin}
        onSecondaryButtonClick={handleCloseResetPinPopup}
      />
    </Box>
  );
};

export default AdminEditFormContainer;
