export const manageAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
  {
    name: 'Activity',
    path: 'activity',
    matchUrls: ['activity'],
  },
];

export const adminAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
  {
    name: 'Activity',
    path: 'activity',
    matchUrls: ['activity'],
  },
];

export const firmAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
  {
    name: 'Users',
    path: 'users',
    matchUrls: ['users'],
  },
  {
    name: 'Banking',
    path: 'banking',
    matchUrls: ['banking'],
  },
  {
    name: 'Case Types',
    path: 'case-types',
    matchUrls: ['case-types'],
  },
  {
    name: 'Questionnaires',
    path: 'questionnaires',
    matchUrls: ['questionnaires'],
  },
  {
    name: 'Snippets',
    path: 'snippets',
    matchUrls: ['snippets'],
  },
  {
    name: 'Cases',
    path: 'cases',
    matchUrls: ['cases'],
  },
  {
    name: 'Clients',
    path: 'clients',
    matchUrls: ['clients'],
  },
  {
    name: 'Defendants',
    path: 'defendants',
    matchUrls: ['defendants'],
  },
  {
    name: 'Document requests',
    path: 'document-requests',
    matchUrls: ['document-requests'],
  },
  {
    name: 'Signature requests',
    path: 'signature-requests',
    matchUrls: ['signature-requests'],
  },
  {
    name: 'Data requests',
    path: 'data-requests',
    matchUrls: ['data-requests'],
  },
  {
    name: 'Deductions',
    path: 'deductions',
    matchUrls: ['deductions'],
  },
  {
    name: 'Settlements',
    path: 'settlements',
    matchUrls: ['settlements'],
  },
];

export const usersAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
  {
    name: 'Activity',
    path: 'activity',
    matchUrls: ['activity'],
  },
];

export const defendantsAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
  {
    name: 'Cases',
    path: 'cases',
    matchUrls: ['cases'],
  },
];

export const clientsAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
  {
    name: 'Cases',
    path: 'cases',
    matchUrls: ['cases'],
  },
  {
    name: 'Activity',
    path: 'activity',
    matchUrls: ['activity'],
  },
];

export const customersAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
  {
    name: 'Firms',
    path: 'firms',
    matchUrls: ['firms'],
  },
  {
    name: 'Cases',
    path: 'cases',
    matchUrls: ['cases'],
  },
  {
    name: 'Banking',
    path: 'banking',
    matchUrls: ['banking'],
  },
  {
    name: 'Financial',
    path: 'financial',
    matchUrls: ['financial'],
  },
  {
    name: 'Activity',
    path: 'activity',
    matchUrls: ['activity'],
  },
];

export type casesAccountTabsType = {
  name: string;
  path: string;
  matchUrls: string[];
  isDraft?: boolean;
};

export const setCasesAccountTabs = (isDraft = false): casesAccountTabsType[] => {
  return [
    {
      name: 'Home',
      path: 'home',
      matchUrls: ['home'],
    },
    {
      name: 'Attorneys’ fees',
      path: 'attorneys-fees',
      matchUrls: ['attorneys-fees'],
    },
    {
      name: 'Document requests',
      path: 'document-requests',
      matchUrls: ['document-requests'],
      isDraft,
    },
    {
      name: 'Signature requests',
      path: 'signature-requests',
      matchUrls: ['signature-requests'],
      isDraft,
    },
    {
      name: 'Data requests',
      path: 'data-requests',
      matchUrls: ['data-requests'],
      isDraft,
    },
    {
      name: 'Deductions',
      path: 'deductions',
      matchUrls: ['deductions'],
      isDraft,
    },
    {
      name: 'Settlements',
      path: 'settlements',
      matchUrls: ['settlements'],
      isDraft,
    },
  ];
};

export const documentsAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
  {
    name: 'Timeline',
    path: 'timeline',
    matchUrls: ['timeline'],
  },
];

export const signaturesAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
  {
    name: 'Timeline',
    path: 'timeline',
    matchUrls: ['timeline'],
  },
];

export const dataAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
  {
    name: 'Timeline',
    path: 'timeline',
    matchUrls: ['timeline'],
  },
];

export const deductionsAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
  {
    name: 'Timeline',
    path: 'timeline',
    matchUrls: ['timeline'],
  },
];

export const settlementsAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
  {
    name: 'Details',
    path: 'details',
    matchUrls: ['details'],
  },
  {
    name: 'Timeline',
    path: 'timeline',
    matchUrls: ['timeline'],
  },
];
