import api from '../service/api.service';

import {
  FormSnippetData,
  FormSnippetShortData,
  PaginationQueryParams,
  QuestionnaireItem,
  ResultSet,
} from '../../types';
import { parseQueryFilters } from '../../helpers/parseQueryFilters';

const BASE_URL = '/v1/questionnaires/form-snippets';

export const getGlobalFormSnippets = async ({
  size,
  page,
  count,
  match,
}: PaginationQueryParams) => {
  const targetUrl = `${BASE_URL}/global?size=${size}&page=${page}&count=${count}&match=${
    match || ''
  }`;

  return await api.get<ResultSet<FormSnippetShortData>>(targetUrl);
};

export const getFormSnippets = async ({
  size,
  page,
  count,
  match,
  filters,
  includeGlobal,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);

  const targetUrl = `${BASE_URL}?size=${size}&page=${page}&count=${count}&match=${
    match || ''
  }&includeGlobal=${includeGlobal}${queryFilters || ''}`;

  return await api.get<ResultSet<FormSnippetShortData>>(targetUrl);
};

export const createFormSnippet = async (data: {
  data: {
    items: Array<QuestionnaireItem>;
  };
  firmId?: number | null;
  name: string;
}) => {
  return await api.post<FormSnippetData>(BASE_URL, data);
};

export const getFormSnippet = async (id: string) => {
  const targetUrl = `${BASE_URL}/${id}`;

  return await api.get<FormSnippetData>(targetUrl);
};

export const updateFormSnippet = async (
  id: string,
  data: {
    data: {
      items: Array<QuestionnaireItem>;
    };
    name: string;
  },
) => {
  const targetUrl = `${BASE_URL}/${id}`;

  return await api.put<FormSnippetData>(targetUrl, data);
};

export const deleteFormSnippet = async (id: string) => {
  const targetUrl = `${BASE_URL}/${id}`;

  return await api.delete<void>(targetUrl);
};
