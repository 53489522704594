import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { QuestionnaireSection } from '../../../types';

import SelectButton from './SelectButton';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    gap: '16px',
  },
}));

interface SectionListProps {
  readonly data: QuestionnaireSection[];
  readonly selectedSections: QuestionnaireSection[];
  readonly handleSelect: (item: QuestionnaireSection) => void;
  readonly handleSelectAll: () => void;
  readonly handleUnselect: (item: QuestionnaireSection) => void;
  readonly handleUnselectAll: () => void;
}

const SectionList = ({
  data,
  selectedSections,
  handleSelect,
  handleSelectAll,
  handleUnselect,
  handleUnselectAll,
}: SectionListProps) => {
  const { classes } = useStyles();
  // const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.checked) {
  //     const items: string[] = [];

  //     selectedSections.forEach((section) => {
  //       section.items.forEach((item) => {
  //         items.push(item.id);
  //       });
  //     });

  //     setAcceptedItems(items);
  //   } else {
  //     setAcceptedItems([]);
  //   }
  // };

  return (
    <Box className={classes.container}>
      <SelectButton
        buttonText={'Select All'}
        checked={data.length === selectedSections.length}
        onClick={() => {
          if (data.length === selectedSections.length) handleUnselectAll();
          else handleSelectAll();
        }}
      />
      {data.map((section) => {
        const checked = selectedSections.some((el) => el.items[0].id === section.items[0].id);

        return (
          <SelectButton
            key={section.items[0].id}
            checked={checked}
            buttonText={section.title}
            onClick={() => {
              if (checked) handleUnselect(section);
              else handleSelect(section);
              // if (checked) {
              //   setSelectedSections((prev) => [
              //     ...prev.filter((item) => item.items[0].id !== section.items[0].id),
              //   ]);
              //   const arr = section.items.map((item) => item.id);
              //   setAcceptedItems((prev) => prev.filter((item) => !arr.includes(item)));
              // } else {
              //   setSelectedSections((prev) => [...prev, section]);
              // }
            }}
          />
        );
      })}

      {/* {selectedSections?.length !== 0 && (
        <Box display="flex" flexDirection="column" mt="15px">
          <FormControlLabel
            label={'Select All'}
            control={
              <Checkbox
                checked={numberOfItems !== 0 && acceptedItems.length === numberOfItems}
                indeterminate={acceptedItems.length > 0 && acceptedItems.length < numberOfItems}
                onChange={handleCheckbox}
                sx={{
                  color: '#FFF',
                  '&.Mui-checked': {
                    color: '#90CAF9',
                  },
                  '&.MuiCheckbox-indeterminate': {
                    color: '#90CAF9',
                  },
                }}
              />
            }
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
            {selectedSections.map((section) => {
              return (
                <IndeterminateCheckbox
                  key={section.items[0].id}
                  section={section}
                  acceptedItems={acceptedItems}
                  setAcceptedItems={setAcceptedItems}
                />
              );
            })}
          </Box>
        </Box>
      )} */}
    </Box>
  );
};

export default SectionList;
