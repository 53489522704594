import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';

import SaveIcon from '@mui/icons-material/Save';
import { Box, useTheme } from '@mui/material';

import { FieldHeadline } from './styled';
import { CreateUserFormSubmitProps } from './types';

import { createUser } from '../../api/usersApi/usersApi';
import FormTextField from '../../components/formFields/FormTextField';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import GoBackButton from '../../components/buttons/GoBackButton';
import FormSelectField from '../../components/formFields/FormSelectField';
import { ROLE_OPTIONS } from '../../constants/constants';
import { CREATE_USER_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { createUserFormSchema } from '../../helpers/formSchemas/formSchemas';
import useSnackBar from '../../hooks/useSnackBar';
import FormAutocompleteField from '../../components/formFields/FormAutocompleteField';
import { handleGetFirms, mapFirmsQueryResults } from '../CasesView/helpers';
import FormMaskedTextField from '../../components/formFields/FormMaskedTextField';

const UsersCreateFormContainer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setAlert } = useSnackBar();

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(createUserFormSchema),
    defaultValues: CREATE_USER_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = formMethods;

  const onSubmitForm = (values: CreateUserFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      phone: values.phone as string,
      role: values.role as string,
      firstName: values.firstName as string,
      lastName: values.lastName as string,
      email: values.email as string,
      firmId: Number(values.firm.id),
    };

    createUser(submitData)
      .then((res) => {
        setIsFormSubmitting(false);
        navigate(`/users/${res.data.id}/home`);

        setAlert((prev) => ({
          ...prev,
          message: 'User created successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => reset();

  return (
    <>
      <GoBackButton />
      <Box display="flex" bgcolor={theme.palette.primary.dark} width="100%">
        <FormProvider {...formMethods}>
          <form style={{ width: '100%', padding: '20px' }}>
            <Box display="flex" width="100%" gap="32px">
              <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                <FieldHeadline>Identity</FieldHeadline>
                <Box mt="20px">
                  <FormTextField name="firstName" label="First Name *" />
                </Box>
                <Box mt="20px">
                  <FormTextField name="lastName" label="Last Name *" />
                </Box>

                <FieldHeadline mt="50px">Firm</FieldHeadline>

                <Box mt="10px">
                  <FormAutocompleteField
                    name="firm"
                    label="Firm *"
                    optionsQuery={handleGetFirms}
                    mapQueryResults={mapFirmsQueryResults}
                  />
                </Box>

                <FieldHeadline mt="50px">Credentials</FieldHeadline>

                <Box mt="10px">
                  <FormTextField name="email" label="Email Address *" />
                </Box>
                <Box mt="20px">
                  <FormMaskedTextField mask="+19999999999" name="phone" label="Mobile Number *" />
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                <FieldHeadline>Role</FieldHeadline>

                <Box mt="20px">
                  <FormSelectField options={ROLE_OPTIONS} name="role" label="Role *" />
                </Box>
              </Box>
            </Box>

            <Box display="flex" justifyContent="space-between" mt="40px">
              <FormSubmitButton
                disabled={!isValid || isFormSubmitting}
                buttonIcon={<SaveIcon />}
                buttonText="Save"
                onClick={handleSubmit(onSubmitForm)}
              />
              {isDirty && <FormCancelButton onClick={handleCancelChanges} />}
            </Box>
          </form>
        </FormProvider>
      </Box>
    </>
  );
};

export default UsersCreateFormContainer;
