import { StatusProvisioning } from '../FirmsView/types';

export interface CustomerEditFormSubmitProps {
  id: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  phone: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
}

export type IntegrationProvisioningData = {
  ofac?: {
    status: string;
    reason: string | null;
  };
  provisioning: {
    error?: {
      status: string;
      reason: string | null;
    };
    status: keyof typeof OFAC_STATUSES | keyof typeof INTEGRATION_STASUSES;
  };
  cip?: {
    status: string;
    reason: string | null;
  };
  isOk: boolean;
};

export enum INTEGRATION_STASUSES {
  InProgress = 'In Progress',
  Error = 'Error',
  CriticalError = 'Critical Error',
  Completed = 'Completed',
}

export enum OFAC_STATUSES {
  PENDING_VERIFICATION = 'Pending Verification',
  VERIFIED = 'Verified',
  UNDER_REVIEW = 'Under Review',
  REJECTED = 'Rejected',
  IGNORED = 'Ignored',
}

export enum CIP_STATUSES {
  PENDING_VERIFICATION = 'Pending Verification',
  VERIFIED = 'Verified',
  UNVERIFIED = 'Unverified',
  UNDER_REVIEW = 'Under Review',
  REJECTED = 'Rejected',
  IGNORED = 'Ignored',
}

export interface CustomerBankgingWalletAccountData {
  accountNumber: string;
  routingNumber: string;
  balance: string;
  status: string;
}

export enum CLIENT_WALLET_ACCOUNT_STATUSES {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  CLOSURE_INITIATED = 'CLOSURE_INITIATED',
  CLOSED = 'CLOSED',
}

export interface CustomerBankingData {
  account: CustomerBankgingWalletAccountData;
  canStartIntegration: boolean;
  plaintiff: {
    id: string;
    firstName: string;
    lastName: string;
    middleName?: string;
  };
  integration: IntegrationProvisioningData;
}

export interface CustomerAccountActivationsState {
  email: {
    label: string;
    isActive: boolean;
  };
  ssn: {
    label: string;
    isActive: boolean;
  };
  dob: {
    label: string;
    isActive: boolean;
  };
}

export interface CustomerBankingAccountData {
  caseTypes: {
    id: number;
    name: string;
  }[];
  createdAt: string;
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
  isVerified: boolean;
  isVerifiedOrigin: boolean;
  canBeDeleted: boolean;
  bankingProvisionFailed: boolean;
  type: string;
}

export interface CustomerBankingAccountSelectedTableItem {
  createdAt: string;
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
  isVerified: string;
  isVerifiedOrigin: string;
  canBeDeleted: boolean;
}

export interface CustomerBankAccountSelectedTableItem {
  createdAt: string;
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
  isVerified: string;
  isVerifiedOrigin: string;
  canBeDeleted: boolean;
}

export interface CustomerBankShortAccountData {
  createdAt: string;
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
  type: string;
  isVerified: boolean;
  isAdminCreated?: boolean;
}

export interface CustomerBankAccountSubmitFormProps {
  accountNumber: string;
  routingNumber: string;
  name: string;
  type: string;
}

export interface CustomerBankingAccountPopupProps {
  isOpen: boolean;
  isLoading: boolean;
  headlineText: string;
  handleEditAccount: (data: CustomerBankAccountSubmitFormProps) => void;
  selectedTableItem: CustomerBankShortAccountData | null;
  handleResetFormState: () => void;
  handleClosePopup: () => void;
  fetchCustomerBankAccounts: () => void;
  isAddForm?: boolean;
}

export interface CustomerBankAccountPopupProps {
  isOpen: boolean;
  onPopupClose: () => void;
  children: React.ReactNode;
  selectedTableItem: CustomerBankShortAccountData | null;
  handleDisabledForm: (disabled: boolean) => void;
  isAddForm?: boolean;
  fetchCustomerBankAccounts: () => void;
  isAdminCreated?: boolean;
}

export interface AccountCustomerBankingInfoResponse {
  status: AccountStatus;
  statusReason: string;
  integration: {
    canStartProvisioning: boolean;
    provisioning: {
      status: StatusProvisioning;
      error?: { message: string | null; retryAvailable: boolean };
    };
    externalAccount?: {
      status: string;
      statusReason: string;
      bank: {
        name: string;
        routingNumber: string;
      };
      ewsValidation: {
        status: string;
        statusReason: string;
      };
      microDepositValidation?: {
        status: string;
        statusReason: string;
      };
    };
  };
}

export enum AccountStatus {
  Active = 'Active',
  Unknown = 'Unknown',
  Created = 'Created',
  Provisioning = 'Provisioning',
  ProvisioningFailed = 'Provisioning Failed',
  PendingVerification = 'Pending Verification',
  Inactive = 'Inactive',
  Blocked = 'Blocked',
}

export enum EWSValidationStatus {
  PENDING = 'PENDING',
  NOT_FOUND = 'NOT FOUND',
  CLOSED = 'CLOSED',
  HIGH_RISK = 'HIGH RISK',
  INVALID_DEBIT_ACCOUNT = 'INVALID DEBIT ACCOUNT',
  OPEN = 'OPEN',
  VALIDATED = 'VALIDATED',
}

export enum MicroDepositValidationStatus {
  PENDING = 'PENDING',
  AWAITING_VERIFICATION = 'AWAITING VERIFICATION',
  VERIFIED = 'VERIFIED',
  VERIFICATION_FAILED = 'VERIFICATION FAILED',
  NOT_REQUIRED = 'NOT REQUIRED',
}

export enum ExternalAccountStatus {
  INACTIVE = 'INACTIVE',
  PENDING_VERIFICATION = 'PENDING VERIFICATION',
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
}
