import { useEffect, useState } from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ArchiveIcon from '@mui/icons-material/Archive';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { Checklist } from '@mui/icons-material';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams, useOutletContext } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';

import {
  cancelDataRequest,
  deleteDataRequest,
  downloadDataRequestAnswers,
  sendDataRequest,
  updateDataRequest,
} from '../../../api/requestsApi/dataRequestsApi';
import FormTextField from '../../../components/formFields/FormTextField';
import FormSubmitButton from '../../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../../components/buttons/FormCancelButton';
import CommonButton from '../../../components/buttons/CommonButton';
import { dataEditFormSchema } from '../../../helpers/formSchemas/formSchemas';
import { DATA_EDIT_FORM_DEFAULT_VALUES } from '../../../helpers/formSchemas/formDefaultValues';
import FormCopyTextField from '../../../components/formFields/FormCopyTextField';
import FormLinkButton from '../../../components/buttons/FormLinkButton';
import usePageTitle from '../../../hooks/usePageTitle';
import useSnackBar from '../../../hooks/useSnackBar';
import { DataRequestData, EntityFlexibleRecord, ErrorData } from '../../../types';
import DocumentDownloader from '../../../components/DocumentsDownloader/DocumentDownloader';
import PreviewFormPopup from '../../../components/PreviewFormPopup';
import { saveCsv } from '../../../helpers/saveCsv';
import { mapQuestionnaireQueryResult } from '../../../components/QuestionnaireFormBuilder/helpers/mapQuestionnaireQueryResult';
import DataRequestFormBuilderPopup from '../../../components/DataRequestFormBuilderPopup';
import { QuestionnaireSubmitForm } from '../../QuestionnairesView/types';
import { mapQuestionnaireData } from '../../QuestionnairesView/helpers/mapQuestionnaireData';
import { DataEditFormSubmitProps } from '../types';
import { FieldHeadline } from '../styled';
import Loader from '../../../components/Loader';
import Popup from '../../../components/Popup';
import { POPUP_DESCRIPTIONS } from '../../../constants/popupDescriptions';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    background: theme.palette.primary.dark,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '16px',
    gap: '42px',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: '32px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '470px',
    gap: '32px',
  },
  infoBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  statisticsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  },
  headlineWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formButtonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  downloadWidgetWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const DataEditFormContainer = () => {
  const { classes } = useStyles();
  const { dataId } = useParams<string>();
  const { entityData } = useOutletContext<EntityFlexibleRecord>();
  const { setPageTitle } = usePageTitle();
  const { setAlert } = useSnackBar();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isFormBuilderPopupOpen, setIsFormBuilderPopupOpen] = useState(false);
  const [isPreviewPopupOpen, setIsPreviewPopupOpen] = useState(false);
  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const formMethods = useForm<DataEditFormSubmitProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(dataEditFormSchema),
    defaultValues: DATA_EDIT_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
    watch,
    setValue,
  } = formMethods;

  const form = watch('form');
  const answers = watch('answers');
  const status = watch('status');
  const caseId = watch('case.id');
  const firmId = watch('firm.id');
  const plaintiffId = watch('plaintiff.id');
  const message = watch('message');

  const isDraft = status === 'Draft';
  const isCompleted = status === 'Completed';
  const isCancelable = entityData?.status === 'Unopened' || entityData?.status === 'Opened';

  const onSubmitForm = (values: DataEditFormSubmitProps) => {
    if (!dataId) return;

    setIsFormSubmitting(true);

    const data = {
      form: values.form ? mapQuestionnaireData(values.form) : null,
      name: values.name,
      ...(values.message && { message: values.message }),
      ...(values.notes && { notes: values.notes }),
    };

    updateDataRequest(dataId, data)
      .then((response) => {
        if (response.status === 200) {
          navigate('/data-requests');

          setAlert((prev) => ({
            ...prev,
            message: 'Data request updated successfully.',
            type: 'success',
          }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      })
      .finally(() => setIsFormSubmitting(false));
  };

  const handleCancelChanges = () => reset();

  const handleSendDataRequest = () => {
    if (!dataId) return;

    setIsFormSubmitting(true);

    sendDataRequest(dataId)
      .then((response) => {
        if (response.status === 200) {
          navigate(-1);

          setAlert((prev) => ({
            ...prev,
            message: 'Data request has been sent successfully.',
            type: 'success',
          }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      })
      .finally(() => setIsFormSubmitting(false));
  };

  const handleCancelDataRequest = () => {
    if (!dataId) return;

    setIsFormSubmitting(true);

    cancelDataRequest(dataId)
      .then((response) => {
        if (response.status === 200) {
          navigate('/data-requests');

          setAlert((prev) => ({
            ...prev,
            message: 'Data request cancelled successfully.',
            type: 'success',
          }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      })
      .finally(() => setIsFormSubmitting(false));
  };

  const handleDeleteDataRequest = () => {
    deleteDataRequest(dataId as string)
      .then(() => {
        navigate(-1);
        setAlert((prev) => ({
          ...prev,
          message: 'Data request deleted successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleRenderCancelDeleteButton = () => {
    if (entityData?.status) {
      if (isDraft) {
        return (
          <CommonButton
            disabled={!isDraft}
            type="error"
            buttonText="delete"
            buttonIcon={<CancelIcon />}
            onButtonClick={() => setIsDeletePopupOpen(true)}
          />
        );
      } else {
        return (
          <CommonButton
            disabled={!isCancelable}
            type="error"
            buttonText="cancel"
            buttonIcon={<CancelIcon />}
            onButtonClick={() => setIsCancelPopupOpen(true)}
          />
        );
      }
    }
  };

  const onSaveForm = (values: QuestionnaireSubmitForm) => {
    setValue('form', values, { shouldDirty: true });
    setIsFormBuilderPopupOpen(false);
  };

  const handleDownloadClick = () => {
    if (!dataId) return;

    downloadDataRequestAnswers(dataId)
      .then((response) => {
        return saveCsv(response, `data_request_${dataId}`);
      })
      .catch((error) => {
        console.error('Cannot download file:', error);

        setAlert((prev) => ({
          ...prev,
          message: 'Cannot download file.',
          type: 'error',
        }));
      });
  };

  useEffect(() => {
    if (entityData) {
      const data: DataRequestData = entityData as DataRequestData;
      let mappedForm: QuestionnaireSubmitForm | null = null;

      if (data.form) mappedForm = mapQuestionnaireQueryResult(data.form);

      reset({
        form: mappedForm,
        answers: data.answers,
        status: data.status,
        id: data.id,
        name: data.name,
        case: data.case,
        firm: data.firm,
        plaintiff: {
          ...data.client,
          middleName: data.client.middleName || '',
        },
        plaintiffFullName: `${data.client.firstName} ${
          data.client.middleName ? `${data.client.middleName} ` : ''
        }${data.client.lastName}`,
        notes: data.notes || '',
        message: data.message || '',
        formItemAnswersCount: data.formItemAnswersCount,
        formItemsCount: data.formItemsCount,
      });

      setPageTitle && setPageTitle('');
      setIsLoading(false);
    }

    return () => setPageTitle && setPageTitle('');
  }, [entityData]);

  if (isLoading) {
    return <Loader colorType="warning" />;
  }

  return (
    <Box className={classes.container}>
      <FormProvider {...formMethods}>
        <form className={classes.formContainer}>
          <Box className={classes.contentWrapper}>
            <Box className={classes.section}>
              <Box className={classes.infoBlock}>
                <FieldHeadline>{'Identity'}</FieldHeadline>
                <FormCopyTextField entityName="Data request" name="id" label="Id" isReadOnly />
              </Box>

              <Box className={classes.infoBlock}>
                <Box className={classes.headlineWrapper}>
                  <FieldHeadline>{'Case'}</FieldHeadline>
                  <FormLinkButton path={`/cases/${caseId}/home`} />
                </Box>
                <FormTextField name="case.referenceId" label="Reference Id" disabled />
                <FormTextField name="case.type.name" label="Type" disabled />
                <FormTextField name="case.name" label="System Name" disabled />
              </Box>

              <Box className={classes.infoBlock}>
                <Box className={classes.headlineWrapper}>
                  <FieldHeadline>{'Lead Counsel'}</FieldHeadline>
                  <FormLinkButton path={`/firms/${firmId}/home`} />
                </Box>
                <FormTextField name="firm.name" label="Firm" disabled />
              </Box>

              <Box className={classes.infoBlock}>
                <Box className={classes.headlineWrapper}>
                  <FieldHeadline>{'Client'}</FieldHeadline>
                  <FormLinkButton path={`/clients/${plaintiffId}/home`} />
                </Box>
                <FormTextField name="plaintiffFullName" label="Client" disabled />
              </Box>
            </Box>

            <Box className={classes.section}>
              {isCompleted && dataId && (
                <Box className={classes.downloadWidgetWrapper}>
                  <DocumentDownloader
                    title="Data File"
                    fileType={'csv'}
                    fileName={`data_request_${dataId}`}
                    onClick={handleDownloadClick}
                  />
                </Box>
              )}

              <Box className={classes.infoBlock}>
                <FieldHeadline>{'Info'}</FieldHeadline>
                <FormTextField name={'name'} label="Name *" disabled={!isDraft} />
                <FormTextField name={'notes'} label="Notes" disabled={!isDraft} isMultiline />
                <FormTextField name={'message'} label="Message" disabled={!isDraft} isMultiline />
              </Box>

              <Box className={classes.infoBlock}>
                <FieldHeadline>{'Statistics'}</FieldHeadline>
                <Box className={classes.statisticsContainer}>
                  <FormTextField name="formItemAnswersCount" label="Answered Questions" disabled />
                  <FormTextField name="formItemsCount" label="Total Questions" disabled />
                </Box>
              </Box>
            </Box>

            <Box className={classes.section}>
              <Box className={classes.infoBlock}>
                <FieldHeadline>{'Actions'}</FieldHeadline>
                <CommonButton
                  disabled={isDirty || !isDraft || !isValid || !form}
                  type="warning"
                  buttonText="Send Data Request"
                  buttonIcon={<ArchiveIcon />}
                  onButtonClick={handleSendDataRequest}
                />
                {handleRenderCancelDeleteButton()}
                <CommonButton
                  disabled
                  type="error"
                  buttonText="Archive"
                  buttonIcon={<ArchiveIcon />}
                />
                <CommonButton
                  disabled={!isDraft}
                  type="info"
                  buttonText="Edit Form"
                  buttonIcon={<EditIcon />}
                  onButtonClick={() => setIsFormBuilderPopupOpen(true)}
                />
                <CommonButton
                  type="info"
                  buttonText={isDraft ? 'Preview Form' : 'Preview Answers'}
                  buttonIcon={<Checklist />}
                  onButtonClick={() => setIsPreviewPopupOpen(true)}
                  disabled={!form}
                />
              </Box>
            </Box>
          </Box>

          <Box className={classes.formButtonsWrapper}>
            <FormSubmitButton
              disabled={!isValid || !isDirty || !isDraft || isFormSubmitting}
              buttonIcon={<SaveIcon />}
              buttonText="Save"
              onClick={handleSubmit(onSubmitForm)}
            />
            {isDirty && <FormCancelButton onClick={handleCancelChanges} />}
          </Box>
        </form>
      </FormProvider>

      <DataRequestFormBuilderPopup
        isOpen={isFormBuilderPopupOpen}
        data={form}
        firmId={firmId}
        onMainButtonClick={onSaveForm}
        onSecondaryButtonClick={() => setIsFormBuilderPopupOpen(false)}
      />
      <PreviewFormPopup
        isOpen={isPreviewPopupOpen}
        title={isDraft ? 'Preview Form' : 'Preview Answers'}
        data={JSON.stringify({
          form: form ? mapQuestionnaireData(form) : null,
          answers,
          message,
          readonly: true,
        })}
        onSecondaryButtonClick={() => setIsPreviewPopupOpen(false)}
      />
      <Popup
        isOpen={isCancelPopupOpen}
        headlineText={POPUP_DESCRIPTIONS.dataRequestCancel.headlineText}
        contentText={POPUP_DESCRIPTIONS.dataRequestCancel.contentText}
        onMainButtonClick={handleCancelDataRequest}
        onSecondaryButtonClick={() => setIsCancelPopupOpen(false)}
      />
      <Popup
        isOpen={isDeletePopupOpen}
        headlineText={POPUP_DESCRIPTIONS.dataRequestDelete.headlineText}
        contentText={POPUP_DESCRIPTIONS.dataRequestDelete.contentText}
        onMainButtonClick={handleDeleteDataRequest}
        onSecondaryButtonClick={() => setIsDeletePopupOpen(false)}
      />
    </Box>
  );
};

export default DataEditFormContainer;
