import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import SaveIcon from '@mui/icons-material/Save';
import { Box, useTheme } from '@mui/material';

import { FirmsFormSubmitProps } from './types';
import { FieldHeadline, FieldsGroupWrapper } from './styled';

import FormCancelButton from '../../components/buttons/FormCancelButton';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import GoBackButton from '../../components/buttons/GoBackButton';
import FormTextField from '../../components/formFields/FormTextField';
import FormSelectField from '../../components/formFields/FormSelectField';
import FormToggleField from '../../components/formFields/FormToggleField';
import { CLIENT_ACTIVATION_TRIGGER_OPTIONS, US_STATES_LIST } from '../../constants/constants';
import { createFirmFormSchema } from '../../helpers/formSchemas/formSchemas';
import { CREATE_FIRM_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { createFirmRecord, uploadFirmLogo } from '../../api/firmsApi/firmsApi';
import useSnackBar from '../../hooks/useSnackBar';
import { createUser } from '../../api/usersApi/usersApi';
import DropzoneField from '../../components/formFields/DropzoneField';
import FormMaskedTextField from '../../components/formFields/FormMaskedTextField';

const FirmsCreateFormContainer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setAlert } = useSnackBar();

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [files, setFiles] = useState<File[] | [] | string[]>([]);
  const [fileError, setFileError] = useState<boolean>(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(createFirmFormSchema),
    defaultValues: CREATE_FIRM_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    watch,
    resetField,
    formState: { isDirty, isValid },
  } = formMethods;

  const clientTriggerToggleValue = watch('isClientTriggerActive');
  const docusignFieldsToggleValue = watch('docusignKeys.isDocusignToggleActive');
  const firmUserToggleValue = watch('firmUser.isFirmUserToggleActive');

  const onSubmitForm = (values: FirmsFormSubmitProps) => {
    setIsFormSubmitting(true);

    const isFirmUserFullFilled = Object.values(values.firmUser).every(
      (objValue) => String(objValue).length >= 1,
    );
    const isDocusignFullFilled = Object.values(values.docusignKeys).every(
      (objValue) => String(objValue).length >= 1,
    );

    const submitData = {
      ...(values.address2?.length && { address2: values.address2 }),
      // ...(values.brandColor?.length && { brandColor: values.brandColor }),
      state: values.state,
      name: values.name,
      tin: values.tin,
      address1: values.address1,
      city: values.city,
      zip: values.zip,
      zendeskBrandName: values.zendeskBrandName,
      ...(isDocusignFullFilled &&
        values.docusignKeys.isDocusignToggleActive && {
          docusignKeys: {
            accountId: values.docusignKeys.accountId,
            integrationKey: values.docusignKeys.integrationKey,
            userId: values.docusignKeys.userId,
            rsaPrivateKey: values.docusignKeys.rsaPrivateKey,
            connectKey: values.docusignKeys.connectKey,
            baseUri: values.docusignKeys.baseUri,
          },
        }),
    };

    createFirmRecord(submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);

          if (files.length) {
            uploadFirmLogo(res.data.id, files[0] as File).catch((err) => {
              setAlert((prev) => ({
                ...prev,
                message: err.response.data.message,
                type: 'error',
              }));
            });
          }

          if (isFirmUserFullFilled) {
            const userData = {
              phone: values.firmUser.phone as string,
              role: 'FirmAdmins',
              firstName: values.firmUser.firstName as string,
              lastName: values.firmUser.lastName as string,
              email: values.firmUser.email as string,
              firmId: Number(res.data.id),
            };
            createUser(userData).catch((err) => {
              setAlert((prev) => ({
                ...prev,
                message: err.response.data.message,
                type: 'error',
              }));
            });
          }

          setAlert((prev) => ({
            ...prev,
            message: 'Firm created successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      })
      .then(() => {
        navigate('/firms');
      });
  };

  const handleCancelChanges = () => {
    reset();
    setFiles([]);
    setFileError(false);
  };

  useEffect(() => {
    !docusignFieldsToggleValue && resetField('docusignKeys');
    !firmUserToggleValue && resetField('firmUser');
  }, [docusignFieldsToggleValue, firmUserToggleValue]);

  return (
    <>
      <GoBackButton />
      <Box display="flex" bgcolor={theme.palette.primary.dark} width="100%">
        <FormProvider {...formMethods}>
          <form style={{ width: '100%', padding: '16px' }}>
            <Box display="flex" width="100%" gap="32px">
              <FieldsGroupWrapper>
                <FieldHeadline>Identity</FieldHeadline>

                <Box mt="10px">
                  <FormTextField name="name" label="Name *" />
                </Box>
                <Box mt="20px">
                  <FormMaskedTextField mask="99-9999999" name="tin" label="TIN *" />
                </Box>

                <FieldHeadline mt="50px">Address</FieldHeadline>

                <Box mt="10px">
                  <FormTextField name="address1" label="Address Line 1 *" />
                </Box>
                <Box mt="20px">
                  <FormTextField name="address2" label="Address Line 2" />
                </Box>

                <Box mt="20px" display="flex" gap="20px" flexDirection="column">
                  <FormTextField name="city" label="City *" />
                  <FormSelectField options={US_STATES_LIST} name="state" label="State *" />
                  <FormTextField name="zip" label="Zip *" />
                </Box>
              </FieldsGroupWrapper>

              <FieldsGroupWrapper>
                <FieldHeadline>Branding</FieldHeadline>

                <Box>
                  <DropzoneField
                    name="logoImageId"
                    setFiles={setFiles}
                    files={files}
                    setFileError={setFileError}
                    fileError={fileError}
                  />
                </Box>
                {/* 
                <Box mt="20px">
                  <FormTextField name="brandColor" label="Primary Color" />
                </Box> */}

                <FieldHeadline mt="50px">Support</FieldHeadline>

                <Box mt="10px">
                  <FormTextField name="zendeskBrandName" label="Zendesk Brand Name *" />
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center" mt="51px">
                  <FieldHeadline>DocuSign</FieldHeadline>
                  <FormToggleField
                    name="docusignKeys.isDocusignToggleActive"
                    label={docusignFieldsToggleValue ? 'Enabled' : 'Disabled'}
                  />
                </Box>

                <Box mt="17px">
                  <FormTextField
                    name="docusignKeys.accountId"
                    label="Account Id"
                    disabled={!docusignFieldsToggleValue}
                  />
                </Box>

                <Box mt="17px">
                  <FormTextField
                    name="docusignKeys.baseUri"
                    label="Base URI"
                    disabled={!docusignFieldsToggleValue}
                  />
                </Box>

                <Box mt="17px">
                  <FormTextField
                    name="docusignKeys.userId"
                    label="User Id"
                    disabled={!docusignFieldsToggleValue}
                  />
                </Box>

                <Box mt="17px">
                  <FormTextField
                    name="docusignKeys.connectKey"
                    label="Connect Key"
                    disabled={!docusignFieldsToggleValue}
                  />
                </Box>

                <Box mt="17px">
                  <FormTextField
                    name="docusignKeys.integrationKey"
                    label="Integration Key"
                    disabled={!docusignFieldsToggleValue}
                  />
                </Box>

                <Box mt="17px">
                  <FormTextField
                    isMultiline
                    name="docusignKeys.rsaPrivateKey"
                    label="RSA Private Key"
                    disabled={!docusignFieldsToggleValue}
                  />
                </Box>
              </FieldsGroupWrapper>

              <FieldsGroupWrapper>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <FieldHeadline>Client Activation Triggers</FieldHeadline>
                  <FormToggleField disabled name="isClientTriggerActive" label="Enabled" />
                </Box>

                <Box mt="10px">
                  <FormSelectField
                    options={CLIENT_ACTIVATION_TRIGGER_OPTIONS.sendAppInviteUpon}
                    name="sendAppInviteTrigger"
                    label="Send App Invite Upon *"
                    disabled={!clientTriggerToggleValue}
                  />
                </Box>

                <Box mt="20px">
                  <FormSelectField
                    options={CLIENT_ACTIVATION_TRIGGER_OPTIONS.createWalletUpon}
                    name="createWalletTrigger"
                    label="Create Wallet Upon *"
                    disabled={!clientTriggerToggleValue}
                  />
                </Box>

                <Box mt="20px">
                  <FormSelectField
                    options={CLIENT_ACTIVATION_TRIGGER_OPTIONS.issueVirtualCardUpon}
                    name="issueVirtualCardTrigger"
                    label="Issue Virtual Card Upon *"
                    disabled={!clientTriggerToggleValue}
                  />
                </Box>

                <Box mt="20px">
                  <FormSelectField
                    options={CLIENT_ACTIVATION_TRIGGER_OPTIONS.issuePhysicalCardUpon}
                    name="issuePhysicalCardTrigger"
                    label="Issue Physical Card Upon *"
                    disabled={!clientTriggerToggleValue}
                  />
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center" mt="51px">
                  <FieldHeadline>User</FieldHeadline>
                  <FormToggleField
                    name="firmUser.isFirmUserToggleActive"
                    label={firmUserToggleValue ? 'Enabled' : 'Disabled'}
                  />
                </Box>

                <Box mt="10px">
                  <FormTextField
                    name="firmUser.firstName"
                    label="First Name"
                    disabled={!firmUserToggleValue}
                  />
                </Box>
                <Box mt="20px">
                  <FormTextField
                    name="firmUser.lastName"
                    label="Last Name"
                    disabled={!firmUserToggleValue}
                  />
                </Box>
                <Box mt="20px">
                  <FormTextField
                    name="firmUser.email"
                    label="Email Address"
                    disabled={!firmUserToggleValue}
                  />
                </Box>
                <Box mt="20px">
                  <FormMaskedTextField
                    mask="+19999999999"
                    name="firmUser.phone"
                    label="Mobile Number"
                    disabled={!firmUserToggleValue}
                  />
                </Box>
              </FieldsGroupWrapper>
            </Box>

            <Box display="flex" justifyContent="space-between" mt="40px">
              <FormSubmitButton
                disabled={!isValid || fileError || isFormSubmitting}
                buttonIcon={<SaveIcon />}
                buttonText="Save"
                onClick={handleSubmit(onSubmitForm as () => void)}
              />
              {(isDirty || !!files.length || fileError) && (
                <FormCancelButton onClick={handleCancelChanges} />
              )}
            </Box>
          </form>
        </FormProvider>
      </Box>
    </>
  );
};

export default FirmsCreateFormContainer;
