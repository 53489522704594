import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import UsersTableDataRow from './UsersTableDataRow';

import { getUsers } from '../../api/usersApi/usersApi';
import TableContainer from '../../components/Table/TableContainer';
import Loader from '../../components/Loader';
import { LAWYERS_LIST_TABLE_LABELS } from '../../constants/tableLabels';
import usePagination from '../../hooks/usePagination';
import { ActiveSortField, FilterTypes, User, ViewProps } from '../../types';
import TableFilters from '../../components/TableFilters/TableFilters';
import TableActions from '../../components/Table/TableActions';
import { downloadCsv } from '../../helpers/downloadCsv';
import useSnackBar from '../../hooks/useSnackBar';
import { handleSortTableData } from '../../helpers/handleSortTableData';

const UsersView = ({ isRelatedToOtherEntity }: ViewProps) => {
  const params = useParams();

  const { pageNumber, rowsPerPage, setTotalCount } = usePagination();
  const { setAlert } = useSnackBar();

  const [lawyersList, setLawyersList] = useState<User[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [filters, setFilters] = useState<FilterTypes>({
    firmId: params?.firmId || '',
    clientId: '',
    defendantId: '',
  });
  const [match, setMatch] = useState<string>('');
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);
  const sortableFields = {
    name: {
      order: '',
      fieldName: 'name',
    },
    createdAt: {
      order: '',
      fieldName: 'createdAt',
    },
  };

  const hasParams = !!Object.entries(params).length;

  useEffect(() => {
    getUsers({
      page: pageNumber,
      size: rowsPerPage,
      count: true,
      match: match,
      filters: { ...filters, ...(hasParams && params) },
      activeSortField,
    }).then((res) => {
      if (res.data) {
        setTotalCount && setTotalCount(res.data.totalCount || 0);
        setLawyersList(res.data.resultSet);
        setIsDataLoading(false);
      }
    });
  }, [pageNumber, rowsPerPage, filters, match, activeSortField]);

  const exportList = () => {
    downloadCsv(
      {
        page: pageNumber,
        size: rowsPerPage,
        count: true,
        match: match,
        filters: filters,
      },
      '/v1/firm-users',
      'Users_List',
    ).then((res) => {
      if (res?.data) {
        setAlert({
          message: 'Users exported successfully.',
          type: 'success',
        });
      }
    });
  };

  return (
    <>
      {isDataLoading && !lawyersList.length ? (
        <Box position="absolute" top="50%" left="50%">
          <Loader colorType="warning" />
        </Box>
      ) : (
        <>
          <TableActions
            hasParams={hasParams}
            createLink="/users/create-user"
            exportList={exportList}
          />

          <Grid container>
            <TableFilters
              filters={filters}
              setFilters={setFilters}
              setMatch={setMatch}
              timeFilter={'CREATED'}
              // statusFilter={['Active', 'Archived']}
              firms
              rolesFilter={['FirmAdmins']}
            />
            <TableContainer
              isNoData={!isDataLoading && lawyersList.length === 0}
              headerLabels={isRelatedToOtherEntity || LAWYERS_LIST_TABLE_LABELS}
              sortableFields={sortableFields}
              activeSortField={activeSortField}
              setActiveSortField={setActiveSortField}
              handleSortTableData={handleSortTableData}
            >
              <UsersTableDataRow
                tableData={lawyersList}
                isRelatedToOtherEntity={!!isRelatedToOtherEntity}
              />
            </TableContainer>
          </Grid>
        </>
      )}
      <Outlet />
    </>
  );
};

export default UsersView;
