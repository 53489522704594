import * as React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { QuestionnaireSection, QuestionnaireItem } from '../../../types';

interface IIndeterminateCheckboxProps {
  section: QuestionnaireSection;
  acceptedItems: string[];
  setAcceptedItems: React.Dispatch<React.SetStateAction<string[]>>;
}

const sx = {
  color: '#FFF',
  '&.Mui-checked': {
    color: '#90CAF9',
  },
  '&.MuiCheckbox-indeterminate': {
    color: '#90CAF9',
  },
};

const IndeterminateCheckbox = ({
  section,
  acceptedItems,
  setAcceptedItems,
}: IIndeterminateCheckboxProps) => {
  const handleParentCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const accepted = section.items
        .filter((item) => {
          return !acceptedItems.includes(item.id);
        })
        .map((item) => {
          return item.id;
        });

      setAcceptedItems((prev) => [...prev, ...accepted]);
    } else {
      const arr = section.items.map((item) => item.id);
      setAcceptedItems((prev) => prev.filter((item) => !arr.includes(item)));
    }
  };

  const handleItemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const index = acceptedItems.indexOf(event.target.value);
    if (index === -1) {
      setAcceptedItems([...acceptedItems, event.target.value]);
    } else {
      setAcceptedItems(acceptedItems.filter((item) => item !== event.target.value));
    }
  };

  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      {section.items.map((item) => {
        return (
          <FormControlLabel
            key={item.id}
            label={item.systemName}
            control={
              <Checkbox
                value={item.id}
                checked={acceptedItems.includes(item.id)}
                onChange={handleItemChange}
                sx={sx}
              />
            }
          />
        );
      })}
    </Box>
  );

  const getCheckedValue = (arr: string[], target: QuestionnaireItem[]) =>
    target.every((el) => arr.includes(el.id));

  const checkIndeterminate = () => {
    const arr = section.items.filter((item) => acceptedItems.includes(item.id));
    return arr.length > 0 && arr.length < section.items.length;
  };

  return (
    <div>
      <FormControlLabel
        label={section.title}
        control={
          <Checkbox
            checked={getCheckedValue(acceptedItems, section.items)}
            indeterminate={checkIndeterminate()}
            onChange={handleParentCheckbox}
            sx={sx}
          />
        }
      />
      {children}
    </div>
  );
};

export default IndeterminateCheckbox;
