import { makeStyles } from 'tss-react/mui';

import { Box } from '@mui/material';

import FormTextField from '../../../../../components/formFields/FormTextField';
// import FormCheckboxField from '../../../../../components/formFields/FormCheckboxField';
import FormMaskedTextField from '../../../../../components/formFields/FormMaskedTextField';

const useStyles = makeStyles()(() => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  formFieldsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    alignItems: 'center',
  },
  checkboxWrapper: {
    marginTop: '14px',

    '& label': {
      marginRight: 0,
    },
  },
  // withdrawalInfoContainer: {
  //   background: 'rgba(244, 67, 54, 0.12)',
  //   padding: '16px',
  //   margin: '16px 0',
  // },
}));

const FirmUnrestrictedAccountForm = ({ disabled = false }: { disabled?: boolean }) => {
  const styles = useStyles();

  return (
    <Box className={styles.classes.formContainer}>
      <Box className={styles.classes.formFieldsWrapper}>
        <FormTextField name="name" label="Name" disabled={disabled} />
        <FormTextField name="accountNumber" label="Account Number" disabled={disabled} />
        <FormMaskedTextField
          mask="999999999"
          name="routingNumber"
          label="Routing Number"
          disabled={disabled}
        />
        {/* <Box className={styles.classes.checkboxWrapper}>
          <FormCheckboxField name="isVerified" label="Verified" />
        </Box> */}
      </Box>
      {/* <Box className={styles.classes.withdrawalInfoContainer}>
        <FormCheckboxField
          name="shouldWithdrawal"
          label="Authorize withdrawal of service fees from this account"
          isCaution
        />
      </Box> */}
    </Box>
  );
};

export default FirmUnrestrictedAccountForm;
