import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import FormSnippetsEditSection from './FormSnippetsEditSection';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    gap: '8px',
  },
  headline: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '23px',
  },
}));

const GlobalFormSnippetsView = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.headline}>{'Global Form Snippets'}</Typography>
      <FormSnippetsEditSection />
    </Box>
  );
};

export default GlobalFormSnippetsView;
