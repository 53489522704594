import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { TableCell, TableRow, Theme } from '@mui/material';

// import { formatDate } from '../../../helpers/formatDate';
import { DataRequestShortData } from '../../../types';
import { formatDate } from '../../../helpers/formatDate';

const useStyles = makeStyles()((theme: Theme) => ({
  styledLink: {
    textDecoration: 'none',
    color: theme.palette.info.main,
  },
}));

interface DataTableDataRowProps {
  tableData: DataRequestShortData[];
  isRelatedToOtherEntity?: { accessor: string; isSortable: boolean; label: string }[];
}

const DataTableDataRow = ({ tableData, isRelatedToOtherEntity }: DataTableDataRowProps) => {
  const styles = useStyles();

  const tableLabelsObjectToFlat = isRelatedToOtherEntity?.map((labelItem) => labelItem.label);

  const formattedFirmsList =
    tableData &&
    tableData.map((data: DataRequestShortData) => ({
      name: (
        <Link className={styles.classes.styledLink} to={`/data-requests/${String(data.id)}/home`}>
          {`${data.client.firstName} ${data.client.middleName || ''} ${data.client.lastName}`}
        </Link>
      ),
      ...((tableLabelsObjectToFlat?.includes('Lead Counsel') || !isRelatedToOtherEntity) && {
        firm: data.firm.name,
      }),
      sentAt: data.sentAt ? formatDate(data.sentAt as string) : '-',
      caseType: data.case.type.name,
      items: data.formItemsCount,
      status: data.status,
      completion: `${data.formItemAnswersCount}/${data.formItemsCount}`,
      ...((tableLabelsObjectToFlat?.includes('Completion Date') || !isRelatedToOtherEntity) && {
        returnDate: data.completedAt ? formatDate(data.completedAt) : '-',
      }),
      ...((tableLabelsObjectToFlat?.includes('Completion Time') || !isRelatedToOtherEntity) && {
        daysToReturn: '-',
      }),
      fileId: '-',
    }));

  return (
    <>
      {formattedFirmsList.map((tableItem, idx) => (
        <TableRow key={idx}>
          {Object.values(tableItem).map((item, idx) => (
            <TableCell key={idx}>{item}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default DataTableDataRow;
