import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { Box } from '@mui/material';

import TabsPanel from '../../components/TabsPanel';
import { setCasesAccountTabs } from '../../constants/tabRoutes';
import GoBackButton from '../../components/buttons/GoBackButton';
import { getCaseRecord } from '../../api/casesApi/casesApi';
import { FIRM_ROLES_OPTIONS } from '../../constants/constants';

const CaseAccountView = () => {
  const { caseId } = useParams();
  const [entityRecordData, setEntityRecordData] = useState<{ [key: string]: unknown }>();

  useEffect(() => {
    if (!caseId) return;

    getCaseRecord(Number(caseId)).then((res) => {
      setEntityRecordData(res.data);

      const defendants = res.data.defendants.map((defendant) => ({
        defendantId: { id: defendant.id, label: defendant.name },
        gross: defendant.grossSettlement ? defendant.grossSettlement : '',
      }));

      const mappedSecondaryFirms = res.data?.secondaryFirms?.map((firm) => ({
        id: {
          id: firm.id,
          label: firm.name,
        },
        role: firm.role,
      }));

      const secondaryFirms = [
        {
          id: {
            id: res.data.firm.id,
            label: res.data.firm.name,
          },
          role: FIRM_ROLES_OPTIONS[0].id,
        },
        ...mappedSecondaryFirms,
      ];

      setEntityRecordData({
        id: res.data.id,
        referenceId: res.data.referenceId,
        caseTypeCategory: res.data.type.category,
        type: {
          id: res.data.type.id,
          label: res.data.type.name,
        },
        trustAccount: {
          id: res.data?.trustAccount?.id.toString() || '',
          label: res.data?.trustAccount?.name || '',
        },
        clientId: {
          id: res.data.client.id,
          label: `${res.data.client.firstName} ${res.data.client.lastName}`,
        },
        defendant: defendants,
        sol: res.data.sol ? moment(res.data.sol) : null,
        filedAt: res.data.filedAt ? moment(res.data.filedAt) : null,
        name: res.data.name,
        status: res.data.status,
        secondaryFirms,
        canBeAbandoned: res.data.canBeAbandoned,
        paymentStarted: res.data.paymentStarted,
      });
    });
  }, [caseId]);

  return (
    <>
      <GoBackButton />
      <Box display="flex" width="100%">
        <TabsPanel
          tabsList={setCasesAccountTabs(entityRecordData && entityRecordData.status === 'Draft')}
          locationSlicePosition={3}
          contextData={entityRecordData}
        />
      </Box>
    </>
  );
};

export default CaseAccountView;
