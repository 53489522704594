import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import useSnackBar from '../../hooks/useSnackBar';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { editClientSSNDoBFormSchema } from '../../helpers/formSchemas/formSchemas';
import { EDIT_CLIENT_SSN_DOB_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { ClientUpdateSSNDobFormSubmitProps } from './types';
import { updateClientPersonalInformation } from '../../api/clientsApi/clientsApi';
import FormDateField from '../../components/formFields/FormDateField';
import moment from 'moment';
import FormMaskedTextField from '../../components/formFields/FormMaskedTextField';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.light,
      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))',
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.secondary.main,
    },
  },
  dialogActions: {
    padding: '16px 8px',
  },
  dialogButton: {
    color: theme.palette.info.main,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
    },

    '&:hover': {
      background: 'rgba(144, 202, 249, 0.08)',
    },
  },
}));

interface ClientUpdateSSNDobFormProps {
  isOpen: boolean;
  onSecondaryButtonClick: () => void;
  formData: { ssn: string; birthdate?: Date | null };
  clientId: number;
  handleUpdateNewSSNDoBvalues: (newValues: { ssn: string; birthdate?: Date | null }) => void;
}

const ClientUpdateSSNDobForm = ({
  isOpen,
  onSecondaryButtonClick,
  formData,
  clientId,
  handleUpdateNewSSNDoBvalues,
}: ClientUpdateSSNDobFormProps) => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();
  const [isFormSubmitting, setIsFormSumbitting] = useState<boolean>(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(editClientSSNDoBFormSchema),
    defaultValues: EDIT_CLIENT_SSN_DOB_FORM_DEFAULT_VALUES,
  });

  const {
    formState: { isValid },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = formMethods;

  const birthdateFieldValue = watch('birthdate');

  const onSubmit = (values: ClientUpdateSSNDobFormSubmitProps) => {
    setIsFormSumbitting(true);

    const submitData = {
      ssn: values.ssn,
      birthdate: moment(values.birthdate).toISOString(true),
    };

    updateClientPersonalInformation(clientId, submitData)
      .then((res) => {
        if (res.status === 204) {
          setAlert((prev) => ({
            ...prev,
            message: 'SSN and Date of Birth updated successfully.',
            type: 'success',
          }));
          setIsFormSumbitting(false);
          handleUpdateNewSSNDoBvalues(values);
          handleCancelButtonClick();
        }
      })
      .catch((err) => {
        setIsFormSumbitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelButtonClick = () => {
    onSecondaryButtonClick();
    reset(EDIT_CLIENT_SSN_DOB_FORM_DEFAULT_VALUES);
  };

  useEffect(() => {
    reset({
      ssn: formData.ssn,
      birthdate: formData?.birthdate,
    });
  }, [formData]);

  return (
    <Dialog
      className={classes.dialogContainer}
      open={isOpen}
      onClose={handleCancelButtonClick}
      fullWidth
      maxWidth={'xs'}
    >
      <DialogTitle>Edit Personal Information</DialogTitle>
      <DialogContent>
        <FormProvider {...formMethods}>
          <form>
            <Box mt="16px">
              <FormDateField
                name="birthdate"
                label="Date of Birth *"
                maxDate={moment()}
                onClearField={() => setValue('birthdate', null)}
              />
            </Box>
            <Box mt="24px">
              <FormMaskedTextField name="ssn" label="Social Security Number *" mask="999-99-9999" />
            </Box>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.dialogButton}
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid || isFormSubmitting || birthdateFieldValue === null}
        >
          {'Save'}
        </Button>
        <Button className={classes.dialogButton} onClick={onSecondaryButtonClick}>
          {'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClientUpdateSSNDobForm;
