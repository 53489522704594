import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import clsx from 'clsx';

import { getFormSnippets, getGlobalFormSnippets } from '../api/questionnairesApi/formSnippetsApi';
import { ErrorData, FormSnippetShortData, PaginationQueryParams } from '../types';
import useSnackBar from '../hooks/useSnackBar';

import FormTextField from './formFields/FormTextField';
import FormToggleField from './formFields/FormToggleField';
import Loader from './Loader';

const useStyles = makeStyles()((theme: Theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.light,
      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))',
      minHeight: '524px',
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.secondary.main,
    },
  },
  formWrapper: {
    padding: '0px 24px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  searchBar: {
    display: 'flex',
    flexDirection: 'row',
  },
  toggleWrapper: {
    marginLeft: '2px',
  },
  popupContent: {
    overflowY: 'hidden',
  },
  emptyPopupContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  popupActions: {
    padding: '16px 8px',
  },
  popupButton: {
    color: theme.palette.info.main,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
    },

    '&:hover': {
      background: 'rgba(144, 202, 249, 0.08)',
    },
  },
  searchResultsBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    maxHeight: '300px',
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      backgroundColor: '#393939',
      width: '15px',
      borderRadius: '20px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 20,
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      border: '3px solid #393939',
    },
  },
  searchListItem: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '40px',
    padding: '0px 10px 0px 10px',
    borderRadius: '5px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(144, 202, 249, 0.08)',
    },
  },
  activeSearchListItem: {
    backgroundColor: 'rgba(144, 202, 249, 0.08)',
  },
  searchListItemColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '33%',
    whiteSpace: 'nowrap',
    minWidth: 0,
  },
  label: {
    fontSize: '14px',
    fontWeight: '400',
    color: 'rgba(255,255, 255, 0.7)',
  },
  text: {
    fontSize: '14px',
    fontWeight: '500',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

interface SearchFormSnippetPopupProps {
  readonly firmId?: number | string;
  readonly isOpen: boolean;
  readonly onMainButtonClick: (selectedFormSnippet: FormSnippetShortData) => void;
  readonly onSecondaryButtonClick: () => void;
}

interface FormSubmitProps {
  includeGlobal: boolean;
  name: string;
}

const SearchFormSnippetPopup = ({
  firmId,
  isOpen,
  onMainButtonClick,
  onSecondaryButtonClick,
}: SearchFormSnippetPopupProps) => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();

  const [snippets, setSnippets] = useState<Array<FormSnippetShortData>>([]);
  const [selectedSnippet, setSelectedSnippet] = useState<FormSnippetShortData | null>(null);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const PAGE_SIZE = 10;

  const formMethods = useForm<FormSubmitProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { includeGlobal: false, name: '' },
  });

  const { reset, setValue, watch } = formMethods;

  const searchValue = watch('name');
  const includeGlobal = watch('includeGlobal');

  const getFetchParams = ({
    page,
    count,
    match,
    includeGlobal,
  }: {
    page?: number;
    count?: boolean;
    match?: string;
    includeGlobal?: boolean;
  }) => {
    const queryParams: PaginationQueryParams = {
      page: page || 1,
      size: PAGE_SIZE,
      count: count !== undefined ? count : true,
      match: match || '',
      includeGlobal,
    };

    return queryParams;
  };

  const handleClosePopup = () => {
    onSecondaryButtonClick();
  };

  const handleSelectButtonClick = () => {
    onMainButtonClick(selectedSnippet as FormSnippetShortData);
  };

  const fetchFormSnippets = (queryParams: PaginationQueryParams) => {
    if (queryParams.page === 1) setIsLoading(true);
    else setIsFetching(true);

    const fetch = firmId ? getFormSnippets : getGlobalFormSnippets;

    fetch({
      page: queryParams.page,
      size: PAGE_SIZE,
      count: queryParams.count,
      match: queryParams.match,
      ...(firmId && { includeGlobal: queryParams.includeGlobal }),
      ...(firmId && { filters: { firmId } }),
    })
      .then((response) => {
        if (response.data) {
          setPage(queryParams.page);
          if (queryParams.page === 1) {
            setSnippets(response.data.resultSet);
            setTotalCount(response.data.totalCount);
          } else {
            setSnippets(Array.from(new Set([...snippets, ...response.data.resultSet])));
          }
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      })
      .finally(() => {
        setIsLoading(false);
        setIsFetching(false);
      });
  };

  const handleSearchInputChange = (value: string) => {
    setValue('name', value, { shouldDirty: true });

    if (value.length === 0 || value.trim().length > 2) {
      fetchFormSnippets(getFetchParams({ match: value, includeGlobal }));
    }
  };

  const loadMoreResults = () => {
    if (totalCount !== snippets.length) {
      fetchFormSnippets(getFetchParams({ page: page + 1, match: searchValue, includeGlobal }));
    }
  };

  const handleToggle = (value: boolean) => {
    setValue('includeGlobal', value, { shouldDirty: true });

    fetchFormSnippets(
      getFetchParams({
        match: searchValue,
        includeGlobal: value,
      }),
    );
  };

  const handleSelectItem = (item: FormSnippetShortData) => {
    setSelectedSnippet(item);
  };

  const handleScroll = (event: BaseSyntheticEvent) => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1) {
      loadMoreResults();
    }
  };

  const getDialogContent = () => {
    if (isLoading) {
      return <Loader colorType="info" />;
    }

    if (!snippets.length) {
      return <Typography fontSize="20px">{'No results.'}</Typography>;
    }

    return (
      <>
        <Box className={classes.searchResultsBlock} onScroll={handleScroll}>
          {snippets.map((snippet) => (
            <Box
              key={snippet.id}
              className={clsx({
                [classes.searchListItem]: true,
                [classes.activeSearchListItem]: snippet.id === selectedSnippet?.id,
              })}
              onClick={() => handleSelectItem(snippet)}
            >
              <Box className={classes.searchListItemColumn}>
                <Typography className={classes.text}>{snippet.name}</Typography>
              </Box>
            </Box>
          ))}

          {isFetching && (
            <Box className={classes.searchListItem}>
              <Loader colorType="info" size={25} />
            </Box>
          )}
        </Box>
      </>
    );
  };

  useEffect(() => {
    if (!isOpen) return;

    reset();
    setSelectedSnippet(null);
    fetchFormSnippets(getFetchParams({}));
  }, [isOpen]);

  return (
    <Dialog open={isOpen} className={classes.popupContainer} onClose={handleClosePopup} fullWidth>
      <DialogTitle>{'Form Snippet'}</DialogTitle>
      <Box className={classes.formWrapper}>
        <FormProvider {...formMethods}>
          <form className={classes.formContainer}>
            {firmId && (
              <Box className={classes.toggleWrapper}>
                <FormToggleField
                  name="includeGlobal"
                  label="Include Global"
                  customHandleChange={handleToggle}
                />
              </Box>
            )}
            <FormTextField name="name" label="Name" handleChange={handleSearchInputChange} />
          </form>
        </FormProvider>
      </Box>
      <DialogContent
        className={clsx({
          [classes.popupContent]: true,
          [classes.emptyPopupContent]: isLoading || !snippets.length,
        })}
      >
        {getDialogContent()}
      </DialogContent>
      <DialogActions className={classes.popupActions}>
        <Button
          className={classes.popupButton}
          onClick={handleSelectButtonClick}
          disabled={!selectedSnippet}
        >
          {'Select'}
        </Button>
        <Button className={classes.popupButton} onClick={handleClosePopup}>
          {'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SearchFormSnippetPopup;
