export const palette = {
  primary: {
    main: '#121212',
    light: '#FFFFFF',
    dark: '#1E1E1E',
  },
  secondary: {
    main: 'rgba(255, 255, 255, 0.7)',
    dark: '#000000',
  },
  info: {
    main: '#99CAF9',
    light: '#57748C',
    dark: '#00667C',
  },
  warning: {
    main: '#F4B139',
    light: '#F4B139',
  },
  error: {
    main: '#F44336',
  },
  success: {
    main: '#4CAF50',
  },
};
