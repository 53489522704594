import { Dispatch, SetStateAction, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';
import clsx from 'clsx';

import { Box, Theme, Typography } from '@mui/material';

import { ReactComponent as LogoImage } from './logo-image.svg';

import { SettlementAdditionalInfoData, SettlementsPayoutCheckData } from '../types';

import { FIRM_ROLES } from '../../CasesView/types';

import { getSettlementsCheck } from '../../../api/paymentsApi/settlementsApi';
import Loader from '../../../components/Loader';
import { numberToUSDCurrency } from '../../../helpers/numberToUSDCurrency';

const useStyles = makeStyles()((theme: Theme) => ({
  feesSummaryWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    paddingRight: '8px',
  },
  feesSummaryHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 8px 12px 8px',
    background: theme.palette.primary.main,
    alignItems: 'flex-end',

    p: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
  },
  feesSummaryHeaderPrintBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
  },
  feesSummaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 20px',
    background: theme.palette.info.dark,
    minHeight: '350px',
    marginTop: '20px',
  },
  feesSummaryTotalGrossHeadline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    padding: '10px',

    p: {
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
  },
  feesSummarySectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '20px',
  },
  feesSummarySectionHeadline: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',

    p: {
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
  },
  feesSummarySectionInnerHeadline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px 10px 34px',

    p: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
  },
  feesSummaryBlockDivider: {
    height: '1px',
    background: 'rgba(255, 255, 255, 0.40)',
    marginTop: '20px',
    marginBottom: '20px',
    marginLeft: '10px',
  },
  firmNameText: {
    flexBasis: 'auto',
    overflow: 'hidden',
    minWidth: 0,
    textOverflow: 'ellipsis',
    maxWidth: '130px',
    whiteSpace: 'nowrap',
  },
  firmRoleTextOpacity: {
    opacity: 0.5,
  },
  printButton: {
    padding: 0,
    color: 'white',

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
    },
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  secondaryFirmsInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  noCheckTextList: {
    listStyleType: 'disc',
    padding: 0,
    paddingLeft: '30px',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Roboto',
    fontSize: '16px',
    gap: '3px',
  },
}));

interface SettlementsPayoutCheckProps {
  isPayoutCheckUpdating?: boolean;
  leadFirmData?: { id: string; name: string } | unknown;
  secondaryFirmsData: { id: string; name: string; role: string }[] | unknown;
  caseSystemName: string | unknown;
  payoutCheck?: SettlementsPayoutCheckData;
  setPayoutCheck?: Dispatch<SetStateAction<SettlementsPayoutCheckData | undefined>>;
  setAdditionalSettlementInfo?: Dispatch<SetStateAction<SettlementAdditionalInfoData | undefined>>;
  setIsPayoutCheckUpdating: Dispatch<SetStateAction<boolean>>;
  status?: string;
}

const SettlementsPayoutCheck = ({
  isPayoutCheckUpdating,
  payoutCheck,
  setPayoutCheck,
  setAdditionalSettlementInfo,
  leadFirmData,
  secondaryFirmsData,
  caseSystemName,
  setIsPayoutCheckUpdating,
  status,
}: SettlementsPayoutCheckProps) => {
  const styles = useStyles();
  const { settlementId } = useParams();

  const currentDateTime = moment().format('hh:mm A, MM/DD/YYYY').split(',');
  const leadFirmName: { id: string; name: string } = leadFirmData as { id: string; name: string };
  const caseName: string = caseSystemName as string;
  const secondaryFirms: { id: string; name: string; role: string }[] = secondaryFirmsData as {
    id: string;
    name: string;
    role: string;
  }[];

  const sortedSecondaryFirms = secondaryFirms
    ?.sort((a, b) => a.role.localeCompare(b.role))
    .map((firm) => ({
      firmName: firm.name,
      firmRole: FIRM_ROLES[firm.role as keyof typeof FIRM_ROLES],
    }));

  useEffect(() => {
    getSettlementsCheck(settlementId as string)
      .then((res) => {
        if (res.data) {
          setPayoutCheck && setPayoutCheck(res.data);
          setAdditionalSettlementInfo && setAdditionalSettlementInfo(res.data.additionalInfo);
          setIsPayoutCheckUpdating(false);
        }
      })
      .catch(() => {
        setIsPayoutCheckUpdating(false);
        setPayoutCheck && setPayoutCheck(undefined);
      });
  }, []);

  if (isPayoutCheckUpdating) {
    return (
      <Box className={styles.classes.feesSummaryContainer}>
        <Box className={styles.classes.loaderContainer}>
          <Loader colorType="inherit" />
        </Box>
      </Box>
    );
  }

  return (
    <Box className={styles.classes.feesSummaryContainer}>
      {payoutCheck === undefined ? (
        <Box display="flex" flexDirection="column" mt="120px" gap="5px">
          {status && status === 'Paid' && (
            <Box display="flex" gap="5px" flexDirection="column" alignSelf="center">
              <Typography fontSize="16px" lineHeight="20px">
                Payment preview unavailable.
              </Typography>
              <Typography fontSize="16px" lineHeight="20px">
                The settlement has been paid.
              </Typography>
            </Box>
          )}
          {status && status !== 'Paid' && (
            <Typography fontSize="16px" lineHeight="20px">
              Payment preview unavailable. Please review and update:
              <ul className={styles.classes.noCheckTextList}>
                <li>Participating law firms</li>
                <li>Defendants and gross settlement amounts</li>
                <li>Attorneys’ fees</li>
                <li>Trust account</li>
              </ul>
            </Typography>
          )}
        </Box>
      ) : (
        <Box className={styles.classes.feesSummaryWrapper} id="printContent">
          <Box className="feesSummaryContainer">
            <Box className="print-header" display="none">
              <Box className="logo-image-wrapper">
                <LogoImage />
              </Box>
              <Typography className="print-case-system-name">{caseName}</Typography>
              <Typography>{`${leadFirmName} (Lead Counsel)`}</Typography>
              {sortedSecondaryFirms?.map((firm) => (
                <Typography key={firm.firmName}>{`${firm.firmName} (${firm.firmRole})`}</Typography>
              ))}
            </Box>

            <Box
              className={clsx(
                styles.classes.feesSummaryTotalGrossHeadline,
                'print-feesSummaryTotalGrossHeadline',
              )}
            >
              <Typography>Settled Amount</Typography>
              <Typography>{numberToUSDCurrency(payoutCheck?.total)}</Typography>
            </Box>

            <Box
              className={clsx(
                styles.classes.feesSummarySectionContainer,
                'print-feesSummarySectionContainer',
              )}
              mt="20px"
            >
              <Box
                className={clsx(
                  styles.classes.feesSummarySectionHeadline,
                  'print-feesSummarySectionHeadline',
                )}
              >
                <Typography>Attorneys’ Fees</Typography>
                <Typography>{numberToUSDCurrency(payoutCheck?.attorneysFees?.total)}</Typography>
              </Box>

              <Box
                className={clsx(
                  styles.classes.feesSummarySectionInnerHeadline,
                  'print-feesSummarySectionInnerHeadline',
                )}
              >
                <Box className={styles.classes.secondaryFirmsInfoContainer}>
                  <Typography className={clsx(styles.classes.firmNameText, 'print-firmNameText')}>
                    {payoutCheck?.attorneysFees?.leadFirm?.name}
                  </Typography>
                  <Typography className={styles.classes.firmRoleTextOpacity}>
                    Lead Counsel
                  </Typography>
                </Box>
                <Typography>
                  {numberToUSDCurrency(payoutCheck?.attorneysFees?.leadFirm?.fee)}
                </Typography>
              </Box>

              {!!payoutCheck?.attorneysFees?.secondaryFirms?.length && (
                <>
                  {payoutCheck.attorneysFees.secondaryFirms.map((firmData, idx) => (
                    <Box
                      key={idx}
                      className={clsx(
                        styles.classes.feesSummarySectionInnerHeadline,
                        'print-feesSummarySectionInnerHeadline',
                      )}
                    >
                      <Box className={styles.classes.secondaryFirmsInfoContainer}>
                        <Typography
                          className={clsx(styles.classes.firmNameText, 'print-firmNameText')}
                        >
                          {firmData.name}
                        </Typography>
                        <Typography className={styles.classes.firmRoleTextOpacity}>
                          {FIRM_ROLES[firmData.role as keyof typeof FIRM_ROLES]}
                        </Typography>
                      </Box>
                      <Typography>{numberToUSDCurrency(firmData.fees?.total)}</Typography>
                    </Box>
                  ))}
                </>
              )}

              <Box
                className={clsx(
                  styles.classes.feesSummaryBlockDivider,
                  'print-feesSummaryBlockDivider',
                )}
              />
            </Box>

            {payoutCheck?.deductions && (
              <Box
                className={clsx(
                  styles.classes.feesSummarySectionContainer,
                  'print-feesSummarySectionContainer',
                )}
              >
                <Box
                  className={clsx(
                    styles.classes.feesSummarySectionHeadline,
                    'print-feesSummarySectionHeadline',
                  )}
                >
                  <Typography>Deductions</Typography>
                  <Typography>{numberToUSDCurrency(payoutCheck.deductions.total)}</Typography>
                </Box>
                <Box
                  className={clsx(
                    styles.classes.feesSummarySectionInnerHeadline,
                    'print-feesSummarySectionInnerHeadline',
                  )}
                >
                  <Typography>Advances</Typography>
                  <Typography>{numberToUSDCurrency(payoutCheck.deductions.advances)}</Typography>
                </Box>
                <Box
                  className={clsx(
                    styles.classes.feesSummarySectionInnerHeadline,
                    'print-feesSummarySectionInnerHeadline',
                  )}
                >
                  <Typography>Liens</Typography>
                  <Typography>{numberToUSDCurrency(payoutCheck.deductions.liens)}</Typography>
                </Box>
                <Box
                  className={clsx(
                    styles.classes.feesSummaryBlockDivider,
                    'print-feesSummaryBlockDivider',
                  )}
                />
              </Box>
            )}

            <Box
              className={clsx(
                styles.classes.feesSummarySectionContainer,
                'print-feesSummarySectionContainer',
              )}
            >
              <Box
                className={clsx(
                  styles.classes.feesSummarySectionHeadline,
                  'print-feesSummarySectionHeadline',
                )}
              >
                <Typography>Net Settlement</Typography>
                <Typography>{numberToUSDCurrency(payoutCheck?.netSettlement?.amount)}</Typography>
              </Box>
            </Box>

            <Box className="print-date-time" display="none">
              <Typography>{`Generated at ${currentDateTime[0]} on ${currentDateTime[1]}`}</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SettlementsPayoutCheck;
