import { makeStyles } from 'tss-react/mui';

import { Box, Button, Theme, Typography } from '@mui/material';

const useStyles = makeStyles<{ type: string; disabled: boolean }>()(
  (theme: Theme, { type, disabled }) => ({
    default: {
      minWidth: '92px',
      background: 'transparent',
      height: '100%',
      maxWidth: '92px',
      width: '100%',
    },
    buttonInnerContainer: {
      display: 'flex',
      gap: '4px',
      justifyContent: 'center',
      alignItems: 'center',
      color: disabled
        ? theme.palette.grey[500]
        : type === 'info'
        ? theme.palette.info.main
        : theme.palette.error.main,

      svg: {
        color: disabled
          ? theme.palette.grey[500]
          : type === 'info'
          ? theme.palette.info.main
          : theme.palette.error.main,
      },
    },
    buttonTypography: {
      fontSize: '14px',
      textTransform: 'uppercase',
      fontWeight: 500,
      lineHeight: 'normal',
    },
  }),
);

interface FirmBankingTableActionButtonProps {
  buttonText: string;
  buttonIcon: JSX.Element;
  type: 'info' | 'error';
  handleClick?: () => void;
  disabled?: boolean;
}

const CustomerBankingTableActionButton = ({
  buttonIcon,
  buttonText,
  type,
  handleClick,
  disabled = false,
}: FirmBankingTableActionButtonProps) => {
  const styles = useStyles({ type, disabled });

  return (
    <Button className={styles.classes.default} onClick={handleClick} disabled={disabled}>
      <Box className={styles.classes.buttonInnerContainer}>
        {buttonIcon && buttonIcon}
        <Typography className={styles.classes.buttonTypography}>{buttonText}</Typography>
      </Box>
    </Button>
  );
};

export default CustomerBankingTableActionButton;
