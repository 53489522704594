import { TooltipProps, Tooltip } from '@mui/material';

interface DefaultTooltipProps extends TooltipProps {
  children: JSX.Element;
}

const TooltipComponent = ({ children, ...rest }: DefaultTooltipProps) => {
  return (
    <Tooltip
      {...rest}
      componentsProps={{
        tooltip: {
          sx: {
            padding: '16px',
            borderRadius: 0,
            backgroundColor: '#333030',
            fontSize: '20px',
            fontWeight: 500,
            maxWidth: '500px',
            color: '#F4B139',
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipComponent;
