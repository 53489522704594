import { AxiosResponse } from 'axios';
import moment from 'moment';

export const saveCsv = (response: AxiosResponse, fileName: string) => {
  const blob = new Blob([response.data], { type: 'text/csv' });
  const time = moment().format('HH-mm');

  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = `${fileName}--${time}.csv`;

  link.click();

  window.URL.revokeObjectURL(url);

  return response;
};
