import { styled } from '@mui/system';
import { Button, Typography } from '@mui/material';

export const StyledSubmitButton = styled(Button)`
  color: rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.12);
`;

export const FieldHeadline = styled(Typography)`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: ${(props) => props.theme.palette.primary.light};
`;
