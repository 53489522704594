import { Box, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { QuestionnaireSection } from '../../../types';

import IndeterminateCheckbox from './IndeterminateCheckbox';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface ItemListProps {
  readonly acceptedItems: string[];
  readonly numberOfItems: number;
  readonly selectedSections: QuestionnaireSection[];
  readonly setAcceptedItems: React.Dispatch<React.SetStateAction<string[]>>;
}

const ItemList = ({
  acceptedItems,
  numberOfItems,
  selectedSections,
  setAcceptedItems,
}: ItemListProps) => {
  const { classes } = useStyles();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const items: string[] = [];

      selectedSections.forEach((section) => {
        section.items.forEach((item) => {
          items.push(item.id);
        });
      });

      setAcceptedItems(items);
    } else {
      setAcceptedItems([]);
    }
  };

  return (
    <Box className={classes.container}>
      {selectedSections?.length !== 0 && (
        <FormGroup className={classes.container}>
          <FormControlLabel
            label={'Select All'}
            control={
              <Checkbox
                checked={numberOfItems !== 0 && acceptedItems.length === numberOfItems}
                indeterminate={acceptedItems.length > 0 && acceptedItems.length < numberOfItems}
                onChange={handleChange}
                sx={{
                  color: '#FFF',
                  '&.Mui-checked': {
                    color: '#90CAF9',
                  },
                  '&.MuiCheckbox-indeterminate': {
                    color: '#90CAF9',
                  },
                }}
              />
            }
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
            {selectedSections.map((section) => {
              return (
                <IndeterminateCheckbox
                  key={section.items[0].id}
                  section={section}
                  acceptedItems={acceptedItems}
                  setAcceptedItems={setAcceptedItems}
                />
              );
            })}
          </Box>
        </FormGroup>
      )}
    </Box>
  );
};

export default ItemList;
