import { SelectOption } from '../../types';

export interface FirmsFormSubmitProps {
  id?: string;
  name: string;
  tin: string;
  address1: string;
  address2: string;
  city: string;
  state: SelectOption;
  zip: string;
  // brandColor: string;
  zendeskBrandName: string;
  sendAppInviteTrigger?: string;
  createWalletTrigger?: string;
  issueVirtualCardTrigger?: string;
  issuePhysicalCardTrigger?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  isClientTriggerActive?: boolean;
  logoImageId?: string;
  logoImageUrl?: string;
  firmUser: {
    firmUserToggleValue: boolean;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
  };
  docusignKeys: {
    isDocusignToggleActive: boolean;
    accountId?: string;
    integrationKey?: string;
    userId?: string;
    rsaPrivateKey?: string;
    connectKey?: string;
    baseUri?: string;
  };
}

export interface FirmCodeItemData {
  idForKey?: number;
  id?: string | number;
  firmId?: string;
  code: string;
  name: string;
  dataFormat: string;
  category?: { id: string; name: string } | null;
  prompt: string;
}

export interface FirmCodeFormData {
  code: string;
  name: string;
  dataFormat: string;
  category: string | undefined;
  prompt: string;
}

export interface FirmUnrestrictedAccountShortData {
  createdAt: string;
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
  isVerified: boolean;
  isVerifiedOrigin: boolean;
  canBeDeleted: boolean;
}

export interface FirmUnrestrictedAccountSelectedTableItem {
  createdAt: string;
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
  isVerified: string;
  isVerifiedOrigin: string;
  canBeDeleted: boolean;
}

export interface FirmUnrestrictedAccountQueryData {
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
  isVerified: boolean;
  canBeDeleted: boolean;
  bankingProvisionFailed: boolean;
  firm: {
    id: number;
    name: string;
  };
}

export interface FirmUnrestrictedAccountSubmitFormProps {
  accountNumber: string;
  routingNumber: string;
  name: string;
  isVerified: boolean;
}

export interface FirmTrustAccountData {
  createdAt: string;
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
  isVerified: boolean;
  wasUsedForPayment: boolean;
}

export interface FirmTrustAccountSubmitFormProps {
  accountNumber: string;
  routingNumber: string;
  name: string;
  isVerified: boolean;
  wasUsedForPayment: boolean;
}

export interface FirmTrustAccountSelectedTableItem {
  createdAt: string;
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
  isVerified: string;
  isVerifiedOrigin: string;
  canBeDeleted: boolean;
}

export interface FirmTrustAccountQueryShortData {
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
  isVerified: boolean;
  firm: {
    id: number;
    name: string;
  };
  caseTypes: Array<{
    id: number;
    name: string;
  }>;
  bankingProvisionFailed: boolean;
  canBeDeleted: boolean;
}

export interface FirmTrustAccountQueryData extends FirmTrustAccountQueryShortData {
  wasUsedForPayment: boolean;
}

export interface FirmTrustAccountTableData {
  caseTypes: {
    id: number;
    name: string;
  }[];
  createdAt: string;
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
  isVerified: boolean;
  isVerifiedOrigin: boolean;
  canBeDeleted: boolean;
}

export interface FirmBankingSystemAccountData {
  accountNumber: string;
  routingNumber: string;
  balance: string;
  status: string;
  withdrawalAvailable: boolean;
}

export interface FirmBankingData {
  accounts: {
    exp: FirmBankingSystemAccountData | null;
    lien: FirmBankingSystemAccountData | null;
    rev: FirmBankingSystemAccountData | null;
  };
  canStartIntegration: boolean;
  firm: {
    id: number;
    name: string;
  };
  integration: IntegrationProvisioningData;
}

export type IntegrationProvisioningData = {
  ofac?: {
    status: string;
    reason: string | null;
  };
  provisioning: {
    error?: {
      status: string;
      reason: string | null;
    };
    status: keyof typeof OFAC_STATUSES | keyof typeof INTEGRATION_STASUSES;
  };
};

export enum INTEGRATION_STASUSES {
  InProgress = 'In Progress',
  Error = 'Error',
  CriticalError = 'Critical Error',
  Completed = 'Completed',
}

export enum OFAC_STATUSES {
  PENDING_VERIFICATION = 'Pending Verification',
  VERIFIED = 'Verified',
  UNDER_REVIEW = 'Under Review',
  REJECTED = 'Rejected',
  IGNORED = 'Ignored',
}

export enum SYSTEM_ACCOUNT_LABELS {
  rev = 'Revenue Recognition',
  exp = 'Expense Reimbursement',
  lien = 'Lien Resolution',
}

export enum SYSTEM_ACCOUNT_STATUSES {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  CLOSURE_INITIATED = 'CLOSURE_INITIATED',
  CLOSED = 'CLOSED',
}

export interface FirmAccountPopupProps {
  isOpen: boolean;
  onPopupClose: () => void;
  children: React.ReactNode;
  selectedTableItem: FirmTrustAccountData | FirmUnrestrictedAccountShortData | null;
  entity: 'trust' | 'unrestricted';
  handleDisabledForm: (disabled: boolean) => void;
  isAddForm?: boolean;
  fetchAccounts: () => void;
}

export enum StatusAccount {
  INACTIVE = 'INACTIVE',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
}

export enum StatusProvisioning {
  InProgress = 'InProgress',
  Error = 'Error',
  CriticalError = 'CriticalError',
  Completed = 'Completed',
}

// /v2/trust-accounts/{id}/banking
// /v2/unrestricted-accounts/{id}/banking
export interface AccountBankingInfoResponse {
  canStartIntegration: boolean;
  account: null | {
    accountNumber: string;
    routingNumber: string;
    status: StatusAccount;
  };
  integration: null | {
    provisioning: {
      status: StatusProvisioning;
      error: { message: string | null; retryAvailable: boolean };
    };
  };
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface UnrestrictedAccountOptionsData {
  id: string;
  label: string;
}

export interface FirmBankingWithdrawFormSubmitProps {
  fromSystemAccountType: string;
  toUnrestrictedAccountId: string;
  amount: string;
}

export enum AccountTypeShortCuts {
  'Revenue Recognition' = 'REV',
  'Expense Reimbursement' = 'EXP',
  'Lien Resolution' = 'LIEN',
}
