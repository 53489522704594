import { Dispatch, SetStateAction, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
} from '@mui/material';

import { SYSTEM_ACCOUNT_LABELS } from '../../types';

import { FIRM_WITHRDAW_FUNDS_FORM_DEFAULT_VALUES } from '../../../../helpers/formSchemas/formDefaultValues';

const useStyles = makeStyles()((theme: Theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.light,
      maxWidth: '960px',
      width: '100%',
      padding: '24px',
      boxSizing: 'border-box',
    },

    '& .MuiDialogTitle-root': {
      padding: 0,
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: 'normal',
    },

    '& .MuiDialogContent-root': {
      padding: 0,
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.secondary.main,
    },
  },
  popupActions: {
    padding: '16px 0',
  },
  popupButton: {
    color: theme.palette.info.main,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
    },

    '&:hover': {
      background: 'rgba(144, 202, 249, 0.08)',
    },
  },
  warningTextContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    color: theme.palette.error.main,
    width: '100%',
    justifyContent: 'center',
    marginTop: '20px',
    minHeight: '24px',

    svg: {
      color: theme.palette.error.main,
    },
  },
}));

interface FirmBankingWithdrawPopupProps {
  isOpen: boolean;
  handleClosePopup: () => void;
  setSelectedAccountType: Dispatch<SetStateAction<string>>;
  selectedAccountType: string;
  children: JSX.Element;
  handleOpenVerificationPopup: () => void;
  isFormSubmitting: boolean;
}

const FirmBankingWithdrawPopup = ({
  isOpen,
  handleClosePopup,
  setSelectedAccountType,
  selectedAccountType,
  children,
  handleOpenVerificationPopup,
  isFormSubmitting,
}: FirmBankingWithdrawPopupProps) => {
  const { classes } = useStyles();

  const {
    reset,
    formState: { isDirty, isValid },
  } = useFormContext();

  const onPopupClose = () => {
    handleClosePopup();
    setSelectedAccountType('');
    reset(FIRM_WITHRDAW_FUNDS_FORM_DEFAULT_VALUES);
  };

  useEffect(() => {
    reset({
      fromSystemAccountType:
        SYSTEM_ACCOUNT_LABELS[selectedAccountType as keyof typeof SYSTEM_ACCOUNT_LABELS],
      toUnrestrictedAccountId: '',
      amount: '',
    });
  }, [selectedAccountType]);

  return (
    <Dialog className={classes.popupContainer} open={isOpen} onClose={onPopupClose}>
      <DialogTitle>Withdraw Funds</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <Box className={classes.warningTextContainer}>
        {isValid && (
          <>
            <WarningAmberOutlinedIcon />
            <Typography>Are you sure you want to perform this transaction?</Typography>
          </>
        )}
      </Box>

      <DialogActions className={classes.popupActions}>
        <Button
          className={classes.popupButton}
          disabled={!isValid || !isDirty || isFormSubmitting}
          onClick={handleOpenVerificationPopup}
        >
          Submit
        </Button>
        <Button className={classes.popupButton} onClick={onPopupClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FirmBankingWithdrawPopup;
