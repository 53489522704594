import { useEffect, useState } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Button,
  Theme,
  Typography,
  Chip,
  useTheme,
  SxProps,
  IconButton,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import SyncIcon from '@mui/icons-material/Sync';
import CommonButton from '../../../../components/buttons/CommonButton';
import {
  AccountBankingInfoResponse,
  FirmAccountPopupProps,
  StatusAccount,
  StatusProvisioning,
  TabPanelProps,
} from '../../types';
import useSnackBar from '../../../../hooks/useSnackBar';
import { useApiRequest } from '../../../../hooks/useApiRequest';
import {
  getFirmTrustAccountBanking,
  startFirmTrustAccountProvisioning,
} from '../../../../api/firmsApi/trustAccountsApi';
import {
  getFirmUnrestrictedAccountBanking,
  startFirmUnrestrictedAccountProvisioning,
} from '../../../../api/firmsApi/unrestrictedAccountsApi';
import { StatusAccountLabels, StatusProvisioningLabels } from '../../../../constants/constants';
import Loader from '../../../../components/Loader';
import CustomerBankingErrorTooltip from '../../../CustomersView/customerBanking/CustomerBankingErrorTooltip';
import { ReactComponent as InfoIcon } from '../../../PaymentsView/SettlementsView/info.svg';

const useStyles = makeStyles()((theme: Theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.light,
      maxWidth: '960px',
      width: '100%',
      padding: '24px',
      boxSizing: 'border-box',
    },

    '& .MuiDialogTitle-root': {
      padding: 0,
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: 'normal',
    },

    '& .MuiDialogContent-root': {
      padding: 0,
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.secondary.main,
    },
  },
  popupActions: {
    padding: '16px 0',
  },
  popupButton: {
    color: theme.palette.info.main,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
    },

    '&:hover': {
      background: 'rgba(144, 202, 249, 0.08)',
    },
  },
  defaultTab: {
    background: theme.palette.primary.dark,
    '& .MuiTabs-scroller': {
      overflowX: 'auto !important' as 'scroll',
      '&::-webkit-scrollbar': {
        backgroundColor: '#2b2b2b',
        height: 10,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 10,
        backgroundColor: '#6b6b6b',
        minHeight: 24,
        border: '1px solid #2b2b2b',
      },
    },
    '& .MuiTab-root': {
      color: theme.palette.secondary.light,

      '&.Mui-selected': {
        color: theme.palette.info.main,
      },
    },

    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.info.main,
    },

    '& .MuiTabs-flexContainer': {
      borderBottom: '0.5px solid #515151',
    },
  },
  commonText: {
    color: theme.palette.info.main,
    fontWeight: 500,
    lineHeight: 'normal',
    fontStyle: 'normal',
    fontSize: '18px',
  },
  statusChip: {
    padding: '4px 16px',
    borderRadius: '16px',
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Roboto',
    lineHeight: 'normal',
    maxHeight: '24px',
    maxWidth: '200px',

    '& .MuiChip-label': {
      padding: 0,
    },
  },
  fontSize22: {
    fontSize: '22px',
  },
  formSectionsDivider: {
    width: '2px',
    background: '#393939',
    marginLeft: '15px',
    marginRight: '15px',
  },
  integrationAccountContainer: {
    display: 'flex',
    padding: '16px',
    background: theme.palette.primary.dark,
    flexDirection: 'column',
    width: '100%',
    gap: '24px',
    boxSizing: 'border-box',
  },
  systemAccountsBlockLabel: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: 'normal',
    textTransform: 'capitalize',
  },
  accountMainPropertyText: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 'normal',
    color: theme.palette.secondary.main,
  },
  accountSecondaryPropText: {
    fontSize: '14px',
    fontWeight: 'normal',
    color: 'rgba(255, 255, 255, 0.5)',
  },
  fontWeight500: {
    fontWeight: 500,
  },
  errorMessageTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 'normal',
    color: theme.palette.common.white,
  },
  errorMessageText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 'normal',
    color: theme.palette.error.main,
  },
}));

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box padding="16px 0px">{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const FirmBankingPopupTabWrapper = ({
  isOpen,
  onPopupClose,
  children,
  entity,
  selectedTableItem,
  handleDisabledForm,
  isAddForm,
  fetchAccounts,
}: FirmAccountPopupProps) => {
  const styles = useStyles();
  const theme = useTheme();
  const { classes } = useStyles();
  const [selectedTabId, setSelectedTabId] = useState(0);
  const [passportAccountStatus, setPassportAccountStatus] = useState<StatusAccount>(
    StatusAccount.INACTIVE,
  );
  const [provisioningStatus, setProvisioningStatus] = useState<StatusProvisioning>(
    StatusProvisioning.InProgress,
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isRetry, setIsRetry] = useState<boolean>(false);
  const [canStartIntegration, setCanStartIntegration] = useState(true);
  const { error, handleApiRequest, data, success } = useApiRequest<
    AccountBankingInfoResponse,
    [number]
  >();
  const [loadingWrapper, setLoadingWrapper] = useState<boolean>(!isAddForm);
  const [statusRequest, setStatusRequest] = useState<'provisioning' | 'getAccount'>('getAccount');
  const { setAlert } = useSnackBar();

  useEffect(() => {
    if (selectedTableItem) {
      setStatusRequest('getAccount');
      handleApiRequest(
        entity === 'trust' ? getFirmTrustAccountBanking : getFirmUnrestrictedAccountBanking,
        selectedTableItem?.id,
      );
    } else {
      handleDisabledForm(false);
    }
  }, [selectedTableItem?.id]);

  useEffect(() => {
    if (data && typeof data === 'object' && 'canStartIntegration' in data) {
      setCanStartIntegration(data.canStartIntegration as boolean);
      data?.account?.status && setPassportAccountStatus(data?.account?.status);
      data?.integration?.provisioning?.status &&
        setProvisioningStatus(data?.integration?.provisioning?.status);
      data?.integration?.provisioning?.error?.message &&
        setErrorMessage(data?.integration?.provisioning?.error?.message);
      data?.integration?.provisioning?.error?.retryAvailable &&
        setIsRetry(data?.integration?.provisioning?.error?.retryAvailable);
      if (!(data?.integration?.provisioning?.status === 'Error' || data.canStartIntegration)) {
        handleDisabledForm(true);
      }
      setLoadingWrapper(false);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setAlert((prev) => ({
        ...prev,
        message: error,
        type: 'error',
      }));
      setLoadingWrapper(false);
    }
  }, [error]);

  useEffect(() => {
    if (success && statusRequest === 'provisioning') {
      const timer = setTimeout(() => {
        fetchNewData();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [success]);

  useEffect(() => {
    if (isOpen) {
      setSelectedTabId(0);
    }
  }, [isOpen]);

  const ProvisioningStatusChipColor = {
    InProgress: theme.palette.warning.main,
    Error: theme.palette.error.main,
    CriticalError: theme.palette.error.main,
    Completed: theme.palette.success.main,
  };

  const PassportAccountStatusChipColor = {
    BLOCKED: theme.palette.error.main,
    INACTIVE: theme.palette.text.disabled,
    ACTIVE: theme.palette.success.main,
    PENDING_VERIFICATION: theme.palette.warning.main,
  };

  const getStatusChipStyles = (
    entity: 'PassportAccountStatusChipColor' | 'ProvisioningStatusChipColor',
    status: string,
  ) => {
    const colorMap =
      entity === 'PassportAccountStatusChipColor'
        ? PassportAccountStatusChipColor
        : ProvisioningStatusChipColor;

    const chipColor = colorMap[status as keyof typeof colorMap];

    return {
      color: status === 'INACTIVE' ? '#FFFFFF' : chipColor,
      border: `1px solid ${chipColor}`,
      background: status === 'INACTIVE' ? 'rgba(255, 255, 255, 0.16)' : `1px solid ${chipColor}`,
    } as SxProps<Theme> | undefined;
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabId(newValue);
  };

  const handleIntegrationSubmit = () => {
    setStatusRequest('provisioning');
    setLoadingWrapper(true);
    selectedTableItem &&
      handleApiRequest(
        entity === 'trust'
          ? startFirmTrustAccountProvisioning
          : startFirmUnrestrictedAccountProvisioning,
        selectedTableItem?.id,
      );
  };

  const fetchNewData = async () => {
    fetchAccounts();

    setAlert((prev) => ({
      ...prev,
      message: 'The request for integration with the payment system has been sent.',
      type: 'success',
    }));

    onPopupClose();
  };

  if (isAddForm) return <>{children}</>;

  if (loadingWrapper)
    return (
      <Box display="flex" width="100%" flexDirection="column" height={250}>
        <Box display="flex">
          <Box display="flex" width="100%" flexDirection="column">
            <Tabs
              className={styles.classes.defaultTab}
              value={selectedTabId}
              onChange={handleChange}
            >
              <Tab label="Edit" {...a11yProps(0)} style={{ opacity: 0.7, color: 'white' }} />
              <Tab label="Details" {...a11yProps(1)} style={{ opacity: 0.7, color: 'white' }} />
            </Tabs>
          </Box>
        </Box>
        <Loader colorType="warning" size={100} />
      </Box>
    );

  return (
    <Box display="flex" width="100%" flexDirection="column">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="16px">
        <Typography className={styles.classes.systemAccountsBlockLabel}>
          {`${entity} Account`}
        </Typography>
      </Box>
      <Box display="flex">
        <Box display="flex" width="100%" flexDirection="column">
          <Tabs className={styles.classes.defaultTab} value={selectedTabId} onChange={handleChange}>
            <Tab label="Edit" {...a11yProps(0)} style={{ opacity: 0.7, color: 'white' }} />
            <Tab label="Details" {...a11yProps(1)} style={{ opacity: 0.7, color: 'white' }} />
          </Tabs>
        </Box>
      </Box>
      <CustomTabPanel value={selectedTabId} index={0}>
        {children}
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabId} index={1}>
        <Typography className={styles.classes.systemAccountsBlockLabel} mb="16px">
          Integration Details
        </Typography>
        {canStartIntegration ? (
          <Box className={styles.classes.integrationAccountContainer}>
            <Box display="flex" flexDirection="column" gap="24px">
              <CommonButton
                onButtonClick={handleIntegrationSubmit}
                type="info"
                buttonText="START"
                minWidth="180px"
              />
            </Box>
          </Box>
        ) : (
          <Box display="flex" width="100%">
            <Box flexBasis="50%">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                bgcolor="rgba(255,255,255, 0.1)"
                padding="16px"
              >
                <Box display="flex" gap="5px">
                  <Typography className={clsx(classes.commonText)}>Provisioning</Typography>

                  {errorMessage && (
                    <CustomerBankingErrorTooltip
                      title={
                        <Box display="flex" flexDirection="column" gap="5px">
                          <Typography className={classes.errorMessageTitle}>
                            Error Message
                          </Typography>
                          <Typography className={classes.errorMessageText}>
                            {errorMessage}
                          </Typography>
                        </Box>
                      }
                      placement="right"
                    >
                      <IconButton
                        sx={{
                          padding: 0,
                        }}
                      >
                        <InfoIcon width="18px" height="18px" />
                      </IconButton>
                    </CustomerBankingErrorTooltip>
                  )}
                </Box>
                {isRetry && (
                  <Box>
                    <CommonButton
                      type="info"
                      buttonText="Retry"
                      buttonIcon={<SyncIcon />}
                      maxWidth="95px"
                      onButtonClick={handleIntegrationSubmit}
                    />
                  </Box>
                )}
                <Box display="flex" alignItems="center">
                  <Chip
                    className={classes.statusChip}
                    label={StatusProvisioningLabels[provisioningStatus]}
                    sx={getStatusChipStyles('ProvisioningStatusChipColor', provisioningStatus)}
                  />
                </Box>
              </Box>
            </Box>
            <Box className={classes.formSectionsDivider} />
            <Box
              display="flex"
              bgcolor="rgba(255,255,255, 0.1)"
              padding="16px"
              gap="16px"
              flexBasis="50%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography className={clsx(classes.commonText)}>External Account</Typography>
              <Chip
                className={classes.statusChip}
                label={StatusAccountLabels[passportAccountStatus]}
                sx={getStatusChipStyles('PassportAccountStatusChipColor', passportAccountStatus)}
              />
            </Box>
          </Box>
        )}

        <Box display="flex" justifyContent="flex-end" mt="20px">
          <Button className={styles.classes.popupButton} onClick={onPopupClose}>
            Close
          </Button>
        </Box>
      </CustomTabPanel>
    </Box>
  );
};

export default FirmBankingPopupTabWrapper;
