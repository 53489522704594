import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useParams, useOutletContext } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

// import ArchiveIcon from '@mui/icons-material/Archive';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import SaveIcon from '@mui/icons-material/Save';
import MailIcon from '@mui/icons-material/Mail';
import { Box, IconButton, useTheme } from '@mui/material';

import ClientUpdateSSNDobForm from './ClientUpdateSSNDobForm';

import { FieldHeadline } from './styled';
import { ClientEditFormSubmitProps } from './types';

import {
  getClientBirthDate,
  getClientSSN,
  resetClientAccount,
  sendEmailConfirmation,
  updateClientRecord,
} from '../../api/clientsApi/clientsApi';
import Loader from '../../components/Loader';
import CommonButton from '../../components/buttons/CommonButton';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import FormCopyTextField from '../../components/formFields/FormCopyTextField';
import FormDateField from '../../components/formFields/FormDateField';
import FormTextField from '../../components/formFields/FormTextField';
import FormSelectField from '../../components/formFields/FormSelectField';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import { CLIENT_ACTIVATION_BUTTON_GROUPS, US_STATES_LIST } from '../../constants/constants';
import { CLIENT_EDIT_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import {
  clientEditFormSchema,
  personalInfoFormSchema,
} from '../../helpers/formSchemas/formSchemas';
import usePageTitle from '../../hooks/usePageTitle';
import useSnackBar from '../../hooks/useSnackBar';
import FormAutocompleteField from '../../components/formFields/FormAutocompleteField';
import { handleGetFirms, mapFirmsQueryResults } from '../CasesView/helpers';
import FormLinkButton from '../../components/buttons/FormLinkButton';
import { ClientData, EntityFlexibleRecord } from '../../types';
import FormSSNField from '../../components/formFields/FormSSNField';
import CustomerActivationsButtonGroup from '../CustomersView/CustomerActivationsButtonGroup';
import ClientActivationsButtonGroup from './ClientActivationsButtonGroup';

const ClientEditFormContainer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const { setPageTitle } = usePageTitle();
  const { setAlert } = useSnackBar();
  const { entityData } = useOutletContext<EntityFlexibleRecord>();

  const [isSSNValueVisible, setIsSSNValueVisible] = useState<boolean>(false);
  const [isSSNLoading, setIsSSNLoading] = useState<boolean>(false);
  const [isBirthDateValueVisible, setIsBirthDateValueVisible] = useState<boolean>(false);
  const [isBirthDateLoading, setIsBirthDateLoading] = useState<boolean>(false);
  const [isEditPersonalInfoPopupOpen, setIsEditPersonalInfoPopupOpen] = useState<boolean>(false);
  const [editPersonalFormData, setEditPersonalFormData] = useState<{
    ssn: string;
    birthdate: Date | null;
  }>({
    ssn: '',
    birthdate: null,
  });
  const [accountActivationsState, setAccountActivationsState] = useState({
    email: {
      label: 'EMAIL',
      isActive: false,
    },
    ssn: {
      label: 'SSN',
      isActive: false,
    },
    dob: {
      label: 'DATE OF BIRTH',
      isActive: false,
    },
  });

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const isSendAppInviteAllowed = Object.values(accountActivationsState).every(
    (state) => state.isActive,
  );

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(clientEditFormSchema),
    defaultValues: CLIENT_EDIT_FORM_DEFAULT_VALUES,
  });

  const personalInfoFormMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(personalInfoFormSchema),
    defaultValues: {
      birthdate: moment(),
      ssn: '***-**-****',
    },
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isValid, isDirty },
  } = formMethods;

  const {
    setValue: personalInfoFormSetValue,
    watch: personalInfoFormWatch,
    formState: { isDirty: personalFormIsDirty },
  } = personalInfoFormMethods;

  const firmFieldValue = watch('firmId.id');
  const ssn = personalInfoFormWatch('ssn');
  const birthdate = personalInfoFormWatch('birthdate');

  const onSubmitForm = (values: ClientEditFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      referenceId: values.referenceId,
      firstName: values.firstName,
      lastName: values.lastName,
      address1: values.address1,
      city: values.city,
      countryState: values.state,
      zip: values.zip,
      address2: values.address2?.length ? values.address2 : null,
      middleName: values.middleName?.length ? values.middleName : null,
    };

    updateClientRecord(clientId as string, submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);
          navigate(`/firms/${firmFieldValue}/clients`);

          setAlert((prev) => ({
            ...prev,
            message: 'Client updated successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => {
    setIsBirthDateValueVisible(false);
    setIsSSNValueVisible(false);
    reset();
  };

  const handleResetClientAccount = () => {
    resetClientAccount(clientId as string)
      .then(() => {
        setAlert((prev) => ({
          ...prev,
          message: 'App invitation has been sent successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const getSSNFieldValue = () => {
    if (!clientId) return;

    if (ssn !== '***-**-****') {
      setIsSSNValueVisible(!isSSNValueVisible);
      return;
    }

    setIsSSNLoading(true);

    getClientSSN(Number(clientId))
      .then((res) => {
        personalInfoFormSetValue('ssn', res.data.ssn);
        setIsSSNValueVisible(true);
      })
      .catch(() => setIsSSNValueVisible(false))
      .finally(() => setIsSSNLoading(false));
  };

  const getBirhtDateFieldValue = () => {
    if (!clientId) return;

    if (!moment(birthdate).isSame(moment(), 'day')) {
      setIsBirthDateValueVisible(!isBirthDateValueVisible);
      return;
    }

    setIsBirthDateLoading(true);

    getClientBirthDate(Number(clientId))
      .then((res) => {
        personalInfoFormSetValue('birthdate', moment(res.data.birthdate));
        setIsBirthDateValueVisible(true);
      })
      .catch(() => setIsBirthDateValueVisible(false))
      .finally(() => setIsBirthDateLoading(false));
  };

  const onEditPersonalButtonClick = () => {
    onEditPersonalInfoPopupOpen();
    setIsEditPersonalInfoPopupOpen(true);
  };

  const onEditPersonalInfoPopupOpen = () => {
    getClientSSN(Number(clientId)).then((res) =>
      setEditPersonalFormData((prevState) => ({
        ...prevState,
        ssn: res.data.ssn,
      })),
    );

    getClientBirthDate(Number(clientId)).then((res) =>
      setEditPersonalFormData((prevState) => ({
        ...prevState,
        birthdate: moment(res.data.birthdate) as unknown as Date,
      })),
    );
  };

  const handleUpdateNewSSNDoBvalues = (newValues: { ssn: string; birthdate?: Date | null }) => {
    personalInfoFormSetValue('ssn', newValues.ssn, { shouldDirty: true });
    personalInfoFormSetValue('birthdate', moment(newValues.birthdate), {
      shouldDirty: true,
    });
  };

  const handleSendEmailConfirmation = () => {
    sendEmailConfirmation(Number(clientId))
      .then(() => {
        setAlert((prev) => ({
          ...prev,
          message: 'Email confirmation has been sent successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  useEffect(() => {
    if (entityData) {
      const data: ClientData = entityData as ClientData;

      reset({
        id: data.id.toString(),
        firmId: { id: data.firm.id.toString(), label: data.firm.name },
        firstName: data.firstName,
        middleName: data.middleName || '',
        lastName: data.lastName,
        referenceId: data.referenceId,
        email: data.email,
        phone: data.phone,
        address1: data.address1,
        address2: data.address2 || '',
        city: data.city,
        state: data.countryState,
        zip: data.zip,
      });

      setPageTitle && setPageTitle(`${entityData.firstName} ${entityData.lastName}`);
      setAccountActivationsState({
        email: {
          label: 'EMAIL',
          isActive: data.emailConfirmed,
        },
        ssn: {
          label: 'SSN',
          isActive: data.ssnConfirmed,
        },
        dob: {
          label: 'DATE OF BIRTH',
          isActive: data.dobConfirmed,
        },
      });
    }

    return () => setPageTitle && setPageTitle('');
  }, [entityData]);

  return (
    <>
      {!entityData ? (
        <Loader colorType="warning" />
      ) : (
        <>
          <Box display="flex" bgcolor={theme.palette.primary.dark} width="100%">
            <form style={{ width: '100%', padding: '20px' }}>
              <Box display="flex" width="100%" gap="32px">
                <FormProvider {...formMethods}>
                  <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                    <FieldHeadline>Identity</FieldHeadline>
                    <Box mt="10px">
                      <FormCopyTextField entityName="Client" name="id" label="Id" isReadOnly />
                    </Box>
                    <Box mt="20px">
                      <FormTextField name="referenceId" label="Reference Id *" />
                    </Box>
                    <Box mt="20px">
                      <FormTextField name="firstName" label="First Name *" />
                    </Box>
                    <Box mt="20px">
                      <FormTextField name="middleName" label="Middle Name" />
                    </Box>
                    <Box mt="20px">
                      <FormTextField name="lastName" label="Last Name *" />
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-end"
                      mt="50px"
                    >
                      <FieldHeadline>Firm</FieldHeadline>
                      <FormLinkButton path={`/firms/${firmFieldValue}/home`} />
                    </Box>

                    <Box mt="10px">
                      <FormAutocompleteField
                        name="firmId"
                        label="Firm *"
                        optionsQuery={handleGetFirms}
                        mapQueryResults={mapFirmsQueryResults}
                        disabled
                      />
                    </Box>

                    <FieldHeadline mt="50px">Credentials</FieldHeadline>
                    <Box mt="10px">
                      <FormTextField name="email" label="Email Address *" disabled />
                    </Box>
                    <Box mt="20px">
                      <FormTextField name="phone" label="Mobile Number *" disabled />
                    </Box>
                  </Box>
                </FormProvider>

                <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                  <FormProvider {...formMethods}>
                    <FieldHeadline>Address</FieldHeadline>
                    <Box mt="10px">
                      <FormTextField name="address1" label="Address Line 1 *" />
                    </Box>
                    <Box mt="20px">
                      <FormTextField name="address2" label="Address Line 2" />
                    </Box>
                    <Box mt="20px" display="flex" gap="20px" flexDirection="column">
                      <FormTextField name="city" label="City *" />
                      <FormSelectField options={US_STATES_LIST} name="state" label="State *" />
                      <FormTextField name="zip" label="Zip *" />
                    </Box>
                  </FormProvider>

                  <FormProvider {...personalInfoFormMethods}>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                      <FieldHeadline mt="50px">Personal Information</FieldHeadline>
                      <IconButton
                        sx={{
                          padding: 0,
                          svg: {
                            color: theme.palette.common.white,
                          },
                          '&.Mui-disabled': {
                            svg: {
                              color: 'rgba(255, 255, 255, 0.3)',
                            },
                          },
                        }}
                        onClick={onEditPersonalButtonClick}
                        disableFocusRipple
                        disabled={
                          accountActivationsState.dob.isActive &&
                          accountActivationsState.ssn.isActive
                        }
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </Box>
                    <Box mt="10px">
                      <FormDateField
                        name="birthdate"
                        label="Date of Birth *"
                        maxDate={moment()}
                        shouldDisableInput
                        isEncrypted
                        isEncryptedValueLoading={isBirthDateLoading}
                        isValueVisible={isBirthDateValueVisible}
                        onRightIconClick={getBirhtDateFieldValue}
                      />
                    </Box>
                    <Box mt="20px">
                      <FormSSNField
                        name="ssn"
                        label="Social Security Number *"
                        isEncrypted
                        isEncryptedValueLoading={isSSNLoading}
                        isValueVisible={isSSNValueVisible}
                        onRightIconClick={getSSNFieldValue}
                      />
                    </Box>
                  </FormProvider>

                  <FieldHeadline mt="50px">Confirmations</FieldHeadline>
                  <Box mt="20px">
                    <CustomerActivationsButtonGroup
                      buttonGroupLabel="Identity"
                      maxWidth="148px"
                      accountActivationsState={accountActivationsState}
                    />
                  </Box>

                  <FieldHeadline mt="50px">Activations</FieldHeadline>
                  <Box mt="12px" width="100%">
                    <ClientActivationsButtonGroup
                      buttonGroupLabel="app"
                      buttonsList={CLIENT_ACTIVATION_BUTTON_GROUPS.app}
                      maxWidth="148px"
                    />
                  </Box>

                  <Box mt="20px">
                    <ClientActivationsButtonGroup
                      buttonGroupLabel="virtual card"
                      buttonsList={CLIENT_ACTIVATION_BUTTON_GROUPS.virtualCard}
                      maxWidth="111px"
                    />
                  </Box>

                  <Box mt="20px">
                    <ClientActivationsButtonGroup
                      buttonGroupLabel="physical card"
                      buttonsList={CLIENT_ACTIVATION_BUTTON_GROUPS.physicalCard}
                      maxWidth="111px"
                    />
                  </Box>
                </Box>

                <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                  <FieldHeadline>Actions</FieldHeadline>

                  <Box mt="16px">
                    <CommonButton
                      type="info"
                      buttonText="Send email confirmation"
                      buttonIcon={<MailIcon />}
                      onButtonClick={handleSendEmailConfirmation}
                      disabled={!!entityData?.emailConfirmed}
                      maxWidth="240px"
                    />
                  </Box>

                  <Box mt="16px">
                    <CommonButton
                      type="info"
                      buttonText="send app invite"
                      buttonIcon={<MobileScreenShareIcon />}
                      onButtonClick={handleResetClientAccount}
                      disabled={!isSendAppInviteAllowed}
                      maxWidth="240px"
                    />
                  </Box>

                  <Box mt="16px">
                    <CommonButton
                      type="info"
                      buttonText="issue virtual card"
                      buttonIcon={<CreditCardIcon />}
                      disabled
                      maxWidth="240px"
                    />
                  </Box>

                  <Box mt="16px">
                    <CommonButton
                      type="info"
                      buttonText="issue physical card"
                      buttonIcon={<CreditCardIcon />}
                      disabled
                      maxWidth="240px"
                    />
                  </Box>

                  {/* <Box mt="24px">
                      <CommonButton
                        type="error"
                        buttonText="archive"
                        buttonIcon={<ArchiveIcon />}
                        disabled
                      />
                    </Box> */}
                </Box>
              </Box>

              <Box display="flex" justifyContent="space-between" mt="40px">
                <FormSubmitButton
                  disabled={!isValid || personalFormIsDirty ? false : !isDirty || isFormSubmitting}
                  buttonIcon={<SaveIcon />}
                  buttonText="Save"
                  onClick={handleSubmit(onSubmitForm)}
                />
                {isDirty && <FormCancelButton onClick={handleCancelChanges} />}
              </Box>
            </form>
            <ClientUpdateSSNDobForm
              isOpen={isEditPersonalInfoPopupOpen}
              onSecondaryButtonClick={() => setIsEditPersonalInfoPopupOpen(false)}
              formData={editPersonalFormData}
              clientId={Number(clientId)}
              handleUpdateNewSSNDoBvalues={handleUpdateNewSSNDoBvalues}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default ClientEditFormContainer;
