import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import { Box, Button, Theme, Typography, useTheme } from '@mui/material';

const useStyles = makeStyles<{ bgColor: string; maxWidth?: string; minWidth?: string }>()(
  (theme: Theme, { bgColor, maxWidth, minWidth }) => ({
    default: {
      background: bgColor,
      maxWidth: maxWidth || '218px',
      width: '100%',
      minWidth: minWidth || 'inherit',
      minHeight: '36px',

      '&:hover': {
        background: bgColor,
        color: theme.palette.primary.main,
      },
    },
    buttonDisabled: {
      '&.Mui-disabled': {
        color: 'rgba(255, 255, 255, 0.3)',
        background: 'rgba(255, 255, 255, 0.12)',
      },
    },
  }),
);

interface ButtonProps {
  type: 'info' | 'error' | 'warning' | 'success';
  buttonText: string;
  onButtonClick?: () => void;
  maxWidth?: string;
  buttonIcon?: JSX.Element;
  disabled?: boolean;
  minWidth?: string;
}

const CommonButton = ({
  type,
  buttonText,
  disabled,
  maxWidth,
  buttonIcon,
  onButtonClick,
  minWidth,
}: ButtonProps) => {
  const theme = useTheme();

  const buttonColors = {
    info: theme.palette.info.main,
    error: theme.palette.error.main,
    warning: theme.palette.warning.main,
    success: theme.palette.success.main,
  };

  const activeColor = buttonColors[type as keyof typeof buttonColors];

  const styles = useStyles({ bgColor: activeColor, maxWidth, minWidth });

  return (
    <Box>
      <Button
        className={clsx(styles.classes.default, disabled && styles.classes.buttonDisabled)}
        onClick={onButtonClick}
        disabled={disabled}
      >
        <Box display="flex" alignItems="center" justifyContent="center" gap="4px">
          {buttonIcon && buttonIcon}
          <Typography
            fontSize="14px"
            textTransform="uppercase"
            fontWeight="500"
            lineHeight="normal"
          >
            {buttonText}
          </Typography>
        </Box>
      </Button>
    </Box>
  );
};

export default CommonButton;
