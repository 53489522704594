import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

import { dataRequestQuestionnaireEditFormSchema } from '../helpers/formSchemas/formSchemas';
import { SelectOption } from '../types';
import { QuestionnaireSubmitForm } from '../views/QuestionnairesView/types';
import { DATA_REQUEST_FORM_BUILDER_DEFAULT_VALUES } from '../helpers/formSchemas/formDefaultValues';

import { extractBooleanItems } from './QuestionnaireFormBuilder/helpers/extractBooleanItems';
import QuestionnaireFormBuilder from './QuestionnaireFormBuilder/QuestionnaireFormBuilder';

const useStyles = makeStyles()((theme: Theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.light,
      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))',
      height: '90%',
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.secondary.main,
    },
  },
  popupButton: {
    color: theme.palette.info.main,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
    },

    '&:hover': {
      background: 'rgba(144, 202, 249, 0.08)',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
  },
}));

interface DataRequestFormBuilderPopupProps {
  isOpen: boolean;
  data: QuestionnaireSubmitForm | null;
  firmId: number;
  onMainButtonClick: (values: QuestionnaireSubmitForm) => void;
  onSecondaryButtonClick: () => void;
}

const DataRequestFormBuilderPopup = ({
  isOpen,
  data,
  firmId,
  onMainButtonClick,
  onSecondaryButtonClick,
}: DataRequestFormBuilderPopupProps) => {
  const { classes } = useStyles();

  const [booleanItems, setBooleanItems] = useState<Array<SelectOption>>([{ id: '', label: '-' }]);

  const formMethods = useForm<{ form: QuestionnaireSubmitForm }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(dataRequestQuestionnaireEditFormSchema),
    defaultValues: DATA_REQUEST_FORM_BUILDER_DEFAULT_VALUES,
  });

  const {
    formState: { isValid, isDirty },
    handleSubmit,
    reset,
    getValues,
    setValue,
  } = formMethods;

  const handleSaveButtonClick = (values: { form: QuestionnaireSubmitForm }) => {
    onMainButtonClick(values.form);
  };

  const handleCancelButtonClick = () => {
    onSecondaryButtonClick();
  };

  const handleUpdateBooleanItems = (items: SelectOption[]) => {
    if (items.length) {
      const { form } = getValues();

      for (let i = 0; i < form.sections.length; i++) {
        for (let j = 0; j < form.sections[i].items.length; j++) {
          const id = form.sections[i].items[j].dependsOnItem?.booleanItemId;

          if (id && !items.some((el) => el.id === id)) {
            setValue(`form.sections.${i}.items.${j}.dependsOnItem.booleanItemId`, '', {
              shouldDirty: true,
            });
            setValue(`form.sections.${i}.items.${j}.dependsOnItem.expectedValue`, '', {
              shouldDirty: true,
            });
          }
        }
      }
    }

    setBooleanItems(items);
  };

  const handleOnDragEnd = ({ destination, source, draggableId }: DropResult) => {
    if (!destination) return;

    const { form } = getValues();
    const [destSectionIdx] = destination.droppableId.split('-').reverse();

    if (destination.droppableId === source.droppableId) {
      const newSectionItems = Array.from(form.sections[Number(destSectionIdx)].items);
      const [draggedItem] = newSectionItems.splice(source.index, 1);
      newSectionItems.splice(destination.index, 0, draggedItem);

      setValue(`form.sections.${Number(destSectionIdx)}.items`, newSectionItems, {
        shouldDirty: true,
        shouldValidate: true,
      });
      return;
    }

    const [draggedItemId] = draggableId.split('-').reverse();
    const [sourceSectionIdx] = source.droppableId.split('-').reverse();

    const newSourceSectionItems = Array.from(form.sections[Number(sourceSectionIdx)].items);
    const [draggedItem] = newSourceSectionItems.splice(Number(draggedItemId), 1);
    const newDestSectionItems = Array.from(form.sections[Number(destSectionIdx)].items);
    newDestSectionItems.splice(destination.index, 0, draggedItem);

    setValue(`form.sections.${Number(sourceSectionIdx)}.items`, newSourceSectionItems, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue(`form.sections.${Number(destSectionIdx)}.items`, newDestSectionItems, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  useEffect(() => {
    if (!isOpen) return;

    if (data) {
      setBooleanItems(extractBooleanItems(data));
      reset({ form: data });
    } else {
      setBooleanItems([{ id: '', label: '-' }]);
      reset(DATA_REQUEST_FORM_BUILDER_DEFAULT_VALUES);
    }
  }, [isOpen]);

  // TODO: Code duplication (DataRequestFormBuilder, QuestionnaireFormBuilder, FormSnippetBuilder)
  return (
    <Dialog
      className={classes.popupContainer}
      open={isOpen}
      onClose={handleCancelButtonClick}
      fullWidth
      maxWidth={'xl'}
    >
      <DialogTitle textAlign="center">{'Edit Form'}</DialogTitle>
      <DialogContent>
        <FormProvider {...formMethods}>
          <form className={classes.form}>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <QuestionnaireFormBuilder
                booleanItems={booleanItems}
                firmId={firmId}
                handleUpdateBooleanItems={handleUpdateBooleanItems}
              />
            </DragDropContext>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.popupButton}
          onClick={handleSubmit(handleSaveButtonClick)}
          disabled={!isDirty || !isValid}
        >
          {'Save'}
        </Button>
        <Button className={classes.popupButton} onClick={handleCancelButtonClick}>
          {'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DataRequestFormBuilderPopup;
