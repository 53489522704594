import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';

import SaveIcon from '@mui/icons-material/Save';
import { Box, Typography, useTheme } from '@mui/material';

import { AdminCreateFormSubmitProps } from './types';

import FormTextField from '../../components/formFields/FormTextField';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import { createAdminRecord } from '../../api/adminsApi/adminsApi';
import GoBackButton from '../../components/buttons/GoBackButton';
import { createAdminFormSchema } from '../../helpers/formSchemas/formSchemas';
import { CREATE_ADMIN_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import FormSelectField from '../../components/formFields/FormSelectField';
import { ROLE_OPTIONS } from '../../constants/constants';
import useSnackBar from '../../hooks/useSnackBar';
import FormMaskedTextField from '../../components/formFields/FormMaskedTextField';

const AdminCreateFormContainer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setAlert } = useSnackBar();

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(createAdminFormSchema),
    defaultValues: CREATE_ADMIN_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = formMethods;

  const onSubmitForm = (values: AdminCreateFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      isSuperAdmin: false,
    };

    createAdminRecord(submitData)
      .then((res) => {
        setIsFormSubmitting(false);

        if (!res.data.error) {
          navigate(`/admins/${res.data.id}/home`);
          setAlert((prev) => ({
            ...prev,
            message: 'Admin created successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));

        setIsFormSubmitting(false);
      });
  };

  const handleCancelChanges = () => reset();

  return (
    <>
      <GoBackButton />
      <Box display="flex" bgcolor={theme.palette.primary.dark} width="100%">
        <FormProvider {...formMethods}>
          <form style={{ width: '100%', padding: '20px' }}>
            <Box display="flex" width="100%" gap="32px">
              <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                <Typography
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  fontSize="20px"
                  lineHeight="23px"
                >
                  Identity
                </Typography>

                <Box mt="20px">
                  <FormTextField name="firstName" label="First Name *" />
                </Box>
                <Box mt="20px">
                  <FormTextField name="lastName" label="Last Name *" />
                </Box>

                <Typography
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  fontSize="20px"
                  lineHeight="23px"
                  mt="40px"
                >
                  Credentials
                </Typography>

                <Box mt="10px">
                  <FormTextField name="email" label="Email Address *" />
                </Box>
                <Box mt="20px">
                  <FormMaskedTextField mask="+19999999999" name="phone" label="Mobile Number *" />
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                <Typography
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  fontSize="20px"
                  lineHeight="23px"
                >
                  Role
                </Typography>

                <Box mt="20px">
                  <FormSelectField options={ROLE_OPTIONS} name="role" label="Role *" />
                </Box>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" mt="40px">
              <FormSubmitButton
                disabled={!isValid || isFormSubmitting}
                buttonIcon={<SaveIcon />}
                buttonText="Save"
                onClick={handleSubmit(onSubmitForm)}
              />
              {isDirty && <FormCancelButton onClick={handleCancelChanges} />}
            </Box>
          </form>
        </FormProvider>
      </Box>
    </>
  );
};

export default AdminCreateFormContainer;
