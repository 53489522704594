import { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useParams, useOutletContext } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import PaymentsIcon from '@mui/icons-material/Payments';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RuleIcon from '@mui/icons-material/Rule';
import DrawIcon from '@mui/icons-material/Draw';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import CreditCardIcon from '@mui/icons-material/CreditCard';
// import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { Box, IconButton, useTheme } from '@mui/material';

import {
  handleGetCaseTypes,
  handleGetDefendants,
  handleGetFirms,
  handleGetClients,
  handleGetTrustAccounts,
  mapCaseTypesQueryResults,
  mapDefendantsQueryResults,
  mapFirmsQueryResults,
  mapClientsQueryResults,
  mapTrustAccountsQueryResults,
} from './helpers';
import { FieldHeadline } from './styled';
import { CaseEditFormSubmitProps, DefendantField, SecondaryFirmsField } from './types';

import { abandonCase, submitCase, updateCaseRecord } from '../../api/casesApi/casesApi';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import FormDateField from '../../components/formFields/FormDateField';
import FormTextField from '../../components/formFields/FormTextField';
import FormSelectField from '../../components/formFields/FormSelectField';
import { CASES_EDIT_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { casesEditFormSchema } from '../../helpers/formSchemas/formSchemas';
import {
  CaseTypeData,
  EntityFlexibleRecord,
  PaginationQueryParams,
  SelectOption,
} from '../../types';
import AddButton from '../../components/buttons/AddButton';
import FormAutocompleteField from '../../components/formFields/FormAutocompleteField';
import FormCopyTextField from '../../components/formFields/FormCopyTextField';
import FormLinkButton from '../../components/buttons/FormLinkButton';
import CommonButton from '../../components/buttons/CommonButton';
import usePageTitle from '../../hooks/usePageTitle';
import useSnackBar from '../../hooks/useSnackBar';
import {
  CASE_TYPES_CATEGORIES_OPTIONS,
  CaseTypeCategories,
  FIRM_ROLES_OPTIONS,
  SECONDARY_FIRM_ROLES_OPTIONS,
} from '../../constants/constants';
import Popup from '../../components/Popup';
import { POPUP_DESCRIPTIONS } from '../../constants/popupDescriptions';
import { getClientRecord, resetClientAccount } from '../../api/clientsApi/clientsApi';

const CasesEditFormContainer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { caseId } = useParams();
  const { setPageTitle } = usePageTitle();
  const { setAlert } = useSnackBar();
  const { entityData } = useOutletContext<EntityFlexibleRecord>();

  const [currentCaseStatus, setCurrentCaseStatus] = useState('');
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isAbandonCasePopupOpen, setIsAbandonCasePopupOpen] = useState<boolean>(false);
  const [caseTypes, setCaseTypes] = useState<CaseTypeData[]>([]);
  const [clientConfirmations, setClientConfirmations] = useState({
    email: false,
    ssn: false,
    dob: false,
  });

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(casesEditFormSchema),
    defaultValues: CASES_EDIT_FORM_DEFAULT_VALUES,
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isValid, isDirty },
  } = formMethods;

  const clientFieldValue = watch('clientId');
  const defendantsFieldValue = watch('defendant');
  const firmsFieldArrayValue = watch('secondaryFirms');
  const caseTypeCategory = watch('caseTypeCategory');
  const type = watch('type');
  const hasChosenFirm = firmsFieldArrayValue && !firmsFieldArrayValue[0].id?.label?.length;
  const leadCounselFirmId = firmsFieldArrayValue && Number(firmsFieldArrayValue[0].id?.id);
  const isDraft = currentCaseStatus === 'Draft';
  const isActive = currentCaseStatus === 'Active';
  const isClosed = currentCaseStatus === 'Closed';
  const isAbandon = currentCaseStatus === 'Abandoned';
  const canAbandon = entityData?.canBeAbandoned;
  const paymentStarted = watch('paymentStarted');
  const trustAcc = watch('trustAccount.id');
  const isSendAppInviteAllowed = Object.values(clientConfirmations).every(
    (confirmation) => confirmation,
  );

  const {
    fields: defendantFields,
    append: defendantAppend,
    remove: defendantRemove,
  } = useFieldArray({
    name: 'defendant',
    control,
  });

  const {
    fields: secondaryFirmsFields,
    append: secondaryFirmAppend,
    remove: secondaryFirmRemove,
  } = useFieldArray({
    name: 'secondaryFirms',
    control,
  });

  const handleAddDefendant = () => {
    defendantAppend({
      defendantId: { id: '', label: '' },
      gross: '',
    });
  };

  const handleAddSecondaryFirm = () => {
    secondaryFirmAppend({
      id: { id: '', label: '' },
      role: '',
    });
  };

  const onSubmitForm = (values: CaseEditFormSubmitProps) => {
    if (!caseId) return;

    setIsFormSubmitting(true);

    const mappedDefendants = values?.defendant?.map((defendant: DefendantField) => ({
      id: defendant.defendantId.id,
      grossSettlement: defendant.gross ? Number(defendant.gross) : null,
    }));

    const mappedFirms = values?.secondaryFirms?.map((firm: SecondaryFirmsField) => ({
      id: firm.id.id,
      role: firm.role,
    }));

    const submitData = {
      filedAt: values.filedAt ? moment(values.filedAt).format('MM-DD-YYYY') : null,
      sol: values.sol ? moment(values.sol).format('MM-DD-YYYY') : null,
      referenceId: values.referenceId,
      firmId: mappedFirms && mappedFirms[0]?.id,
      secondaryFirms: mappedFirms?.slice(1),
      clientId: Number(values.clientId.id),
      defendants: mappedDefendants,
      trustAccountId: values.trustAccount?.id ? Number(values.trustAccount.id) : null,
      caseTypeId: Number(values.type.id),
    };

    updateCaseRecord(Number(caseId), submitData)
      .then(() => {
        setIsFormSubmitting(false);
        navigate('/cases');

        setAlert((prev) => ({
          ...prev,
          message: 'Case updated successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleSubmitCase = () => {
    submitCase(caseId as string)
      .then(() => {
        navigate('/cases');
        setAlert((prev) => ({
          ...prev,
          message: 'Case submitted successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => reset();

  const handleChangeFirmValue = () => {
    setValue('clientId', { id: '', label: '' });
    setValue('defendant', [{ defendantId: { id: '', label: '' }, gross: '' }]);
    setValue('caseTypeCategory', '');
    setValue('type', { id: '', label: '' });
    setValue('trustAccount', { id: '', label: '' });
    setValue('name', '');
  };

  const handleResetClientAccount = () => {
    resetClientAccount(clientFieldValue.id as string)
      .then(() => {
        setAlert((prev) => ({
          ...prev,
          message: 'App invitation has been sent successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleAbandonCase = () => {
    abandonCase(caseId as string)
      .then(() => {
        navigate('/cases');
        setAlert((prev) => ({
          ...prev,
          message: 'Case abandoned successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleChangeCaseTypeCategoryValue = (_name: string, value: string) => {
    setValue('caseTypeCategory', value, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue('type', { id: '', label: '' }, { shouldDirty: true, shouldValidate: true });
    setValue('trustAccount', { id: '', label: '' }, { shouldDirty: true, shouldValidate: true });
  };

  const handleChangeCaseTypeValue = (value: SelectOption | null) => {
    if (!value) {
      setValue(
        'trustAccount',
        {
          id: '',
          label: '',
        },
        { shouldDirty: true, shouldValidate: true },
      );
      return;
    }

    const caseType = caseTypes.find((caseType) => caseType.id === Number(value.id));

    if (caseType && caseType.trustAccounts.length) {
      const trustAccount = caseType.trustAccounts.find((el) => el.firmId === leadCounselFirmId);

      if (trustAccount) {
        setValue(
          'trustAccount',
          {
            id: trustAccount.id.toString(),
            label: trustAccount.name,
          },
          { shouldDirty: true, shouldValidate: true },
        );
      }
    }
  };

  useEffect(() => {
    if (entityData) {
      reset({
        ...entityData,
      });

      const clientId = entityData?.clientId as { id: string; label: string };

      getClientRecord(clientId.id as string).then((res) => {
        if (res.data) {
          setClientConfirmations((...prevState) => ({
            ...prevState,
            email: res.data.emailConfirmed,
            ssn: res.data.ssnConfirmed,
            dob: res.data.dobConfirmed,
          }));
        }
      });

      setCurrentCaseStatus(entityData.status as string);
      setPageTitle && setPageTitle(entityData.name as string);
    }

    return () => setPageTitle && setPageTitle('');
  }, [entityData]);

  return (
    <>
      <Box display="flex" bgcolor={theme.palette.primary.dark} width="100%">
        <FormProvider {...formMethods}>
          <form style={{ width: '100%', padding: '20px' }}>
            <Box display="flex" width="100%" gap="32px">
              <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                <FieldHeadline>Identity</FieldHeadline>
                <Box mt="12px">
                  <FormCopyTextField entityName="Case" name="id" label="Id" isReadOnly />
                </Box>
                <Box mt="20px">
                  <FormTextField name="name" label="System Name" readOnly isReadOnly />
                </Box>
                <Box mt="20px">
                  <FormTextField name="referenceId" label="Reference Id *" disabled={!isDraft} />
                </Box>
                <Box mt="20px">
                  <FormSelectField
                    disabled={!isDraft || hasChosenFirm}
                    options={CASE_TYPES_CATEGORIES_OPTIONS}
                    name="caseTypeCategory"
                    label="Case Type Category *"
                    customHandleChange={handleChangeCaseTypeCategoryValue}
                  />
                </Box>
                <Box mt="20px">
                  <FormAutocompleteField
                    disabled={!isDraft || !caseTypeCategory}
                    name={'type'}
                    label="Case Type *"
                    filters={{
                      category: caseTypeCategory,
                      ...(caseTypeCategory === CaseTypeCategories.Custom && {
                        firmId: leadCounselFirmId,
                      }),
                    }}
                    optionsQuery={handleGetCaseTypes}
                    mapQueryResults={mapCaseTypesQueryResults}
                    onSearchResultsChange={(items) => {
                      setCaseTypes(items);
                    }}
                    onValueChange={handleChangeCaseTypeValue}
                  />
                </Box>

                <FieldHeadline mt="50px">Trust Account</FieldHeadline>
                <Box mt="12px">
                  <FormAutocompleteField
                    maxWidth="468px"
                    disabled={!type?.id || paymentStarted}
                    name="trustAccount"
                    label="Trust Account *"
                    optionsQuery={(params: PaginationQueryParams) => {
                      return handleGetTrustAccounts(params, leadCounselFirmId?.toString() || '');
                    }}
                    mapQueryResults={mapTrustAccountsQueryResults}
                    isDisableClearable={!trustAcc}
                  />
                </Box>

                <FieldHeadline mt="50px">Key Dates</FieldHeadline>
                <Box display="flex" alignItems="center" gap="16px" mt="12px">
                  <Box>
                    <FormDateField name="filedAt" label="Date Filed" disabled={!isDraft} />
                  </Box>
                  <Box>
                    <FormDateField name="sol" label="SOL Date" disabled={!isDraft} />
                  </Box>
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" width="100%" maxWidth="520px">
                <Box
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="space-between"
                  maxWidth="470px"
                >
                  <FieldHeadline>Client</FieldHeadline>
                  <FormLinkButton
                    path={`/clients/${clientFieldValue && clientFieldValue.id}/home`}
                    disabled={!clientFieldValue}
                  />
                </Box>
                <Box mt="12px">
                  <FormAutocompleteField
                    maxWidth="468px"
                    disabled={!isDraft}
                    name="clientId"
                    label="Client *"
                    optionsQuery={handleGetClients}
                    mapQueryResults={mapClientsQueryResults}
                    filters={{
                      firmId: leadCounselFirmId,
                    }}
                  />
                </Box>
                <Box mt="30px">
                  <AddButton buttonText="create new client" path="/clients/create-client" />
                </Box>

                <Box
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="space-between"
                  maxWidth="470px"
                >
                  <FieldHeadline mt="40px">Defendants</FieldHeadline>
                  <FormLinkButton
                    path={`/defendants?caseId=${caseId}`}
                    disabled={!(defendantsFieldValue && defendantsFieldValue[0]?.defendantId?.id)}
                  />
                </Box>

                {defendantFields.map((field, idx) => (
                  <Box display="flex" alignItems="center" gap="16px" key={field.id} mt="12px">
                    <FormAutocompleteField
                      name={`defendant.${idx}.defendantId`}
                      label="Defendant *"
                      maxWidth="272px"
                      disabled={!isDraft}
                      optionsQuery={handleGetDefendants}
                      mapQueryResults={mapDefendantsQueryResults}
                      filters={{
                        firmId: leadCounselFirmId,
                      }}
                    />

                    <FormTextField
                      name={`defendant.${idx}.gross`}
                      label="Gross Settlement"
                      maxWidth="180px"
                      disabled={!isDraft}
                    />

                    {idx >= 1 ? (
                      <IconButton
                        sx={{
                          padding: 0,
                          color: theme.palette.error.main,
                          marginTop: '13px',

                          '&.Mui-disabled': {
                            color: 'rgba(255, 255, 255, 0.3)',
                          },
                        }}
                        disabled={!isDraft}
                        onClick={() => defendantRemove(idx)}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    ) : null}
                  </Box>
                ))}

                <Box mt="30px" display="flex" justifyContent="space-between" maxWidth="468px">
                  <AddButton
                    buttonText="create new defendant"
                    path="/defendants/create-defendant"
                  />
                  <AddButton
                    buttonText="add defendant"
                    handleClick={handleAddDefendant}
                    disabled={!isDraft}
                  />
                </Box>

                <Box
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="space-between"
                  maxWidth="470px"
                >
                  <FieldHeadline mt="40px">Firms</FieldHeadline>
                  <FormLinkButton path={`/firms/${leadCounselFirmId}/home`} />
                </Box>

                {secondaryFirmsFields.map((field, idx) => (
                  <Box display="flex" alignItems="center" gap="16px" key={field.id} mt="12px">
                    <FormAutocompleteField
                      name={`secondaryFirms.${idx}.id`}
                      label="Firm *"
                      maxWidth="272px"
                      disabled={!isDraft}
                      optionsQuery={handleGetFirms}
                      mapQueryResults={mapFirmsQueryResults}
                      onValueChange={idx === 0 ? handleChangeFirmValue : undefined}
                    />

                    <FormSelectField
                      name={`secondaryFirms.${idx}.role`}
                      label="Role"
                      maxWidth="180px"
                      disabled={!isDraft}
                      options={idx > 0 ? SECONDARY_FIRM_ROLES_OPTIONS : FIRM_ROLES_OPTIONS}
                    />

                    {idx >= 1 ? (
                      <IconButton
                        sx={{
                          padding: 0,
                          color: theme.palette.error.main,
                          marginTop: '13px',

                          '&.Mui-disabled': {
                            color: 'rgba(255, 255, 255, 0.3)',
                          },
                        }}
                        disabled={!isDraft}
                        onClick={() => secondaryFirmRemove(idx)}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    ) : null}
                  </Box>
                ))}

                <Box mt="30px" display="flex" justifyContent="space-between" maxWidth="468px">
                  <AddButton buttonText="create new firm" path="/firms/create-firm" />
                  <AddButton
                    buttonText="add firm"
                    handleClick={handleAddSecondaryFirm}
                    disabled={!isDraft}
                  />
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" width="100%" maxWidth="220px">
                <FieldHeadline>Actions</FieldHeadline>

                {isDraft && (
                  <Box mt="16px">
                    <CommonButton
                      type="warning"
                      buttonText="submit"
                      buttonIcon={<SaveIcon />}
                      onButtonClick={handleSubmitCase}
                      disabled={!isValid || isDirty || !isDraft || isFormSubmitting}
                    />
                  </Box>
                )}

                <Box mt="24px">
                  <CommonButton
                    type="info"
                    buttonText="send app invite"
                    buttonIcon={<MobileScreenShareIcon />}
                    onButtonClick={handleResetClientAccount}
                    disabled={isAbandon || !isSendAppInviteAllowed}
                  />
                </Box>

                <Box mt="16px">
                  <CommonButton
                    type="info"
                    buttonText="issue virtual card"
                    buttonIcon={<CreditCardIcon />}
                    disabled
                  />
                </Box>

                <Box mt="16px">
                  <CommonButton
                    type="info"
                    buttonText="issue physical card"
                    buttonIcon={<CreditCardIcon />}
                    disabled
                  />
                </Box>

                <Box mt="16px">
                  <CommonButton
                    type="info"
                    buttonText="request document"
                    buttonIcon={<DocumentScannerIcon />}
                    disabled
                  />
                </Box>

                <Box mt="16px">
                  <CommonButton
                    type="info"
                    buttonText="request signature"
                    buttonIcon={<DrawIcon />}
                    disabled
                  />
                </Box>

                <Box mt="16px">
                  <CommonButton
                    type="info"
                    buttonText="request data"
                    buttonIcon={<RuleIcon />}
                    disabled
                  />
                </Box>

                <Box mt="24px">
                  <CommonButton
                    type="success"
                    buttonText="add deduction"
                    buttonIcon={<ReceiptIcon />}
                    disabled
                  />
                </Box>

                <Box mt="16px">
                  <CommonButton
                    type="success"
                    buttonText="process settlement"
                    buttonIcon={<PaymentsIcon />}
                    disabled
                  />
                </Box>

                <Box mt="24px">
                  <CommonButton
                    onButtonClick={() => setIsAbandonCasePopupOpen(true)}
                    type="error"
                    buttonText="abandon"
                    buttonIcon={<DoNotDisturbIcon />}
                    disabled={!isValid || isDirty || (!canAbandon as boolean) || isFormSubmitting}
                  />
                </Box>

                {/* <Box mt="16px">
                  <CommonButton
                    type="error"
                    buttonText="archive"
                    buttonIcon={<ArchiveIcon />}
                    disabled
                  />
                </Box> */}
              </Box>
            </Box>

            <Box display="flex" justifyContent="space-between" mt="40px">
              <FormSubmitButton
                disabled={
                  !isValid ||
                  !isDirty ||
                  isClosed ||
                  isAbandon ||
                  (isActive && paymentStarted) ||
                  isFormSubmitting
                }
                buttonIcon={<SaveIcon />}
                buttonText="Save"
                onClick={handleSubmit(onSubmitForm)}
              />
              {isDirty && <FormCancelButton onClick={handleCancelChanges} />}
            </Box>
          </form>
        </FormProvider>
        <Popup
          isOpen={isAbandonCasePopupOpen}
          headlineText={POPUP_DESCRIPTIONS.caseAbandon.headlineText}
          contentText={POPUP_DESCRIPTIONS.caseAbandon.contentText}
          onMainButtonClick={handleAbandonCase}
          onSecondaryButtonClick={() => setIsAbandonCasePopupOpen(false)}
        />
      </Box>
    </>
  );
};

export default CasesEditFormContainer;
