import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

// import ArchiveIcon from '@mui/icons-material/Archive';
import SaveIcon from '@mui/icons-material/Save';
import { Box, useTheme } from '@mui/material';

import { FirmsFormSubmitProps } from './types';
import { FieldHeadline, FieldsGroupWrapper } from './styled';

import FormCancelButton from '../../components/buttons/FormCancelButton';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
// import CommonButton from '../../components/buttons/CommonButton';
import FormTextField from '../../components/formFields/FormTextField';
import FormSelectField from '../../components/formFields/FormSelectField';
import FormToggleField from '../../components/formFields/FormToggleField';
import { CLIENT_ACTIVATION_TRIGGER_OPTIONS, US_STATES_LIST } from '../../constants/constants';
import { editFirmFormSchema } from '../../helpers/formSchemas/formSchemas';
import { EDIT_FIRM_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { getFirmRecord, updateFirmRecord, uploadFirmLogo } from '../../api/firmsApi/firmsApi';
import FormCopyTextField from '../../components/formFields/FormCopyTextField';
import usePageTitle from '../../hooks/usePageTitle';
import useSnackBar from '../../hooks/useSnackBar';
import DropzoneField from '../../components/formFields/DropzoneField';
import FormMaskedTextField from '../../components/formFields/FormMaskedTextField';

const FirmsEditFormContainer = () => {
  const { firmId } = useParams<string>();
  const { setPageTitle } = usePageTitle();
  const { setAlert } = useSnackBar();

  const theme = useTheme();
  const navigate = useNavigate();

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [preloadedFileUrl, setPreloadedFileUrl] = useState<string>('');
  const [files, setFiles] = useState<File[] | [] | string[]>([]);
  const [fileError, setFileError] = useState<boolean>(false);
  const [shouldShowCancelButton, setShouldShowCancelButton] = useState<boolean>(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(editFirmFormSchema),
    defaultValues: EDIT_FIRM_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    watch,
    resetField,
    formState: { isDirty, isValid },
  } = formMethods;

  const clientTriggerToggleValue = watch('isClientTriggerActive');
  const docusignFieldsToggleValue = watch('docusignKeys.isDocusignToggleActive');

  const onSubmitForm = (values: FirmsFormSubmitProps) => {
    setIsFormSubmitting(true);

    const isDocusignFullFilled = Object.values(values.docusignKeys).every(
      (objValue) => String(objValue).length >= 1,
    );

    const submitData = {
      state: values.state,
      name: values.name,
      tin: values.tin,
      address1: values.address1,
      city: values.city,
      zip: values.zip,
      zendeskBrandName: values.zendeskBrandName,
      address2: values.address2?.length ? values.address2 : null,
      // brandColor: values.brandColor?.length ? values.brandColor : null,
      ...(isDocusignFullFilled &&
        values.docusignKeys.isDocusignToggleActive && {
          docusignKeys: {
            accountId: values.docusignKeys.accountId,
            integrationKey: values.docusignKeys.integrationKey,
            userId: values.docusignKeys.userId,
            rsaPrivateKey: values.docusignKeys.rsaPrivateKey,
            connectKey: values.docusignKeys.connectKey,
            baseUri: values.docusignKeys.baseUri,
          },
        }),
    };

    updateFirmRecord(firmId as string, submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);

          if (files.length) {
            if (typeof files[0] !== 'string') uploadFirmLogo(firmId as string, files[0]);
          }

          setAlert((prev) => ({
            ...prev,
            message: 'Firm updated successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      })
      .then(() => {
        navigate('/firms');
      });
  };

  const handleCancelChanges = () => {
    reset();
    setFiles([preloadedFileUrl]);
    setFileError(false);
    setShouldShowCancelButton(false);
  };

  useEffect(() => {
    getFirmRecord(firmId as string).then((res) => {
      if (res.data) {
        const isDocusignFullFilled =
          res.data.docusignKeys !== null &&
          Object.values(res.data.docusignKeys).every((objValue) => String(objValue).length >= 1);

        setPageTitle && setPageTitle(res.data.name);

        if (res.data.logoImageUrl) {
          setPreloadedFileUrl(res.data.logoImageUrl);
          setFiles([res.data.logoImageUrl]);
        }

        reset({
          address1: res.data.address1,
          address2: res.data.address2 || '',
          // brandColor: res.data.brandColor || '',
          id: res.data.id,
          name: res.data.name,
          state: res.data.state,
          tin: res.data.tin,
          zendeskBrandName: res.data.zendeskBrandName,
          zip: res.data.zip,
          city: res.data.city,
          docusignKeys: {
            isDocusignToggleActive: isDocusignFullFilled,
            accountId: res.data.docusignKeys?.accountId || '',
            baseUri: res.data.docusignKeys?.baseUri || '',
            connectKey: res.data.docusignKeys?.connectKey || '',
            integrationKey: res.data.docusignKeys?.integrationKey || '',
            rsaPrivateKey: res.data.docusignKeys?.rsaPrivateKey || '',
            userId: res.data.docusignKeys?.userId || '',
          },
        });
      }
    });

    return () => setPageTitle && setPageTitle('');
  }, [firmId]);

  useEffect(() => {
    if (JSON.stringify(preloadedFileUrl) === JSON.stringify(files[0])) {
      setShouldShowCancelButton(false);
    } else {
      setShouldShowCancelButton(true);
    }
  }, [files, preloadedFileUrl]);

  useEffect(() => {
    !docusignFieldsToggleValue && resetField('docusignKeys');
  }, [docusignFieldsToggleValue]);

  return (
    <>
      <Box display="flex" bgcolor={theme.palette.primary.dark} width="100%">
        <FormProvider {...formMethods}>
          <form style={{ width: '100%', padding: '16px' }}>
            <Box display="flex" width="100%" gap="32px">
              <FieldsGroupWrapper>
                <FieldHeadline>Identity</FieldHeadline>

                <Box mt="10px">
                  <FormCopyTextField entityName="Firm" name="id" label="Id" isReadOnly />
                </Box>

                <Box mt="20px">
                  <FormTextField name="name" label="Name *" />
                </Box>
                <Box mt="20px">
                  <FormMaskedTextField mask="99-9999999" name="tin" label="TIN *" />
                </Box>

                <FieldHeadline mt="50px">Address</FieldHeadline>

                <Box mt="10px">
                  <FormTextField name="address1" label="Address Line 1 *" />
                </Box>
                <Box mt="20px">
                  <FormTextField name="address2" label="Address Line 2" />
                </Box>

                <Box mt="20px" display="flex" gap="20px" flexDirection="column">
                  <FormTextField name="city" label="City *" />
                  <FormSelectField options={US_STATES_LIST} name="state" label="State *" />
                  <FormTextField name="zip" label="Zip *" />
                </Box>
              </FieldsGroupWrapper>

              <FieldsGroupWrapper>
                <FieldHeadline>Branding</FieldHeadline>

                <Box>
                  <DropzoneField
                    name="logoImageId"
                    setFiles={setFiles}
                    files={files}
                    setFileError={setFileError}
                    fileError={fileError}
                  />
                </Box>

                {/* <Box mt="20px">
                  <FormTextField name="brandColor" label="Primary Color" />
                </Box> */}

                <FieldHeadline mt="50px">Support</FieldHeadline>

                <Box mt="10px">
                  <FormTextField name="zendeskBrandName" label="Zendesk Brand Name *" />
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center" mt="51px">
                  <FieldHeadline>DocuSign</FieldHeadline>
                  <FormToggleField
                    name="docusignKeys.isDocusignToggleActive"
                    label={docusignFieldsToggleValue ? 'Enabled' : 'Disabled'}
                  />
                </Box>

                <Box mt="17px">
                  <FormTextField
                    name="docusignKeys.accountId"
                    label="Account Id"
                    disabled={!docusignFieldsToggleValue}
                  />
                </Box>

                <Box mt="17px">
                  <FormTextField
                    name="docusignKeys.baseUri"
                    label="Base URI"
                    disabled={!docusignFieldsToggleValue}
                  />
                </Box>

                <Box mt="17px">
                  <FormTextField
                    name="docusignKeys.userId"
                    label="User Id"
                    disabled={!docusignFieldsToggleValue}
                  />
                </Box>

                <Box mt="17px">
                  <FormTextField
                    name="docusignKeys.connectKey"
                    label="Connect Key"
                    disabled={!docusignFieldsToggleValue}
                  />
                </Box>

                <Box mt="17px">
                  <FormTextField
                    name="docusignKeys.integrationKey"
                    label="Integration Key"
                    disabled={!docusignFieldsToggleValue}
                  />
                </Box>

                <Box mt="17px">
                  <FormTextField
                    isMultiline
                    name="docusignKeys.rsaPrivateKey"
                    label="RSA Private Key"
                    disabled={!docusignFieldsToggleValue}
                  />
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center" mt="50px">
                  <FieldHeadline>Client Activation Triggers</FieldHeadline>
                  <FormToggleField disabled name="isClientTriggerActive" label="Enabled" />
                </Box>

                <Box mt="10px">
                  <FormSelectField
                    options={CLIENT_ACTIVATION_TRIGGER_OPTIONS.sendAppInviteUpon}
                    name="sendAppInviteTrigger"
                    label="Send App Invite Upon *"
                    disabled={!clientTriggerToggleValue}
                  />
                </Box>

                <Box mt="20px">
                  <FormSelectField
                    options={CLIENT_ACTIVATION_TRIGGER_OPTIONS.createWalletUpon}
                    name="createWalletTrigger"
                    label="Create Wallet Upon *"
                    disabled={!clientTriggerToggleValue}
                  />
                </Box>

                <Box mt="20px">
                  <FormSelectField
                    options={CLIENT_ACTIVATION_TRIGGER_OPTIONS.issueVirtualCardUpon}
                    name="issueVirtualCardTrigger"
                    label="Issue Virtual Card Upon *"
                    disabled={!clientTriggerToggleValue}
                  />
                </Box>

                <Box mt="20px">
                  <FormSelectField
                    options={CLIENT_ACTIVATION_TRIGGER_OPTIONS.issuePhysicalCardUpon}
                    name="issuePhysicalCardTrigger"
                    label="Issue Physical Card Upon *"
                    disabled={!clientTriggerToggleValue}
                  />
                </Box>
              </FieldsGroupWrapper>

              {/* <FieldsGroupWrapper>
                <FieldHeadline>Actions</FieldHeadline>
                <Box mt="25px">
                  <CommonButton
                    disabled
                    type="error"
                    buttonText="archive"
                    buttonIcon={<ArchiveIcon />}
                  />
                </Box>
              </FieldsGroupWrapper> */}
            </Box>

            <Box display="flex" justifyContent="space-between" mt="40px">
              <FormSubmitButton
                disabled={!isValid || fileError || isFormSubmitting}
                buttonIcon={<SaveIcon />}
                buttonText="Save"
                onClick={handleSubmit(onSubmitForm as () => void)}
              />
              {(isDirty || shouldShowCancelButton) && (
                <FormCancelButton onClick={handleCancelChanges} />
              )}
            </Box>
          </form>
        </FormProvider>
      </Box>
    </>
  );
};

export default FirmsEditFormContainer;
