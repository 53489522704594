import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { TableCell, TableRow, Theme } from '@mui/material';

import { formatDate } from '../../../helpers/formatDate';
import { SettlementData } from '../../../types';
import { numberToUSDCurrency } from '../../../helpers/numberToUSDCurrency';
import { parseStatusTitle } from '../../../helpers/parseStatusTitle';

const useStyles = makeStyles()((theme: Theme) => ({
  styledLink: {
    textDecoration: 'none',
    color: theme.palette.info.main,
  },
}));

interface SettlementsTableDataRowProps {
  tableData: SettlementData[];
  isRelatedToOtherEntity?: { accessor: string; isSortable: boolean; label: string }[];
}

const SettlementsTableDataRow = ({
  tableData,
  isRelatedToOtherEntity,
}: SettlementsTableDataRowProps) => {
  const styles = useStyles();

  const formattedFirmsList =
    tableData &&
    tableData.map((settlement: SettlementData) => ({
      name: (
        <Link
          className={styles.classes.styledLink}
          to={`/settlements/${String(settlement.id)}/home`}
        >
          {settlement.case.name}
        </Link>
      ),
      ...(!isRelatedToOtherEntity && { firm: settlement.firm.name }),
      // ...((tableLabelsObjectToFlat?.includes('Client') || !isRelatedToOtherEntity) && {
      plaintiff: `${settlement.client.firstName} ${settlement.client.lastName}`,
      // }),
      defendant: settlement.defendant?.name || '-',
      entryDate: formatDate(settlement.createdAt as string),
      amount: numberToUSDCurrency(settlement.settledAmount),
      // eslint-disable-next-line quotes
      // ...(tableLabelsObjectToFlat?.includes("Attorneys' Fees") && {
      fees: settlement?.attorneyFees ? numberToUSDCurrency(settlement?.attorneyFees) : '-',
      // }),
      // ...(tableLabelsObjectToFlat?.includes('Deductions') && {
      deductions: settlement?.deductions ? numberToUSDCurrency(settlement?.deductions) : '-',
      // }),
      // ...(tableLabelsObjectToFlat?.includes('Net Settlement') && {
      net: settlement?.netSettlement ? numberToUSDCurrency(settlement?.netSettlement) : '-',
      // }),
      status: parseStatusTitle(settlement.status),
      paidAt: settlement.paidAt ? formatDate(settlement.paidAt as string) : '-',
    }));

  return (
    <>
      {formattedFirmsList.map((tableItem, idx) => (
        <TableRow key={idx}>
          {Object.values(tableItem).map((item, idx) => (
            <TableCell key={idx}>{item}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default SettlementsTableDataRow;
