import { makeStyles } from 'tss-react/mui';

import { Theme } from '@mui/material';

export const sidebarStyles = makeStyles()((theme: Theme) => ({
  activeLink: {
    color: theme.palette.primary.light,
    fontWeight: 500,
  },
  defaultLink: {
    color: theme.palette.secondary.light,
  },
  linkHover: {
    '& :hover:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderLeft: `3px solid ${theme.palette.info.main}`,
      cursor: 'pointer',
    },
  },
  activeLinkBorder: {
    '& :after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderLeft: `3px solid ${theme.palette.info.main}`,
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pointerOnHover: {
    '& :hover': {
      cursor: 'pointer',
    },
  },
  menuItemWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    color: theme.palette.primary.light,
    position: 'relative',
  },
  upperItemsListContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    paddingTop: '15px',
  },
  bottomItemsListContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    paddingBottom: '15px',
    marginTop: 'auto',
  },
  divider: {
    display: 'flex',
    height: '1px',
    background: '#515151',
    margin: '8px 16px 0px 16px',
    width: '100%',
  },
  collapseControlContainer: {
    display: 'flex',
    gap: '16px',
    maxHeight: '24px',

    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
