import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { TableCell, TableRow, Theme } from '@mui/material';

import { formatDate } from '../../helpers/formatDate';
import { CustomerData } from '../../types';
import { numberToUSDCurrency } from '../../helpers/numberToUSDCurrency';

const useStyles = makeStyles()((theme: Theme) => ({
  styledLink: {
    textDecoration: 'none',
    color: theme.palette.info.main,
  },
}));

interface CustomerTableDataRowProps {
  tableData: CustomerData[];
}

const CustomerTableDataRow = ({ tableData }: CustomerTableDataRowProps) => {
  const styles = useStyles();

  const formattedCustomersList =
    tableData &&
    tableData.map((client) => ({
      name: (
        <Link className={styles.classes.styledLink} to={`/customers/${String(client.id)}/home`}>
          {`${client.firstName} ${client.middleName || ''} ${client.lastName}`}
        </Link>
      ),
      email: client.email,
      createdAt: formatDate(client.createdAt),
      status: client.status,
      activeCases: client.additonalInfo.activeCasesCount,
      grossSettlements: numberToUSDCurrency(client?.additonalInfo.grossSettlements),
      netSettlements: numberToUSDCurrency(client?.additonalInfo.netSettlements),
      walletBalance: '-',
    }));

  return (
    <>
      {formattedCustomersList.map((tableItem, idx) => (
        <TableRow key={idx}>
          {Object.values(tableItem).map((item, idx) => (
            <TableCell key={idx}>{item}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default CustomerTableDataRow;
