import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Theme, Typography } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  createButtonContainer: {
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  buttonInnerContainer: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    color: theme.palette.info.main,

    '&:hover': {
      cursor: 'pointer',
    },
  },
  linkMargin: {
    marginLeft: '8px',
  },
}));

const GoBackButton = () => {
  const styles = useStyles();
  const navigate = useNavigate();

  const onBack = () => {
    navigate(-1);
  };

  return (
    <Box className={styles.classes.createButtonContainer}>
      <Box className={styles.classes.buttonInnerContainer} onClick={onBack}>
        <ArrowBackIcon />
        <Typography fontSize="13px" textTransform="uppercase" lineHeight="15px" mt="3px">
          Go back
        </Typography>
      </Box>
    </Box>
  );
};

export default GoBackButton;
