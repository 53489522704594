import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { FormControlLabel, Checkbox, Theme, useTheme } from '@mui/material';

const useStyles = makeStyles<{ isCaution?: boolean }>()((theme: Theme, { isCaution }) => ({
  root: {
    marginLeft: 0,
    '& .MuiCheckbox-root': {
      padding: 0,
      paddingRight: '8px',
      color: isCaution ? theme.palette.error.main : theme.palette.common.white,
    },
    '& .MuiFormControlLabel-label': {
      color: isCaution ? theme.palette.error.main : theme.palette.common.white,
      fontSize: isCaution ? '16px' : '14px',
      fontWeight: isCaution ? 400 : 500,
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
  },
}));

interface FormCheckboxFieldProps {
  name: string;
  label?: string;
  disabled?: boolean;
  isCaution?: boolean;
}

const FormCheckboxField = ({ name, label, isCaution }: FormCheckboxFieldProps) => {
  const { control } = useFormContext();
  const styles = useStyles({ isCaution });
  const theme = useTheme();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          className={styles.classes.root}
          control={
            <Checkbox
              sx={{
                '&.Mui-checked': {
                  color: `${
                    isCaution ? theme.palette.error.main : theme.palette.info.main
                  } !important`,
                },
              }}
              disableRipple
              onChange={onChange}
              checked={value}
            />
          }
          label={label}
        />
      )}
    />
  );
};

export default FormCheckboxField;
