import api from '../service/api.service';

import { ActiveSortField, PaginationQueryParams } from '../../types';
import { parseQueryFilters } from '../../helpers/parseQueryFilters';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';

const BASE_URL_V3 = '/v3/settlements';
const BASE_URL_V4 = '/v4/settlements';

export const getSettlementsList = async ({
  size,
  page,
  count,
  match,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL_V4}?size=${size}&page=${page}&count=${count}&match=${match || ''}${
    queryFilters || ''
  }${sortFilters}`;

  return await api.get(targetUrl);
};

export const createSettlementRecord = async (data: unknown) => {
  return await api.post(BASE_URL_V4, data);
};

export const getSettlementRecord = async (settlementId: string) => {
  const targetUrl = `${BASE_URL_V4}/${settlementId}`;

  return await api.get(targetUrl);
};

export const updateSettlementRecord = async (settlementId: string, data: unknown) => {
  const targetUrl = `${BASE_URL_V4}/${settlementId}`;

  return await api.put(targetUrl, data);
};

export const getSettlementTimeline = async (settlementId: string) => {
  const targetUrl = `${BASE_URL_V3}/${settlementId}/timeline`;

  return await api.get(targetUrl);
};

export const getSettlementsCheck = async (settlementId: string, deductionIds?: number[]) => {
  const targetUrl = `${BASE_URL_V4}/${settlementId}/payment-preview`;

  return await api.get(targetUrl, {
    params: {
      includedDeductionIds: deductionIds ? deductionIds : {},
    },
  });
};

export const deleteSettlementRecord = async (settlementId: string) => {
  const targetUrl = `${BASE_URL_V3}/${settlementId}`;

  return await api.delete(targetUrl);
};

export const getSettlementDetails = async (settlementId: string) => {
  const targetUrl = `${BASE_URL_V4}/${settlementId}/payment-details`;

  return await api.get(targetUrl);
};

export const holdSettlement = async (settlementId: string) => {
  const targetUrl = `${BASE_URL_V4}/${settlementId}/hold`;

  return await api.put(targetUrl);
};

export const releaseSettlement = async (settlementId: string) => {
  const targetUrl = `${BASE_URL_V4}/${settlementId}/release`;

  return await api.put(targetUrl);
};

export const paySettlement = async (settlementId: string, pin: string, deductionIds?: number[]) => {
  const targetUrl = `${BASE_URL_V3}/${settlementId}/pay`;

  return await api.put(targetUrl, deductionIds ? { includedDeductionIds: deductionIds } : {}, {
    headers: { 'x-pin': pin },
  });
};

export const paySettlementRetry = async (settlementId: string) => {
  const targetUrl = `${BASE_URL_V4}/${settlementId}/retry`;
  return await api.put(targetUrl);
};
