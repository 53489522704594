import { Navigate } from 'react-router';

import useAuth from '../../hooks/useAuth';

const AWS_COGNITO_AUTH_URL = process.env.REACT_APP_AWS_COGNITO_AUTH_URL;
const CLIENT_ID = process.env.REACT_APP_AWS_COGNITO_CLIEND_ID;
const AWS_COGNITO_REDIRECT_URL = process.env.REACT_APP_AWS_COGNITO_REDIRECT_URL;

export const AWS_SIGN_IN_URL = `${AWS_COGNITO_AUTH_URL}/login?client_id=${CLIENT_ID}&response_type=code&scope=openid&redirect_uri=${AWS_COGNITO_REDIRECT_URL}`;
export const AWS_LOGOUT_URL = `${AWS_COGNITO_AUTH_URL}/logout?client_id=${CLIENT_ID}&response_type=code&scope=openid&redirect_uri=${AWS_COGNITO_REDIRECT_URL}`;

const SignInView = () => {
  const { token } = useAuth();

  if (token) {
    return <Navigate to="firms" />;
  } else {
    window.open(AWS_SIGN_IN_URL, '_self');
  }

  return null;
};

export default SignInView;
