import api from '../service/api.service';

import { parseQueryFilters } from '../../helpers/parseQueryFilters';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';
import { ActiveSortField, ClientData, PaginationQueryParams, ResultSet } from '../../types';

const BASE_URL_V1 = '/v1/clients';

export const createClientRecord = async (data: unknown) => {
  return await api.post(BASE_URL_V1, data);
};

export const getClientsList = async ({
  size,
  page,
  count,
  match,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL_V1}?size=${size}&page=${page}&count=${count}&match=${match || ''}${
    queryFilters || ''
  }${sortFilters}&includeAdditionalInfo=true`;

  return await api.get<ResultSet<ClientData>>(targetUrl);
};

export const getClientRecord = async (id: number | string) => {
  const targetUrl = `${BASE_URL_V1}/${id}`;

  return await api.get<ClientData>(targetUrl);
};

export const updateClientRecord = async (clientId: string, data: unknown) => {
  const targetUrl = `${BASE_URL_V1}/${clientId}`;

  return await api.put(targetUrl, data);
};

export const resetClientAccount = async (clientId: string) => {
  const targetUrl = `${BASE_URL_V1}/${clientId}/invite`;

  return await api.put(targetUrl, null);
};

export const getClientBirthDate = async (id: number) => {
  const targetUrl = `${BASE_URL_V1}/${id}/birthdate`;

  return await api.get<{ birthdate: string; id: number }>(targetUrl);
};

export const getClientSSN = async (id: number) => {
  const targetUrl = `${BASE_URL_V1}/${id}/ssn`;

  return await api.get<{ id: number; ssn: string }>(targetUrl);
};

export const updateClientPersonalInformation = async (
  id: number,
  data: { ssn: string; birthdate: Date | null | string },
) => {
  const targetUrl = `${BASE_URL_V1}/${id}/ssn-dob`;

  return await api.put(targetUrl, {
    ...data,
  });
};

export const sendEmailConfirmation = async (id: number) => {
  const targetUrl = `${BASE_URL_V1}/${id}/send-email-confirmation`;

  return await api.put<{ id: number; ssn: string }>(targetUrl);
};
