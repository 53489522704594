import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { TableCell, TableRow, Theme } from '@mui/material';

import { formatDate } from '../../helpers/formatDate';
import { translateStatus } from '../../helpers/translateStatus';
import { AdminsListData } from '../../types';

const useStyles = makeStyles()((theme: Theme) => ({
  styledLink: {
    textDecoration: 'none',
    color: theme.palette.info.main,
  },
}));

interface AdminsTableDataRowProps {
  tableData: AdminsListData[];
}

const AdminsTableDataRow = ({ tableData }: AdminsTableDataRowProps) => {
  const styles = useStyles();

  const formattedAdminsList =
    tableData &&
    tableData.map((admin) => ({
      name: (
        <Link
          className={styles.classes.styledLink}
          to={`/admins/${String(admin.id)}/home`}
        >{`${admin.firstName} ${admin.lastName}`}</Link>
      ),
      createdAt: formatDate(admin.createdAt),
      role: 'Admin',
      status: translateStatus(admin.isEnabled),
      email: admin.email,
      phone: admin.phone,
    }));

  return (
    <>
      {formattedAdminsList.map((tableItem, idx) => (
        <TableRow key={idx}>
          {Object.values(tableItem).map((item, idx) => (
            <TableCell key={idx}>{item}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default AdminsTableDataRow;
