import { Navigate, Route, Routes } from 'react-router-dom';

import SignInView from '../views/SignInView/SignInView';
import AuthVerifyView from '../views/SignInView/AuthVerifyView';
import ProtectedRoute from './ProtectedRoute';
// import DashboardView from '../views/DashboardView/DashboardView';
import ManageAccountView from '../views/ManageAccountView/ManageAccountView';
import NoMatchRoute from './NoMatchRoute';
import FirmsView from '../views/FirmsView/FirmsView';
import UsersView from '../views/UsersView/UsersView';
import CasesView from '../views/CasesView/CasesView';
import CaseTypesView from '../views/CaseTypesView/CaseTypesView';
import DefendantsView from '../views/DefendantsView/DefendantsView';
// import FileManagerView from '../views/FileManagerView/FileManagerView';
import CommunicationsView from '../views/CommunicationsView/CommunicationsView';
// import FinancialView from '../views/FinancialView/FinancialView';
import ManageAccountHomeTab from '../views/ManageAccountView/ManageAccountHomeTab/ManageAccountHomeTab';
import AdminsView from '../views/AdminsView/AdminsView';
import AdminCreateFormContainer from '../views/AdminsView/AdminCreateFormContainer';
import AdminAccountView from '../views/AdminsView/AdminAccountView';
import AdminEditFormContainer from '../views/AdminsView/AdminEditFormContainer';
import FirmsCreateFormContainer from '../views/FirmsView/FirmsCreateFormContainer';
import FirmAccountView from '../views/FirmsView/FirmAccountView';
import FirmsEditFormContainer from '../views/FirmsView/FirmsEditFormContainer';
import UsersCreateFormContainer from '../views/UsersView/UsersCreateFormContainer';
import UsersEditFormContainer from '../views/UsersView/UsersEditFormContainer';
import UsersAccountView from '../views/UsersView/UsersAccountView';
import DefendantsCreateFormContainer from '../views/DefendantsView/DefendantsCreateFormContainer';
import DefendantsEditFormContainer from '../views/DefendantsView/DefendantsEditFormContainer';
import PaginationProvider from '../providers/PaginationProvider';
import CasesCreateFormContainer from '../views/CasesView/CasesCreateFormContainer';
import CasesEditFormContainer from '../views/CasesView/CasesEditFormContainer';
import CaseAccountView from '../views/CasesView/CaseAccountView';
// import FirmCodesTab from '../views/FirmsView/firmTabs/firmCodes/FirmCodesTab';
// import FirmCategoriesTab from '../views/FirmsView/firmTabs/firmCategories/FirmCategoriesTab';
import CreateDeductionFormContainer from '../views/PaymentsView/DeductionsView/CreateDeductionFormContainer';
import CreateSettlementFormContainer from '../views/PaymentsView/SettlementsView/CreateSettlementFormContainer';
import DefendantAccountView from '../views/DefendantsView/DefendantAccountView';
import DeductionsView from '../views/PaymentsView/DeductionsView/DeductionsView';
import SettlementsView from '../views/PaymentsView/SettlementsView/SettlementsView';
import DeductionsEditFormContainer from '../views/PaymentsView/DeductionsView/DeductionsEditFormContainer';
import DeductionAccountView from '../views/PaymentsView/DeductionsView/DeductionAccountView';
import SettlementAccountView from '../views/PaymentsView/SettlementsView/SettlementAccountView';
import SettlementsEditFormContainer from '../views/PaymentsView/SettlementsView/SettlementsEditFormContainer';
import DataView from '../views/RequestsView/DataView/DataView';
import SignaturesView from '../views/RequestsView/SignaturesView/SignaturesView';
import DocumentsView from '../views/RequestsView/DocumentsView/DocumentsView';
import CreateDataFormContainer from '../views/RequestsView/DataView/CreateDataFormContainer';
import DataAccountView from '../views/RequestsView/DataView/DataAccountView';
// import CreateSignatureFormContainer from '../views/RequestsView/SignaturesView/CreateSignatureFormContainer';
import SignaturesAccountView from '../views/RequestsView/SignaturesView/SignaturesAccountView';
import CreateDocumentFormContainer from '../views/RequestsView/DocumentsView/CreateDocumentFormContainer';
import DocumentsAccountView from '../views/RequestsView/DocumentsView/DocumentsAccountView';
import SignaturesEditFormContainer from '../views/RequestsView/SignaturesView/SignaturesEditFormContainer';
import DocumentsEditFormContainer from '../views/RequestsView/DocumentsView/DocumentsEditFormContainer';
import DataEditFormContainer from '../views/RequestsView/DataView/DataEditFormContainer';
import DocumentsTimeline from '../views/RequestsView/DocumentsView/DocumentsTimeline';
import SignaturesTimeline from '../views/RequestsView/SignaturesView/SignaturesTimeline';
import DataTimeline from '../views/RequestsView/DataView/DataTimeline';
import SettlementsTimeline from '../views/PaymentsView/SettlementsView/SettlementsTimeline';
import DeductionsTimeline from '../views/PaymentsView/DeductionsView/DeductionsTimeline';
import {
  CASES_DATA_REQUEST_LIST_TABLE_LABELS,
  CASES_DEDUCTIONS_LIST_TABLE_LABELS,
  CASES_DOCUMENTS_LIST_TABLE_LABELS,
  CASES_SETTLEMENTS_LIST_TABLE_LABELS,
  CASES_SIGNATURE_LIST_TABLE_LABELS,
  CLIENTS_CASES_LIST_TABLE_LABELS,
  CUSTOMER_CASES_LIST_TABLE_LABELS,
  CUSTOMER_FIRMS_LIST_TABLE_LABELS,
  FIRM_CASES_LIST_TABLE_LABELS,
  FIRM_DATA_REQUEST_LIST_TABLE_LABELS,
  FIRM_DEDUCTIONS_LIST_TABLE_LABELS,
  FIRM_DEFENDANTS_LIST_TABLE_LABELS,
  FIRM_DOCUMENTS_LIST_TABLE_LABELS,
  FIRM_LAWYERS_LIST_TABLE_LABELS,
  FIRM_SETTLEMENTS_LIST_TABLE_LABELS,
  FIRM_SIGNATURE_LIST_TABLE_LABELS,
} from '../constants/tableLabels';
import GlobalCaseTypesView from '../views/CaseTypesView/GlobalCaseTypesView';
import CaseAttorneyFeesView from '../views/CasesView/CaseAttorneyFeesView';
import RequestStateProvider from '../providers/RequestStateProvider';
import FirmBankingView from '../views/FirmsView/firmTabs/firmBanking/FirmBankingView';
import QuestionnairesView from '../views/QuestionnairesView/QuestionnairesView';
import GlobalQuestionnairesView from '../views/QuestionnairesView/GlobalQuestionnairesView';
import FormSnippetsView from '../views/FormSnippetsView/FormSnippetsView';
import GlobalFormSnippetsView from '../views/FormSnippetsView/GlobalFormSnippetsView';
import SettlementsDetailsView from '../views/PaymentsView/SettlementsView/SettlementsDetailsView';
import CustomersView from '../views/CustomersView/CustomerView';
import CustomerAccountView from '../views/CustomersView/CustomerAccountView';
import CustomerEditFormContainer from '../views/CustomersView/CustomerEditFormContainer';
import CustomerBankingView from '../views/CustomersView/customerBanking/CustomerBankingView';
import ClientsView from '../views/ClientsView/ClientsView';
import ClientAccountView from '../views/ClientsView/ClientAccountView';
import ClientEditFormContainer from '../views/ClientsView/ClientEditFormContainer';
import ClientCreateFormContainer from '../views/ClientsView/ClientCreateFormContainer';

const AppRoutes = () => {
  return (
    <Routes>
      <Route index element={<SignInView />} />
      <Route path="auth/callback" element={<AuthVerifyView />} />

      <Route path="firms">
        <Route
          index
          element={
            <ProtectedRoute>
              <PaginationProvider key="firmsView">
                <FirmsView />
              </PaginationProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="create-firm"
          element={
            <ProtectedRoute>
              <FirmsCreateFormContainer />
            </ProtectedRoute>
          }
        />

        <Route
          path=":firmId"
          element={
            <ProtectedRoute>
              <FirmAccountView />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <FirmsEditFormContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="users"
            element={
              <PaginationProvider key="firmsUsersView">
                <UsersView isRelatedToOtherEntity={FIRM_LAWYERS_LIST_TABLE_LABELS} />
              </PaginationProvider>
            }
          />
          <Route
            path="banking"
            element={
              <ProtectedRoute>
                <FirmBankingView />
              </ProtectedRoute>
            }
          />
          <Route
            path="case-types"
            element={
              <ProtectedRoute>
                <CaseTypesView />
              </ProtectedRoute>
            }
          />
          <Route
            path="questionnaires"
            element={
              <ProtectedRoute>
                <QuestionnairesView />
              </ProtectedRoute>
            }
          />
          <Route
            path="snippets"
            element={
              <ProtectedRoute>
                <FormSnippetsView />
              </ProtectedRoute>
            }
          />
          <Route
            path="cases"
            element={
              <ProtectedRoute>
                <PaginationProvider key="firmsCasesView">
                  <CasesView isRelatedToOtherEntity={FIRM_CASES_LIST_TABLE_LABELS} />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="clients"
            element={
              <ProtectedRoute>
                <PaginationProvider key="firmsClientsView">
                  <ClientsView />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="defendants"
            element={
              <ProtectedRoute>
                <PaginationProvider key="firmsDefendantsView">
                  <DefendantsView isRelatedToOtherEntity={FIRM_DEFENDANTS_LIST_TABLE_LABELS} />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="document-requests"
            element={
              <ProtectedRoute>
                <PaginationProvider key="firmsDocumentsView">
                  <DocumentsView isRelatedToOtherEntity={FIRM_DOCUMENTS_LIST_TABLE_LABELS} />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="data-requests"
            element={
              <ProtectedRoute>
                <PaginationProvider key="firmsDataView">
                  <DataView isRelatedToOtherEntity={FIRM_DATA_REQUEST_LIST_TABLE_LABELS} />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />

          <Route
            path="signature-requests"
            element={
              <ProtectedRoute>
                <PaginationProvider key="firmsSignaturesView">
                  <SignaturesView isRelatedToOtherEntity={FIRM_SIGNATURE_LIST_TABLE_LABELS} />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="deductions"
            element={
              <ProtectedRoute>
                <PaginationProvider key="firmsDeductionsView">
                  <DeductionsView isRelatedToOtherEntity={FIRM_DEDUCTIONS_LIST_TABLE_LABELS} />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="settlements"
            element={
              <ProtectedRoute>
                <PaginationProvider key="firmsSettlementsView">
                  <SettlementsView isRelatedToOtherEntity={FIRM_SETTLEMENTS_LIST_TABLE_LABELS} />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>

      <Route path="users">
        <Route
          index
          element={
            <ProtectedRoute>
              <PaginationProvider key="usersView">
                <UsersView />
              </PaginationProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="create-user"
          element={
            <ProtectedRoute>
              <UsersCreateFormContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path=":lawyerId"
          element={
            <ProtectedRoute>
              <UsersAccountView />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <UsersEditFormContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="activity"
            element={
              <ProtectedRoute>
                <div>Page is in development</div>
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>

      <Route path="cases">
        <Route
          index
          element={
            <ProtectedRoute>
              <PaginationProvider key="casesView">
                <CasesView />
              </PaginationProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="create-case"
          element={
            <ProtectedRoute>
              <CasesCreateFormContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path=":caseId"
          element={
            <ProtectedRoute>
              <CaseAccountView />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <CasesEditFormContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="attorneys-fees"
            element={
              <ProtectedRoute>
                <CaseAttorneyFeesView />
              </ProtectedRoute>
            }
          />
          <Route
            path="document-requests"
            element={
              <ProtectedRoute>
                <PaginationProvider key="casesDocumentsView">
                  <DocumentsView isRelatedToOtherEntity={CASES_DOCUMENTS_LIST_TABLE_LABELS} />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="data-requests"
            element={
              <ProtectedRoute>
                <PaginationProvider key="casesDataView">
                  <DataView isRelatedToOtherEntity={CASES_DATA_REQUEST_LIST_TABLE_LABELS} />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="signature-requests"
            element={
              <ProtectedRoute>
                <PaginationProvider key="casesSignaturesView">
                  <SignaturesView isRelatedToOtherEntity={CASES_SIGNATURE_LIST_TABLE_LABELS} />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="deductions"
            element={
              <ProtectedRoute>
                <PaginationProvider key="casesDeductionsView">
                  <DeductionsView isRelatedToOtherEntity={CASES_DEDUCTIONS_LIST_TABLE_LABELS} />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="settlements"
            element={
              <ProtectedRoute>
                <PaginationProvider key="casesSettlementsView">
                  <SettlementsView isRelatedToOtherEntity={CASES_SETTLEMENTS_LIST_TABLE_LABELS} />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>

      <Route
        path="case-types"
        element={
          <ProtectedRoute>
            <GlobalCaseTypesView />
          </ProtectedRoute>
        }
      />

      <Route
        path="questionnaires"
        element={
          <ProtectedRoute>
            <GlobalQuestionnairesView />
          </ProtectedRoute>
        }
      />

      <Route
        path="snippets"
        element={
          <ProtectedRoute>
            <GlobalFormSnippetsView />
          </ProtectedRoute>
        }
      />

      <Route path="clients">
        <Route
          index
          element={
            <ProtectedRoute>
              <PaginationProvider key="clientsView">
                <ClientsView />
              </PaginationProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="create-client"
          element={
            <ProtectedRoute>
              <ClientCreateFormContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path=":clientId"
          element={
            <ProtectedRoute>
              <ClientAccountView />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <ClientEditFormContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="activity"
            element={
              <ProtectedRoute>
                <div>Page is in development</div>
              </ProtectedRoute>
            }
          />
          <Route
            path="cases"
            element={
              <ProtectedRoute>
                <PaginationProvider>
                  <CasesView isRelatedToOtherEntity={CLIENTS_CASES_LIST_TABLE_LABELS} />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>

      <Route path="customers">
        <Route
          index
          element={
            <ProtectedRoute>
              <PaginationProvider key="customersView">
                <CustomersView />
              </PaginationProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path=":customerId"
          element={
            <ProtectedRoute>
              <CustomerAccountView />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <CustomerEditFormContainer />
              </ProtectedRoute>
            }
          />
          <Route path="activity" element={<div>Page is in development</div>} />
          <Route
            path="firms"
            element={
              <ProtectedRoute>
                <PaginationProvider key="customersFirmsView">
                  <ClientsView isRelatedToOtherEntity={CUSTOMER_FIRMS_LIST_TABLE_LABELS} />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="cases"
            element={
              <ProtectedRoute>
                <PaginationProvider key="customersCasesView">
                  <CasesView isRelatedToOtherEntity={CUSTOMER_CASES_LIST_TABLE_LABELS} />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="banking"
            element={
              <ProtectedRoute key="customersBankingView">
                <CustomerBankingView />
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>

      <Route path="defendants">
        <Route
          index
          element={
            <ProtectedRoute>
              <PaginationProvider key="defendantsView">
                <DefendantsView />
              </PaginationProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="create-defendant"
          element={
            <ProtectedRoute>
              <DefendantsCreateFormContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path=":defendantId"
          element={
            <ProtectedRoute>
              <DefendantAccountView />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <DefendantsEditFormContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="cases"
            element={
              <ProtectedRoute>
                <PaginationProvider>
                  <CasesView isRelatedToOtherEntity={FIRM_CASES_LIST_TABLE_LABELS} />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>

      <Route path="document-requests">
        <Route
          index
          element={
            <ProtectedRoute>
              <PaginationProvider key="documentsView">
                <DocumentsView />
              </PaginationProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="create-document-request"
          element={
            <ProtectedRoute>
              <CreateDocumentFormContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path=":documentId"
          element={
            <ProtectedRoute>
              <DocumentsAccountView />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <DocumentsEditFormContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="timeline"
            element={
              <ProtectedRoute>
                <DocumentsTimeline />
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>

      <Route path="signature-requests">
        <Route
          index
          element={
            <ProtectedRoute>
              <PaginationProvider key="signaturesView">
                <SignaturesView />
              </PaginationProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path=":signatureId"
          element={
            <ProtectedRoute>
              <SignaturesAccountView />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <SignaturesEditFormContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="timeline"
            element={
              <ProtectedRoute>
                <SignaturesTimeline />
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>

      <Route path="data-requests">
        <Route
          index
          element={
            <ProtectedRoute>
              <PaginationProvider key="dataView">
                <DataView />
              </PaginationProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="create-data-request"
          element={
            <ProtectedRoute>
              <CreateDataFormContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path=":dataId"
          element={
            <ProtectedRoute>
              <RequestStateProvider>
                <DataAccountView />
              </RequestStateProvider>
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <DataEditFormContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="timeline"
            element={
              <ProtectedRoute>
                <DataTimeline />
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>

      <Route path="deductions">
        <Route
          index
          element={
            <ProtectedRoute>
              <PaginationProvider key="deductionsView">
                <DeductionsView />
              </PaginationProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="create-deduction"
          element={
            <ProtectedRoute>
              <CreateDeductionFormContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path=":deductionId"
          element={
            <ProtectedRoute>
              <DeductionAccountView />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <DeductionsEditFormContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="timeline"
            element={
              <ProtectedRoute>
                <DeductionsTimeline />
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>

      <Route path="settlements">
        <Route
          index
          element={
            <ProtectedRoute>
              <PaginationProvider key="settlementsView">
                <SettlementsView />
              </PaginationProvider>
            </ProtectedRoute>
          }
        />
        <Route path="create-settlement" element={<CreateSettlementFormContainer />} />
        <Route
          path=":settlementId"
          element={
            <ProtectedRoute>
              <SettlementAccountView />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <SettlementsEditFormContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="details"
            element={
              <ProtectedRoute>
                <SettlementsDetailsView />
              </ProtectedRoute>
            }
          />
          <Route
            path="timeline"
            element={
              <ProtectedRoute>
                <SettlementsTimeline />
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>

      <Route
        path="communications"
        element={
          <ProtectedRoute>
            <CommunicationsView />
          </ProtectedRoute>
        }
      />
      <Route
        path="manage-account"
        element={
          <ProtectedRoute>
            <ManageAccountView />
          </ProtectedRoute>
        }
      >
        <Route index element={<Navigate to="home" />} />
        <Route
          path="home"
          element={
            <ProtectedRoute>
              <ManageAccountHomeTab />
            </ProtectedRoute>
          }
        />
        <Route
          path="activity"
          element={
            <ProtectedRoute>
              <div>Page is in development</div>
            </ProtectedRoute>
          }
        />
      </Route>

      <Route path="admins">
        <Route
          index
          element={
            <ProtectedRoute>
              <PaginationProvider key="adminsView">
                <AdminsView />
              </PaginationProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="create-admin"
          element={
            <ProtectedRoute>
              <AdminCreateFormContainer />
            </ProtectedRoute>
          }
        />

        <Route
          path=":adminId"
          element={
            <ProtectedRoute>
              <AdminAccountView />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <AdminEditFormContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="activity"
            element={
              <ProtectedRoute>
                <div>Page is in development</div>
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>

      <Route
        path="*"
        element={
          <ProtectedRoute>
            <NoMatchRoute />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
