import { Box } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { makeStyles } from 'tss-react/mui';
import { useFormContext } from 'react-hook-form';

import FormTextField from '../formFields/FormTextField';
import FormSelectField from '../formFields/FormSelectField';
import { DATA_TYPES_OPTIONS, EXPECTED_ANSWERS_OPTIONS } from '../../constants/constants';
import FormCheckboxField from '../formFields/FormCheckboxField';
import { QuestionnaireItemFormat, SelectOption } from '../../types';
import { FormSnippetEditFormSubmitProps } from '../../views/FormSnippetsView/types';
import IconButton from '../QuestionnaireFormBuilder/IconButton';
import FormTextFieldWithPopupInput from '../formFields/FormTextFieldWithPopupInput/FormTextFieldWithPopupInput';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    margin: '8px 0px 16px 0px',
    gap: '16px',
    alignItems: 'center',
  },
  inputFieldWrapper: {
    minWidth: '150px',
  },
}));

interface ItemProps {
  readonly booleanItems: SelectOption[];
  readonly itemIdx: number;
  readonly handleUpdateBooleanItems: (items: SelectOption[]) => void;
  readonly onDeleteClick: (index?: number | number[] | undefined) => void;
  readonly openWarningPopup: () => void;
}

const Item = ({
  booleanItems,
  itemIdx,
  handleUpdateBooleanItems,
  onDeleteClick,
  openWarningPopup,
}: ItemProps) => {
  const { classes } = useStyles();
  const { getValues, setValue, watch } = useFormContext<FormSnippetEditFormSubmitProps>();

  const item = watch(`data.items.${itemIdx}`);

  // TODO: Can we make it simpler?
  const handleDeleteButtonClick = () => {
    if (item.format === QuestionnaireItemFormat.Boolean) {
      const { data } = getValues();

      for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].dependsOnItem.booleanItemId === item.id) {
          setValue(`data.items.${i}.dependsOnItem.booleanItemId`, '', {
            shouldDirty: true,
          });

          setValue(`data.items.${i}.dependsOnItem.expectedValue`, '', {
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      }

      handleUpdateBooleanItems([...booleanItems.filter((el) => el.id !== item.id)]);
    }

    onDeleteClick(itemIdx);
  };

  const handleFormatChange = (_name: string, value: string) => {
    if (value === QuestionnaireItemFormat.Boolean) {
      handleUpdateBooleanItems([...booleanItems, { id: item.id, label: item.systemName }]);
    }

    if (item.format === QuestionnaireItemFormat.Boolean) {
      handleUpdateBooleanItems([...booleanItems.filter((el) => el.id !== item.id)]);
    }

    setValue(
      `data.items.${itemIdx}`,
      {
        id: item.id,
        code: item.code,
        systemName: item.systemName,
        questionText: item.questionText,
        immutable: item.immutable,
        dependsOnItem: item.dependsOnItem,
        format: value as QuestionnaireItemFormat,
        ...(value === QuestionnaireItemFormat.DateRange && { allowPartial: false }),
        ...(value === QuestionnaireItemFormat.Text && { placeholder: '' }),
      },
      {
        shouldDirty: true,
      },
    );
  };

  const handleBlurSystemName = (value: string) => {
    if (item.format === QuestionnaireItemFormat.Boolean) {
      const index = booleanItems.findIndex((el) => el.id === item.id);

      if (index !== -1 && booleanItems[index].label !== value) {
        const tmp = [...booleanItems];
        tmp[index].label = value;
        handleUpdateBooleanItems(tmp);
      }
    }
  };

  const handleDependsOnChange = (_name: string, value: string) => {
    if (item.id === value) {
      openWarningPopup();
      return;
    }

    if (!value) {
      setValue(`data.items.${itemIdx}.dependsOnItem.expectedValue`, '', {
        shouldDirty: true,
      });
    }

    setValue(`data.items.${itemIdx}.dependsOnItem.booleanItemId`, value, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return (
    <Box className={classes.container}>
      <DragIndicatorIcon htmlColor="rgba(255, 255, 255, 0.3)" />
      <Box className={classes.inputFieldWrapper}>
        <FormSelectField
          options={booleanItems}
          name={`data.items.${itemIdx}.dependsOnItem.booleanItemId`}
          label="Depends On"
          disabled={!booleanItems.length}
          customHandleChange={handleDependsOnChange}
        />
      </Box>
      <Box className={classes.inputFieldWrapper}>
        <FormSelectField
          options={EXPECTED_ANSWERS_OPTIONS}
          name={`data.items.${itemIdx}.dependsOnItem.expectedValue`}
          label="Expected Answer"
          disabled={!item.dependsOnItem?.booleanItemId}
        />
      </Box>
      <Box className={classes.inputFieldWrapper}>
        <FormTextField
          name={`data.items.${itemIdx}.systemName`}
          label="System Name *"
          onBlur={handleBlurSystemName}
        />
      </Box>
      <Box className={classes.inputFieldWrapper}>
        <FormSelectField
          options={DATA_TYPES_OPTIONS}
          name={`data.items.${itemIdx}.format`}
          label="Format"
          customHandleChange={handleFormatChange}
        />
      </Box>
      <Box width="100%">
        <FormTextFieldWithPopupInput
          label="Question Text *"
          name={`data.items.${itemIdx}.questionText`}
          value={item.questionText}
          handleChange={(value) =>
            setValue(`data.items.${itemIdx}.questionText`, value, {
              shouldDirty: true,
              shouldValidate: true,
            })
          }
        />
      </Box>
      <Box className={classes.inputFieldWrapper}>
        <FormTextField name={`data.items.${itemIdx}.code`} label="Code *" />
      </Box>
      <Box className={classes.inputFieldWrapper}>
        {item.format === QuestionnaireItemFormat.DateRange && (
          <FormCheckboxField name={`data.items.${itemIdx}.allowPartial`} label="Allow Partial" />
        )}
        {item.format === QuestionnaireItemFormat.Text && (
          <FormTextField name={`data.items.${itemIdx}.placeholder`} label="Placeholder" />
        )}
      </Box>
      <IconButton buttonType="delete" onClick={handleDeleteButtonClick} />
    </Box>
  );
};

export default Item;
