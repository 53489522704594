import { useEffect } from 'react';
import { Theme, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import FormTextField from '../FormTextField';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.light,
      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))',
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.secondary.main,
    },
  },
  dialogActions: {
    padding: '16px 8px',
  },
  dialogButton: {
    color: theme.palette.info.main,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
    },

    '&:hover': {
      background: 'rgba(144, 202, 249, 0.08)',
    },
  },
}));

interface EditTextFieldPopupProps {
  readonly isOpen: boolean;
  readonly value: string;
  readonly onMainButtonClick: (value: string) => void;
  readonly onSecondaryButtonClick: () => void;
}

interface FormSubmitProps {
  value: string;
}

const schema = yup
  .object()
  .shape({
    value: yup
      .string()
      .max(1000, 'The maximum field length is 1000 characters.')
      .required('Required *'),
  })
  .required();

const EditTextFieldPopup = ({
  isOpen,
  value,
  onMainButtonClick,
  onSecondaryButtonClick,
}: EditTextFieldPopupProps) => {
  const { classes } = useStyles();

  const formMethods = useForm<FormSubmitProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { value: '' },
  });

  const {
    formState: { isDirty, isValid },
    handleSubmit,
    reset,
  } = formMethods;

  const handleSaveButtonClick = (values: FormSubmitProps) => {
    onMainButtonClick(values.value);
  };

  useEffect(() => {
    if (isOpen)
      reset({
        value,
      });
  }, [isOpen]);

  return (
    <Dialog
      className={classes.dialogContainer}
      fullWidth
      maxWidth={'xs'}
      open={isOpen}
      onClose={onSecondaryButtonClick}
    >
      <DialogTitle>{'Question Text'}</DialogTitle>
      <DialogContent>
        <FormProvider {...formMethods}>
          <form>
            <FormTextField name="value" label="Question Text" isMultiline />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.dialogButton}
          onClick={handleSubmit(handleSaveButtonClick)}
          disabled={!isValid || !isDirty}
        >
          {'Save'}
        </Button>
        <Button className={classes.dialogButton} onClick={onSecondaryButtonClick}>
          {'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTextFieldPopup;
