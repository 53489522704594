import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';

import SaveIcon from '@mui/icons-material/Save';
import { Box, useTheme } from '@mui/material';

import { FieldHeadline } from './styled';
import { DefendantFormSubmitProps } from './types';

import { createDefendantRecord } from '../../api/defendantsApi/defendantsApi';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import GoBackButton from '../../components/buttons/GoBackButton';
import FormTextField from '../../components/formFields/FormTextField';
import { DEFENDANT_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { defendantFormSchema } from '../../helpers/formSchemas/formSchemas';
import useSnackBar from '../../hooks/useSnackBar';
import FormAutocompleteField from '../../components/formFields/FormAutocompleteField';
import { handleGetFirms, mapFirmsQueryResults } from '../CasesView/helpers';

const DefendantsCreateFormContainer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setAlert } = useSnackBar();

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(defendantFormSchema),
    defaultValues: DEFENDANT_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = formMethods;

  const onSubmitForm = (values: DefendantFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      referenceId: values.referenceId,
      name: values.name,
      firmId: values.firm.id,
    };

    createDefendantRecord(submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);
          navigate(`/defendants/${res.data.id}/home`);

          setAlert((prev) => ({
            ...prev,
            message: 'Defendant created successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => reset();

  return (
    <>
      <GoBackButton />
      <Box display="flex" bgcolor={theme.palette.primary.dark} width="100%">
        <FormProvider {...formMethods}>
          <form style={{ width: '100%', padding: '20px' }}>
            <Box display="flex" width="100%" gap="32px">
              <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                <FieldHeadline>Identity</FieldHeadline>
                <Box mt="20px">
                  <FormTextField name="referenceId" label="Reference Id *" />
                </Box>
                <Box mt="20px">
                  <FormTextField name="name" label="Name *" />
                </Box>

                <FieldHeadline mt="50px">Firm</FieldHeadline>

                <Box mt="10px">
                  <FormAutocompleteField
                    name="firm"
                    label="Firm *"
                    optionsQuery={handleGetFirms}
                    mapQueryResults={mapFirmsQueryResults}
                  />
                </Box>
              </Box>
            </Box>

            <Box display="flex" justifyContent="space-between" mt="40px">
              <FormSubmitButton
                disabled={!isValid || isFormSubmitting}
                buttonIcon={<SaveIcon />}
                buttonText="Save"
                onClick={handleSubmit(onSubmitForm)}
              />
              {isDirty && <FormCancelButton onClick={handleCancelChanges} />}
            </Box>
          </form>
        </FormProvider>
      </Box>
    </>
  );
};

export default DefendantsCreateFormContainer;
